<template>
  <div>
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <ModalFeedback
      v-if="showFeedbackModal"
      :title="$t('sharedPrograms.feedbackModal.title')"
      :message="feedback"
      @close="onCloseFeedbackModal"
    ></ModalFeedback>
    <div class="mb-5" data-cy="programs-content">
      <div v-if="programs.length === 0">
        <h3 class="text-center" data-cy="noProgram">
          {{ $t("sharedPrograms.noProgram") }}
        </h3>
      </div>
      <div v-else>
        <div class="row programs-header">
          <div class="col-2">
            <div>{{ $t("sharedPrograms.name") }}</div>
          </div>
          <div class="col-2">
            <div>{{ $t("sharedPrograms.createdBy") }}</div>
          </div>
          <div class="col-2">
            <div>{{ $t("sharedPrograms.createdAt") }}</div>
          </div>
          <div class="col-2">
            <div>{{ $t("sharedPrograms.shared") }}</div>
          </div>
          <div class="col-2">
            <div>{{ $t("sharedPrograms.status.title") }}</div>
          </div>
        </div>
        <div v-for="program in programsSorted" :key="program.id" data-cy="programList">
          <div :id="`accordion-programs-item-${program.id}`">
            <div class="card">
              <div
                :id="`accordion-programs-item-header-${program.id}`"
                class="card-header d-flex align-items-center"
              >
                <div class="col-2">
                  <div :data-cy="`protection-${program.id}`">
                    <span :data-cy="`program-name-${program.id}`">
                      {{ program.name + " #" + program.id }}
                    </span>
                  </div>
                </div>
                <div class="col-2">
                  <div v-if="program.user.first_name">
                    {{ program.user.first_name + " " + program.user.last_name }}
                  </div>
                  <div v-else>
                    {{ program.user.username }}
                  </div>
                </div>
                <div class="col-2">{{ format(program.created_at) }}</div>
                <div class="col-2">
                  <div>
                    {{
                      genuineProgramShared(program).length +
                      " " +
                      $tc("sharedPrograms.share", genuineProgramShared(program).length)
                    }}
                  </div>
                </div>
                <div class="col-2">
                  <div>
                    {{ $t("sharedPrograms.status." + program.status) }}
                  </div>
                  <div
                    v-if="program.has_beneficiaries"
                    class="program-beneficiaries"
                    data-cy="program_with_beneficiaries"
                  >
                    {{ $t("sharedPrograms.withBeneficiaries") }}
                  </div>
                </div>
                <div class="col-2">
                  <div class="programs-icons">
                    <i
                      class="icon-pencil"
                      :data-cy="`program-name-click-${program.id}`"
                      @click.prevent="onProgramNameClick(program.id)"
                    ></i>
                    <i
                      v-if="!isProgramFinalised(program)"
                      class="icon-volume-medium"
                      :title="$t(`programParameters.title`)"
                      :data-cy="`icon-parameters-program-${program.id}`"
                      @click.prevent="onIconClick(program.id)"
                    ></i>
                    <i
                      :class="[
                        isProgramShow(program)
                          ? 'icon-chevron-down'
                          : 'icon-chevron-right',
                      ]"
                      :data-cy="`accordion-content-${program.id}`"
                      @click.prevent="onAccordeonClick(program.id)"
                    ></i>
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div
                  v-show="isProgramShow(program)"
                  :id="`accordion-programs-item-collapse-${program.id}`"
                  class="sharingListColor"
                  :class="['collapse', isProgramShow(program) ? 'show' : 'hide']"
                  data-cy="sharingContent"
                >
                  <div
                    v-if="genuineProgramShared(program).length === 0"
                    class="programs-no-shared"
                    data-cy="noSharing"
                  >
                    <h5 class="text-center">
                      {{ $t("sharedPrograms.noSharing") }}
                    </h5>
                  </div>
                  <div v-else>
                    <div
                      v-for="programShared in genuineProgramShared(program)"
                      :key="programShared.id"
                    >
                      <ShareListItem
                        :program-shared="programShared"
                        @update="getListPrograms"
                        @feedback="setFeedback"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShareListItem from "@/components/ShareListItem"
import DateService from "@/services/technical/DateService"
import StatusService from "@/services/business/StatusService"
import ModalFeedback from "@/components/modal/ModalFeedback"
import { mapGetters } from "vuex"

export default {
  name: "SharedPrograms",
  components: {
    ShareListItem,
    ModalFeedback,
  },
  data() {
    return {
      programs: [],
      isLoading: true,
      showFeedbackModal: false,
      feedback: "",
      showProgramIds: [],
      currentProgramId: null,
    }
  },
  computed: {
    ...mapGetters("program", ["getProgram", "getProgramStatus", "listPrograms"]),
    ...mapGetters("config", ["getCurrentLang"]),
    programsSorted() {
      if (this.$route.name === "programs_demo") {
        return this.programs.filter((program) => program.status === "DRAFT")
      }
      return this.programs
    },
  },
  methods: {
    onAccordeonClick(id) {
      if (!this.showProgramIds.includes(id)) {
        this.showProgramIds.push(id)
      } else {
        this.showProgramIds = this.showProgramIds.filter((i) => i !== id)
      }
    },
    isProgramShow(program) {
      return this.showProgramIds.includes(program.id)
    },
    isProgramFinalised(program) {
      return program.status === "ACTIVE"
    },
    async getListPrograms() {
      await this.$store.dispatch("program/listPrograms")
      this.programs = this.listPrograms
    },
    onProgramNameClick(id) {
      this.$router.push({ name: "programHome", params: { id: id } })
    },
    async onIconClick(programId) {
      this.$store.dispatch("program/changeCurrentProgramId", programId)
      let statusRoute = StatusService.getStatusRoute(this.getProgramStatus)
      await this.$router.push({
        name: statusRoute,
        params: { programId: programId },
      })
    },
    format(date) {
      return DateService.format(date, "DD MMMM YYYY", this.getCurrentLang)
    },
    setFeedback(feedback) {
      this.feedback = feedback
      this.showFeedbackModal = true
    },
    onCloseFeedbackModal() {
      this.showFeedbackModal = false
    },
    genuineProgramShared(program) {
      return program.program_shared.filter(
        (programShared) => !programShared.user.email.includes(programShared.token)
      )
    },
  },
  async created() {
    try {
      await this.getListPrograms()
      this.isLoading = false
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
}
</script>
<style lang="scss" scoped>
.programs-header {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: $secondary;
  font-size: 18px;
}

.card {
  border: 0;
}

.card-header {
  background: white;
  border: initial;
  border-bottom: 1px solid $light-gray;
  border-radius: 0;
  font-size: 18px;
}

.program-beneficiaries {
  color: $secondary;
  font-size: 12px;
}

$nbIcons: 3;
$iconsWidth: 18px;
$nbGaps: $nbIcons - 1;

.programs-icons {
  display: flex;
  justify-content: flex-end;
  gap: calc((100% - (#{$nbIcons} * #{$iconsWidth})) / #{$nbGaps});
  i {
    font-size: $iconsWidth;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}

.sharingListColor {
  background: $quaternary;
  font-size: 18px;
  .programs-no-shared {
    padding: 16px;
    h5 {
      margin: 0;
    }
  }
}

/deep/ .list-group-item {
  background: none;
  padding: 8px 20px;
  border: initial;
  border-bottom: 1px solid $light-gray;
}
</style>

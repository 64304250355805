<template>
  <header class="partners-header">
    <h1 class="partners-header__title" data-cy="partners-title">
      {{ $t($router.currentRoute.meta.partnerTitle) }}
    </h1>
    <button
      v-if="$router.currentRoute.name === 'partners'"
      @click.prevent="goToNewPartner"
      class="btn btn-primary"
      data-cy="new-partner-button"
    >
      {{ $t("partners.new") }}
    </button>
  </header>
</template>

<script>
export default {
  name: "HeaderPartner",
  methods: {
    goToNewPartner() {
      this.$router.push({ name: "form_new_partner" })
    },
  },
}
</script>

<style scoped lang="scss">
.partners-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 100px;
  margin-bottom: 25px;
  padding: 0 38px;

  &__title {
    font-family: "Effra", sans-serif;
    font-size: 24px;
  }
}
</style>

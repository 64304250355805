var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"program-timeline"},[(_vm.program.offers.length > 0)?_c('timeline',_vm._l((_vm.program.offers),function(offer,index){return _c('div',{key:offer.id,staticClass:"program-timeline__div",attrs:{"data-cy":"offer-content"}},[_c('timeline-title',{attrs:{"bg-color":_vm.bgColor(_vm.offerStates[offer.id]),"line-color":_vm.lineColor(_vm.offerStates[offer.id]),"icon-size":"large","data-cy":("title-offer-" + (offer.id))}},[(
            _vm.offerStates[offer.id] === _vm.check_state ||
            _vm.offerStates[offer.id] === _vm.current_and_check_state
          )?_c('i',{staticClass:"icon-check",attrs:{"data-cy":("offer-" + (index + 1) + "-icon-check")}}):_vm._e(),(_vm.clickable(_vm.offerStates[offer.id]))?_c('router-link',{staticClass:"timeline-field",attrs:{"to":_vm.offerRouteLink(offer),"data-cy":("offer-" + (index + 1) + "-" + (_vm.isObjectActive(offer)) + "-button")}},[_vm._v(" "+_vm._s(_vm.offerTitle(index))+" ")]):_c('p',{staticClass:"timeline-field no-clickable",attrs:{"data-cy":("offer-" + (index + 1))}},[_vm._v(" "+_vm._s(_vm.offerTitle(index))+" ")])],1),_vm._l((offer.program_products),function(product,indexPp){return _c('timeline-item',{key:product.id,attrs:{"bg-color":_vm.bgColor(_vm.productStates[product.id]),"line-color":_vm.lineColor(_vm.productStates[product.id]),"icon-size":"medium"}},[(
            _vm.productStates[product.id] === _vm.check_state ||
            _vm.productStates[product.id] === _vm.current_and_check_state
          )?_c('i',{staticClass:"icon-check",attrs:{"data-cy":("product-id-" + (indexPp + 1) + "-icon-check")}}):_vm._e(),(_vm.clickable(_vm.productStates[product.id]))?_c('router-link',{staticClass:"timeline-field",attrs:{"to":_vm.programProductRouteLink(offer, product),"data-cy":("product-id-" + (product.id) + "-" + (_vm.isObjectActive(product)) + "-button")}},[_vm._v(" "+_vm._s(product.name)+" ")]):_c('p',{staticClass:"timeline-field no-clickable",attrs:{"data-cy":("product-id-" + (product.id))}},[_vm._v(" "+_vm._s(product.name)+" ")])],1)}),(!_vm.isOfferExternal(offer.id))?_c('timeline-item',{attrs:{"bg-color":_vm.bgColor(_vm.synthesisStates[offer.id]),"line-color":_vm.lineColor(_vm.synthesisStates[offer.id]),"icon-size":"medium"}},[(
            _vm.synthesisStates[offer.id] === _vm.check_state ||
            _vm.synthesisStates[offer.id] === _vm.current_and_check_state
          )?_c('i',{staticClass:"icon-check",attrs:{"data-cy":("offer-synthesis-" + (index + 1) + "-icon-check")}}):_vm._e(),(_vm.clickable(_vm.synthesisStates[offer.id]))?_c('router-link',{staticClass:"timeline-field",attrs:{"to":_vm.pricingRangeRouteLink(offer),"data-cy":("offer-synthesis-" + (index + 1) + "-button")}},[_vm._v(" "+_vm._s(_vm.$t("programs.timeline.synthesis").toUpperCase())+" ")]):_c('p',{staticClass:"timeline-field no-clickable",attrs:{"data-cy":("offer-synthesis-" + (index + 1))}},[_vm._v(" "+_vm._s(_vm.$t("programs.timeline.synthesis").toUpperCase())+" ")])],1):_vm._e()],2)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
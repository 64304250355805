<template>
  <div class="product-description__container" data-cy="offer-details">
    <div class="product-description__input-div">
      <FormRowInput
        :name="`offer-${offerInProgress.id}-name`"
        :label="$t('programParameters.description.nameLabel') + `${offerNumber}`"
        :label-class="['col-sm-12', 'product-description__label']"
        :control-class="['col-sm-12']"
        :placeholder="$t('programParameters.description.productNamePlaceholder')"
        :debounce-input="true"
        @input="onUpdate(offerInProgress.content_id)"
        v-model="form.name"
      ></FormRowInput>
    </div>
    <div class="product-description__input-div">
      <FormRow
        :label="$t('programParameters.description.descriptionLabel') + `${offerNumber}`"
        :label-class="['col-sm-12', 'product-description__label']"
        :data-cy="`offer-${offerInProgress.id}-description`"
      ></FormRow>
      <FormTextArea
        :name="`offer-${offerInProgress.id}-description`"
        :placeholder="$t('programParameters.description.productDescriptionPlaceholder')"
        :debounce-input="true"
        @input="onUpdate(offerInProgress.content_id)"
        v-model="form.description"
      ></FormTextArea>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "OfferDescription",
  props: {
    offerInProgress: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("offer", ["getOffer", "getOfferNumberInProgram"]),
    offerNumber() {
      return this.getOfferNumberInProgram(this.offerInProgress.id)
    },
  },
  data() {
    return {
      form: {
        description: this.offerInProgress.description,
        name: this.offerInProgress.name,
      },
    }
  },
  watch: {
    // Update form values as soon as active product changes
    offerInProgress(value) {
      this.form.name = this.offerInProgress.name
      this.form.description = this.offerInProgress.description
    },
  },
  methods: {
    async onUpdate(contentId) {
      this.retrieveOfferBasisInformations(this.offerInProgress.id)
      const payload = {
        offer_content_id: contentId,
        details: {
          name: this.form.name,
          description: this.form.description,
        },
      }
      await this.$store.dispatch("offer/updateOfferContent", payload)
    },
    retrieveOfferBasisInformations(offerId) {
      if (this.form.name === "") this.form.name = this.getOffer(offerId).name
      if (this.form.description === "")
        this.form.description = this.getOffer(offerId).description
    },
  },
}
</script>

<style scoped></style>

<template>
  <div class="offer-flitter-page" data-cy="flitter-product">
    <div class="offer-flitter">
      <div class="offer-flitter-content">
        <img
          src="https://res.cloudinary.com/caarea/image/upload/v1677772189/cs/assets/car_vag23k.svg"
          alt=""
        />
        <div class="offer-flitter-txt">
          <div>
            <b>{{ programProduct.name }}</b>
          </div>
          <div>{{ programProduct.description }}</div>
        </div>
      </div>
      <button
        v-if="isCustomizationMode"
        class="btn btn-primary btn-block"
        @click="onValidate"
      >
        {{ $t("button.next") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Flitter",
  props: {
    programProductId: { type: [Number, String], required: true },
    isCustomizationMode: { type: Boolean, required: false },
  },
  data() {
    return {
      programProduct: null,
    }
  },
  computed: {
    ...mapGetters("programProduct", ["getProgramProductById"]),
  },
  methods: {
    onValidate() {
      this.$emit("validate-program-product")
    },
  },
  created() {
    this.programProduct = this.getProgramProductById(this.programProductId)
  },
}
</script>

<style lang="scss" scoped>
.offer-flitter-page {
  background-color: $background-program;

  .offer-page__header {
    margin-bottom: 0.3rem;
  }

  .offer-flitter {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-end;
    padding: 5rem 1rem;
    background-color: #fff;
  }

  .offer-flitter-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-items: center;
    align-items: center;
    gap: 24px;
    width: 100%;
  }

  .offer-flitter-txt {
    font-size: 22px;
    line-height: 35px;
    letter-spacing: 0.09em;
    text-align: center;
  }
}
</style>

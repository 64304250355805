<template>
  <teatree
    class="d-flex align-items-baseline ml-1"
    :roots="productDomains"
    v-if="!isLoading"
  >
    <template slot="node-toggle" slot-scope="{ node }">
      <span
        class="teatree-node-item-icon mr-1 font-size-14"
        :class="[node.icon]"
        aria-hidden="true"
      ></span>
    </template>
    <template slot="node-name" slot-scope="{ node }">
      <node-name
        :node="node"
        :handle-node-left-click="() => onNodeLeftClick(node)"
        :handle-node-right-click="() => {}"
        :data-cy="`tree-${node.key}`"
      />
    </template>
  </teatree>
  <spinner :no-message="true" v-else></spinner>
</template>

<script>
import { Teatree, NodeName } from "vue-teatree"
import { mapGetters } from "vuex"

export default {
  name: "TreeProductDomains",
  components: {
    Teatree,
    NodeName,
  },
  props: {
    domain: { Type: String, required: true },
  },
  data() {
    return {
      isLoading: true,
      defaultIconName: "icon-chevron-right",
      engineFilters: ["electrique", "thermique"],
      icons: {
        protectdomain: "icon-home",
        vehicule: "icon-caarea-car",
        capital: "icon-caarea-finance",
        personnes: "icon-caarea-people",
        assurance_automobile: "icon-caarea-people",
      },
      productDomains: null,
    }
  },
  watch: {
    getFilters() {
      this.buildTree()
    },
    getProgramTags: {
      handler() {
        this.buildTree()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("reference", ["getProductDomains", "getDomainSubDomains"]),
    ...mapGetters("product", [
      "getFilters",
      "hasProductFilter",
      "hasAnyEngineFilter",
      "hasEngineFilter",
      "getProgramTags",
    ]),
    ...mapGetters("basket", ["basketIsEmpty"]),
    buildProductDomains() {
      let productDomains = {
        key: "protectdomain",
        name: this.$t("treeMenu.protection_fields"),
        type: "root",
        icon: this.icons.protectdomain,
        show: true,
        showChildren: true,
        selected: true,
        children: [],
      }

      for (const domainKey in this.getProductDomains) {
        productDomains.children.push(this.buildProductDomain(domainKey))
      }
      return [productDomains]
    },
  },
  methods: {
    async onNodeLeftClick(node) {
      if (node.type !== "root") {
        if (node.hasOwnProperty("engineFilter")) {
          await this.$store.dispatch("product/setProgramTag", {
            name: "engine",
            value: node.engineFilter,
          })
        }
        if (node.key === "usage_quotidien") {
          await this.$store.dispatch("product/setProgramTag", {
            name: "engine",
            value: "",
          })
        }
        await this.$store.dispatch("product/setFilter", node)
      } else {
        if (this.basketIsEmpty) {
          await this.$store.dispatch("product/setAllFilters")
          await this.$store.dispatch("product/resetProgramTags")
        } else {
          await this.$store.dispatch("product/setAllFilters")
        }
      }
    },
    isLeaf(node) {
      return !node.children
    },
    buildProductSubDomain(domainKey, subdomainKey) {
      const subdomain = this.getDomainSubDomains(domainKey)[subdomainKey]
      let subdomainData = {
        key: subdomainKey,
        domainKey: domainKey,
        subdomainKey: subdomainKey,
        name: subdomain.label,
        show: true,
        showChildren: true,
        selected: this.hasProductFilter(domainKey, subdomainKey),
        children: [],
      }
      if (subdomainKey === "usage_quotidien") {
        for (const engineFilter of this.engineFilters) {
          subdomainData.children.push({
            key: `usage_quotidien_${engineFilter}`,
            domainKey: domainKey,
            subdomainKey: subdomainKey,
            name: this.$t(`products.usage_quotidien_${engineFilter}`),
            show:
              !this.hasAnyEngineFilter(this.engineFilters) ||
              this.hasEngineFilter(engineFilter),
            engineFilter: engineFilter,
            showChildren: true,
            selected:
              this.hasEngineFilter(engineFilter) &&
              this.hasProductFilter(domainKey, subdomainKey),
            children: [],
          })
        }
      }
      return subdomainData
    },
    buildProductDomain(domainKey) {
      const domain = this.getProductDomains[domainKey]

      let domainData = {
        key: domainKey,
        domainKey: domainKey,
        subdomainKey: null,
        name: domain.label,
        icon: this.icons[domainKey],
        show: true,
        showChildren: true,
        selected: this.hasProductFilter(domainKey),
        children: [],
      }

      for (const subdomainKey in domain.subdomains) {
        if (subdomainKey !== "assurance_auto_au_km") {
          domainData.children.push(this.buildProductSubDomain(domainKey, subdomainKey))
        }
      }

      return domainData
    },
    buildTree() {
      this.productDomains = this.buildProductDomains
    },
  },
  async created() {
    try {
      await this.$store.dispatch("reference/loadProductDomains")
    } catch (e) {
      console.error("failed: ", e)
    } finally {
      await this.$store.dispatch("product/setAllFilters")
      this.buildTree()
      this.isLoading = false
    }
  },
}
</script>

<style lang="scss">
.teatree {
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.09em;
  color: $bleucaarea;
  cursor: pointer;

  .teatree-node-item {
    .teatree-node-item-icon {
      align-self: baseline;
    }

    &:active,
    &.teatree-node-item-selected,
    &:hover {
      font-family: "Gilroy SemiBold", sans-serif;
    }
  }

  .teatree-node-item-name-padded-leaf {
    margin-left: 28px;
  }

  &
    > div
    > .teatree-node
    > .teatree-node-item
    > .teatree-node-item-icon
    + .teatree-node-item-name {
    text-decoration: underline;
  }
}
</style>

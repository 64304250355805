export default {
  wear_egvn: {
    grille_vetuste_1: {
      row_span: 7,
      grid_info: [
        { kilometrage: "100 000", coefficient: 0 },
        { kilometrage: "120 000", coefficient: 10 },
        { kilometrage: "140 000", coefficient: 20 },
        { kilometrage: "160 000", coefficient: 30 },
        { kilometrage: "180 000", coefficient: 40 },
        { kilometrage: "180 000", coefficient: 50 },
      ],
    },
  },
  wear_egvn_elec: {
    grille_vetuste_1: {
      row_span: 7,
      grid_info: [
        { kilometrage: "100 000", coefficient: 0 },
        { kilometrage: "120 000", coefficient: 10 },
        { kilometrage: "140 000", coefficient: 20 },
        { kilometrage: "160 000", coefficient: 30 },
        { kilometrage: "180 000", coefficient: 40 },
        { kilometrage: "180 000", coefficient: 50 },
      ],
    },
  },
  wear_vo: {
    grille_vetuste_1: {
      row_span: 7,
      grid_info: [
        { kilometrage: "100 000", coefficient: 0 },
        { kilometrage: "120 000", coefficient: 10 },
        { kilometrage: "140 000", coefficient: 20 },
        { kilometrage: "160 000", coefficient: 30 },
        { kilometrage: "180 000", coefficient: 40 },
        { kilometrage: "180 000", coefficient: 50 },
      ],
    },
    grille_vetuste_2: {
      row_span: 6,
      grid_info: [
        { kilometrage: "75 000", coefficient: 0 },
        { kilometrage: "100 000", coefficient: 15 },
        { kilometrage: "125 000", coefficient: 30 },
        { kilometrage: "150 000", coefficient: 45 },
        { kilometrage: "150 000", coefficient: 60 },
      ],
    },
  },
}

import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

// STATES (snake_case)
const state = () => ({
  base100: null,
  coef: null,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_BASE100(state, matrices) {
    state.base100 = matrices
  },
  SET_COEF(state, matrices) {
    state.coef = matrices
  },
}

// ACTIONS (camelCase)
const actions = {
  async initVn({ commit }) {
    try {
      let matrices = await HttpService.get(UrlService.render("pricingMatricesVn"))
      commit("SET_BASE100", matrices.base100)
      commit("SET_COEF", matrices.coef_vn)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async initVo({ commit }) {
    try {
      let matrices = await HttpService.get(UrlService.render("pricingMatricesVo"))
      commit("SET_BASE100", matrices.base100)
      commit("SET_COEF", matrices.coef_vo)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  /**
   * Base 100
   */
  getBase100: (state, getter) => (key, coefKey) => {
    if (key === "vehicle_km") {
      return getter.listBase100VehicleKms.filter((c) => c.key === coefKey)[0]
    } else {
      return getter.listBase100(key).filter((c) => c.key === coefKey)[0]
    }
  },
  listBase100: (state) => (key) =>
    Object.keys(state.base100[key]).map((k) => {
      return { key: k, label: state.base100[key][k].label }
    }),
  listBase100Countries: (state, getters) => getters.listBase100("country"),
  listBase100Makes: (state, getters) => getters.listBase100("make"),
  listBase100VehiclePrices: (state, getters) => getters.listBase100("vehicle_price"),
  listBase100VehicleOrigin: (state, getters) => getters.listBase100("vehicle_origin"),
  listBase100SparePartsOrigin: (state, getters) =>
    getters.listBase100("spare_parts_origin"),
  listBase100Energy: (state, getters) => getters.listBase100("energy"),
  listBase100SpecialFactor: (state, getters) => getters.listBase100("special_factor"),
  listBase100PowerFactor: (state, getters) => getters.listBase100("power_factor"),
  listBase100ManufacturerWarranty: (state, getters) =>
    getters.listBase100("manufacturer_warranty"),
  listBase100VehicleAges: (state, getters) => getters.listBase100("vehicle_age"),
  listBase100VehicleKms: (state) => {
    const first_key = Object.keys(state.base100.vehicle_age)[0]
    return Object.keys(state.base100.vehicle_age[first_key])
      .filter((k) => k !== "label")
      .map((k) => {
        return {
          key: k,
          label: state.base100.vehicle_age[first_key][k].label,
        }
      })
  },
  /**
   * Coef
   */
  getCoefCoverageDuration: (state, getter) => (coefKey) =>
    getter.listCoefCoverageDurations.filter((c) => c.key === coefKey)[0],
  getCoefCoverageKm: (state, getter) => (coefKey) =>
    getter.listCoefCoverageKms.filter((c) => c.key === coefKey)[0],
  getCoefCoverageTypes: (state, getter) => (coefKey) =>
    getter.listCoefCoverageTypes.filter((c) => c.key === coefKey)[0],
  listCoefCoverageDurations: (state) =>
    Object.keys(state.coef.coverage_duration).map((k) => {
      return {
        key: k,
        label: state.coef.coverage_duration[k].label,
      }
    }),
  listCoefCoverageKms: (state, getters, rootState, rootGetters) => {
    const first_key = Object.keys(state.coef.coverage_duration)[0]
    return Object.keys(state.coef.coverage_duration[first_key])
      .filter((k) => k !== "label")
      .map((k) => {
        const isDisabled =
          k === "illimite" && rootGetters["old_pricing/hasVariableUsageTaxi"]
        return {
          key: k,
          label: state.coef.coverage_duration[first_key][k].label,
          $isDisabled: isDisabled,
        }
      })
  },
  listCoefCoverageTypes: (state) =>
    Object.keys(state.coef.coverage_type).map((k) => {
      return {
        key: k,
        label: state.coef.coverage_type[k].label,
      }
    }),
  /**
   * Variable
   */
  getVariableTransferability: (state, getter) => (coefKey) =>
    getter.listVariableTransferability.filter((c) => c.key === coefKey)[0],
  getVariableSalesMode: (state, getter) => (coefKey) =>
    getter.listVariableSalesModes.filter((c) => c.key === coefKey)[0],
  getVariableClaimsManager: (state, getter) => (coefKey) =>
    getter.listVariableClaimsManagers.filter((c) => c.key === coefKey)[0],
  getVariablePricingValidity: (state, getter) => (coefKey) =>
    getter.listVariablePricingValidity.filter((c) => c.key === coefKey)[0],
  getVariableLimit: (state, getter) => (coefKey) =>
    getter.listVariableLimit.filter((c) => c.key === coefKey)[0],
  getVariableClaimsNumber: (state, getter) => (coefKey) =>
    getter.listVariableClaimsNumbers.filter((c) => c.key === coefKey)[0],
  getVariableWarrantyEffectiveDate: (state, getter) => (coefKey) =>
    getter.listVariableWarrantyEffectiveDate.filter((c) => c.key === coefKey)[0],
  getVariableWear: (state, getter) => (coefKey) =>
    getter.listVariableWears.filter((c) => c.key === coefKey)[0],
  getVariableRefund: (state, getter) => (coefKey) =>
    getter.listVariableRefunds.filter((c) => c.key === coefKey)[0],
  getVariableUsage: (state, getter) => (coefKey) =>
    getter.listVariableUsages.filter((c) => c.key === coefKey)[0],
  getVariableDeductible: (state, getter) => (deductibleType, coefKey) =>
    getter
      .listVariableDeductibleByType(deductibleType)
      .filter((c) => c.key === coefKey)[0],
  getVariableDeductibleType: (state, getter) => (coefKey) =>
    getter.listVariableDeductibleType.filter((c) => c.key === coefKey)[0],
  getVariableDeductibleCurrency: (state, getter) => (coefKey) =>
    getter.listVariableDeductibleCurrency.filter((c) => c.key === coefKey)[0],
  getVariableDeductiblePercent: (state, getter) => (coefKey) =>
    getter.listVariableDeductiblePercent.filter((c) => c.key === coefKey)[0],
  getVariableLimitLoyability: (state, getter) => (coefKey) =>
    getter.listVariableLimitLoyability.filter((c) => c.key === coefKey)[0],
  getVariableDiscountType: (state, getter) => (coefKey) =>
    getter.listVariableDiscountType.filter((c) => c.key === coefKey)[0],
  getVariableDiscount: (state, getter) => (discountType, coefKey) =>
    getter.listVariableDiscountByType(discountType).filter((c) => c.key === coefKey)[0],
  getVariableVehiclePreparation: (state, getter) => (coefKey) =>
    getter.listVariableVehiclePreparation.filter((c) => c.key === coefKey)[0],
  listVariableTransferability: (state) =>
    Object.keys(state.coef.transferability).map((k) => {
      return {
        key: k,
        label: state.coef.transferability[k].label,
      }
    }),
  listVariableSalesModes: (state) =>
    Object.keys(state.coef.sales_mode).map((k) => {
      return {
        key: k,
        label: state.coef.sales_mode[k].label,
      }
    }),
  listVariableClaimsManagers: (state) =>
    Object.keys(state.coef.claims_manager).map((k) => {
      return {
        key: k,
        label: state.coef.claims_manager[k].label,
      }
    }),
  listVariablePricingValidity: (state) =>
    Object.keys(state.coef.pricing_validity).map((k) => {
      return {
        key: k,
        label: state.coef.pricing_validity[k].label,
      }
    }),
  // listVariableLimit deductible_currency
  listVariableLimit: (state) => {
    const first_key = Object.keys(state.coef.deductible_currency)[0]
    const second_key = Object.keys(state.coef.deductible_currency[first_key]).filter(
      (k) => k !== "label"
    )[0]
    return Object.keys(state.coef.deductible_currency[first_key][second_key])
      .filter((k) => k !== "label")
      .map((k) => {
        return {
          key: k,
          label: state.coef.deductible_currency[first_key][second_key][k].label,
        }
      })
  },
  listVariableClaimsNumbers: (state) =>
    Object.keys(state.coef.claims_number).map((k) => {
      return {
        key: k,
        label: state.coef.claims_number[k].label,
      }
    }),
  listVariableWears: (state) =>
    Object.keys(state.coef.wear).map((k) => {
      return {
        key: k,
        label: state.coef.wear[k].label,
      }
    }),
  listVariableRefunds: (state) =>
    Object.keys(state.coef.refund).map((k) => {
      return {
        key: k,
        label: state.coef.refund[k].label,
      }
    }),
  listVariableUsages: (state) =>
    Object.keys(state.coef.usage).map((k) => {
      return {
        key: k,
        label: state.coef.usage[k].label,
      }
    }),
  listVariableDeductibleType: (state) => [
    { key: "currency", label: "€" },
    { key: "percent", label: "%" },
  ],
  listVariableDeductibleCurrency: (state) =>
    Object.keys(state.coef.deductible_currency).map((k) => {
      return {
        key: k,
        label: state.coef.deductible_currency[k].label,
      }
    }),
  listVariableDeductiblePercent: (state) =>
    Object.keys(state.coef.deductible_percentage).map((k) => {
      return {
        key: k,
        label: state.coef.deductible_percentage[k].label,
      }
    }),
  listVariableDeductibleByType:
    (state, getter) =>
    (deductibleType = null) => {
      if (deductibleType !== null && deductibleType === "percent") {
        return getter.listVariableDeductiblePercent
      }
      return getter.listVariableDeductibleCurrency
    },
  listVariableLimitLoyability: (state) =>
    Object.keys(state.coef.limit_of_loyability).map((k) => {
      return {
        key: k,
        label: state.coef.limit_of_loyability[k].label,
      }
    }),
  listVariableVehiclePreparation: (state) =>
    Object.keys(state.coef.vehicle_preparation).map((k) => {
      return {
        key: k,
        label: state.coef.vehicle_preparation[k].label,
      }
    }),
  listVariableDiscountType: (state) => [
    { key: "spare_parts", label: "Pièce" },
    { key: "spare_parts_and_ingredients", label: "Pièces et ingrédients" },
    { key: "bill", label: "Pied de facture" },
  ],
  listVariableDiscountSpareParts: (state) =>
    Object.keys(state.coef.spare_parts_discount).map((k) => {
      return {
        key: k,
        label: state.coef.spare_parts_discount[k].label,
      }
    }),
  listVariableDiscountSparePartsAndIngredients: (state) =>
    Object.keys(state.coef.spare_parts_and_ingredients_discount).map((k) => {
      return {
        key: k,
        label: state.coef.spare_parts_and_ingredients_discount[k].label,
      }
    }),
  listVariableDiscountBill: (state) =>
    Object.keys(state.coef.bill_discount).map((k) => {
      return {
        key: k,
        label: state.coef.bill_discount[k].label,
      }
    }),
  listVariableDiscountByType:
    (state, getter) =>
    (discountType = null) => {
      if (discountType !== null) {
        if (discountType === "spare_parts") {
          return getter.listVariableDiscountSpareParts
        }
        if (discountType === "spare_parts_and_ingredients") {
          return getter.listVariableDiscountSparePartsAndIngredients
        }
      }
      return getter.listVariableDiscountBill
    },
  listVariableWarrantyEffectiveDate: (state) =>
    Object.keys(state.coef.warranty_effective_date).map((k) => {
      return {
        key: k,
        label: state.coef.warranty_effective_date[k].label,
      }
    }),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

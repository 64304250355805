<template>
  <div class="program-end-page">
    <ProgramContentHeader
      :desc="$t('programs.programEnd.programContentHeaderDesc')"
      :program="this.program"
      :title="title"
      :is-share-button-visible="true"
    ></ProgramContentHeader>
    <div class="p-3 mt-2 program-end-div">
      <div class="program-end__end_configuration font-size-15">
        {{ $t("programs.programEnd.endConfiguration") }}
      </div>
      <div class="program-end__top text-center">
        <i class="program-end__icon__checkmark__circle icon-checkmark-circle"></i>
        <h1
          class="program-end__title mt-3 font-effra-normal primary-color"
          data-cy="program-end-title"
        >
          {{ $t("programs.programEnd.title") }}
        </h1>
        <div class="mb-3">
          <p class="font-size-18">
            {{ $t("programs.programEnd.text.program") }}
            <span class="program-end__span">{{ title }}</span>
            {{ $t("programs.programEnd.text.config") }}
            <br />
            <span v-if="!hasSharingToken">
              {{ $t("programs.programEnd.text.advice") }}
            </span>
          </p>
          <div class="program-end__bottom btn-position" v-if="!hasSharingToken">
            <button
              class="btn btn-light-gray font-roboto-medium p-2 mt-2"
              data-cy="program-end-button-pre-sale"
              type="button"
            >
              <router-link :to="{ name: 'presale' }">
                {{ $t("programs.programEnd.button.back") }}
              </router-link>
            </button>
            <button
              class="btn btn-light-gray p-2 text-left font-roboto-medium mt-2"
              data-cy="program-end-button-distribution"
              type="button"
              @click="onDistributionButtonClick"
            >
              <span>
                <i class="program-end__icon__play__circle icon-volume-medium mr-3"></i>
                {{ $t("programs.programEnd.button.distribution") }}
              </span>
            </button>
            <div class="flex-column-btn">
              <button
                class="btn btn-light-gray p-2 text-left font-roboto-medium mt-2"
                data-cy="program-end-button-program"
                type="button"
                @click="onSharedProgramClick"
              >
                <span>
                  <i class="program-end__icon__play__circle icon-play-circle mr-3"></i>
                  {{ $t("programs.programEnd.button.program") }}
                </span>
              </button>
              <button
                class="btn btn-light-gray p-2 text-left font-roboto-medium mt-2"
                data-cy="program-end-button-network"
                type="button"
                @click="onSharedNetworkClick"
              >
                <span>
                  <i class="program-end__icon__share2 icon-share2 mr-3"></i>
                  {{ $t("programs.programEnd.button.network") }}
                </span>
              </button>
              <button
                class="btn btn-light-gray p-2 text-left font-roboto-medium mt-2"
                data-cy="program-end-button-api"
                type="button"
                @click="onApiClick"
              >
                <span>
                  <i class="program-end__icon__play__circle icon-cog mr-3"></i>
                  {{ $t("programs.programEnd.button.api") }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramMixin from "../mixins/ProgramMixin"
import ProgramContentHeader from "./ProgramContentHeader"
import { mapGetters } from "vuex"
import StatusService from "../../../services/business/StatusService"

export default {
  name: "ProgramEnd",
  components: { ProgramContentHeader },
  props: {
    title: { type: String, required: true },
    program: { type: Object, required: true },
  },
  mixins: [ProgramMixin],
  computed: {
    ...mapGetters("auth", ["hasSharingToken"]),
    ...mapGetters("program", [
      "getSharedProgram",
      "getSharedProgramTestUrl",
      "getSharedNetworkTestUrl",
      "getProgramStatus",
    ]),
  },
  methods: {
    async onSharedProgramClick() {
      await this.$store.dispatch("program/createTestingProgram")
      await this.$store.dispatch(
        "program/getProgramSharedUrl",
        this.getSharedProgram.id
      )
      window.open(this.getSharedProgramTestUrl, "_blank")
    },
    async onSharedNetworkClick() {
      await this.$store.dispatch("program/createTestingProgram")
      await this.$store.dispatch(
        "program/getNetworkSharedUrl",
        this.getSharedProgram.id
      )
      window.open(this.getSharedNetworkTestUrl, "_blank")
    },
    async onApiClick() {
      window.open(`${process.env.VUE_APP_API_PORTAL_URL}fr/apis/apisales`, "_blank")
    },
    async onDistributionButtonClick() {
      let statusRoute = StatusService.getStatusRoute(this.getProgramStatus)
      await this.$router.push({
        name: statusRoute,
        params: { programId: this.program.id },
      })
    },
  },
  async created() {
    await this.$store.dispatch(
      "offer/setCurrentOfferId",
      this.program.offers.slice(-1)[0].id
    )
  },
}
</script>

<style lang="scss" scoped>
p {
  font-family: "Effra-light", sans-serif;
  letter-spacing: 0.09rem;
}

.program-end-page {
  background-color: $background-program;

  .program-end-div {
    background-color: #fff;
    margin-bottom: 2rem;

    .program-end__top {
      margin-top: 5rem;

      .program-end__icon__checkmark__circle {
        font-size: 10em;
        color: $bleucaarea;
      }

      .program-end__title {
        letter-spacing: 1.5px;
      }

      .program-end__span {
        font-family: "Gilroy SemiBold", sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        color: $bleucaarea;
        letter-spacing: 1.5px;
      }

      .program-end__bottom {
        margin-top: 5rem;
      }
    }

    .program-end__end_configuration {
      font-family: "Effra-light", sans-serif;
      font-weight: normal;
      text-transform: uppercase;
      color: $bleuc100;
      border-left: solid 2px $bleucaarea;
      padding: 0 0 0 5px;
    }
  }
}

button {
  grid-area: button;
  font-family: "Gilroy SemiBold", sans-serif;
  font-weight: bold;
  line-height: 1 !important;
}

.flex-column-btn {
  display: flex;
  flex-direction: column;
}

.btn-position {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.program-end__icon__checkmark__circle,
.program-end__icon__play__circle,
.program-end__icon__share2,
.program-end__icon__user {
  grid-area: icon;
  justify-self: start;
  align-self: start;
  font-weight: bold;
}

button {
  .disabled {
    cursor: not-allowed;
  }

  span {
    vertical-align: middle;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"offer-eligibility__title"},[_c('p',{staticClass:"font-effra-light font-size-15 text-uppercase"},[_vm._v(" "+_vm._s(_vm.eligibilityTitle)+" ")])]),_c('div',{staticClass:"vehicles-eligibility",attrs:{"data-cy":"vehicles-eligibility"}},[_c('div',{staticClass:"vehicles-eligibility__guidance",attrs:{"data-cy":"vehicles-eligibility-guidance"}},[_c('i',{class:[
          'vehicles-eligibility__guidance__icon',
          'icon-pushpin',
          'font-size-22' ]}),_c('div',[_c('p',{staticClass:"vehicles-eligibility__guidance__text1 font-size-16"},[_vm._v(" "+_vm._s(_vm.$t("offers.vehicles_eligibility.guidance_text_1"))+" ")]),_c('p',{staticClass:"vehicles-eligibility__guidance__text2 font-size-16"},[_vm._v(" "+_vm._s(_vm.$t("offers.vehicles_eligibility.guidance_text_2"))+" ")])])]),(_vm.isLoading)?_c('spinner',{staticClass:"mt-5"}):_c('div',{staticClass:"vehicles-eligibility__selects",attrs:{"data-cy":"vehicles-eligibility-selects"}},[_c('div',{staticClass:"vehicles-eligibility__selects__values"},[_vm._l((_vm.eligibilityCriteriaFiltered),function(criterion){return _c('div',{key:criterion},[(_vm.isEligibilityCriterionSelect(criterion))?_c('div',[(criterion === 'engine_code')?_c('FormRowSelect',{key:criterion,staticClass:"eligibility__criterion",attrs:{"is-loading":_vm.engineCodeOptionsLoading,"label":_vm.getEligibilityCriterionLabel(criterion),"label-class":[
                'text-right',
                'col-sm-4',
                'align-self-center',
                'font-size-20' ],"control-class":['col-sm-6', 'align-self-center'],"name":_vm.getEligibilityInputName(criterion, _vm.offerId),"selected-option":_vm.engineCodesSelectedOptions,"label-select-attr":"label","allow-empty":true,"placeholder":_vm.form.eligibility_criteria[criterion].length === 0
                  ? _vm.getEligibilityEmptyLabel(criterion)
                  : '',"select-options":_vm.getEligibilityCriterionOptions(criterion),"multiple":true},on:{"update:selectedOption":function($event){_vm.engineCodesSelectedOptions=$event},"update:selected-option":[function($event){_vm.engineCodesSelectedOptions=$event},function($event){return _vm.onUpdate(criterion)}]}}):_c('FormRowSelect',{key:criterion,staticClass:"eligibility__criterion",attrs:{"is-loading":criterion === 'model' && _vm.modelOptionsLoading,"label":_vm.getEligibilityCriterionLabel(criterion),"label-class":[
                'text-right',
                'col-sm-4',
                'align-self-center',
                'font-size-20' ],"control-class":['col-sm-6', 'align-self-center'],"name":_vm.getEligibilityInputName(criterion, _vm.offerId),"selected-option":_vm.form.eligibility_criteria[criterion],"label-select-attr":"label","allow-empty":true,"placeholder":_vm.form.eligibility_criteria[criterion].length === 0
                  ? _vm.getEligibilityEmptyLabel(criterion)
                  : '',"select-options":_vm.getEligibilityCriterionOptions(criterion),"disabled":(criterion === 'model' && _vm.isModelDisabled) ||
                _vm.isEligibilityCriterionDisabled(
                  criterion,
                  _vm.form.eligibility_criteria.make
                ),"multiple":true},on:{"update:selectedOption":function($event){return _vm.$set(_vm.form.eligibility_criteria, criterion, $event)},"update:selected-option":[function($event){return _vm.$set(_vm.form.eligibility_criteria, criterion, $event)},function($event){return _vm.onUpdate(criterion)}]}})],1):_c('div',[_c('FormRowInput',{staticClass:"eligibility__criterion",attrs:{"label":_vm.getEligibilityCriterionLabel(criterion),"label-class":[
                'text-right',
                'col-sm-4',
                'align-self-center',
                'font-size-20' ],"control-class":['col-sm-6', 'align-self-center'],"name":_vm.getEligibilityInputName(criterion, _vm.offerId),"type":"text","errors":_vm.getEligibilityUpdateErrors,"disabled":_vm.isEligibilityCriterionDisabled(
                  criterion,
                  _vm.form.eligibility_criteria.make
                )},on:{"input":function($event){return _vm.onUpdate(criterion)}},model:{value:(_vm.form.eligibility_criteria[criterion]),callback:function ($$v) {_vm.$set(_vm.form.eligibility_criteria, criterion, $$v)},expression:"form.eligibility_criteria[criterion]"}})],1)])}),(_vm.isAccordionVisible)?_c('div',[(!_vm.showAccordion)?_c('div',{staticClass:"accordion",attrs:{"data-cy":"eligibility-criteria-see-more"},on:{"click":function($event){_vm.showAccordion = true}}},[_c('span',{staticClass:"font-effra-normal font-size-14"},[_vm._v(" "+_vm._s(_vm.$t("offers.vehicles_eligibility.see_more"))+" ")]),_c('span',{staticClass:"icon-chevron-down font-size-14 ml-1"})]):_c('div',[_vm._l((_vm.eligibilityCriteriaHiddenByAccordion),function(criterionAccordion){return _c('div',{key:criterionAccordion},[(criterionAccordion === 'max_age')?_c('div',[_c('div',{staticClass:"eligibility__criterion__max-age"},[_c('FormRowInput',{staticClass:"eligibility__criterion__max-age-value",attrs:{"label":_vm.getMaxAgeEligibilityCriterionLabel(
                        _vm.form.eligibility_criteria['max_age_unity']
                      ),"label-class":[
                      'text-right',
                      'col-sm-6',
                      'align-self-center',
                      'font-size-20' ],"control-class":['col-sm-4', 'align-self-center'],"name":_vm.getEligibilityInputName(criterionAccordion, _vm.offerId),"type":"text","errors":_vm.getEligibilityUpdateErrors,"disabled":_vm.isEligibilityCriterionDisabled(
                        criterionAccordion,
                        _vm.form.eligibility_criteria.make
                      )},on:{"input":function($event){return _vm.onUpdate(criterionAccordion)}},model:{value:(_vm.form.eligibility_criteria[criterionAccordion]),callback:function ($$v) {_vm.$set(_vm.form.eligibility_criteria, criterionAccordion, $$v)},expression:"form.eligibility_criteria[criterionAccordion]"}}),_c('FormSelect',{staticClass:"eligibility__criterion__max-age-unity text-left col-sm-6",attrs:{"selected-option":_vm.form.eligibility_criteria['max_age_unity'],"select-options":_vm.getAgeUnityOptions,"label-select-attr":"label","disabled":_vm.isEligibilityCriterionDisabled(
                        criterionAccordion,
                        _vm.form.eligibility_criteria.make
                      ),"name":_vm.getEligibilityInputName('max_age_unity', _vm.offerId)},on:{"update:selectedOption":function($event){return _vm.$set(_vm.form.eligibility_criteria, 'max_age_unity', $event)},"update:selected-option":function($event){return _vm.$set(_vm.form.eligibility_criteria, 'max_age_unity', $event)}}})],1)]):_c('div',[_c('FormRowInput',{staticClass:"eligibility__criterion",attrs:{"label":_vm.getEligibilityCriterionLabel(criterionAccordion),"label-class":[
                    'text-right',
                    'col-sm-4',
                    'align-self-center',
                    'font-size-20' ],"control-class":['col-sm-6', 'align-self-center'],"name":_vm.getEligibilityInputName(criterionAccordion, _vm.offerId),"type":"text","errors":_vm.getEligibilityUpdateErrors,"disabled":_vm.isEligibilityCriterionDisabled(
                      criterionAccordion,
                      _vm.form.eligibility_criteria.make
                    )},on:{"input":function($event){return _vm.onUpdate(criterionAccordion)}},model:{value:(_vm.form.eligibility_criteria[criterionAccordion]),callback:function ($$v) {_vm.$set(_vm.form.eligibility_criteria, criterionAccordion, $$v)},expression:"form.eligibility_criteria[criterionAccordion]"}})],1)])}),_c('div',{staticClass:"accordion",on:{"click":function($event){_vm.showAccordion = false}}},[_c('span',{staticClass:"font-effra-normal font-size-14"},[_vm._v(" "+_vm._s(_vm.$t("offers.vehicles_eligibility.see_less"))+" ")]),_c('span',{staticClass:"icon-chevron-up font-size-14 ml-1"})])],2)]):_vm._e()],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <spinner v-if="isLoading" class="mt-5"></spinner>
  <div v-else>
    <div
      class="layout-program-parameters__header mx-auto container-fluid bg-white mb-2"
    >
      <h4 class="text-center py-3 text-uppercase font-size-18">
        {{ $t("programParameters.title") }}
      </h4>
      <div class="w-95 text-center container-fluid pt-3 pb-4">
        <div class="d-flex justify-content-around">
          <div v-for="(step, stepName) in steps" :key="stepName" class="">
            <a
              @click="onStepClick(stepName)"
              :data-cy="'step-' + stepName"
              :class="isStepClickable(stepName) ? 'mouse-pointer' : 'mouse-forbidden'"
            >
              <span
                class="font-size-25"
                :class="[
                  isStepComplete(stepName) ? 'icon-checkmark-circle' : 'icon-circle',
                  isStepActive(stepName) ? 'active-step' : 'inactive-step',
                ]"
                :data-cy="`program-parameters-step-${stepName}`"
              ></span>
              <div
                class="font-size-13"
                :class="[isStepActive(stepName) ? 'active-step' : 'inactive-step']"
              >
                {{ step.title }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-program-parameters__card mx-auto container-fluid bg-white">
      <div class="row">
        <VerticalDistribTabs
          v-if="showVerticalTabs"
          :tabs="verticalTabs"
          @tab-change="setActiveTabIndex"
          :is-offer-menu="isOfferMenu"
        ></VerticalDistribTabs>
        <router-view
          v-if="verticalTabs[activeTabIndex]"
          :class="[showVerticalTabs ? 'col-9' : 'col-12']"
          :item="currentItem"
          :item-type="currentItemType"
          :product-line-id="activeProductLineId"
        ></router-view>
      </div>
    </div>
    <div class="layout-program-parameters__button container-fluid py-3 px-0">
      <button
        type="button"
        class="btn btn-primary btn-block"
        :disabled="isStepEndButtonDisabled"
        :title="stepEndButtonMouseoverTitle"
        :data-cy="stepEndButtonDataCy"
        @click.prevent="onStepEndButtonClick"
      >
        {{ stepEndButtonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VerticalDistribTabs from "@/components/utils/VerticalDistribTabs"
import LayoutProgramParametersMixin from "@/mixins/LayoutProgramParametersMixin"

export default {
  name: "ProgramParameters",
  components: { VerticalDistribTabs },
  mixins: [LayoutProgramParametersMixin],
  data() {
    return {
      steps: {
        description: {
          status: { start: "DRAFT", end: "SETTING_DESC" },
          title: this.$t("programParameters.description.title"),
          previousStep: null,
          nextStep: "synthesis",
        },
        synthesis: {
          status: { start: "SETTING_DESC", end: "SETTING_SYNTHESIS" },
          title: this.$t("programParameters.synthesis.title"),
          previousStep: "description",
          nextStep: "documents",
        },
        documents: {
          status: { start: "SETTING_SYNTHESIS", end: "SETTING_DOCUMENTS" },
          title: this.$t("programParameters.documents.title"),
          previousStep: "synthesis",
          nextStep: "identity",
        },
        identity: {
          status: { start: "SETTING_DOCUMENTS", end: "SETTING_IDENTITY" },
          title: this.$t("programParameters.visual_identity.title"),
          previousStep: "documents",
          nextStep: null,
        },
      },
      mappingUrlNameStep: {
        programDescription: "description",
        programSynthesis: "synthesis",
        programDocuments: "documents",
        programVisualIdentity: "identity",
      },
      activeStepName: "description",
      menuItems: {},
      activeTabIndex: 0,
      activeProductLineId: 0,
      isLoading: true,
      program: null,
      verticalTabs: [],
    }
  },
  computed: {
    ...mapGetters("programProduct", [
      "listProducts",
      "listProductsWithMissingMutualAgreement",
    ]),
    ...mapGetters("program", [
      "getProgramStatus",
      "listProgramSettingStatusComplete",
      "getProgram",
    ]),
    ...mapGetters("programDocument", ["areAllDocsUploadedForProgram"]),
    ...mapGetters("offerDocument", ["areAllDocsUploadedForOffer"]),
    ...mapGetters("offer", ["isOfferExternal"]),
    areAllOffersDocsUploaded() {
      for (const offer of this.program.offers) {
        if (!this.isOfferExternal(offer.id)) {
          if (!this.areAllDocsUploadedForOffer(offer.id)) return false
        }
      }
      return true
    },
    isStepEndButtonDisabled() {
      return (
        this.activeStepName === "documents" &&
        !(
          this.areAllDocsUploadedForProgram(this.program.id) &&
          this.areAllOffersDocsUploaded
        )
      )
    },
    showVerticalTabs() {
      const stepsWithoutVerticalTabs = ["network", "identity"]
      return !stepsWithoutVerticalTabs.includes(this.activeStepName)
    },
    currentItem() {
      return this.verticalTabs[this.activeTabIndex].item
    },
    currentItemType() {
      return this.verticalTabs[this.activeTabIndex].type
    },
    stepEndButtonLabel() {
      return this.activeStepName === "identity"
        ? this.$t("button.validate")
        : this.$t("button.next")
    },
    stepEndButtonMouseoverTitle() {
      return this.activeStepName === "documents"
        ? this.$t("programParameters.documents.button_mouseover_title")
        : ""
    },
    stepEndButtonDataCy() {
      return this.activeStepName === "identity" ? "button-validate" : "button-next"
    },
    isOfferMenu() {
      return this.activeStepName === "description"
    },
  },
  methods: {
    initVerticalTabs() {
      let tabs = this.getProgramTabs(this.program)
      if (this.activeStepName === "documents") {
        // For documents view, tabs to display are the program and all its offers
        // (no need to display program products)
        tabs.unshift({
          type: "program",
          label: this.$t("verticaltabs.common_documents"),
          item: this.program,
          isSubTab: false,
        })
        tabs.forEach((tab) => {
          if (tab.type === "offer") {
            tab.label = `${this.$t("verticaltabs.offer_documents")}${tab.label}`
          }
        })
        tabs = tabs.filter((tab) => tab.type !== "program_product")
      }
      this.verticalTabs = tabs
    },
    getProgramTabs(program) {
      let tabs = []
      program.offers.forEach((offer, index) => {
        tabs.push({
          type: "offer",
          label: `${this.$t("verticaltabs.offer_number")}${index + 1}`,
          item: offer,
          isSubTab: false,
        })
        offer.program_products.forEach((pp) => {
          let produtLines = []
          if (this.hasSeveralProductLines(pp)) {
            pp.product_lines.forEach((pl) => {
              produtLines.push(pl)
            })
          }
          tabs.push({
            type: "program_product",
            label: pp.name,
            item: pp,
            isSubTab: true,
            productLines: produtLines,
          })
        })
      })
      return tabs
    },
    setActiveTabIndex(tabIndex, productLineId) {
      this.activeTabIndex = tabIndex
      this.activeProductLineId = productLineId
    },
    async onStepEndButtonClick() {
      await this.$store.dispatch("program/updateStatus", this.activeStep.status.end)
      if (this.activeStep.nextStep) {
        await this.changeStep(this.activeStep.nextStep)
      } else {
        await this.$router.push({ name: "programParametersStartpage" })
      }
    },
    async onActiveStepDocuments() {
      await this.$store.dispatch("programDocument/getDocuments")
      await this.$store.dispatch("offerDocument/getDocuments")
    },
    hasSeveralProductLines(programProduct) {
      return programProduct.product_lines.length > 1
    },
  },
  async created() {
    await this.$store.dispatch(
      "program/getProgram",
      this.$router.currentRoute.params.programId
    )
    this.program = JSON.parse(JSON.stringify(this.getProgram))
    await this.$store.dispatch("offer/setCurrentOfferId", this.program.offers[0].id)

    this.activeStepName = this.mappingUrlNameStep[this.$router.currentRoute.name]
    if (this.activeStepName === "documents") {
      await this.onActiveStepDocuments()
    }

    this.initVerticalTabs()
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
.layout-program-parameters__header {
  width: 80%;

  h4 {
    letter-spacing: 0.25px;
  }

  .active-step {
    font-weight: bold;
  }

  .inactive-step {
    opacity: 0.5;
  }
}

.layout-program-parameters__card {
  width: 80%;
  border-radius: 0;
  border: none;
  background-color: transparent;
  padding-top: 50px;
}

.layout-program-parameters__button {
  width: 80%;
  display: grid;

  button {
    justify-self: end;
  }
}
</style>

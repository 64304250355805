<template>
  <div>
    <ModalProgramCriteria
      v-if="isGuaranteesModalOpen(productLineId)"
      :product-line-id="productLineId"
    ></ModalProgramCriteria>
    <ModalCommissionsAndTaxes
      v-if="isCommissionsAndTaxesModalOpen(productLineId)"
      :product-line-id="productLineId"
    ></ModalCommissionsAndTaxes>
    <ModalPaymentFrequency
      v-if="isFrequencyModalOpen(productLineId)"
      :product-line-id="productLineId"
    ></ModalPaymentFrequency>
    <ModalWarning
      v-if="isPaymentFrequencyModalWarningOpen"
      :reason="modalWarningInfo.reason"
      :advice="modalWarningInfo.advice"
      :title="modalWarningInfo.title"
      @close="onPaymentFrequencyModalWarningClose"
    ></ModalWarning>
    <div class="product-line-edition">
      <ProductLineConfigHeader
        v-if="isFirstProductLine"
        class="product-line-edition__header"
        icon="icon-shield-check"
        :title="$t('programs.form.programCriteria.title')"
        :sub-title="$t('programs.form.programCriteria.help')"
      />
      <div class="product-line-edition__criteria">
        <div
          v-for="criterion in listCustomProgramCriteria(productLineId)"
          :key="criterion"
          class="d-flex flex-row"
        >
          <ProductLineConfigCriterion
            class="product-line-edition__criterion"
            :name="getProgramCriterionLabelToDisplay(criterion).toUpperCase()"
            :value="getProgramCriterionValueToDisplay(productLineId, criterion)"
            :data-cy="`${productLineId}-${criterion}`"
          ></ProductLineConfigCriterion>
        </div>
      </div>
      <div class="product-line-edition__footer">
        <DropdownButton v-if="!disabled" :product-line-id="productLineId" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProductLineConfigHeader from "@/components/presales/components/ProductLineConfigHeader"
import ProductLineConfigCriterion from "@/components/presales/components/ProductLineConfigCriterion"
import ModalProgramCriteria from "@/components/presales/modal/ModalProgramCriteria"
import ModalPaymentFrequency from "@/components/presales/modal/ModalPaymentFrequency"
import FormProductLineMixin from "@/components/presales/mixins/FormProductLineMixin"
import DropdownButton from "@/components/presales/components/FormProductLineProgramDropdownGuarantee"
import ModalCommissionsAndTaxes from "../modal/ModalCommissionsAndTaxes"
import ModalWarning from "@/components/modal/ModalWarning"

export default {
  name: "FormProductLineProgram",
  components: {
    ProductLineConfigHeader,
    ProductLineConfigCriterion,
    ModalProgramCriteria,
    ModalCommissionsAndTaxes,
    ModalPaymentFrequency,
    DropdownButton,
    ModalWarning,
  },
  mixins: [FormProductLineMixin],
  props: {
    productLineId: { type: [Number, String], required: true },
    disabled: { type: Boolean, default: false },
    index: Number,
    productName: { type: String, required: true },
    offerName: { type: String, required: true },
  },
  computed: {
    ...mapGetters("productLineModal", [
      "isGuaranteesModalOpen",
      "isCommissionsAndTaxesModalOpen",
      "isFrequencyModalOpen",
      "isPaymentFrequencyModalWarningOpen",
    ]),
    ...mapGetters("programProductCustomization", [
      "listCustomProgramCriteria",
      "getProgramCriterionValueToDisplay",
      "getProgramCriterionLabelToDisplay",
      "getPaymentFrequencyValue",
    ]),
    modalWarningInfo() {
      let advice3 = this.$t(
        `programs.payment_frequency_modal_warning.advice_3.${this.getPaymentFrequencyValue(
          this.productLineId
        )}`
      )
      let info = {
        title: this.$t("programs.payment_frequency_modal_warning.title"),
        reason: this.$t("programs.payment_frequency_modal_warning.reason"),
        advice: this.$t("programs.payment_frequency_modal_warning.advice_1"),
      }
      info.advice = `${info.advice} ${this.productName} ${this.$t(
        "programs.payment_frequency_modal_warning.advice_2"
      )} ${this.offerName} \n ${advice3}\n\n ${this.$t(
        "programs.payment_frequency_modal_warning.advice_4"
      )}`
      return info
    },
  },
  methods: {
    onPaymentFrequencyModalWarningClose() {
      this.$store.dispatch(
        "productLineModal/setIsPaymentFrequencyModalWarningOpen",
        false
      )
    },
  },
}
</script>

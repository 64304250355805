<template>
  <div class="product-line-summary">
    <h4 class="font-size-20 primary-color font-gilroy text-uppercase pl-3">
      {{ programProduct.name }}
    </h4>
    <ModalWarning
      v-if="warningModal.show"
      :title="warningModal.title"
      :reason="warningModal.reason"
      :advice="warningModal.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <div class="bg-caarea-secondary summary__micro-div mb-1"></div>
    <div class="bg-caarea-secondary summary__details">
      <CoverageDurationDistance
        v-if="eligibleProductLine !== null"
        class="summary__details__bcriteria"
        :program-product-id="programProduct.id"
        :product-line-id="eligibleProductLine.id"
        :form-coefficient="eligibleProductLine.config.coefficient"
        :beneficiary-criteria="form.beneficiaryCriteria"
        @coverage-update="onUpdateCriteria"
        @vehicle-criteria-update="onUpdateCriteria"
      ></CoverageDurationDistance>
      <div v-else class="summary__details__bcriteria">
        {{ $t("offers.pricing_summary.no_eligible_pl") }}
      </div>
      <div class="summary__details__price font-size-30 font-gilroy-bold">
        <spinner v-if="isLoading"></spinner>
        <template v-else>{{ price }}</template>
      </div>
      <div class="summary__details__taxes">
        <span class="font-size-11 font-effra-light">
          {{ $t("offers.pricing_summary.after_tax") }}
        </span>
        <span
          class="summary__details__price__info--frequency"
          v-if="isMonthlyPayment(eligibleProductLine.id)"
        >
          {{ $t("programs.monthly") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CoverageDurationDistance from "@/components/presales/components/CoverageDurationDistance"
import ModalWarning from "@/components/modal/ModalWarning"
import BeneficiaryCriteriaService from "@/services/business/BeneficiaryCriteriaService"
import PricingService from "@/services/business/PricingService"

export default {
  name: "OfferPricingSummary",
  components: {
    CoverageDurationDistance,
    ModalWarning,
  },
  props: {
    programProduct: {
      type: Object,
      required: true,
    },
    vehicle: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      form: {
        config: {
          vehicle: null,
        },
        beneficiaryCriteria: {},
      },
      warningModal: {
        show: false,
        title: this.$t("programs.pricing_failed_modal.title"),
        advice: this.$t("programs.pricing_failed_modal.advice"),
      },
      eligibleProductLine: null,
      isLoading: false,
    }
  },
  watch: {
    vehicle: async function () {
      this.eligibleProductLine = this.getEligibleProductLine()
      if (this.eligibleProductLine) {
        await this.getCoefficientCriteriaIfNeeded()
        await this.computePricing()
      }
    },
  },
  computed: {
    ...mapGetters("programProduct", ["getEligibleProductLines"]),
    ...mapGetters("productLine", [
      "getProductLinePrice",
      "getCoefficientCriteria",
      "getConfig",
      "getBeneficiaryCriteriaValues",
      "getUserSelectableValues",
      "isMonthlyPayment",
    ]),
    ...mapGetters("vehicle", [
      "getVehiclePrice",
      "getVehicleKm",
      "getVehicleFirstReleaseDate",
    ]),
    price() {
      if (this.eligibleProductLine && !this.isVehicleEmpty) {
        const price = this.getProductLinePrice(this.eligibleProductLine.id)
        if (price) {
          return PricingService.getFormattedPriceToDisplay(
            price,
            this.isMonthlyPayment(this.eligibleProductLine.id)
          )
        }
      }
      return "--"
    },
    isVehicleEmpty() {
      return this.vehicle ? Object.keys(this.vehicle).length === 0 : true
    },
    vehicleInfo() {
      return !this.isVehicleEmpty
        ? `${this.vehicle.name} ${this.vehicle.version}`
        : this.$t("programs.form.beneficiaryCriteria.vehicle.action")
    },
  },
  methods: {
    async computePricing() {
      if (this.isVehicleEmpty) {
        return null
      } else {
        this.isLoading = true
        this.$emit("isTotalLoading", this.isLoading)
      }
      const beneficiary_criteria =
        this.eligibleProductLine.config.coefficient.beneficiary.reduce((acc, k) => {
          acc[k] = this.eligibleProductLine.config.coefficient.values[k]
          return acc
        }, {})
      const dynamic_inputs = {
        ...(this.programProduct.config.required_dynamic_inputs.includes(
          "retail_price"
        ) && {
          retail_price: this.getVehiclePrice,
        }),
        ...(this.programProduct.config.required_dynamic_inputs.includes("km") && {
          km: this.getVehicleKm,
        }),
        ...(this.programProduct.config.required_dynamic_inputs.includes(
          "first_release_date"
        ) && {
          first_release_date: this.getVehicleFirstReleaseDate,
        }),
      }
      const payload = {
        product_line_id: this.eligibleProductLine.id,
        vehicle_id: this.vehicle.vehicle_id,
        beneficiary_criteria,
        dynamic_inputs,
      }
      const vehicle = { ...this.vehicle, ...dynamic_inputs }
      const cleanVehicle = Object.fromEntries(
        Object.entries(vehicle).filter(([_, v]) => v != null)
      )
      try {
        await this.$store.dispatch("productLine/getProductLinesPrices", [payload])
      } catch (e) {
        this.managePricingError(e)
      }
      this.isLoading = false
      this.$emit("isTotalLoading", this.isLoading)
    },
    async onUpdateCriteria(criterion) {
      this.isLoading = true
      this.$emit("isTotalLoading", this.isLoading)
      this.form.beneficiaryCriteria = { ...this.form.beneficiaryCriteria, ...criterion }
      const criterionName = Object.keys(criterion)[0]
      // In case duration has changed, ensure to use a km value selectable for this duration
      if (
        criterionName.includes("coverage_duration") &&
        BeneficiaryCriteriaService.hasCoverageDurationKmCouple(
          this.form.beneficiaryCriteria
        )
      ) {
        this.form.beneficiaryCriteria =
          BeneficiaryCriteriaService.getValidCoverageCoupleOnDurationChange(
            this.form.beneficiaryCriteria,
            this.getUserSelectableValues(this.eligibleProductLine.id)
          )
      }

      this.eligibleProductLine.config.coefficient.values = {
        ...this.eligibleProductLine.config.coefficient.values,
        ...this.form.beneficiaryCriteria,
      }
      const payload = {
        productLineId: this.eligibleProductLine.id,
        data: {
          config: { ...this.eligibleProductLine.config },
        },
      }
      await this.$store.dispatch("productLine/update", payload)
      await this.computePricing()
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
    managePricingError(exception) {
      console.error("failed: ", exception.data, exception.status)
      this.pricing = null
      if (exception.status === 400 || exception.status === 422) {
        this.warningModal.reason =
          this.$t("programs.pricing_failed_modal.reason") +
          ` :<br><strong>${this.vehicleInfo}</strong>`

        if (exception.status === 422 || process.env.NODE_ENV !== "production") {
          if (exception.data.hasOwnProperty("dynamic_inputs")) {
            this.warningModal.advice = `dynamic_inputs : ${exception.data.dynamic_inputs}`
          } else {
            this.warningModal.advice = exception.data
          }
        }
        this.warningModal.show = true
      }
    },
    getEligibleProductLine() {
      // Return only 1 product line :
      // in nominal case, only 1 product line will be eligible
      // but in case several ones are actually eligible, just return the first one

      // if no vehicle is selected, no need to search for the eligible product line
      // (they are all eligible, just return the first one)
      if (this.isVehicleEmpty) {
        return this.programProduct.product_lines[0]
      }
      // if a vehicle is selected, search for the eligible product line
      else {
        const eligibleProductLines = this.getEligibleProductLines(
          this.programProduct.id
        )
        if (eligibleProductLines !== null && eligibleProductLines.length > 0) {
          // always return the first one
          return eligibleProductLines[0]
        }
        // no eligible product line : return null
        return null
      }
    },
    async getCoefficientCriteriaIfNeeded() {
      // If coefficient criteria of the eligible product line are not in the store
      // retrieve them from API call and update store
      if (this.getCoefficientCriteria(this.eligibleProductLine.id) === null) {
        await this.$store.dispatch(
          "productLine/getCoefficientCriteria",
          this.eligibleProductLine.id
        )
      }
    },
  },
  async created() {
    this.eligibleProductLine = this.getEligibleProductLine()

    if (this.eligibleProductLine) {
      // Yes it's strange but it allows to add "coverage_duration_km" criterion if it's not present
      // in the config
      // (anyway, beneficiary criteria couple will be removed in next sprints)
      this.eligibleProductLine.config = this.getConfig(
        this.eligibleProductLine.id,
        this.programProduct.id
      )
      this.form.beneficiaryCriteria = this.getBeneficiaryCriteriaValues(
        this.eligibleProductLine.id
      )
      await this.getCoefficientCriteriaIfNeeded()
      await this.computePricing()
    }
  },
}
</script>

<style lang="scss" scoped>
.product-line-summary {
  margin-top: 20px;
}
.summary__micro-div {
  height: 0.3em;
}
.summary__details {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  grid-template-areas: "beneficiary-criteria price taxes";
  column-gap: 10px;
  padding: 20px;
}
.summary__details__bcriteria {
  grid-area: beneficiary-criteria;
  align-self: center;
}

.summary__details__price {
  grid-area: price;
  justify-self: right;
  align-self: center;
  &__info--frequency {
    font-family: "Gilroy SemiBold", sans-serif;
  }
}
.summary__details__taxes {
  grid-area: taxes;
  align-self: center;
  display: flex;
  flex-direction: column;
}
.summary__label {
  margin-right: 50px;
  label {
    color: $primarybutton;
    font-family: "Effra", sans-serif;
    font-size: 14px;
  }
}
</style>

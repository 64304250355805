import Vue from "vue"
import VueRouter from "vue-router"
import CypressAuth from "@/views/CypressAuth"
import Home from "@/views/Home"
import Layout from "@/layouts/Layout"
import NoLayout from "@/layouts/LayoutAuth"
import LayoutSharing from "@/layouts/LayoutSharing"
import guards from "./guards"
import Page403 from "@/views/errors/Page403"
import Page404 from "@/views/errors/Page404"
import Page500 from "@/views/errors/Page500"
import PreSale from "@/views/PreSale"
import Account from "@/views/Account"
import SharedPrograms from "@/views/SharedPrograms"
import Parameters from "@/views/Parameters"
import Partners from "@/views/Partners"
import ProgramParametersStartpage from "@/views/ProgramParametersStartpage"
import GroupService from "@/services/business/GroupService"
import LayoutProgramParameters from "@/layouts/LayoutProgramParameters"
import LayoutProgramCommercialTaxes from "@/layouts/LayoutProgramCommercialTaxes"
import ProgramDescription from "@/views/programParameters/ProgramDescription"
import ProgramSynthesis from "@/views/programParameters/ProgramSynthesis"
import ProgramDocuments from "@/views/programParameters/ProgramDocuments"
import ProgramNetwork from "@/views/programParameters/ProgramNetwork"
import ProgramPublishing from "@/views/programParameters/ProgramPublishing"
import LayoutProgram from "@/layouts/LayoutProgram"
import Products from "@/views/presale/Products"
import Offer from "@/views/presale/Offer"
import OfferFlitter from "@/components/presales/components/Flitter"
import ProgramProductCustomization from "@/components/presales/components/ProgramProductCustomization"
import OfferPricing from "@/components/presales/components/OfferPricing"
import Program from "@/views/presale/Program"
import PricingRange from "@/views/pricing/PricingRange"
import VisualIdentity from "@/views/programParameters/VisualIdentity"
import OffersPreview from "@/views/programParameters/OffersPreview"
import LayoutProgramNetworkParameters from "@/layouts/LayoutProgramNetworkParameters"
import FormNewPartner from "@/components/partners/FormNewPartner"
import UsersList from "@/views/users/UsersList.vue"
import LayoutUsers from "@/layouts/LayoutUsers"
import UsersNew from "@/views/users/UsersNew.vue"
import UsersAccount from "@/views/users/UsersAccount.vue"
import UsersEdit from "@/views/users/UsersEdit.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/:lang",
    name: "lang",
    beforeEnter: guards.beforeEnter,
    redirect: { name: "privateZone" },
    component: {
      render(c) {
        return c("router-view")
      },
    },
    children: [
      {
        path: "",
        name: "privateZone",
        beforeEnter: guards.requireAuth,
        redirect: { name: "home" },
        component: Layout,
        meta: { breadcrumb: "breadcrumb.home" },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
            meta: {
              breadcrumb: "breadcrumb.home",
              maxGroupId: GroupService.BENEFICIARY,
              fullLayout: true,
            },
          },
          {
            path: "presale",
            name: "presale",
            beforeEnter: guards.requireAcceptedCookies,
            component: PreSale,
            meta: {
              breadcrumb: "breadcrumb.presale",
              maxGroupId: GroupService.BENEFICIARY,
              contentTitle: "products.title",
            },
          },
          {
            path: "administration",
            name: "administration",
            beforeEnter: guards.requireAcceptedCookies,
            redirect: { name: "programs" },
            meta: {
              breadcrumb: "breadcrumb.administration",
              maxGroupId: GroupService.BENEFICIARY,
            },
            component: {
              render(c) {
                return c("router-view")
              },
            },
            children: [
              {
                path: "parameters",
                name: "parameters",
                component: Parameters,
                meta: {
                  breadcrumb: "breadcrumb.parameters",
                  maxGroupId: GroupService.INTERNAL_ADMIN,
                  contentTitle: "parameters.title",
                  contentDesc: "parameters.description",
                },
              },
              {
                path: "programs/parameters/:programId",
                name: "programParametersStartpage",
                component: ProgramParametersStartpage,
                props: true,
              },
              {
                path: "programs/parameters/:programId",
                name: "programParameters",
                component: LayoutProgramParameters,
                redirect: { name: "programDescription" },
                props: true,
                children: [
                  {
                    path: "description",
                    name: "programDescription",
                    component: ProgramDescription,
                    props: true,
                    meta: {
                      title: "programParameters.description.title2",
                    },
                  },
                  {
                    path: "synthesis",
                    name: "programSynthesis",
                    component: ProgramSynthesis,
                    props: true,
                  },
                  {
                    path: "documents",
                    name: "programDocuments",
                    component: ProgramDocuments,
                    props: true,
                    meta: {
                      title: "programParameters.documents.title2",
                      desc: "programParameters.documents.info",
                    },
                  },
                  {
                    path: "visual-identity",
                    name: "programVisualIdentity",
                    component: VisualIdentity,
                    props: true,
                    meta: {
                      title: "programParameters.offer_preview.title",
                    },
                  },
                ],
              },
              {
                path: "programs/parameters/:programId/offers-preview",
                name: "programOffersPreview",
                component: OffersPreview,
                meta: {
                  breadcrumb: "breadcrumb.visual_identity_preview",
                  maxGroupId: GroupService.INTERNAL_USER,
                },
              },
              {
                path: "programs/parameters/:programId/distribution",
                name: "programNetworkParameters",
                component: LayoutProgramNetworkParameters,
                redirect: { name: "distributionStartpage" },
                meta: {
                  breadcrumb: "breadcrumb.programParameters",
                  maxGroupId: GroupService.INTERNAL_USER,
                  title: "breadcrumb.programParameters",
                },
                children: [
                  {
                    path: "",
                    name: "distributionStartpage",
                    component: ProgramParametersStartpage,
                    meta: {
                      title: "programParameters.network.title2",
                      desc: "programParameters.network.info",
                    },
                  },
                  {
                    path: "network-selection",
                    name: "programNetwork",
                    component: ProgramNetwork,
                    meta: {
                      title: "programParameters.network.title2",
                      desc: "programParameters.network.info",
                    },
                  },
                  {
                    path: "program-publishing",
                    name: "programPublishing",
                    component: ProgramPublishing,
                    meta: {
                      title: "programParameters.publishing.title2",
                    },
                  },
                ],
              },
              {
                path: "programs/parameters/:programId/taxes",
                name: "programCommercialTaxes",
                component: LayoutProgramCommercialTaxes,
                meta: {
                  breadcrumb: "breadcrumb.programParameters",
                  maxGroupId: GroupService.INTERNAL_USER,
                },
              },
              {
                path: "programs",
                name: "programs",
                component: SharedPrograms,
                meta: {
                  breadcrumb: "breadcrumb.sharedProgram",
                  maxGroupId: GroupService.INTERNAL_USER,
                  contentTitle: "sharedPrograms.title",
                  contentDesc: "sharedPrograms.description",
                },
              },
              {
                path: "programs_demo",
                name: "programs_demo",
                component: SharedPrograms,
                meta: {
                  breadcrumb: "breadcrumb.sharedProgram",
                  maxGroupId: GroupService.INTERNAL_ADMIN,
                  contentTitle: "sharedPrograms.demo_title",
                  contentDesc: "sharedPrograms.description",
                },
              },
              {
                path: "partners",
                name: "partners",
                component: Partners,
                meta: {
                  breadcrumb: "breadcrumb.partners",
                  maxGroupId: GroupService.INTERNAL_USER,
                  partnerTitle: "partners.title.main",
                },
                children: [
                  {
                    path: "new",
                    name: "form_new_partner",
                    component: FormNewPartner,
                    meta: {
                      partnerTitle: "partners.title.create",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "products",
            name: "products",
            beforeEnter: guards.requireAcceptedCookies,
            redirect: { name: "productsHome" },
            meta: {
              breadcrumb: "breadcrumb.products",
              maxGroupId: GroupService.BENEFICIARY,
            },
            component: {
              render(c) {
                return c("router-view")
              },
            },
            children: [
              {
                path: "",
                name: "productsHome",
                component: Products,
                meta: {
                  fullLayout: true,
                },
              },
              {
                path: "program/:id",
                name: "program",
                props: true,
                redirect: { name: "programHome" },
                meta: {
                  breadcrumb: "breadcrumb.programs",
                  maxGroupId: GroupService.BENEFICIARY,
                },
                component: LayoutProgram,
                children: [
                  {
                    path: "",
                    name: "programHome",
                    props: false,
                    component: Program,
                    meta: {
                      fullLayout: true,
                      layoutBackgroundColor: "white",
                    },
                  },
                  {
                    path: "offer/:offerId",
                    name: "programOffer",
                    props: true,
                    component: Offer,
                    meta: {
                      fullLayout: true,
                    },
                  },
                  {
                    path: "offer/:offerId/flitter",
                    name: "offerFlitter",
                    props: true,
                    component: OfferFlitter,
                    meta: {
                      fullLayout: true,
                      layoutBackgroundColor: "white",
                    },
                  },
                  {
                    path: "offer/:offerId/pricing_range",
                    name: "offerPricingRange",
                    props: true,
                    component: PricingRange,
                    meta: {
                      fullLayout: true,
                      layoutBackgroundColor: "white",
                    },
                  },
                  {
                    path: "offer/:offerId/program_product/:programProductId",
                    name: "programProduct",
                    props: true,
                    component: ProgramProductCustomization,
                    meta: {
                      fullLayout: true,
                      layoutBackgroundColor: "white",
                    },
                  },
                  {
                    path: "offer/:offerId/program_product/:programProductId/product_line/:productLineId/pricing_range",
                    name: "program_product_pricing_range",
                    props: true,
                    component: PricingRange,
                    meta: {
                      fullLayout: true,
                      layoutBackgroundColor: "white",
                    },
                  },
                ],
              },
              {
                path: "offer/:offerId/pricing-range-2",
                name: "PricingRange",
                beforeEnter: guards.requireAcceptedCookies,
                component: PricingRange,
                props: true,
                meta: {
                  fullLayout: true,
                  breadcrumb: "breadcrumb.pricingRange",
                },
              },
            ],
          },
          {
            path: "account",
            name: "account",
            beforeEnter: guards.requireAcceptedCookies,
            component: Account,
            meta: {
              maxGroupId: GroupService.INTERNAL_ADMIN,
            },
          },
          {
            path: "users/list",
            name: "layoutUsers",
            redirect: { name: "usersList" },
            beforeEnter: guards.requireAcceptedCookies,
            component: LayoutUsers,
            meta: {
              breadcrumb: "breadcrumb.users",
            },
            children: [
              {
                path: "",
                name: "usersList",
                beforeEnter: guards.requireAcceptedCookies,
                component: UsersList,
                meta: {
                  maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                  usersTitle: "users.title.main",
                },
              },
              {
                path: "users/new",
                name: "usersNew",
                beforeEnter: guards.requireAcceptedCookies,
                component: UsersNew,
                meta: {
                  breadcrumb: "breadcrumb.user.new",
                  maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                  usersTitle: "users.title.create",
                },
              },
              {
                path: "users/:userId/",
                name: "usersAccount",
                beforeEnter: guards.requireAcceptedCookies,
                component: UsersAccount,
                meta: {
                  breadcrumb: "breadcrumb.user.detail",
                  maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                  usersTitle: "users.title.account",
                },
              },
              {
                path: "users/:userId/edit",
                name: "usersEdit",
                beforeEnter: guards.requireAcceptedCookies,
                component: UsersEdit,
                meta: {
                  breadcrumb: "breadcrumb.user.edit",
                  maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                  usersTitle: "users.title.update",
                },
              },
            ],
          },
        ],
      },
      {
        path: "",
        redirect: { name: "home" },
        name: "publicZone",
        component: NoLayout,
        children: [
          {
            beforeEnter: guards.logout,
            path: "logout",
            name: "logout",
          },
        ],
      },
      {
        path: "sharing/:sharedToken",
        name: "sharingZone",
        beforeEnter: guards.requireSharedTokenAuth,
        component: LayoutSharing,
        redirect: { name: "shared_programHome" },
        children: [
          {
            path: "",
            name: "sharingProgram",
            component: LayoutProgram,
            redirect: { name: "shared_programHome" },
            props: true,
            children: [
              {
                path: "",
                name: "shared_programHome",
                component: Program,
                props: false,
                meta: {
                  layoutBackgroundColor: "white",
                },
              },
              {
                path: "offer/:offerId",
                name: "shared_programOffer",
                props: true,
                component: Offer,
                meta: {
                  layoutBackgroundColor: "white",
                },
              },
              {
                path: "offer/:offerId/pricing_range",
                name: "shared_offerPricingRange",
                props: true,
                component: OfferPricing,
                meta: {
                  layoutBackgroundColor: "white",
                },
              },
              {
                path: "offer/:offerId/program_product/:programProductId",
                name: "shared_programProduct",
                props: true,
                component: ProgramProductCustomization,
                meta: {
                  layoutBackgroundColor: "white",
                },
              },
              {
                path: "offer/:offerId/program_product/:programProductId/pricing_range",
                name: "shared_program_product_pricing_range",
                redirect: { name: "403" },
                props: true,
                component: PricingRange,
                meta: {
                  layoutBackgroundColor: "white",
                },
              },
              {
                path: "offer/:offerId/pricing-range-2",
                name: "shared_PricingRange",
                component: PricingRange,
                props: true,
                meta: {
                  breadcrumb: "breadcrumb.pricingRange",
                },
              },
            ],
          },
        ],
      },
      {
        path: "error",
        redirect: { name: "404" },
        name: "error",
        component: NoLayout,
        children: [
          {
            path: "403",
            name: "403",
            component: Page403,
          },
          {
            path: "404/:message?",
            name: "404",
            component: Page404,
            props: true,
          },
          {
            path: "500",
            name: "500",
            component: Page500,
          },
        ],
      },
      {
        path: "*",
        beforeEnter: guards.beforeEnter,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: guards.beforeEnter,
  },
]

if (window.Cypress) {
  // add route for testing purpose
  routes[0].children[0].children.push({
    path: "cypress",
    name: "cypress",
    component: CypressAuth,
  })
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router

import AbstractService from "../AbstractService"

const statusList = {
  DRAFT: "programParametersStartpage",
  SETTING_DESC: "programSynthesis",
  SETTING_SYNTHESIS: "programDocuments",
  SETTING_DOCUMENTS: "programVisualIdentity",
  SETTING_IDENTITY: "programNetwork",
  SETTING_NETWORK: "programPublishing",
  ACTIVE: "programParametersStartpage",
}

class Status extends AbstractService {
  getStatusRoute(status) {
    return statusList[status] || statusList["ACTIVE"]
  }
}

let StatusService = new Status()
export default StatusService

<template>
  <div>
    <ModalCaareaAndPartnerTaxes
      v-if="isModalCommissionAndTaxesOpen"
      @close="onCloseModal"
      :product-line-id="productLineId"
      :tax-type="taxType"
    ></ModalCaareaAndPartnerTaxes>
    <div class="commission-taxe__container" :class="containerClass">
      <h5 :class="titleClass">{{ titleText }}</h5>
      <div
        v-if="taxes.length < 1"
        class="tax-row__container"
        :data-cy="`no-${productLineId}-${taxType}-taxe`"
      >
        <p>{{ $t("commercial.no_fees_or_tax") }} {{ titleText }}</p>
      </div>
      <TaxRow v-for="(tax, index) in taxes" :key="index" :tax="tax"></TaxRow>
      <ActionButton
        :button-text="$t('button.modify')"
        :button-class="['commission-taxe__container-button']"
        btn-class="btn btn-block btn-tertiary"
        @click-action="modifyTaxes"
        :button-data-cy="`modify-${productLineId}-${taxType}-taxe`"
        data-toggle="modal"
        data-target="#modalCaareaAndPartnerTaxes"
      ></ActionButton>
    </div>
  </div>
</template>

<script>
import TaxRow from "@/components/commercial/TaxRow"
import ActionButton from "@/components/button/ActionButton"
import ModalCaareaAndPartnerTaxes from "@/components/commercial/ModalCaareaAndPartnerTaxes"
import { mapGetters } from "vuex"

export default {
  name: "CommissionsAndTaxes",
  components: { ActionButton, TaxRow, ModalCaareaAndPartnerTaxes },
  props: {
    textAlign: { type: String, default: "left" },
    titleText: { type: String, required: true },
    productLineId: { type: Number, required: true },
    taxType: { type: String, required: true },
  },
  data() {
    return {
      isModalCommissionAndTaxesOpen: false,
    }
  },
  computed: {
    ...mapGetters("productLine", ["getConfig"]),
    titleClass() {
      return [`commission-taxe__title--${this.textAlign}`]
    },
    containerClass() {
      return this.textAlign === "left" ? "commission-taxe__container-margin" : ""
    },
    taxes() {
      const config = this.getConfig(this.productLineId)
      return config["commercial"].values[this.taxType]
    },
  },
  methods: {
    modifyTaxes() {
      this.isModalCommissionAndTaxesOpen = true
    },
    onCloseModal() {
      this.isModalCommissionAndTaxesOpen = false
    },
  },
}
</script>

<style scoped lang="scss">
.commission-taxe__container {
  padding-top: 22px;
  display: grid;
  grid-template-columns: 1fr 260px;
  .commission-taxe__container-button {
    grid-column: 2 / span 1;
  }
  .tax-row__container {
    grid-column: 1 / span 2;
    margin-bottom: 8px;
    padding: 8px 27px 8px 16px;
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
  h5 {
    font-size: 14px;
    font-family: "Gilroy SemiBold", sans-serif;
    grid-column: 1 / span 2;
  }
  .commission-taxe__title--left {
    text-align: left;
  }
  .commission-taxe__title--right {
    text-align: right;
  }
}
.commission-taxe__container-margin {
  margin-right: 36px;
}
</style>

<template>
  <spinner v-if="isLoading" class="mt-5"></spinner>
  <div v-else class="layout-program-commercial-taxe">
    <ModalUwr
      v-if="isUwrModalOpen"
      :product-line-id="productLine.id"
      @update-product-line="onUpdateUwr"
      @close="onCloseUwrModal"
    ></ModalUwr>
    <CommercialHeader></CommercialHeader>
    <div class="program-commercial__offers">
      <HorizontalTabs :tabs="offers" @tab-click="changeSelectedOffer">
        {{ $t("commercial.offer") }} #
      </HorizontalTabs>
      <div>
        <div class="program-commercial__rate" data-cy="uwr-taxe">
          <div class="program-commercial__rate-div">
            <p>{{ $t("commercial.subscription_rate") }}</p>
            <p data-cy="uwr-value">{{ form.config.commercial.values.uwr_target }} %</p>
          </div>
          <ActionButton
            :button-text="$t('button.modify')"
            btn-class="btn btn-block btn-tertiary"
            @click-action="openUwrModal"
            button-data-cy="modify-uwr-taxe"
            data-toggle="modal"
            data-target="#modalUwr"
          ></ActionButton>
        </div>
      </div>
      <div
        v-for="programProduct in listProgramProducts(offerIdSelected)"
        :key="programProduct.id"
        class="program-commercial__products"
      >
        <ProductCommercialTaxes
          :program-product="programProduct"
        ></ProductCommercialTaxes>
      </div>
    </div>
  </div>
</template>

<script>
import CommercialHeader from "@/layouts/components/CommercialHeader"
import HorizontalTabs from "@/components/utils/HorizontalTabs"
import ActionButton from "@/components/button/ActionButton"
import ProductCommercialTaxes from "@/components/commercial/ProductCommercialTaxes"
import ModalUwr from "@/components/commercial/ModalUwr"
import { mapGetters } from "vuex"
export default {
  name: "LayoutProgramCommercialTaxes",
  components: {
    ActionButton,
    HorizontalTabs,
    CommercialHeader,
    ProductCommercialTaxes,
    ModalUwr,
  },
  computed: {
    ...mapGetters("program", ["listOffersByProgramId"]),
    ...mapGetters("offer", ["listProgramProducts", "getOffer"]),
    ...mapGetters("productLine", ["getConfig", "getUpdateErrors"]),
  },
  data() {
    return {
      offers: null,
      offerIdSelected: null,
      isLoading: false,
      isUwrModalOpen: false,
      form: {
        config: null,
      },
      productLine: null,
    }
  },
  methods: {
    changeSelectedOffer(offerId) {
      this.offerIdSelected = offerId
      this.updateOfferAndProductLineData(this.getOffer(offerId))
    },
    openUwrModal() {
      this.isUwrModalOpen = true
    },
    onCloseUwrModal() {
      this.isUwrModalOpen = false
    },
    onUpdateUwr(value) {
      this.form.config.commercial.values.uwr_target = Number(value)
      this.onUpdateProductLine()
    },
    async onUpdateProductLine() {
      const payload = {
        productLineId: this.productLine.id,
        data: {
          config: this.form.config,
        },
      }
      try {
        await this.$store.dispatch("productLine/update", payload)
      } catch (error) {
        console.log("error", error)
      } finally {
        console.log(
          "finally",
          Object.keys(this.getUpdateErrors(this.productLine.id)).length
        )
        if (!Object.keys(this.getUpdateErrors(this.productLine.id)).length) {
          this.onCloseUwrModal()
        }
      }
    },
    updateOfferAndProductLineData(offer) {
      this.offerIdSelected = offer.id
      this.productLine = offer.program_products[0].product_lines[0]
      this.form.config = this.getConfig(this.productLine.id)
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("program/getProgram", this.$route.params.programId)
    this.offers = this.listOffersByProgramId(this.$route.params.programId)
    this.updateOfferAndProductLineData(this.offers[0])
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.layout-program-commercial-taxe {
  margin: 0 12rem;
}
.program-commercial__offers {
  margin-top: 25px;
  background-color: #fff;
  .program-commercial__rate {
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding: 40px;
    div:nth-child(2) {
      grid-column: 3 / span 1;
    }
    .program-commercial__rate-div {
      grid-column: 1 / span 4;
      display: grid;
      grid-template-columns: auto 50px;
      background-color: rgba(214, 203, 180, 0.2);
      align-items: center;
      margin-bottom: 8px;
      padding: 10px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
.program-commercial__products {
  padding: 0 40px 40px;
}
</style>

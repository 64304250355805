import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import ProgramService from "@/services/business/ProgramService"
import VehicleSearchService from "@/services/business/VehicleSearchService"

// STATES (snake_case)
const state = {
  filters: {
    vehicle_type: {
      rank: 1,
      data: "all_vehicles",
      isDisabled: true,
    },
    make: {
      rank: 2,
      data: [],
      isDisabled: false,
    },
    model: {
      rank: 3,
      data: [],
      isDisabled: true,
    },
    engine_type: {
      rank: 8,
      data: [],
      isDisabled: false,
    },
  },
  selectable_values: {
    vehicle_type: [
      { id: "vn_only", local_name: "vehicleChoice.vn_only" },
      { id: "all_vehicles", local_name: "vehicleChoice.all_vehicles" },
    ],
    makes: [],
    models: [],
    engine_types: [],
  },
  // List of filters to take into account for each API call, depending on the context :
  // - vehicle_type : determined from program's vehicle tag
  // - engine_type : determined from program's engine tag
  context_filters: ["vehicle_type", "engine_type"],
  program_context_values: {
    engine_type: [],
    is_vn: null,
  },
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  INIT_PROGRAM_CONTEXT_VALUES(state, payload) {
    state.program_context_values.engine_type = payload.engine_types
    state.program_context_values.is_vn = payload.is_vn
  },
  SET_MAKES_SELECTABLE_VALUES(state, makes) {
    state.selectable_values.makes = makes
  },
  SET_MODELS_SELECTABLE_VALUES(state, models) {
    state.selectable_values.models = models
  },
  SET_ENGINE_TYPES_SELECTABLE_VALUES(state, engine_types) {
    state.selectable_values.engine_types = engine_types
  },
  SET_MAKE_FILTER_VALUES(state, makes) {
    state.filters.make.data = makes
  },
  SET_MODEL_FILTER_VALUES(state, models) {
    state.filters.model.data = models
  },
  SET_MODEL_FILTER_DISABLED_PROP(state, value) {
    state.filters.model.isDisabled = value
  },
  SET_VEHICLE_TYPE_FILTER_VALUE(state, value) {
    state.filters.vehicle_type.data = value
  },
}

// ACTIONS (camelCase)
const actions = {
  async initSelectableValues({ state, commit, dispatch, rootGetters }, offerId) {
    await dispatch("_retrieveProgramContext")
    let payload = { engine_type: state.program_context_values.engine_type }
    if (state.program_context_values.is_vn) {
      payload["is_vn"] = state.program_context_values.is_vn
    }
    const makesSelectableValues = await HttpService.get(
      UrlService.render("cvdbMake", {}, payload)
    )
    commit("SET_MAKES_SELECTABLE_VALUES", makesSelectableValues)
    payload = state.program_context_values.is_vn
      ? { is_vn: state.program_context_values.is_vn }
      : {}
    const engineTypesSelectableValues = await HttpService.get(
      UrlService.render("cvdbEngineType", {}, payload)
    )
    commit("SET_ENGINE_TYPES_SELECTABLE_VALUES", engineTypesSelectableValues)
    const offerMakeEligibilityCriteria =
      rootGetters["offer/listEligibilityCriteriaValues"](offerId).make
    if (offerMakeEligibilityCriteria.length > 0) {
      const makesFilterValues = offerMakeEligibilityCriteria.map((make) => make.id)
      await dispatch("setMakeFilterValues", makesFilterValues)
    }
    const offerModelEligibilityCriteria =
      rootGetters["offer/listEligibilityCriteriaValues"](offerId).model
    if (offerModelEligibilityCriteria.length > 0) {
      const modelsFilterValues = offerModelEligibilityCriteria.map((model) => model.id)
      await dispatch("setModelFilterValues", modelsFilterValues)
    }
  },
  async _retrieveProgramContext({ commit, rootGetters }) {
    const engineTypes = ProgramService.listEngineCodesFromEngineTag(
      rootGetters["program/getProgram"].engine_tag.label
    )
    const isVn = ProgramService.isVnContextValueFromProgramVehicleTag(
      rootGetters["program/getProgram"].vehicle_tag.label
    )
    if (isVn) {
      commit("SET_VEHICLE_TYPE_FILTER_VALUE", "vn_only")
    }
    commit("INIT_PROGRAM_CONTEXT_VALUES", {
      engine_types: engineTypes,
      is_vn: isVn,
    })
  },
  async setMakeFilterValues({ commit, dispatch }, makes) {
    commit("SET_MAKE_FILTER_VALUES", makes)
    if (makes.length === 0) {
      commit("SET_MODEL_FILTER_DISABLED_PROP", true)
      commit("SET_MODEL_FILTER_VALUES", [])
    } else {
      commit("SET_MODEL_FILTER_DISABLED_PROP", false)
    }
    await dispatch("_retrieveNewSelectableValues", "make")
  },
  async setModelFilterValues({ commit, dispatch }, models) {
    commit("SET_MODEL_FILTER_VALUES", models)
    await dispatch("_retrieveNewSelectableValues", "model")
  },
  async _retrieveNewSelectableValues({ commit }, filterUpdated) {
    const selectableValuesToUpdate = ["engine_type"]
    if (filterUpdated === "make") {
      selectableValuesToUpdate.push("model")
    }
    for (const select of selectableValuesToUpdate) {
      const queryParams = VehicleSearchService.createQueryParams(
        select,
        state.filters,
        state.context_filters
      )
      if (
        !VehicleSearchService.isFilter(select, "engine_type") &&
        VehicleSearchService.isFilterEmpty(state.filters, "engine_type")
      ) {
        Object.assign(queryParams, {
          engine_type: state.program_context_values.engine_type,
        })
      }
      const selectableValues = await HttpService.get(
        UrlService.render(
          VehicleSearchService.urlFilterNameMatching[select],
          {},
          queryParams
        )
      )
      commit(
        `SET_${VehicleSearchService.uppercaseSelectableNameMatching[select]}_SELECTABLE_VALUES`,
        selectableValues
      )
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getMakeObjectFromId: (state) => (makeId) => {
    return state.selectable_values.makes.filter(
      (make) => make.id === parseInt(makeId)
    )[0]
  },
  getModelObjectFromId: (state) => (modelId) => {
    return state.selectable_values.models.filter(
      (model) => model.id === parseInt(modelId)
    )[0]
  },
  getMakeIdFromName: (state) => (makeName) => {
    const make = state.selectable_values.makes.filter(
      (make) => make.name === makeName
    )[0]
    return make.id.toString()
  },
  getModelIdFromName: (state) => (modelName) => {
    const model = state.selectable_values.models.filter((model) => {
      return model.name === modelName
    })[0]
    return model.id.toString()
  },
  getMakeSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.makes
    )
  },
  getModelSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.models
    )
  },
  getMakeNameFromId: (state) => (makeId) => {
    const make = state.selectable_values.makes.filter(
      (make) => make.id === parseInt(makeId)
    )[0]
    return make.name
  },
  getModelNameFromId: (state) => (modelId) => {
    const model = state.selectable_values.models.filter(
      (model) => model.id === parseInt(modelId)
    )[0]
    return model.name
  },
  getMakeLocalNameFromName: (state) => (makeName) => {
    const make = state.selectable_values.makes.filter(
      (make) => make.name === makeName
    )[0]
    return make?.local_name
  },
  getModelLocalNameFromName: (state) => (modelName) => {
    const model = state.selectable_values.models.filter(
      (model) => model.name === modelName
    )[0]
    return model?.local_name
  },
  getEngineTypeSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.engine_types
    )
  },
  isModelDisabled: (state) => {
    return state.filters.model.isDisabled
  },
  isStoreInit: (state) => {
    return state.selectable_values.makes.length > 0
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div class="crit-option-selection">
    <label
      class="crit-option-selection__label"
      :for="getId"
      :data-cy="`criterion-option-value-${optionKey}`"
    >
      {{ $t("criteria." + criteriaType + "." + optionKey) }}
      <input
        class="crit-option-selection__checkmark"
        :id="getId"
        type="checkbox"
        :checked="selected"
        @change="onCheck($event.target.checked)"
        :data-cy="`criterion-option-${optionKey}-checkbox`"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "CriterionOptionSelection",
  props: {
    option: { type: Object, required: true },
    selected: { type: Boolean, required: true },
    id: { type: String, default: "" },
    criteriaType: { type: String, required: true },
  },
  data() {
    return {}
  },
  computed: {
    getId() {
      return this.id !== "" ? this.id : `id-${this.optionValue}`
    },
    optionValue() {
      return Object.values(this.option)[0]
    },
    optionKey() {
      return Object.keys(this.option)[0]
    },
  },
  methods: {
    onCheck(value) {
      this.$emit("update:selected", value)
    },
  },
}
</script>

<style scoped lang="scss">
.crit-option-selection {
  display: grid;
  grid-template-rows: 1fr;
  background-color: $caarealightgray;
  height: 3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  align-items: center;
  position: relative;
  user-select: none;
}

.crit-option-selection__label {
  display: grid;
  grid-template-columns: 4fr 1fr;
  color: $bleucaarea;
  cursor: pointer;
  margin-bottom: 0;

  input {
    justify-self: end;
  }
}
</style>

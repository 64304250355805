<template>
  <div class="col-3 pr-0 vl">
    <div class="list-group list-group-flush">
      <div class="pb-3" v-for="(label, key) in tabs" :key="key">
        <a
          :id="key"
          href=""
          @click.prevent="onTabClick(key)"
          class="list-group-item list-group-item-action py-2 menu-item"
          :class="{ activeMenuItem: isActive(key) }"
          :data-cy="`${key}-menu-item`"
        >
          {{ label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerticalTabs",
  props: {
    tabs: {
      type: Object,
      required: true,
    },
    activeTab: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    isActive(menuItem) {
      return String(this.activeTab) === String(menuItem)
    },
    onTabClick(menuItem) {
      this.$emit("tab-change", menuItem)
    },
  },
}
</script>

<style scoped>
.menu-item {
  border-left: 6px solid #fff;
  border-bottom: 0;
  border-right: 0;
  border-top: 0;
}

.activeMenuItem {
  color: #1c2b4e;
  background-color: #e7eaf0;
  border-left: 6px solid #1c2b4e;
}
</style>

<template>
  <div class="coverage-crit-selection">
    <div class="coverage-crit-selection__data">
      <div v-if="isDurationKmCriterion === false">
        <CriterionOptionSelection
          v-for="(value, key) in userSelectableValues.duration"
          class="coverage-crit-selection__data__duration"
          :key="key"
          :option="{ [key]: value }"
          :selected.sync="userSelectableValues.duration[key]"
          :id="`id-${criterionType}-${key}`"
          :criteria-type="criterionType"
          @update:selected="onUpdate()"
        ></CriterionOptionSelection>
      </div>
      <div v-else>
        <CriterionOptionAccordion
          v-for="(value, key) in userSelectableValues.duration_km"
          class="coverage-crit-selection__data__duration-distance"
          :key="key"
          :option="{ [key]: value }"
          :linked-value="key"
          :linked-criterion-options-selection.sync="
            userSelectableValues.duration_km[key]
          "
          :product-line-id="productLineId"
          :criteria-type="criterionType"
          @linked-criterion-options-selection-update="onUpdate"
          @update:selected="onUpdate()"
        ></CriterionOptionAccordion>
      </div>
    </div>
    <div class="coverage-crit-selection__data__buttons">
      <button
        class="btn btn-primary"
        :disabled="isSelectAllButtonDisabled"
        @click="onClick('all')"
        data-cy="coverage-select-all-button"
      >
        {{ $t("button.selectAll") }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="isUnselectAllButtonDisabled"
        @click="onClick('none')"
        data-cy="coverage-unselect-all-button"
      >
        {{ $t("button.unselectAll") }}
      </button>
    </div>
  </div>
</template>

<script>
import CriterionOptionSelection from "@/components/presales/modal/components/CriterionOptionSelection"
import CriterionOptionAccordion from "@/components/presales/modal/components/CriterionOptionAccordion"
import BeneficiaryCriteriaService from "@/services/business/BeneficiaryCriteriaService"

export default {
  name: "CoverageCriteriaOptionsSelection",
  components: { CriterionOptionSelection, CriterionOptionAccordion },
  props: {
    userSelectableValues: { type: Object, required: true },
    productLineId: { type: Number, required: true },
  },
  data() {
    return {
      isDurationKmCriterion: false,
      criterionType: "durations",
      isSelectAllButtonDisabled: false,
      isUnselectAllButtonDisabled: false,
    }
  },
  watch: {
    userSelectableValues: {
      handler(val) {
        this.disableSelectButtons(val)
      },
      deep: true,
    },
  },
  methods: {
    onUpdate() {
      this.$emit("coverage-options-update")
    },
    onClick(value) {
      if (this.isDurationKmCriterion === true) {
        if (value === "all") {
          for (const duration of Object.keys(this.userSelectableValues.duration_km)) {
            for (const distance of Object.keys(
              this.userSelectableValues.duration_km[duration]
            )) {
              // eslint-disable-next-line vue/no-mutating-props
              this.userSelectableValues.duration_km[duration][distance] = true
            }
          }
        } else if (value === "none") {
          for (const duration of Object.keys(this.userSelectableValues.duration_km)) {
            for (const distance of Object.keys(
              this.userSelectableValues.duration_km[duration]
            )) {
              // eslint-disable-next-line vue/no-mutating-props
              this.userSelectableValues.duration_km[duration][distance] = false
            }
          }
        }
      } else {
        if (value === "all") {
          for (const duration of Object.keys(this.userSelectableValues.duration)) {
            // eslint-disable-next-line vue/no-mutating-props
            this.userSelectableValues.duration[duration] = true
          }
        } else if (value === "none") {
          for (const duration of Object.keys(this.userSelectableValues.duration)) {
            // eslint-disable-next-line vue/no-mutating-props
            this.userSelectableValues.duration[duration] = false
          }
        }
      }
      this.$emit("coverage-options-update")
    },
    disableSelectButtons(selectableValues) {
      this.isSelectAllButtonDisabled =
        BeneficiaryCriteriaService.areAllCoverageUserSelectableValuesTrue(
          selectableValues
        )
      this.isUnselectAllButtonDisabled =
        BeneficiaryCriteriaService.areAllCoverageUserSelectableValuesFalse(
          selectableValues
        )
    },
  },
  created() {
    if (this.userSelectableValues.hasOwnProperty("duration_km")) {
      this.isDurationKmCriterion = true
      this.criterionType = "kms"
    }
    this.disableSelectButtons(this.userSelectableValues)
  },
}
</script>

<style scoped lang="scss">
.coverage-crit-selection {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "data buttons";
  gap: 50px;
}

.coverage-crit-selection__data {
  grid-area: data;
}

.coverage-crit-selection__data__duration {
  margin-bottom: 0.5rem;
}

.coverage-crit-selection__data__duration-distance {
  margin-bottom: 0.5rem;
}

.coverage-crit-selection__data__buttons {
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>

// STATES (snake_case)
import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "../services/technical/UrlService"
import ArrayService from "../services/technical/ArrayService"

const state = {
  offers: null,
  update_errors: {},
  update_content_errors: {},
  coefficient_criteria: null,
  current_offer_id: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_OFFERS(state, offers) {
    state.offers = offers.reduce((acc, offer) => {
      acc[offer.id] = offer
      return acc
    }, {})
  },
  SET_OFFER(state, offer) {
    if (state.offers === null) state.offers = {}
    Vue.set(state.offers, offer.id, offer)
  },
  SET_OFFER_UPDATE_ERRORS(state, payload) {
    Vue.set(state.update_errors, payload.id, payload.errors)
  },
  SET_OFFER_UPDATE_CONTENT_ERRORS(state, payload) {
    Vue.set(state.update_content_errors, payload.id, payload.errors)
  },
  SET_COEFFICIENT_CRITERIA(state, payload) {
    if (state.coefficient_criteria === null) state.coefficient_criteria = {}
    Vue.set(state.coefficient_criteria, payload.id, payload.coefficientCriteria)
  },
  RESET_OFFER_UPDATE_ERRORS(state, idToRemove) {
    state.update_errors = Object.keys(state.update_errors).reduce((acc, id) => {
      if (parseInt(id) !== parseInt(idToRemove)) {
        acc[id] = state.update_errors[id]
      }
      return acc
    }, {})
  },
  RESET_OFFER_UPDATE_CONTENT_ERRORS(state, idToRemove) {
    if (state.update_content_errors.hasOwnProperty(idToRemove))
      Vue.delete(state.update_content_errors, idToRemove)
  },
  SET_CURRENT_OFFER_ID(state, offerId) {
    state.current_offer_id = offerId
  },
}

// ACTIONS (camelCase)
const actions = {
  _updateOtherStores({ dispatch }, offer) {
    // Update program products in dedicated store
    dispatch("programProduct/setProgramProducts", offer.program_products, {
      root: true,
    })

    // Update product lines in dedicated store
    const productLines = offer.program_products.reduce((acc, product) => {
      for (const productLine in product.product_lines) {
        // noinspection JSUnfilteredForInLoop
        acc.push(product.product_lines[productLine])
      }
      return acc
    }, [])
    dispatch("productLine/setProductLines", [...productLines], { root: true })
  },

  async getOffer({ dispatch, commit }, id) {
    try {
      let offer = await HttpService.get(UrlService.render("offerById", { id: id }))
      commit("SET_OFFER", offer)

      // Update program products in dedicated store
      dispatch("programProduct/setProgramProducts", offer.program_products, {
        root: true,
      })

      // Update product lines in dedicated store
      const productLines = offer.program_products.reduce((acc, product) => {
        for (const productLine in product.product_lines) {
          // noinspection JSUnfilteredForInLoop
          acc.push(product.product_lines[productLine])
        }
        return acc
      }, [])
      dispatch("productLine/setProductLines", [...productLines], { root: true })
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async updateOffer({ commit }, payload) {
    try {
      let offer = await HttpService.post(UrlService.render("offerUpdate"), payload)
      commit("SET_OFFER", offer)
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async updateEligibilityCriteria({ dispatch, commit }, payload) {
    commit("RESET_OFFER_UPDATE_ERRORS", payload.id)
    let offer = null
    try {
      offer = await HttpService.put(
        UrlService.render("offerById", { id: payload.id }),
        { eligibility_criteria: payload.eligibility_criteria }
      )
    } catch (e) {
      console.error("updateEligibilityCriteria failed : ", e)
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_OFFER_UPDATE_ERRORS", {
            id: payload.id,
            errors: e.data,
          })
          return
        }
      }
      throw e
    }
    commit("SET_OFFER", offer)
    dispatch("_updateOtherStores", offer)
  },

  async activate({ commit }, offerId) {
    try {
      const offer = await HttpService.post(
        UrlService.render("offerActivation", { id: offerId })
      )
      commit("SET_OFFER", offer)
    } catch (e) {
      console.error("offer activation failed: ", e)
      throw e
    }
  },

  setOffers({ commit }, offers) {
    commit("SET_OFFERS", offers)
  },

  async getCoefficientCriteria({ commit }, offerId) {
    try {
      const coefficientCriteria = await HttpService.get(
        UrlService.render("offerCoeffCriteria", { id: offerId })
      )
      commit("SET_COEFFICIENT_CRITERIA", {
        id: offerId,
        coefficientCriteria: coefficientCriteria,
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  setLastSelectedVehicle({ commit }, payload) {
    commit("SET_OFFER", {
      ...state.offers[payload.offerId],
      vehicle: payload.vehicle,
    })
  },

  resetUpdateErrors({ commit }, offerId) {
    commit("RESET_OFFER_UPDATE_ERRORS", offerId)
  },

  async updateOfferContent({ commit }, payload) {
    try {
      commit("RESET_OFFER_UPDATE_CONTENT_ERRORS", payload.offer_content_id)
      let offer = await HttpService.put(
        UrlService.render("offerContentById", { id: payload.offer_content_id }),
        payload.details
      )
      commit("SET_OFFER", offer)
    } catch (e) {
      console.error("updateOfferContent failed : ", e)
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_OFFER_UPDATE_CONTENT_ERRORS", {
            id: payload.offer_content_id,
            errors: e.data,
          })
          return
        }
      }
      throw e
    }
  },

  setCurrentOfferId({ commit }, offerId) {
    commit("SET_CURRENT_OFFER_ID", offerId)
  },
}

// GETTERS (camelCase)
const getters = {
  isOfferActive: (state) => (id) =>
    state.offers.hasOwnProperty(id) ? state.offers[id].status === "ACTIVE" : false,
  getOffer: (state) => (id) =>
    state.offers.hasOwnProperty(id) ? state.offers[id] : null,
  listEligibilityCriteria: (state) => (id) =>
    Object.keys(state.offers[id].eligibility_criteria),
  listEligibilityCriteriaValues: (state) => (id) =>
    state.offers[id].eligibility_criteria,
  listProgramProducts: (state) => (id) => state.offers[id].program_products,
  getCurrentProgramProductIndex: (state, getters) => (offerId, programProductId) =>
    getters
      .listProgramProducts(offerId)
      .findIndex((product) => product.id === parseInt(programProductId)),
  isLastProgramProduct: (state, getters) => (offerId, programProductId) => {
    const currentProgramProductIndex = getters.getCurrentProgramProductIndex(
      offerId,
      programProductId
    )
    if (currentProgramProductIndex === -1) {
      return false
    }
    return (
      currentProgramProductIndex + 1 === getters.listProgramProducts(offerId).length
    )
  },
  getNextProgramProductId: (state, getters) => (offerId, programProductId) => {
    const currentProgramProductIndex = getters.getCurrentProgramProductIndex(
      offerId,
      programProductId
    )
    if (
      currentProgramProductIndex === -1 ||
      currentProgramProductIndex + 1 > getters.listProgramProducts(offerId).length
    ) {
      return null
    }
    return getters.listProgramProducts(offerId)[currentProgramProductIndex + 1].id
  },
  lastSelectedVehicle: (state) => (id) => {
    if (state.offers[id] && state.offers[id].hasOwnProperty("vehicle"))
      return state.offers[id].vehicle
    for (const programProduct of state.offers[id].program_products) {
      for (const productLine of programProduct.product_lines) {
        if (
          productLine.config.vehicle &&
          productLine.config.vehicle.hasOwnProperty("name")
        ) {
          return JSON.parse(JSON.stringify(productLine.config.vehicle))
        }
      }
    }
    return null
  },
  getUpdateErrors: (state) => (id) =>
    state.update_errors.hasOwnProperty(id) ? state.update_errors[id] : null,
  getCoefCritOptions: (state) => (id, criterion) => {
    if (state.coefficient_criteria !== null) {
      return state.coefficient_criteria[id]
        ? state.coefficient_criteria[id][criterion]
        : {}
    }
    return {}
  },
  getUpdateContentErrors: (state) => (id) =>
    state.update_content_errors.hasOwnProperty(id)
      ? state.update_content_errors[id]
      : null,
  getCurrentOffer: (state) => {
    return state.current_offer_id !== null &&
      state.offers.hasOwnProperty(state.current_offer_id)
      ? state.offers[state.current_offer_id]
      : null
  },
  getOfferContentByType: (state, getters) => (offerId, type) => {
    const offer = getters.getOffer(offerId)
    if (offer) {
      return offer.offer_contents.filter(
        (offerContent) => offerContent.type === type
      )[0]
    }
    return null
  },
  getB2COfferContent: (state, getters) => (offerId) =>
    getters.getOfferContentByType(offerId, "B2C"),
  getOfferByProgramProductId: (state, getters) => (programProductId) => {
    const offerId = Object.keys(state.offers).filter((offerId) => {
      const ppIds = getters.listProgramProducts(offerId).map((pp) => pp.id)
      return ppIds.includes(programProductId)
    })
    return state.offers[offerId]
  },
  listCurrentOfferSharedCoefCriteriaWithSpecificNames: (state, getters) => {
    // List all the coefficient criteria shared within the current offer
    // For criteria that have specific name for a given product, use this specific name
    // (ex: will return "sales_mode_vo" and "sales_mode_maintenance" instead of just "sales_mode")
    const offer = getters.getCurrentOffer
    let criteria = []
    for (const program_product of offer.program_products) {
      const productLine = program_product.product_lines[0]
      Object.values(productLine.config.shared_coefficient_criteria).forEach(
        (criterion) => ArrayService.pushIfNotExist(criteria, criterion)
      )
    }
    return criteria
  },
  isPaymentFrequencyMonthly: (state, getters, rootState, rootGetters) => (offerId) => {
    const productLineId =
      getters.getOffer(offerId).program_products[0].product_lines[0].id
    return (
      rootGetters["productLine/getPaymentFrequencyValue"](productLineId) === "monthly"
    )
  },
  getOfferNumberInProgram: (state, getters, rootState, rootGetters) => (offerId) => {
    const program = rootGetters["program/getProgramByOfferId"](offerId)
    const offerIndex = program.offers.findIndex((offer) => offer.id === offerId)
    const isOfferFound = offerIndex !== -1
    return isOfferFound ? offerIndex + 1 : null
  },
  getFirstProductLineId: (state, getters) => (offerId) => {
    return getters.getOffer(offerId).program_products[0].product_lines[0].id
  },
  isOfferExternal: (state) => (offerId) =>
    state.offers[offerId].program_products[0].product_lines[0].is_external,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import AbstractService from "@/services/AbstractService"
import store from "@/store"

class CurrencyService extends AbstractService {
  currencyCode = {
    kow: "₩",
    eur: "€",
  }

  getCurrencySymbol() {
    const currencySymbol = process.env.VUE_APP_CURRENCY_CODE || "eur"
    return this.currencyCode[currencySymbol]
  }

  getProductMemoCurrencySymbol() {
    return store.getters["config/getCurrentLang"] !== "ko"
      ? this.currencyCode["eur"]
      : this.currencyCode["kow"]
  }

  getCurrencyEurToKrwRate() {
    return process.env.VUE_APP_EUR_TO_KRW_CHANGE_RATE || 1400
  }
}

export default new CurrencyService()

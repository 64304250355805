<template>
  <ModalBootstrap
    id-modal="modalEligibilityCriteria"
    class="criteria"
    :title="$t('programs.eligibility_criteria_modal.title')"
    modal-size="large"
    @close="onClose"
    data-cy="presales-eligibility-criteria-modal"
  >
    <template #body class="mx-3">
      <spinner v-if="isLoading" class="mt-5"></spinner>
      <div v-else>
        <div class="row">
          <div class="col-12 mb-2 text-center font-size-13">
            {{ $t("programs.eligibility_criteria_modal.desc") }}
          </div>
          <div class="col-12">
            <table class="table table-striped" aria-describedby="programs criteria">
              <thead></thead>
              <tbody>
                <tr v-for="criterion in eligibilityCriteriaFiltered" :key="criterion">
                  <td v-if="isEligibilityCriterionSelect(criterion)">
                    <FormRowSelect
                      v-if="criterion === 'engine_type'"
                      :is-loading="engineCodeOptionsLoading"
                      :key="criterion"
                      :label="getEligibilityCriterionLabel(criterion)"
                      :label-class="['text-left', 'col-sm-6']"
                      :control-class="['col-sm-6']"
                      :name="getEligibilityInputName(criterion, productLineId)"
                      :selected-option.sync="engineCodesSelectedOptions"
                      @update:selected-option="onUpdate(criterion)"
                      label-select-attr="label"
                      :allow-empty="true"
                      :placeholder="
                        form.values[criterion].length === 0
                          ? getEligibilityEmptyLabel(criterion)
                          : ''
                      "
                      :select-options="getEligibilityCriterionOptions(criterion)"
                      :multiple="true"
                    ></FormRowSelect>
                    <FormRowSelect
                      v-else
                      :is-loading="criterion === 'model' && modelOptionsLoading"
                      :key="criterion"
                      :label="getEligibilityCriterionLabel(criterion)"
                      :label-class="['text-left', 'col-sm-6']"
                      :control-class="['col-sm-6']"
                      :name="getEligibilityInputName(criterion, productLineId)"
                      :selected-option.sync="form.values[criterion]"
                      @update:selected-option="onUpdate(criterion)"
                      label-select-attr="label"
                      :allow-empty="true"
                      :placeholder="
                        form.values[criterion].length === 0
                          ? getEligibilityEmptyLabel(criterion)
                          : ''
                      "
                      :select-options="getEligibilityCriterionOptions(criterion)"
                      :disabled="
                        (criterion === 'model' && isModelDisabled) ||
                        isEligibilityCriterionDisabled(criterion, form.values.make)
                      "
                      :multiple="true"
                    ></FormRowSelect>
                  </td>
                  <td v-else-if="criterion === 'max_age'">
                    <div class="row align-items-center">
                      <div class="row align-items-center mx-auto text-left col-sm-6">
                        <label class="col-7 pl-0 mb-0">
                          {{
                            getMaxAgeEligibilityCriterionLabel(
                              form.values["max_age_unity"]
                            )
                          }}
                        </label>
                        <FormInput
                          v-model="form.values[criterion]"
                          :name="getEligibilityInputName(criterion, productLineId)"
                          type="text"
                          :debounce-input="false"
                          :errors="eligibilityUpdateErrors"
                          :disabled="disabled"
                          @input="onUpdate(criterion)"
                          class="col-5"
                        />
                      </div>
                      <div class="col-sm-6">
                        <FormSelect
                          :selected-option.sync="form.values['max_age_unity']"
                          :select-options="getAgeUnityOptions"
                          label-select-attr="label"
                          :disabled="disabled"
                          :name="
                            getEligibilityInputName('max_age_unity', productLineId)
                          "
                        ></FormSelect>
                      </div>
                    </div>
                  </td>
                  <td v-else>
                    <FormRowInput
                      v-model="form.values[criterion]"
                      :label="getEligibilityCriterionLabel(criterion)"
                      :label-class="['text-left', 'col-sm-6']"
                      :control-class="['col-sm-6']"
                      :name="getEligibilityInputName(criterion, productLineId)"
                      type="text"
                      :errors="eligibilityUpdateErrors"
                      @input="onUpdate(criterion)"
                    ></FormRowInput>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col d-flex">
            <button
              class="btn btn-primary ml-auto"
              @click.prevent="onSubmitButton"
              data-cy="validate-eligibility-criteria"
            >
              {{ $t("button.validate") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"
import FormProductLineMixin from "@/components/presales/mixins/FormProductLineMixin"
import EligibilityCriteriaMixin from "@/components/presales/mixins/EligibilityCriteriaMixin"
import ArrayService from "../../../services/technical/ArrayService"
import NumberService from "../../../services/technical/NumberService"
import ProgramProductCustomizationService from "../../../services/pages/ProgramProductCustomizationService"
import ProgramService from "../../../services/business/ProgramService"
import i18n from "@/i18n"
import VehicleSearchService from "@/services/business/VehicleSearchService"

export default {
  name: "ModalEligibilityCriteria",
  components: { ModalBootstrap },
  mixins: [FormProductLineMixin, EligibilityCriteriaMixin],
  props: {
    productLineId: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      form: { custom: null, values: null },
      default_values: {},
      isLoading: true,
      modelOptionsLoading: false,
      engineCodeOptionsLoading: false,
      engineCodesSelectedOptions: [],
    }
  },
  computed: {
    ...mapGetters("offer", ["listEligibilityCriteriaValues"]),
    ...mapGetters("program", ["getProgram"]),
    ...mapGetters("productLine", [
      "getEligibilityCriteriaDefaultValues",
      "getCoefCritOptions",
      "getUpdateErrors",
    ]),
    ...mapGetters("programProductCustomization", [
      "getModelIdFromName",
      "listCustomEligibilityCriteria",
      "getEligibilityCriteriaValues",
      "getCriterionValueToDisplay",
      "getEligibilityCriterionValue",
    ]),
    ...mapGetters("offerContext", [
      "getMakeIdFromName",
      "getMakeLocalNameFromName",
      "getModelLocalNameFromName",
      "getMakeNameFromId",
    ]),
    ...mapGetters("eligibilityCriteriaModal", [
      "getMakeSelectableValues",
      "getModelSelectableValues",
      "getEngineTypeSelectableValues",
      "getMakeObjectFromId",
      "getModelObjectFromId",
      "isModelDisabled",
      "listFilterModelValues",
    ]),
    eligibilityCriteriaFiltered() {
      return this.listEligibilityCriteriaFiltered(this.productLineId)
    },
    eligibilityUpdateErrors() {
      const errors = this.getUpdateErrors(this.productLineId)
      if (
        errors.hasOwnProperty("config") &&
        errors.config.hasOwnProperty("eligibility") &&
        errors.config.eligibility.hasOwnProperty("values")
      ) {
        return Object.entries(errors.config.eligibility.values).reduce(
          (acc, [k, v]) => {
            acc[this.getEligibilityInputName(k, this.productLineId)] = v
            return acc
          },
          {}
        )
      }
      return {}
    },
    hasUpdateFailed() {
      return Object.keys(this.eligibilityUpdateErrors).length > 0
    },
  },
  methods: {
    onClose() {
      // Reset update error to avoid displaying error of a previous update when opening the modal for the first time
      // (scenario: open modal -> enter invalid value -> validate -> update error -> close modal -> quit current page
      //            -> go back to current page -> open modal : invalid criterion has now its default value so the
      //            update error should not be displayed)
      if (this.eligibilityUpdateErrors) {
        this.$store.dispatch("productLine/resetUpdateErrors", this.productLineId)
      }
      this.closeModal()
    },
    async onSubmitButton() {
      this.cleanCriteriaBeforeStoreUpdate()
      const makes = this.form.values.make.map((make) => this.getMakeObjectFromId(make))
      const models = this.form.values.model.map((model) =>
        this.getModelObjectFromId(model)
      )
      const engineCodeValue = []
      const selectableValues = this.getEngineTypeSelectableValues
      this.form.values.engine_code.forEach((value) => {
        if (value === i18n.t("vehicleChoice.hybrid")) {
          engineCodeValue.push(
            ...Object.keys(selectableValues).filter((selectableValueKey) => {
              return VehicleSearchService.hybridTypes.includes(selectableValueKey)
            })
          )
        } else {
          engineCodeValue.push(value)
        }
      })
      await ProgramProductCustomizationService.updateEligibilityCriteria(
        this.productLineId,
        [...this.form.custom],
        {
          ...this.form.values,
          engine_code: engineCodeValue,
          make: makes || [],
          model: models || [],
        }
      )
      if (!this.hasUpdateFailed) {
        this.closeModal()
      }
    },
    closeModal() {
      this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName: "ELIGIBILITY",
        productLineId: null,
      })
    },
    cleanCriteriaBeforeStoreUpdate() {
      // Before updating infos in the store :
      // - reset criterion value to its default value, if it is currently empty or null
      // (Why is it done here ? To avoid an unexpected behavior with the values displayed in the inputs/selects :
      //  when setting criterion to empty or null, it would immediately display default value)
      // - update list of custom criteria : only keep the ones whose value is different from the default one

      Object.keys(this.form.values).forEach((criterion) => {
        // Reset to default value if value is empty or null
        if (
          (ArrayService.isArray(this.form.values[criterion]) &&
            this.form.values[criterion].length === 0) ||
          this.form.values[criterion] === null ||
          this.form.values[criterion] === ""
        ) {
          if (criterion === "make") {
            this.form.values[criterion] = this.default_values[criterion].map((make) =>
              make.id.toString()
            )
          } else if (criterion === "model") {
            this.form.values[criterion] = this.default_values[criterion].map((model) =>
              model.id.toString()
            )
          } else {
            this.form.values[criterion] = this.default_values[criterion]
          }
        }

        this.updateCustomCriteria(criterion)
      })
    },
    getEligibilityCriterionOptions(criterion) {
      if (criterion === "make") {
        return this.getMakeSelectableValues
      }
      if (criterion === "model") {
        return this.getModelSelectableValues
      }
      if (criterion === "engine_code") {
        return this.getEngineCodeOptions()
      }
      return this.getCoefCritOptions(this.productLineId, criterion)
    },
    getEngineCodeOptions() {
      let isHybridIncluded = false
      let eligibleEngineCodes = this.listEligibleEngineCodes()
      const selectableValues = Object.entries(
        this.getEngineTypeSelectableValues
      ).reduce((acc, [engineCode, engineName]) => {
        if (eligibleEngineCodes.includes(engineCode))
          if (!VehicleSearchService.HYBRID_ENGINE_CODES.includes(engineCode)) {
            acc[engineCode] = engineName
          } else {
            isHybridIncluded = true
          }
        return acc
      }, {})
      if (isHybridIncluded) {
        selectableValues[i18n.t("vehicleChoice.hybrid")] =
          i18n.t("vehicleChoice.hybrid")
      }
      return selectableValues
    },
    listEligibleEngineCodes() {
      const eligibleEngineCodesInEligibilityCriteria =
        this.listEligibilityCriteriaValues(this.$route.params.offerId).engine_code
      return eligibleEngineCodesInEligibilityCriteria.length > 0
        ? eligibleEngineCodesInEligibilityCriteria
        : ProgramService.listEngineCodesFromEngineTag(this.getProgram.engine_tag.label)
    },
    isCriterionValueDifferentFromDefaultValue(criterion) {
      if (ArrayService.isArray(this.form.values[criterion])) {
        return !ArrayService.areSame(
          this.form.values[criterion],
          this.default_values[criterion]
        )
      } else if (
        this.default_values[criterion] !== null &&
        NumberService.isNumber(this.default_values[criterion]) &&
        NumberService.isNumber(this.form.values[criterion])
      ) {
        return parseInt(this.form.values[criterion]) !== this.default_values[criterion]
      } else {
        return this.form.values[criterion] !== this.default_values[criterion]
      }
    },
    async onUpdate(criterion) {
      if (criterion === "make") {
        this.modelOptionsLoading = true
        this.engineCodeOptionsLoading = true
        await this.$store.dispatch(
          "eligibilityCriteriaModal/setMakeFilterValues",
          this.form.values["make"]
        )
        this.form.values.model = this.listFilterModelValues.map((modelId) =>
          modelId.toString()
        )
        this.modelOptionsLoading = false
        this.engineCodeOptionsLoading = false
      } else if (criterion === "model") {
        this.engineCodeOptionsLoading = true
        await this.$store.dispatch(
          "eligibilityCriteriaModal/setModelFilterValues",
          this.form.values["model"]
        )
        this.engineCodeOptionsLoading = false
      }
    },
    removeCriterionFromCustomCriteria(criterionToRemove) {
      this.form.custom = this.form.custom.filter(
        (criterion) => criterion !== criterionToRemove
      )

      if (criterionToRemove === "make" && this.form.custom.includes("model")) {
        this.removeCriterionFromCustomCriteria("model")
      }
    },

    updateCustomCriteria(criterion) {
      if (this.isCriterionValueDifferentFromDefaultValue(criterion)) {
        this.addCriterionToCustomCriteria(criterion)

        if (criterion === "max_age_unity") {
          // Add also max_age in custom criteria
          this.addCriterionToCustomCriteria("max_age")
        }
      } else if (this.form.custom.includes(criterion)) {
        this.removeCriterionFromCustomCriteria(criterion)

        if (criterion === "max_age_unity") {
          // Remove also max_age from custom criteria
          this.removeCriterionFromCustomCriteria("max_age")
        }
      }
    },
    async addCriterionToCustomCriteria(criterion) {
      this.form.custom = ArrayService.pushIfNotExist(this.form.custom, criterion)
    },
    getEngineCodesSelectedOptions() {
      let isHybridIncluded = false
      let engineCodeValues = []
      this.getEligibilityCriteriaValues(this.productLineId).engine_code.forEach(
        (engineCode) => {
          if (VehicleSearchService.hybridTypes.includes(engineCode)) {
            isHybridIncluded = true
          } else {
            engineCodeValues.push(engineCode)
          }
        }
      )
      if (isHybridIncluded) {
        engineCodeValues.push(i18n.t("vehicleChoice.hybrid"))
      }
      return engineCodeValues
    },
  },
  async created() {
    this.isLoading = true
    this.form.custom = this.listCustomEligibilityCriteria(this.productLineId)
    this.form.values = this.getEligibilityCriteriaValues(this.productLineId)
    this.default_values = this.getEligibilityCriteriaDefaultValues(this.productLineId)
    await this.$store.dispatch(
      "eligibilityCriteriaModal/initFiltersAndSelectableValues",
      {
        productLineId: this.productLineId,
        offerId: this.$route.params.offerId,
      }
    )
    this.form.values.make = this.form.values.make.map((make) => make.id.toString())
    this.form.values.model = this.form.values.model.map((model) => model.id.toString())
    this.engineCodesSelectedOptions = this.getEngineCodesSelectedOptions()
    this.form.values.engine_code = this.engineCodesSelectedOptions
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.table {
  td {
    padding: 0.75rem;
  }
}
</style>

// STATES (snake_case)
import Vue from "vue"
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import ArrayService from "../services/technical/ArrayService"
import local_storage_names from "@/conf/local_storage_names"

const state = () => ({
  products: null,
  /**
   * filters {
   *   "<domainKey>": [<list subdomain Key>],
   *   ...
   * }
   */
  filters: {},
  chargeable_products: [
    "extension_garantie_vn",
    "extension_garantie_vn_elec",
    "garantie_vo",
    "body_care",
    "gap",
    "gap_finance",
    "interior_care",
    "rachat_franchise",
    "vitrerie_eclairage",
    "lost_key",
    "assistance",
    "assurance_automobile",
  ],
  products_with_no_details: ["garantie_pneumatiques"],
  vehicleTypeTags: ["VNPJ", "VNR", "VO"],
  engineTags: ["electrique", "thermique"],
  productSelectTags: [],
  programTags: JSON.parse(localStorage.getItem(local_storage_names.programTags)) || {
    vehicle: "",
    engine: "",
  },
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  ADD_DOMAIN_FILTER(state, payload) {
    Vue.set(state.filters, payload.domain, payload.subdomains)
  },
  DEL_DOMAIN_FILTER(state, domainKey) {
    Vue.delete(state.filters, domainKey)
  },
  ADD_SUBDOMAIN_FILTER(state, filter) {
    if (!state.filters.hasOwnProperty(filter.domainKey)) {
      Vue.set(state.filters, filter.domainKey, [filter.subdomainKey])
    } else {
      const subdomains = Object.keys(
        state.products[filter.domainKey].subdomains
      ).filter(
        (s) => state.filters[filter.domainKey].includes(s) || s === filter.subdomainKey
      )
      // il faut delete avant pour la réactivité, pourquoi ??
      Vue.delete(state.filters, filter.domainKey)
      Vue.set(state.filters, filter.domainKey, subdomains)
    }
  },
  DEL_SUBDOMAIN_FILTER(state, filter) {
    const subdomains = state.filters[filter.domainKey].filter(
      (s) => s !== filter.subdomainKey
    )
    Vue.delete(state.filters, filter.domainKey)
    Vue.set(state.filters, filter.domainKey, subdomains)
  },
  RESET_FILTER(state) {
    state.filters = {}
  },
  SET_PROGRAM_TAG(state, tag) {
    Vue.set(state.programTags, tag.name, tag.value)
    localStorage.setItem(
      local_storage_names.programTags,
      JSON.stringify(state.programTags)
    )
  },
  RESET_PROGRAM_TAGS(state) {
    state.programTags = {
      vehicle: "",
      engine: "",
    }
    localStorage.removeItem(local_storage_names.programTags)
  },
  RESET_PROGRAM_TAG_VEHICLE(state) {
    state.programTags["vehicle"] = ""
    localStorage.removeItem(local_storage_names.programTags)
    localStorage.setItem(
      local_storage_names.programTags,
      JSON.stringify(state.programTags)
    )
  },
  RESET_PROGRAM_TAG_ENGINE(state) {
    state.programTags["engine"] = ""
    localStorage.removeItem(local_storage_names.programTags)
    localStorage.setItem(
      local_storage_names.programTags,
      JSON.stringify(state.programTags)
    )
  },
}

// ACTIONS (camelCase)
const actions = {
  async initProducts({ commit }) {
    try {
      let products = await HttpService.get(UrlService.render("products"))
      commit("SET_PRODUCTS", products)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  /**
   * Enable/disable filter
   * @param filter { domainKey: "" } domain filter
   *               { domainKey: "", subdomainKey: "" } subdomain filter
   */
  toggleFilter({ commit, state, dispatch }, filter) {
    if (filter.subdomainKey) {
      if (
        state.filters.hasOwnProperty(filter.domainKey) &&
        state.filters[filter.domainKey].includes(filter.subdomainKey)
      ) {
        commit("DEL_SUBDOMAIN_FILTER", filter)

        // If the associated domain has no subdomain selected anymore, delete this domain
        if (state.filters[filter.domainKey].length === 0) {
          commit("DEL_DOMAIN_FILTER", filter.domainKey)
        }
      } else {
        commit("ADD_SUBDOMAIN_FILTER", filter)
      }
    } else if (state.filters.hasOwnProperty(filter.domainKey)) {
      commit("DEL_DOMAIN_FILTER", filter.domainKey)
    } else {
      // Delete domain(s) currently selected if any
      Object.keys(state.filters).forEach((domainKey) => {
        commit("DEL_DOMAIN_FILTER", domainKey)
      })
      dispatch("addDomainFilter", filter.domainKey)
    }
  },
  addDomainFilter({ commit, rootGetters }, domainKey) {
    commit("ADD_DOMAIN_FILTER", {
      domain: domainKey,
      subdomains: rootGetters["reference/listDomainSubDomains"](domainKey),
    })
  },
  setFilter({ commit, dispatch }, filter) {
    commit("RESET_FILTER")
    if (filter.subdomainKey) {
      commit("ADD_SUBDOMAIN_FILTER", filter)
    } else {
      dispatch("addDomainFilter", filter.domainKey)
    }
  },
  setAllFilters({ commit, state, rootGetters, dispatch }) {
    Object.keys(rootGetters["reference/getProductDomains"]).forEach((d) =>
      dispatch("addDomainFilter", d)
    )
  },
  setProgramTag({ commit }, tag) {
    if (tag["name"] === "vehicle" && tag["value"] === "all") {
      commit("RESET_PROGRAM_TAG_VEHICLE")
    } else if (tag["name"] === "engine" && tag["value"] === "all") {
      commit("RESET_PROGRAM_TAG_ENGINE")
    } else {
      commit("SET_PROGRAM_TAG", tag)
    }
  },
  resetProgramTags({ commit }) {
    commit("RESET_PROGRAM_TAGS")
  },
}

// GETTERS (camelCase)
const getters = {
  getProducts: (state) => state.products,
  getFilters: (state) => state.filters,
  getProgramTags: (state) => state.programTags,
  hasProductFilter:
    (state) =>
    (domainKey, subdomainKey = null) =>
      (state.filters.hasOwnProperty(domainKey) && subdomainKey === null) ||
      (state.filters.hasOwnProperty(domainKey) &&
        state.filters[domainKey].includes(subdomainKey)),
  hasEngineFilter: (state) => (engine) => state.programTags.engine === engine,
  hasAnyEngineFilter: (state) => (engineFilters) => {
    return engineFilters.some((engine) => state.programTags.engine === engine)
  },
  listFilteredProducts: (state) => {
    if (!state.products) return []
    return state.products.filter(
      (p) =>
        state.filters.hasOwnProperty(p.subdomain.domain.code) &&
        state.filters[p.subdomain.domain.code].includes(p.subdomain.code)
    )
  },
  listProductTagsByEngine: (state) => {
    return state.products.reduce((productTags, product) => {
      for (const tag of product.tags) {
        if (state.engineTags.includes(tag.label)) {
          productTags = ArrayService.pushIfNotExist(productTags, tag.label)
        }
      }
      return productTags
    }, [])
  },
  listProductTagsByVehicleType: (state) => {
    return state.products.reduce((productTags, product) => {
      for (const tag of product.tags) {
        if (state.vehicleTypeTags.includes(tag.label)) {
          productTags = ArrayService.pushIfNotExist(productTags, tag.label)
        }
      }
      return productTags
    }, [])
  },
  listProductsFilteredByProgramTags: (state, getters) => {
    if (state.programTags.vehicle === "all") return getters.listFilteredProductsByEngine
    if (state.programTags.engine === "all") return getters.listFilteredProductsByVehicle
    let selectedTags = []
    if (state.programTags.engine) selectedTags.push(state.programTags.engine)
    if (state.programTags.vehicle) selectedTags.push(state.programTags.vehicle)
    if (selectedTags.length === 0) return getters.listFilteredProducts
    return getters.listFilteredProducts.reduce((products, product) => {
      const productTags = product.tags.map((tag) => tag.label)
      if (selectedTags.every((selectedTag) => productTags.includes(selectedTag))) {
        products.push(product)
      }
      return products
    }, [])
  },
  listFilteredProductsByEngine: (state, getters) => {
    if (!state.programTags.engine || state.programTags.engine === "all") {
      return getters.listFilteredProducts
    }
    return getters.listFilteredProducts.reduce((products, product) => {
      const productTags = product.tags.map((tag) => tag.label)
      if (productTags.includes(state.programTags.engine)) {
        products.push(product)
      }
      return products
    }, [])
  },
  listFilteredProductsByVehicle: (state, getters) => {
    if (!state.programTags.vehicle || state.programTags.vehicle === "all") {
      return getters.listFilteredProducts
    }
    return getters.listFilteredProducts.reduce((products, product) => {
      const productTags = product.tags.map((tag) => tag.label)
      if (productTags.includes(state.programTags.vehicle)) {
        products.push(product)
      }
      return products
    }, [])
  },
  hasFilteredProducts: (state, getters) => getters.listFilteredProducts.length > 0,
  isProductChargeable: (state) => (productCode) =>
    state.chargeable_products.includes(productCode),
  hasDetails: (state) => (productCode) =>
    !state.products_with_no_details.includes(productCode),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <LayoutHeader
    :title="$t('breadcrumb.programParametersTaxes')"
    :desc="$t('breadcrumb.programParametersTaxesDesc')"
    :parent-div-class="['commercial-header__container']"
    :h3-class="['font-size-24, font-effra-medium']"
    :has-underline="true"
    :display-program-detail="true"
  ></LayoutHeader>
</template>

<script>
import LayoutHeader from "@/layouts/components/LayoutHeader"
export default {
  components: { LayoutHeader },
  name: "CommercialHeader",
}
</script>

<style scoped lang="scss">
.commercial-header__container {
  background-color: #fff;
  padding: 25px 25px 10px;
}
</style>

<template>
  <div class="tax-row__container">
    <p>{{ tax.name }}</p>
    <p :data-cy="`tax-${tax.name}-value`">{{ tax.value }}{{ taxUnit }}</p>
  </div>
</template>

<script>
import CurrencyService from "@/services/business/CurrencyService"

export default {
  name: "TaxRow",
  props: {
    tax: {
      type: Object,
      required: true,
    },
  },
  computed: {
    taxUnit() {
      return this.tax.type === "flat" ? CurrencyService.getCurrencySymbol() : "%"
    },
  },
}
</script>

<style scoped lang="scss">
.tax-row__container {
  grid-column: 1 / span 2;
  background-color: rgba(214, 203, 180, 0.2);
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 8px 27px 8px 16px;
  justify-content: space-between;
  p {
    margin-bottom: 0;
  }
  p:nth-child(2) {
    text-align: right;
  }
}
</style>

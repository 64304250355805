<template>
  <ModalBootstrap
    id-modal="modalExportPricingRange"
    :title="$t('pricingRange.modal_export.title')"
    modal-size="large"
    @close="onClose"
    data-cy="pricing-range-export-modal"
  >
    <template #body>
      <form class="form-simple" @submit.prevent="onSubmission">
        <FormRowInput
          name="nb_vehicles_exported"
          :label="$t('pricingRange.modal_export.nb_vehicles')"
          v-model="form.nbVehicles"
          :max-length="2"
          :errors="listPricingRangeExportErrors"
          class="mb-5 mt-3"
          input-class="text-right"
          :focus="true"
        />

        <SpinnerButton
          class="mx-auto btn btn-secondary btn-block col-4 mb-3"
          data-cy="button-validate-export"
          :is-loading="isLoading"
          :disabled="isLoading || !isFormComplete"
          @click="onSubmission"
        ></SpinnerButton>
      </form>
    </template>
    >
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import SpinnerButton from "@/components/button/SpinnerButton"
import { mapGetters } from "vuex"
import ProgramMixin from "@/components/presales/mixins/ProgramMixin"

export default {
  name: "ModalExportPricingRange",
  components: { ModalBootstrap, SpinnerButton },
  props: {
    programProductsId: { type: Array, required: true },
    beneficiaryCriteria: { type: Object, required: true },
    dynamicInputs: { type: Object },
  },
  mixins: [ProgramMixin],
  data() {
    return {
      form: {
        nbVehicles: "",
      },
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("productLine", ["listPricingRangeExportErrors"]),
    ...mapGetters("program", ["getCurrentProgramVehicleTagLabel", "getProgram"]),
    isFormComplete() {
      return this.form.nbVehicles !== ""
    },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
    async onSubmission() {
      if (!this.isFormComplete) {
        return
      }
      this.isLoading = true
      const payload = {
        program_products_pk: this.programProductsId,
        nb_vehicles_exported: this.form.nbVehicles,
        label: this.getCurrentProgramVehicleTagLabel,
        program_type: this.getProgramTypeToDisplay(this.getProgram),
        dynamic_inputs: this.dynamicInputs,
      }
      try {
        const pricingRange = await this.$store.dispatch(
          "productLine/exportPricingRange",
          payload
        )
        // TODO make a service to build and download file (but how to test it ?)
        const fileURL = window.URL.createObjectURL(new Blob([pricingRange]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", this.$t("pricingRange.modal_export.filename"))
        document.body.appendChild(fileLink)
        fileLink.click()
        this.onClose()
      } catch (e) {
        console.warn("Any error occurred during export", e)
      }
      this.isLoading = false
    },
  },
  async created() {
    await this.$store.dispatch("productLine/resetPricingRangeErrors")
  },
}
</script>

<style scoped></style>

<template>
  <div class="user-image">
    <img
      src="https://res.cloudinary.com/caarea/image/upload/v1676381053/cs/assets/users/form-users_qpdkdz.jpg"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "ImageUsers",
}
</script>

<style lang="scss" scoped>
.user-image {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>

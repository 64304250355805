<template>
  <div class="product-commercial-taxes__container">
    <div class="product-commercial-taxes">
      <div
        class="product-commercial-taxes__title"
        :data-cy="`product_taxe-${programProduct.id}`"
      >
        {{ programProduct.name }}
      </div>
    </div>
    <div class="product-commercial-taxes__underline"></div>
    <CommissionsAndTaxes
      :title-text="$t('commercial.taxes_title')"
      :product-line-id="programProduct.product_lines[0].id"
      tax-type="caarea_fees"
    ></CommissionsAndTaxes>
    <CommissionsAndTaxes
      :title-text="$t('commercial.fees_title')"
      :product-line-id="programProduct.product_lines[0].id"
      tax-type="other_fees"
      text-align="right"
    ></CommissionsAndTaxes>
  </div>
</template>

<script>
import CommissionsAndTaxes from "@/components/commercial/CommissionsAndTaxes"
export default {
  name: "ProductCommercialTaxes",
  components: { CommissionsAndTaxes },
  props: {
    programProduct: { type: Object, required: true },
  },
}
</script>

<style scoped lang="scss">
.product-commercial-taxes__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .product-commercial-taxes__title {
    font-size: 20px;
    font-family: "Gilroy", sans-serif;
    grid-column: 1 / span 2;
  }
  .product-commercial-taxes__underline {
    width: 100%;
    height: 1px;
    background-color: $bleuc50;
    margin-top: 10px;
    grid-column: 1 / span 2;
  }
}
</style>

<template>
  <div class="program-timeline__div">
    <div class="program-timeline__timeline">
      <span
        class="dot"
        :class="{
          'plain-dot': status === 'SETTING_IDENTITY' || status === 'ACTIVE',
        }"
        data-cy="step-1-dot"
      ></span>
      <span class="font-effra-bold" data-cy="program-timeline-step-1">
        {{ $t("programParameters.parameters_start_page.step") }} 1:
      </span>

      {{ $t("programParameters.parameters_start_page.technical_settings") }}
    </div>
    <div class="program-timeline__timeline">
      <span
        class="dot"
        :class="{
          'plain-dot': status === 'ACTIVE',
        }"
        data-cy="step-2-dot"
      ></span>
      <span class="font-effra-bold" data-cy="program-timeline-step-2">
        {{ $t("programParameters.parameters_start_page.step") }} 2:
      </span>

      {{ $t("programParameters.parameters_start_page.distribution_network") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.program-timeline__div {
  text-transform: uppercase;

  div:first-child {
    margin-top: 15px;
    margin-bottom: 30px;

    &::after {
      position: absolute;
      content: "";
      left: 15px;
      top: 35px;
      width: 1px;
      height: 110%;
      background-color: $bleuc100;
      z-index: 0;
    }
  }
}

.program-timeline__timeline {
  position: relative;
  height: 45px;
}

.dot {
  line-height: 45px;
  vertical-align: middle;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid $bleucaarea;
  position: relative;
  z-index: 1;
}

.plain-dot {
  background-color: $bleucaarea;
}

.plain-dot:after {
  width: 32px;
  height: 32px;
  display: block;
  content: "";
  position: relative;
  top: 9px;
  left: 0.5rem;
  background-image: url("https://res.cloudinary.com/caarea/image/upload/v1645006530/cs/assets/Vector_wxjjks.svg");
  background-repeat: no-repeat;
}
</style>

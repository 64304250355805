<template>
  <div>
    <div :data-cy="`product-id-${programProductId}-config`">
      <ModalWarning
        v-if="hasWarningModalToBeDisplayed"
        :title="warningModalInfo.title"
        :reason="warningModalInfo.reason"
        :advice="warningModalInfo.advice"
        @close="onCloseWarningModal"
      ></ModalWarning>
      <modal-confirmation
        v-if="isProductLineDeletionModalOpen(productLineId)"
        :message="$t('productLines.deleteConfirmationModal.message')"
        :title="$t('productLines.deleteConfirmationModal.title')"
        @close="closeProductLineDeletionModal()"
        @confirm="deleteProductLine"
        :data-cy="`productline-${productLineId}-delete-modal`"
      ></modal-confirmation>

      <div class="sections" v-if="canBeDisplayed">
        <FormProductLineEligibility
          class="sections__eligibility"
          :product-line-id="productLineId"
          :index="index"
          :disabled="disabled"
        ></FormProductLineEligibility>
        <FormProductLineProgram
          class="sections__guarantees"
          :product-line-id="productLineId"
          :index="index"
          :disabled="disabled"
          :product-name="getProgramProductById(programProductId).name"
          :offer-name="getOffer(offerId).name"
        ></FormProductLineProgram>
        <div class="sections__price">
          <div class="product-line-edition">
            <ProductLineConfigHeader
              v-if="isFirstProductLine"
              class="product-line-edition__header"
              icon="icon-coin-euro"
              :title="$t('programs.form.beneficiaryCriteria.title')"
              :sub-title="$t('programs.form.beneficiaryCriteria.help')"
            />
            <div class="product-line-edition__criteria">
              <VehicleChoice
                :product-line-id="productLineId"
                :program-product-id="programProductId"
                @vehicle-selected="onVehicleSelected"
                :vehicle-info="vehicleInfo"
                :eligibility-search-filter="eligibilitySearchFilters"
                :form-row-class="['product-line-edition__criteria__vehicle']"
              ></VehicleChoice>
              <CoverageDurationDistance
                :product-line-id="productLineId"
                :beneficiary-criteria="beneficiaryCriteria"
                :disabled="disabled"
                :component-class="['bg-caarea-secondary']"
                @coverage-update="onUpdateBeneficiaryCriterion"
              ></CoverageDurationDistance>
              <div class="price">
                <spinner
                  class="price__spinner"
                  v-if="isPricingInProgress(productLineId)"
                ></spinner>
                <div
                  class="price__value font-size-45"
                  v-else
                  :data-cy="`price-product-line-${productLineId}`"
                >
                  {{ formattedPriceToDisplay }}
                </div>
                <div class="price__info">
                  <div>
                    {{ $t("programs.after_tax") }}
                  </div>
                  <div
                    v-if="isPaymentFrequencyMonthly(productLineId)"
                    class="price__info--frequency"
                    data-cy="price-monthly"
                  >
                    {{ $t("programs.monthly") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="product-line-edition__footer">
              <button
                v-if="!isFirstProductLine && !disabled"
                class="btn btn-light-gray btn-block button-with-icon"
                @click.prevent="onDeleteProductLineButtonClick"
                :data-cy="`delete-product-line-${productLineId}-button`"
                data-toggle="modal"
                data-target="#modalConfirmation"
              >
                <em class="icon-trash2"></em>
                <span>{{ $t("button.delete_product_line") }}</span>
              </button>
              <VehicleChoiceButton
                class="vehicle-choice-button"
                :product-line-id="productLineId"
                :offer-id="offerId"
                :program-id="$router.currentRoute.params.id"
                :program-product-id="programProductId"
                @vehicle-selected="onVehicleSelected"
                :eligibility-search-filter="eligibilitySearchFilters"
              ></VehicleChoiceButton>
              <button
                v-if="!isLoggedInSharingMode"
                class="btn btn-light-gray btn-block w-100"
                @click="goToPricingRange"
                :data-cy="`get-pricing-range-${productLineId}-button`"
              >
                {{ $t("button.get_pricing_range") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProductLineConfigHeader from "@/components/presales/components/ProductLineConfigHeader"
import ModalWarning from "@/components/modal/ModalWarning"
import FormProductLineProgram from "@/components/presales/components/FormProductLineProgram"
import FormProductLineEligibility from "@/components/presales/components/FormProductLineEligibility"
import FormProductLineMixin from "@/components/presales/mixins/FormProductLineMixin"
import ModalConfirmation from "../modal/ModalConfirmation"
import VehicleChoice from "@/components/presales/components/VehicleChoice"
import VehicleChoiceButton from "./components/VehicleChoiceButton"
import CoverageDurationDistance from "@/components/presales/components/CoverageDurationDistance"
import ProgramProductCustomizationService from "../../services/pages/ProgramProductCustomizationService"
import ObjectService from "../../services/technical/ObjectService"
import PricingService from "../../services/business/PricingService"

export default {
  name: "FormProductLine",
  components: {
    ModalWarning,
    ProductLineConfigHeader,
    VehicleChoice,
    VehicleChoiceButton,
    FormProductLineProgram,
    FormProductLineEligibility,
    ModalConfirmation,
    CoverageDurationDistance,
  },
  data() {
    return {
      noPrice: "--",
    }
  },
  mixins: [FormProductLineMixin],
  props: {
    canBeDisplayed: { type: Boolean, required: true },
    offerId: {
      type: [Number, String],
      required: true,
    },
    programProductId: {
      type: [Number, String],
      required: true,
    },
    productLineId: {
      type: [Number, String],
      required: true,
    },
    index: Number,
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters("productLine", [
      "hasUpdateErrors",
      "getUpdateErrors",
      "getProductLinePrice",
      "getProductLineCurrencyCode",
      "hasPricingError",
      "getPricingError",
    ]),
    ...mapGetters("offer", ["getOffer"]),
    ...mapGetters("programProductCustomization", [
      "isPaymentFrequencyMonthly",
      "getBeneficiaryCriteriaValues",
      "getVehicleInfoToDisplay",
      "getEligibilityCriteriaValues",
      "isPricingInProgress",
      "isVehicleUpdateWithinProgramInProgress",
    ]),
    ...mapGetters("productLineModal", ["isProductLineDeletionModalOpen"]),
    ...mapGetters("auth", ["getRouteName", "isLoggedInSharingMode"]),
    ...mapGetters("programProduct", ["getProgramProductById"]),
    hasWarningModalToBeDisplayed() {
      return (
        this.hasPricingError(this.productLineId) ||
        this.hasUpdateErrors(this.productLineId)
      )
    },
    warningModalInfo() {
      if (this.hasPricingError(this.productLineId)) {
        return this.buildWarningModalInfoForPricingErrors()
      } else if (
        this.hasUpdateErrors(this.productLineId) &&
        !this.isVehicleUpdateWithinProgramInProgress
      ) {
        // Do not manage update errors here
        // if update deals with applying vehicle to all product lines within program
        return this.buildWarningModalInfoForUpdateErrors()
      }
      return {}
    },
    vehicleInfo() {
      return this.getVehicleInfoToDisplay(this.productLineId)
    },
    price() {
      return this.getProductLinePrice(this.productLineId)
    },
    formattedPriceToDisplay() {
      if (this.price) {
        return PricingService.getFormattedPriceToDisplay(
          this.price,
          this.isPaymentFrequencyMonthly(this.productLineId),
          this.getProductLineCurrencyCode(this.productLineId)
        )
      }
      return this.noPrice
    },
    eligibilitySearchFilters() {
      const eligibilityCriteriaValues = this.getEligibilityCriteriaValues(
        this.productLineId
      )
      return Object.entries(eligibilityCriteriaValues).reduce(
        (newObject, [key, value]) => {
          if (this.isEligibilityCriterionSelect(key) && value.length === 0) {
            value = null
          }
          if (value) {
            newObject[key] = value
          }
          return newObject
        },
        {}
      )
    },
    beneficiaryCriteria() {
      return this.getBeneficiaryCriteriaValues(this.productLineId)
    },
  },
  methods: {
    onUpdateBeneficiaryCriterion(criterion) {
      ProgramProductCustomizationService.updateBeneficiaryCriterion(
        this.productLineId,
        criterion
      )
    },
    async onVehicleSelected(vehicle) {
      await ProgramProductCustomizationService.updateVehicle(this.productLineId, {
        ...vehicle,
      })
      if (!this.hasUpdateErrors(this.productLineId)) {
        this.$emit("update-vehicle-within-program")
      }
    },
    onCloseWarningModal() {
      if (this.hasPricingError(this.productLineId)) {
        this.$store.dispatch("productLine/resetPricingError", this.productLineId)
      } else if (this.hasUpdateErrors(this.productLineId)) {
        this.$store.dispatch("productLine/resetUpdateErrors", this.productLineId)
      }
    },
    onDeleteProductLineButtonClick() {
      return this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName: "PRODUCT_LINE_DELETION",
        productLineId: this.productLineId,
      })
    },
    async deleteProductLine() {
      await ProgramProductCustomizationService.deleteProductLine(this.productLineId)
      this.closeProductLineDeletionModal("productLineDeletion")
    },
    closeProductLineDeletionModal() {
      return this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName: "PRODUCT_LINE_DELETION",
        productLineId: null,
      })
    },
    buildWarningModalInfoForPricingErrors() {
      let warningModalInfo = {
        title: this.$t("programs.pricing_failed_modal.title"),
        reason: this.$t("programs.pricing_failed_modal.reason"),
      }
      const pricingError = this.getPricingError(this.productLineId)
      if (pricingError !== null) {
        warningModalInfo.reason =
          warningModalInfo.reason + ` :<br><strong>${this.vehicleInfo}</strong>`
        if (process.env.NODE_ENV !== "production") {
          if (typeof pricingError === "string") {
            warningModalInfo.advice = pricingError
          } else {
            warningModalInfo.advice = ObjectService.getPrintableContent(
              pricingError,
              "\n"
            )
          }
        } else {
          warningModalInfo.advice = this.$t("programs.pricing_failed_modal.advice")
        }
      }
      return warningModalInfo
    },
    buildWarningModalInfoForUpdateErrors() {
      let warningModalInfo = {
        title: this.$t("programs.update_failed_modal.title"),
        reason: this.$t("programs.update_failed_modal.reason"),
      }
      const updateErrors = this.getUpdateErrors(this.productLineId)
      if (process.env.NODE_ENV !== "production") {
        warningModalInfo.advice = ObjectService.getPrintableContent(
          updateErrors.config,
          "\n"
        )
      } else {
        warningModalInfo.advice = this.$t("programs.update_failed_modal.advice")
      }
      return warningModalInfo
    },
    goToPricingRange() {
      this.$router.push({
        name: this.getRouteName("program_product_pricing_range"),
        params: {
          offerId: this.offerId,
          id: this.$route.params.id,
          productLineId: this.productLineId,
        },
      })
    },
  },
}
</script>
<style scoped lang="scss">
.button-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  column-gap: 0.5rem;
  text-align: end;
  margin-top: 0.5rem;
  background-color: #602936 !important;
  color: white !important;
  margin-bottom: 0.5rem;
}

.vehicle-choice-button {
  margin-bottom: 0.5rem;
}
</style>

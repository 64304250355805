// STATES (snake_case)
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import Vue from "vue"
import FileUploadService from "@/store/services/FileUploadService"

const state = {
  networks: null,
  network_upload_errors: null,
  network_update_errors: null,
  network_file: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_NETWORKS(state, networks) {
    state.networks = networks
  },
  SET_NETWORK(state, network) {
    const index = state.networks.findIndex((n) => n.id === network.id)
    Vue.set(state.networks, index, network)
  },
  SET_NETWORK_UPLOAD_ERRORS(state, errors) {
    state.network_upload_errors = errors
  },
  RESET_NETWORK_UPLOAD_ERRORS(state) {
    state.network_upload_errors = null
  },
  SET_NETWORK_FILE(state, file) {
    state.network_file = file
  },
  SET_NETWORK_UPDATE_ERRORS(state, errors) {
    state.network_update_errors = errors
  },
  RESET_NETWORK_UPDATE_ERRORS(state) {
    state.network_update_errors = null
  },
}

// ACTIONS (camelCase)
const actions = {
  async getNetworks({ commit }, partnerId) {
    try {
      const networks = await HttpService.get(
        UrlService.render("networkByPartnerId", { id: partnerId })
      )
      commit("SET_NETWORKS", networks)
    } catch (e) {
      console.error("getNetworks failed: ", e)
      throw e
    }
  },
  async update({ commit }, payload) {
    try {
      commit("RESET_NETWORK_UPDATE_ERRORS")
      const network = await HttpService.put(
        UrlService.render("networkById", { id: payload.networkId }),
        payload.data
      )
      commit("SET_NETWORK", network)
    } catch (e) {
      if (e.status === 422 || e.status === 400) {
        commit("SET_NETWORK_UPDATE_ERRORS", e.data)
      }
      console.error("network update failed: ", e)
      throw e
    }
  },
  async upload({ commit, dispatch, rootGetters }, file) {
    try {
      await FileUploadService.upload(
        file,
        rootGetters["program/getCurrentProgramId"],
        "networks",
        rootGetters["program/getProgramPartnerId"]
      )
    } catch (e) {
      console.error("network upload failure", e)
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 400) {
          commit("SET_NETWORK_UPLOAD_ERRORS", e.data)
        }
        return
      }
      throw e
    }
  },
  async download({ commit }, networkId) {
    try {
      const response = await HttpService.get(
        UrlService.render("downloadNetworkFileById", { id: networkId }),
        { responseType: "arraybuffer" }
      )
      commit("SET_NETWORK_FILE", response)
    } catch (e) {
      console.error("download failure", e)
    }
  },
  async inviteMembers({ commit, dispatch }, payload) {
    try {
      const network = await HttpService.post(
        UrlService.render("networkSendEmails", { id: payload.networkId }),
        payload.data
      )
      commit("SET_NETWORK", network)
    } catch (e) {
      console.error("network invite members failed: ", e)
      throw e
    }
  },
  async resetMembersEmail({ commit, dispatch }, networkId) {
    try {
      await HttpService.post(
        UrlService.render("networkSendResetEmails", { id: networkId })
      )
    } catch (e) {
      console.error("network reset members email failed: ", e)
      throw e
    }
  },
  async setSelectedNetwork({ commit }, network) {
    commit("SET_SELECTED_NETWORK", network)
  },
  resetNetworkUploadErrors({ commit }) {
    commit("RESET_NETWORK_UPLOAD_ERRORS")
  },
  resetNetworkUpdateErrors({ commit }) {
    commit("RESET_NETWORK_UPDATE_ERRORS")
  },
}

// GETTERS (camelCase)
const getters = {
  getNetworkById: (state) => (id) => state.networks[id],
  listNetworks: (state) => state.networks,
  hasNetworks: (state) => state.networks?.length > 0,
  hasNetworkUploadErrors: (state) => state.network_upload_errors !== null,
  hasNetworkImportErrors: (state) =>
    FileUploadService.hasImportErrors(state.network_upload_errors),
  getNetworkUploadErrors: (state) =>
    FileUploadService.getUploadErrors(state.network_upload_errors),
  getNetworkUpdateErrors: (state) => state.network_update_errors,
  listNetworkImportErrors: (state) =>
    FileUploadService.listImportErrors(state.network_upload_errors),
  getNetworkFile: (state) => state.network_file,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div :class="[...parentDivClass]">
    <h3 :class="[...h3Class]" data-cy="content-title">
      {{ getTitle }} {{ getProgramDetails }}
    </h3>
    <div v-if="hasUnderline" class="layout-header__underline"></div>
    <p v-if="getDesc" :class="pClass" data-cy="content-desc">
      {{ getDesc }}
    </p>
  </div>
</template>

<script>
export default {
  name: "LayoutHeader",
  props: {
    title: { type: String, default: null },
    desc: { type: String, default: null },
    parentDivClass: { type: Array, default: () => ["pb-3"] },
    h3Class: { type: Array, default: () => ["font-size-40 w-100"] },
    pClass: { type: String, default: "font-size-14 w-100" },
    hasUnderline: { type: Boolean, default: false },
    displayProgramDetail: { type: Boolean, default: false },
  },
  computed: {
    getTitle() {
      if (this.title) {
        return this.title
      }
      return this.$router.currentRoute.meta.hasOwnProperty("contentTitle")
        ? this.$t(this.$router.currentRoute.meta.contentTitle)
        : null
    },
    getDesc() {
      if (this.desc) {
        return this.desc
      }
      return this.$router.currentRoute.meta.hasOwnProperty("contentDesc")
        ? this.$t(this.$router.currentRoute.meta.contentDesc)
        : null
    },
    getProgramDetails() {
      if (this.$router.currentRoute.params.hasOwnProperty("programId")) {
        return `- ${this.$t("header.program")} # ${
          this.$router.currentRoute.params.programId
        }`
      }
      return ""
    },
  },
}
</script>

<style scoped lang="scss">
.layout-header__underline {
  height: 1px;
  width: 100%;
  background-color: $buttonstroke;
  margin: 10px 0;
}
</style>

<template>
  <div :class="['product__price', ...componentClass]">
    <div>
      <p class="text-uppercase font-size-22 font-gilroy-bold underline">
        {{ $t("offers.pricing_summary.total_price") }}
      </p>
      <p v-if="isPaymentFrequencyMonthly(offerId)">
        {{ $t("offers.pricing_summary.monthly") }}
      </p>
      <p v-else>{{ $t("offers.pricing_summary.cash") }}</p>
    </div>
    <div class="product__price-ttc font-size-45">
      <div>
        <spinner v-if="isPriceTotalLoading"></spinner>
        <template v-else>{{ getAmount }}</template>
      </div>
      <div>
        <span class="font-size-11 font-effra-light">
          {{ $t("offers.pricing_summary.after_tax") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import PricingService from "@/services/business/PricingService"

export default {
  name: "ProductsPriceTotal",
  props: {
    componentClass: {
      type: Array,
    },
    amount: {
      type: Number,
    },
    offerId: {
      type: [Number, String],
    },
    isPriceTotalLoading: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("offer", ["isPaymentFrequencyMonthly"]),
    getAmount() {
      if (this.amount) {
        return PricingService.getFormattedPriceToDisplay(
          this.amount,
          this.isPaymentFrequencyMonthly(this.offerId)
        )
      }
      return "--"
    },
  },
}
</script>

<style scoped lang="scss">
.product__price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 30px;
  gap: 10px;
  .product__price-ttc {
    display: flex;
    span {
      position: relative;
      top: -27px;
      left: 7px;
    }
  }
}
p {
  margin-bottom: 0;
}
</style>

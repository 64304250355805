<template>
  <ModalBootstrap
    id-modal="modalProgramCriteria"
    class="criteria"
    :title="$t('programs.program_criteria_modal.title')"
    modal-size="extraLarge"
    @close="closeModal"
    data-cy="presales-criteria-modal"
  >
    <template #body class="mx-3">
      <div class="mb-3">
        <div class="row">
          <VerticalTabs
            :tabs="menuItems"
            :active-tab="activeMenu"
            @tab-change="setActive"
          ></VerticalTabs>
          <div class="col-9">
            <div class="mb-2 text-right w-100 font-size-13">
              {{ $t("programs.program_criteria_modal.desc") }}
            </div>
            <div v-if="isActiveMenuCoverage">
              <CoverageCriteriaOptionsSelection
                :user-selectable-values.sync="form.userSelectableValues"
                :product-line-id="productLineId"
                @coverage-options-update="onCoverageOptionsUpdate"
              ></CoverageCriteriaOptionsSelection>
            </div>
            <div v-else class="row modal-content-min-height">
              <div class="col">
                <table class="table table-striped" aria-describedby="programs criteria">
                  <thead></thead>
                  <tbody>
                    <tr v-for="criterion in listMenuCriteria" :key="criterion">
                      <td>
                        <div
                          v-if="isMultipleSelectCriterion(criterion)"
                          class="row p-3"
                        >
                          <label class="pl-0 col-sm-12">
                            {{ getProgramCriterionLabelToDisplay(criterion) }}
                          </label>
                          <FormCheckbox
                            v-for="(value, key) in getCoefCritOptions(
                              productLineId,
                              criterion
                            )"
                            :name="value"
                            :key="key"
                            :label="value"
                            :value="isValueSelected(criterion, key)"
                            @input="onUpdate(criterion, key)"
                          ></FormCheckbox>
                        </div>
                        <FormRowSelect
                          v-else
                          :label="getProgramCriterionLabelToDisplay(criterion)"
                          :label-class="['text-left', 'col-sm-4', 'align-self-center']"
                          :control-class="[getSelectWidthClass(criterion)]"
                          :name="`${productLineId}-${criterion}`"
                          :selected-option.sync="form.programCriteria.values[criterion]"
                          @update:selected-option="onUpdate(criterion)"
                          label-select-attr="label"
                          :select-options="getCoefCritOptions(productLineId, criterion)"
                          :disabled="hasConflictingCriteria(criterion)"
                          :required="isSharedCriterion(criterion)"
                        ></FormRowSelect>
                        <div class="mt-3" v-if="isWearCriterionGrid(criterion)">
                          <table class="table table-striped">
                            <thead></thead>
                            <tbody>
                              <tr>
                                <td
                                  class="table__grid-label"
                                  :rowspan="getWearGridInfo(criterion).row_span"
                                >
                                  {{
                                    getProgramCriterionValueToDisplay(
                                      productLineId,
                                      criterion
                                    )
                                  }}
                                </td>
                                <td class="table__grid-header">
                                  {{ $t("criteria.wear_critera_grid.km_label") }}
                                </td>
                                <td class="table__grid-header">
                                  {{
                                    $t("criteria.wear_critera_grid.coefficient_label")
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-for="(grid, index) in getWearGridInfo(criterion)
                                  .grid_info"
                                :key="`${index}-${criterion}`"
                              >
                                <td class="table__grid-content">
                                  {{ getGridKmToDisplay(criterion, grid, index) }}
                                </td>
                                <td class="table__grid-content">
                                  {{ grid.coefficient }} %
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="font-effra-light font-size-11 mb-5">
                  * {{ $t("programs.program_criteria_modal.shared_criteria_label") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <div>
              <div
                v-if="isSubmitButtonDisabled"
                class="font-effra-light font-size-12 text-danger"
                data-cy="error-validate-criteria"
              >
                {{ $t("programs.program_criteria_modal.buttonDisabled") }}
              </div>
              <button
                class="btn btn-primary ml-auto"
                @click.prevent="onSubmitButton"
                data-cy="validate-criteria"
                :disabled="isSubmitButtonDisabled"
              >
                {{ $t("programs.program_criteria_modal.validate") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import ArrayService from "@/services/technical/ArrayService"
import VerticalTabs from "@/components/utils/VerticalTabs"
import BeneficiaryCriteriaService from "@/services/business/BeneficiaryCriteriaService"
import CoverageCriteriaOptionsSelection from "@/components/presales/modal/components/CoverageCriteriaOptionsSelection"
import { mapGetters } from "vuex"
import CriteriaService from "../../../services/business/CriteriaService"
import ProgramProductCustomizationService from "../../../services/pages/ProgramProductCustomizationService"

export default {
  name: "ModalProgramCriteria",
  components: { ModalBootstrap, VerticalTabs, CoverageCriteriaOptionsSelection },
  props: {
    productLineId: { type: Number, required: true },
  },
  data() {
    return {
      form: {
        programCriteria: { custom: null, values: null },
        userSelectableValues: null,
      },
      menuItems: {
        product: this.$t(`programs.program_criteria_modal.menu.product`),
        compensation: this.$t(`programs.program_criteria_modal.menu.compensation`),
        reparations: this.$t(`programs.program_criteria_modal.menu.reparations`),
        others: this.$t(`programs.program_criteria_modal.menu.others`),
        coverage: "",
      },
      activeMenu: "product",
      haveCustomValuesChanged: false,
      haveUserSelectableValuesChanged: false,
    }
  },
  computed: {
    ...mapGetters("productLine", [
      "listCoefCritProgramByGroup",
      "getCoefCritOptions",
      "getConfig",
    ]),
    ...mapGetters("programProductCustomization", [
      "listCustomProgramCriteria",
      "getProgramCriteriaValues",
      "getUserSelectableValues",
      "getProgramCriterionValueToDisplay",
      "getProgramCriterionLabelToDisplay",
      "getBeneficiaryCriteriaValues",
    ]),
    listMenuCriteria() {
      return this.listCoefCritProgramByGroup(this.productLineId, this.activeMenu)
    },
    isActiveMenuCoverage() {
      return this.activeMenu === "coverage"
    },
    isSubmitButtonDisabled() {
      return (
        BeneficiaryCriteriaService.hasCoverageCriteria(
          this.getBeneficiaryCriteriaValues(this.productLineId)
        ) &&
        BeneficiaryCriteriaService.areAllCoverageUserSelectableValuesFalse(
          this.form.userSelectableValues
        )
      )
    },
  },
  methods: {
    isSharedCriterion(criterion) {
      const productLineConfig = this.getConfig(this.productLineId)
      return CriteriaService.isSharedCriterion(productLineConfig, criterion)
    },
    getCoverageMenuLabel(coverageCriteria) {
      return coverageCriteria.hasOwnProperty("duration_km")
        ? this.$t(`programs.program_criteria_modal.menu.coverage_duration_mileage`)
        : this.$t(`programs.program_criteria_modal.menu.coverage_duration`)
    },
    /**
     * Returns the most appropriate width class for the provided criterion's select,
     * according to the length of the longest option in select element.
     *
     * @param criterion
     * @returns {String}
     */
    getSelectWidthClass(criterion) {
      const OPTIONS = Object.values(
        this.getCoefCritOptions(this.productLineId, criterion)
      )
      const LENGTH_40 = 40
      const LENGTH_24 = 24
      const LENGTH_15 = 15
      const MAX_LENGTH = ArrayService.getStringMaxLength(OPTIONS)

      if (MAX_LENGTH > LENGTH_40) {
        return "col-sm-8"
      }
      if (MAX_LENGTH > LENGTH_24) {
        return "col-sm-6"
      }
      if (MAX_LENGTH > LENGTH_15) {
        return "col-sm-4"
      }
      return "col-sm-3"
    },
    async onSubmitButton() {
      // We don't wait for the response from the service, in order to close the modal as soon as possible
      // (Displayed values will be automatically refreshed when response will be completely treated)
      if (this.haveCustomValuesChanged && this.haveUserSelectableValuesChanged) {
        this.storeProgramCriteriaAndUserSelectableValues()
      } else if (this.haveCustomValuesChanged) {
        this.storeProgramCriteria()
      } else if (this.haveUserSelectableValuesChanged) {
        this.storeUserSelectableValues()
      }
      this.closeModal()
    },
    storeProgramCriteriaAndUserSelectableValues() {
      ProgramProductCustomizationService.updateProgramCriteriaAndUserSelectableValues(
        this.productLineId,
        JSON.parse(JSON.stringify(this.form.programCriteria)),
        JSON.parse(JSON.stringify(this.form.userSelectableValues))
      )
      this.haveCustomValuesChanged = false
      this.haveUserSelectableValuesChanged = false
    },
    storeProgramCriteria() {
      ProgramProductCustomizationService.updateProgramCriteria(
        this.productLineId,
        [...this.form.programCriteria.custom],
        { ...this.form.programCriteria.values }
      )
      this.haveCustomValuesChanged = false
    },
    storeUserSelectableValues() {
      ProgramProductCustomizationService.updateUserSelectableValues(
        this.productLineId,
        JSON.parse(JSON.stringify(this.form.userSelectableValues))
      )
      this.haveUserSelectableValuesChanged = false
    },
    setActive(item) {
      this.activeMenu = item
    },
    onUpdate(criterion, key) {
      this.form.programCriteria.custom = ArrayService.pushIfNotExist(
        this.form.programCriteria.custom,
        criterion
      )
      if (this.isACheckboxUpdate(key)) {
        this.addOrRemoveValueInCriteriaForm(criterion, key)
      }
      this.haveCustomValuesChanged = true
    },
    isACheckboxUpdate(key) {
      return !!key
    },
    addOrRemoveValueInCriteriaForm: function (criterion, key) {
      if (this.form.programCriteria.values[criterion].includes(key)) {
        this.form.programCriteria.values[criterion] = this.form.programCriteria.values[
          criterion
        ].filter((value) => value !== key)
      } else {
        if (Array.isArray(this.form.programCriteria.values[criterion])) {
          this.form.programCriteria.values[criterion].push(key)
        } else {
          this.form.programCriteria.values[criterion] = []
          this.form.programCriteria.values[criterion].push(key)
        }
      }
    },
    isMultipleSelectCriterion(criterion) {
      return CriteriaService.isMultipleSelectCriterion(criterion)
    },
    getWearGridInfo(criterion) {
      return CriteriaService.getWearGridInfo(
        criterion,
        this.form.programCriteria.values[criterion]
      )
    },
    isWearCriterionGrid(criterion) {
      return CriteriaService.isWearCriterionGrid(
        criterion,
        this.form.programCriteria.values[criterion]
      )
    },
    getGridKmToDisplay(criterion, grid, index) {
      const prefix = this.isLessThanLabel(criterion, index)
        ? this.$t("criteria.wear_critera_grid.less_than")
        : this.$t("criteria.wear_critera_grid.over")
      return `${prefix} ${grid.kilometrage} km`
    },
    isLessThanLabel(criterion, index) {
      const lastIndex = this.getWearGridInfo(criterion).grid_info.length - 1
      return index < lastIndex
    },
    isValueSelected(criterion, key) {
      return this.form.programCriteria.values[criterion].includes(key)
    },
    hasConflictingCriteria(criterion) {
      return !!(
        criterion.startsWith("deductible") && this.conflictingCriteriaNotNull(criterion)
      )
    },
    conflictingCriteriaNotNull(criterion) {
      return (
        (criterion === "deductible_currency" &&
          this.form.programCriteria.values["deductible_percentage"] !== "0") ||
        (criterion === "deductible_percentage" &&
          this.form.programCriteria.values["deductible_currency"] !== "0")
      )
    },
    onCoverageOptionsUpdate() {
      this.haveUserSelectableValuesChanged = true
    },
    closeModal() {
      this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName: "GUARANTEES",
        productLineId: null,
      })
    },
    getMenuItemsNotEmpty(productLineConfig) {
      return Object.fromEntries(
        Object.entries(this.menuItems).filter(([name, value]) => {
          if (name === "coverage") {
            return Object.keys(productLineConfig.user_selectable_values).length !== 0
          } else {
            return (
              productLineConfig.coefficient.program.hasOwnProperty(name) &&
              productLineConfig.coefficient.program[name].length !== 0
            )
          }
        })
      )
    },
  },
  created() {
    this.form.programCriteria.custom = this.listCustomProgramCriteria(
      this.productLineId
    )
    this.form.programCriteria.values = this.getProgramCriteriaValues(this.productLineId)
    this.form.userSelectableValues = this.getUserSelectableValues(this.productLineId)

    const config = this.getConfig(this.productLineId)
    this.menuItems = this.getMenuItemsNotEmpty(config)

    if (this.menuItems.hasOwnProperty("coverage")) {
      this.menuItems.coverage = this.getCoverageMenuLabel(config.user_selectable_values)
    }
  },
}
</script>

<style lang="scss" scoped>
.table {
  th {
    border-top: none !important;
  }

  td {
    padding: 0.75rem;
  }

  &__grid-label {
    background: #e7e0d1;
  }

  &__grid-header {
    background: #f7f5ef;
    text-align: center;
  }

  &__grid-content {
    background: #fbfaf8;
    text-align: center;
  }
}

.modal-content-min-height {
  min-height: 400px;
}

table tbody tr:last-child {
  border-bottom: solid 1px $table-border-color !important;
}

.vl {
  border-right: 1px solid lightgray;
}

.custom-checkbox:nth-child(even) {
  max-width: 50%;
  flex: 0 0 100%;
}
</style>

<template>
  <header data-cy="top_bar" class="header">
    <div class="container-fluid mt-2">
      <div class="row align-items-center justify-content-between ml-5">
        <div class="col-auto">
          <a>
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-header_ef6uoh.png"
              alt="Caarea Solutions"
            />
          </a>
        </div>
        <div class="d-flex align-items-center mr-5">
          <div class="d-flex align-items-center mr-5">
            <h4 class="font-size-30 mb-0">
              {{ $t("topbar.sharing.title") }}
            </h4>
          </div>
          <div class="dropdown show">
            <button
              class="invisible-button dropdown-btn dropdown-toggle text-uppercase"
              role="button"
              id="languageDropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentLang }}
            </button>

            <div class="dropdown-menu" aria-labelledby="languageDropdownMenuButton">
              <button
                v-for="lang in languages"
                class="dropdown-item dropdown-btn text-uppercase"
                :data-cy="`change-language-btn-${lang}`"
                :key="lang"
                :disabled="lang === getCurrentLang"
                @click.prevent="onChangeCurrentLanguage(lang)"
              >
                {{ lang }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-header"></div>
    <div class="breadcrumb-header"></div>
  </header>
</template>

<script>
import { mapGetters } from "vuex"
import i18n from "@/i18n"

export default {
  name: "TopBarSharing",
  computed: {
    ...mapGetters("config", ["getCurrentLang", "isLangAvailable"]),
    currentLang() {
      return this.getCurrentLang
    },
    languages() {
      let languages = []
      for (const lang of Object.keys(i18n.messages)) {
        if (lang === this.getCurrentLang) {
          languages.unshift(lang)
        } else {
          languages.push(lang)
        }
      }
      return languages
    },
  },
  methods: {
    onChangeCurrentLanguage(newLang) {
      this.$store.dispatch("config/setCurrentLang", newLang)
      const to = this.$router.resolve({ params: { lang: newLang } })
      this.$router.push(to.location)
    },
  },
}
</script>

<template>
  <div data-cy="offer-content" class="offer-page">
    <ModalWarning
      v-if="warningModal.show"
      :title="warningModal.title"
      :reason="warningModal.reason"
      :advice="warningModal.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <spinner class="mt-5" v-if="isLoading"></spinner>
    <div v-else class="offer-page">
      <ProgramContentHeader
        class="offer-page__header"
        :program="program"
        :title="program.name"
        :offer-number="offerNumber"
        :desc="$t('offers.description')"
        :is-share-button-visible="false"
      ></ProgramContentHeader>
      <div class="offer-page__steps">
        <OfferEligibilityCriteria
          class="offer-page__eligibility-criteria"
          :offer-id="offerId"
          v-model="form.eligibility_criteria"
          :is-disabled="isDisabled"
          :eligibility-title="eligibilityTitle"
        ></OfferEligibilityCriteria>
        <button
          class="offer-page__validate-button btn btn-primary"
          @click="onValidateButtonClick"
          data-cy="next-button"
        >
          {{ $t("button.validate") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import StringService from "../../services/technical/StringService"
import OfferEligibilityCriteria from "../../components/presales/components/OfferEligibilityCriteria"
import ProgramMixin from "../../components/presales/mixins/ProgramMixin"
import ProgramContentHeader from "@/components/presales/components/ProgramContentHeader"
import ModalWarning from "../../components/modal/ModalWarning"
import ObjectService from "../../services/technical/ObjectService"

export default {
  name: "Offer",
  components: {
    ProgramContentHeader,
    OfferEligibilityCriteria,
    ModalWarning,
  },
  mixins: [ProgramMixin],
  props: {
    offerId: { type: [Number, String], required: true },
  },
  data() {
    return {
      isLoading: true,
      isPricingRangeVisible: false,
      program: null,
      products: null,
      form: {
        eligibility_criteria: {},
      },
      isDisabled: false,
      firstProductLineId: null,
      warningModal: {
        show: false,
        title: this.$t("offers.warning_modal.title"),
        advice: this.$t("offers.warning_modal.advice"),
        reason: "",
      },
    }
  },
  computed: {
    ...mapGetters("auth", ["getRouteName"]),
    ...mapGetters("offer", [
      "getOffer",
      "getUpdateErrors",
      "listProgramProducts",
      "getOfferNumberInProgram",
      "getFirstProductLineId",
      "isOfferExternal",
    ]),
    ...mapGetters("program", [
      "getProgram",
      "getProgramByOfferId",
      "isReadOnlyInPresale",
    ]),
    ...mapGetters("programProduct", ["isActive"]),
    ...mapGetters("offerContext", [
      "getMakeIdFromName",
      "getModelIdFromName",
      "getMakeNameFromId",
      "getModelNameFromId",
      "getMakeObjectFromId",
      "getModelObjectFromId",
    ]),
    offerNumber() {
      return this.getOfferNumberInProgram(parseInt(this.offerId))
    },
    title() {
      return `${this.program.name} - ${StringService.upperFirst(
        this.$t("offers.offer")
      )} #${this.offerNumber}`
    },
    eligibilityTitle() {
      return `${this.$t("offers.offer")} #${this.offerNumber} : ${this.$t(
        "offers.vehicles_eligibility.select_title"
      )}`
    },
  },
  methods: {
    getFirstProductId() {
      return this.listProgramProducts(this.offerId)[0].id
    },
    async onValidateButtonClick() {
      await this.updateEligibilityCriteria()
      const isUpdateSuccessful = !this.getUpdateErrors(this.offerId)
      if (isUpdateSuccessful) {
        await this.$router.push({
          name: this.getRouteName("programProduct"),
          params: { programProductId: this.getFirstProductId() },
        })
      } else {
        // TODO: format errors
        this.warningModal.reason = "NOT YET IMPLEMENTED"
        this.warningModal.show = true
      }
    },
    async updateEligibilityCriteria() {
      const eligibilityCriteria = this.formatEligibilityCriteriaForOfferConfigUpdate()
      const payload = {
        id: this.offerId,
        eligibility_criteria: eligibilityCriteria,
      }
      await this.$store.dispatch("offer/updateEligibilityCriteria", payload)
    },
    formatEligibilityCriteriaForOfferConfigUpdate() {
      // Note: in offer eligibility criteria config, make and model are stored as a list of names (not local names !)
      // We need to convert ids into names
      const makes = this.form.eligibility_criteria.make.map((makeId) => {
        return { ...this.getMakeObjectFromId(makeId) }
      })
      const models = this.form.eligibility_criteria.model.map((modelId) => {
        return { ...this.getModelObjectFromId(modelId) }
      })
      return {
        ...this.form.eligibility_criteria,
        make: makes || [],
        model: models || [],
      }
    },
    async updateStoresAndInitData() {
      await this.retrieveOffer()
      this.program = this.getProgramByOfferId(parseInt(this.offerId))
      // case of a page reload : current program is not stored anymore in the store
      // => get its id from the offer id then retrieve its content and update store
      if (this.getProgram === null) {
        await this.$store.dispatch("program/getProgram", this.program.id)
      }
      await this.$store.dispatch("offer/getCoefficientCriteria", this.offerId)
      this.isDisabled = this.isReadOnlyInPresale
      // Reset update error to avoid displaying error of a previous update when going back to this page
      // (scenario: enter invalid value -> validate -> update error -> quit current page
      //            -> go back to current page -> invalid criterion has now its default value so the
      //            update error should not be displayed)
      this.$store.dispatch("offer/resetUpdateErrors", this.offerId)
      this.firstProductLineId = this.getFirstProductLineId(this.offerId)
      await this.initEligibilityCriteria()
    },
    async retrieveOffer() {
      await this.$store.dispatch("offer/getOffer", this.offerId)
    },
    async initEligibilityCriteria() {
      this.form.eligibility_criteria = {
        ...this.getOffer(this.offerId).eligibility_criteria,
      }
      if (!this.isOfferExternal(this.offerId)) {
        await this.$store.dispatch("offerContext/initSelectableValues", this.offerId)

        // Note: in offer eligibility criteria config, make and model are stored as a list of names (not local names !)
        // We need to convert them into their corresponding id
        // ! Warning : can only be done once selectable values are in the store !
        // this.form.eligibility_criteria.make = this.form.eligibility_criteria.make.map(
        //   (makeName) => this.getMakeIdFromName(makeName)
        // )
        this.form.eligibility_criteria.make = this.form.eligibility_criteria.make.map(
          (make) => make.id.toString()
        )
        this.form.eligibility_criteria.model = this.form.eligibility_criteria.model.map(
          (model) => model.id.toString()
        )
      }
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("offer/setCurrentOfferId", this.offerId)
    await this.updateStoresAndInitData()
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.offer-page {
  background-color: $background-program;
  .offer-page__header {
    margin-bottom: 0.3rem;
  }
  .offer-page__steps {
    background-color: #fff;
    padding: 28px 11px;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .offer-page__eligibility-criteria {
      grid-column: 1 / span 5;
    }
    .offer-page__validate-button {
      grid-column: 5 / span 1;
      margin-top: 0.3rem;
    }
  }
}
</style>

export default {
  vn: {
    variable_criteria: {
      transferability: "oui",
      sales_mode: "option",
      claims_manager: "caarea",
      pricing_validity: "1_an",
      limit: "valeur_residuelle",
      claims_number: "illimite",
      wear: "aucun",
      refund: "ttc",
      usage: "particulier",
      deductible_type: "currency",
      deductible_value: "0",
      limit_of_loyability: "valeur_residuelle",
      discount_type: "spare_parts",
      discount_value: "0",
      coverage_option_1_nev: "non",
      coverage_option_2_battery: "non",
      second_hand_parts: "non",
    },
    commercial_criteria: {
      uwr_target: 90,
      taxes: 18,
      caarea_fees: 15,
      commission: 10,
      other_fees: 0,
      discount: 0,
    },
  },
  vo: {
    variable_criteria: {
      transferability: "oui",
      sales_mode: "option",
      claims_manager: "caarea",
      pricing_validity: "1_an",
      limit: "valeur_residuelle",
      claims_number: "illimite",
      wear: "grille_vetuste_1",
      refund: "ttc",
      usage: "particulier",
      deductible_type: "currency",
      deductible_value: "0",
      limit_of_loyability: "10000",
      discount_type: "spare_parts",
      discount_value: "0",
      second_hand_parts: "non",
      warranty_effective_date: "aucune",
      vehicle_preparation: "oui_independant",
    },
    commercial_criteria: {
      uwr_target: 90,
      taxes: 18,
      caarea_fees: 15,
      commission: 10,
      other_fees: 0,
      discount: 0,
    },
  },
}

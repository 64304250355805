import AbstractService from "../AbstractService"
import wearCriteriaGrid from "@/conf/wear_criteria_grid_info"

class Criteria extends AbstractService {
  special_criteria = ["sales_mode_", "usage_"]

  getSpecialCriteriaValue(offerConfig, listCriteria, criteriaBeginningName) {
    for (let criteria in listCriteria) {
      const criteriaName = listCriteria[criteria]
      if (criteriaName.startsWith(criteriaBeginningName)) {
        return criteriaName
      }
    }
    return ""
  }

  getSpecialCriteriaName(listCriteria) {
    let specialCriteria = []
    for (let criteria in listCriteria) {
      const criteriaName = listCriteria[criteria]
      for (let criteriaBeginning in this.special_criteria) {
        if (criteriaName.startsWith(this.special_criteria[criteriaBeginning])) {
          specialCriteria.push(criteriaName)
        }
      }
    }
    return specialCriteria
  }

  /**
   * Indicate whether the provided criterion is a criterion shared within the offer that includes the specified
   * product line.
   *
   * @param {Object} productLineConfig config of the product line
   * @param {String} criterion name of the criterion
   *
   * @returns {Boolean}
   */
  isSharedCriterion(productLineConfig, criterion) {
    return Object.values(productLineConfig.shared_coefficient_criteria).includes(
      criterion
    )
  }

  /**
   * Indicate whether the provided criterion is a criterion for which we can select several values.
   *
   * @param {String} criterion name of the criterion
   *
   * @returns {Boolean}
   */
  isMultipleSelectCriterion(criterion) {
    const multipleSelectCriteria = ["coverage_type_body_care"]
    return multipleSelectCriteria.includes(criterion)
  }

  /**
   * Indicate whether the provided criterion is a wear criterion.
   *
   * @param {String} criterion name of the criterion
   *
   * @returns {Boolean}
   */
  isWearCriterion(criterion) {
    return wearCriteriaGrid.hasOwnProperty(criterion)
  }

  /**
   * Indicate whether the provided criterion is a wear criterion of type grid.
   *
   * @param {String} criterion name of the criterion
   * @param {String} value value of the criterion
   *
   * @returns {Boolean}
   */
  isWearCriterionGrid(criterion, value) {
    return this.isWearCriterion(criterion)
      ? wearCriteriaGrid[criterion].hasOwnProperty(value)
      : false
  }

  /**
   * Return the info of the provided wear criterion grid.
   * Caller is intended to ensure criterion is actually a wear criterion grid (by using method isWearCriterionGrid).
   *
   * @param {String} criterion name of the wear criterion
   * @param {String} value value of the wear criterion (grid)
   *
   * @returns {Object}
   */
  getWearGridInfo(criterion, value) {
    return wearCriteriaGrid[criterion][value]
  }
}

let CriteriaService = new Criteria()
export default CriteriaService

<template>
  <div
    class="list-group-item"
    :class="[isModal ? 'border-none' : '', !isLast ? 'border-share-list' : '']"
    :data-cy="`shared-list-item-${programShared.id}`"
  >
    <div class="d-flex align-items-center">
      <ModalConfirmation
        v-if="showConfirmationModal"
        :title="$t('sharedPrograms.confirmationModal.title')"
        :message="$t('sharedPrograms.confirmationModal.message')"
        @confirm="onConfirm"
        @close="onCloseConfirmationModal"
      ></ModalConfirmation>
      <div class="col-6 text-left" :data-cy="`programShared-email-${programShared.id}`">
        {{ programShared.user.email }}
      </div>
      <div class="col-4" :data-cy="`sharing-status-${programShared.id}`">
        {{ getStatus }}
      </div>
      <div class="col-2">
        <div class="share-icons">
          <i
            v-for="icon in listIcons"
            :class="icon.name"
            :title="icon.title"
            :key="icon.title"
            :data-cy="`${icon.action}-${programShared.id}`"
            @click="onActionSharedProgram(icon.action)"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateService from "@/services/technical/DateService"
import i18n from "@/i18n/index"
import ModalConfirmation from "@/components/modal/ModalConfirmation"

export default {
  name: "ListItem",
  components: {
    ModalConfirmation,
  },
  data: function () {
    return {
      icons: [
        {
          title: i18n.t("sharedPrograms.renew"),
          name: "icon-sync",
          action: "renew",
        },
        {
          title: i18n.t("sharedPrograms.resend"),
          name: "icon-reply",
          action: "resend",
        },
        {
          title: i18n.t("sharedPrograms.delete"),
          name: "icon-trash2",
          action: "revoke",
        },
        {
          title: i18n.t("sharedPrograms.reactivate"),
          name: "icon-enter-up",
          action: "reactivate",
        },
      ],
      showConfirmationModal: false,
    }
  },
  props: ["programShared", "isModal", "isLast"],
  computed: {
    listIcons() {
      if (!this.programShared.is_active) {
        return this.icons.filter((i) => i.action === "reactivate")
      }
      const daysLeft = DateService.diffDaysBetweenDates(
        this.programShared.expiration_date
      )
      // Expired ?
      if (daysLeft <= 0) {
        return this.icons.filter((i) => ["renew", "revoke"].includes(i.action))
      }
      return this.icons.filter((i) => i.action !== "reactivate")
    },
    getStatus() {
      if (!this.programShared.is_active) {
        return this.$t("sharedPrograms.sharingStatus.revoked")
      }
      const daysLeft = DateService.diffDaysBetweenDates(
        this.programShared.expiration_date
      )
      // Expired ?
      if (daysLeft <= 0) {
        return this.$t("sharedPrograms.sharingStatus.expired")
      }
      return daysLeft + " " + this.$t("sharedPrograms.daysLeft")
    },
  },
  methods: {
    async onActionSharedProgram(action) {
      if (action === "resend") {
        await this.$store.dispatch(
          "programShared/sendSharingLinkEmail",
          this.programShared
        )
        this.$emit("feedback", this.$t("programs.share_modal.resend_feedback"))
      } else if (action === "renew") {
        const programShared = await this.$store.dispatch(
          "programShared/renewSharedProgram",
          this.programShared
        )
        this.$emit("update")
        this.$emit(
          "feedback",
          this.$t("programs.share_modal.renew_feedback") +
            " " +
            DateService.format(programShared.expiration_date, "DD MMMM YYYY")
        )
      } else if (action === "revoke") {
        await this.$store.dispatch(
          "programShared/revokeSharedProgram",
          this.programShared
        )
        this.$emit("update")
        this.$emit("feedback", this.$t("programs.share_modal.revoke_feedback"))
      } else if (action === "reactivate") {
        this.showConfirmationModal = true
      }
    },
    async onConfirm() {
      this.showConfirmationModal = false
      const programShared = await this.$store.dispatch(
        "programShared/reactivateSharedProgram",
        this.programShared
      )
      this.$emit("update", programShared)
      this.$emit("feedback", this.$t("programs.share_modal.reactivate_feedback"))
    },
    onCloseConfirmationModal() {
      this.showConfirmationModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.border-none {
  border: initial !important;
}

.border-share-list {
  border-bottom: 1px solid #d8d8d8 !important;
}

$nbIcons: 3;
$iconsWidth: 18px;
$nbGaps: $nbIcons - 1;

.share-icons {
  display: flex;
  justify-content: flex-end;
  gap: calc((100% - (#{$nbIcons} * #{$iconsWidth})) / #{$nbGaps});
  i {
    font-size: $iconsWidth;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}
</style>

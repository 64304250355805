<template>
  <ModalBootstrap
    id-modal="modalShare"
    class="criteria"
    :title="$t('programs.share_modal.title')"
    modal-size="large"
    @close="onClose"
    data-cy="share-modal"
  >
    <template #body>
      <div class="mx-5 mb-3 d-flex flex-column justify-content-center">
        <div class="mb-5 text-center w-100 font-size-13">
          {{ $t("programs.share_modal.instructions") }}
        </div>
        <div class="row mt-3">
          <div class="col-3 my-auto align-items-center">
            <p>{{ $t("programs.share_modal.label") }}</p>
          </div>
          <div class="col-5">
            <FormInput
              :placeholder="$t('programs.share_modal.placeholder')"
              id="contact-email-input"
              name="contact-email-input"
              v-model="contactEmail"
              data-cy="contact-email-input"
              type="email"
            ></FormInput>
          </div>
          <div class="col-4">
            <button
              class="btn btn-primary w-100 px-1"
              data-cy="share-send-button"
              :disabled="isDisabled"
              @click="onShareProgram"
            >
              {{ $t("button.send_invitation") }}
            </button>
          </div>
        </div>
        <transition name="fade">
          <div class="feedback">
            <div
              data-cy="feedback"
              class="text-center font-size-16 feedback-text"
              v-show="feedback"
            >
              {{ feedback }}
            </div>
          </div>
        </transition>
        <div class="row">
          <spinner v-if="isLoading" :no-message="true" class="w-100 mb-5"></spinner>
          <ul class="w-100 mb-5" v-else>
            <li
              v-for="(programShared, index) in listProgramSharedByProgramId(programId)"
              :key="programShared.id"
            >
              <ShareListItem
                v-if="programShared.is_active"
                :program-shared="programShared"
                :is-modal="true"
                :is-last="index === Object.keys(programsShared).length - 1"
                @feedback="setFeedback"
                @update="loadProgramShared"
              />
            </li>
          </ul>
        </div>
        <div class="row justify-content-center mb-4">
          <div class="col-md-6 px-2 pt-4">
            <button
              class="btn btn-secondary btn-block px-1 m-auto"
              @click.prevent="onClose"
              data-cy="close-sharing-center-button"
            >
              {{ $t("button.close_sharing_center") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import router from "@/router"
import ShareListItem from "@/components/ShareListItem"
import { mapGetters } from "vuex"

export default {
  name: "ModalShare",
  components: { ModalBootstrap, ShareListItem },
  props: {
    programId: { type: Number, required: true },
  },
  data() {
    return {
      contactEmail: "",
      feedback: "",
      programsShared: [],
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters("programShared", ["listProgramSharedByProgramId"]),
    isDisabled() {
      return this.validEmail()
    },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
    validEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return !re.test(this.contactEmail)
    },
    async onShareProgram() {
      try {
        await this.$store.dispatch("program/share", this.contactEmail)
        this.updateSharing(this.$t("programs.share_modal.send_feedback"))
      } catch (e) {
        if (e && e.status && e.status === 409) {
          this.setFeedback(this.$t("programs.share_modal.already_shared_feedback"))
        } else {
          await router.push({ name: "500" })
          throw Error(e)
        }
      }
    },
    setFeedback(feedback) {
      this.feedback = feedback
      setTimeout(() => (this.feedback = ""), 3000)
    },
    async loadProgramShared() {
      this.isLoading = true
      try {
        await this.$store.dispatch("programShared/loadByProgramId", this.programId)
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
    },
    async updateSharing(feedback) {
      await this.loadProgramShared()
      this.setFeedback(feedback)
      this.contactEmail = ""
    },
  },
  async created() {
    this.loadProgramShared()
  },
}
</script>

<style lang="scss" scoped>
.feedback {
  min-height: 45px;
  margin-top: 15px;
}
.feedback-text {
  background: $tertiary;
  padding: 2px;
  font-weight: 600;
}
.shareModal {
  border: none;
}
/deep/ li > .list-group-item {
  border-width: 0 0 1px 0 !important; /* top right bottom left */
  border-style: solid;
  border-color: $primary;
}

/deep/ li:last-child > .list-group-item {
  border: 0;
}
</style>

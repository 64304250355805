<template>
  <div class="program-start-page">
    <ProgramContentHeader
      :desc="$t('programs.programStart.programContentHeaderDesc')"
      :program="this.program"
      :title="title"
      :is-share-button-visible="false"
    ></ProgramContentHeader>
    <div class="p-3 mt-2 program-start-div">
      <div class="program-start__start_configuration font-size-15">
        {{ $t("programs.programStart.startConfiguration") }}
      </div>
      <div class="program-start__top text-center">
        <i class="program-start__icon__film__play icon-film-play"></i>
        <h1
          class="program-start__title mt-3 font-effra-normal text-uppercase primary-color"
          data-cy="program-start-title"
        >
          {{ $t("programs.programStart.title") }}
        </h1>
        <div class="program-start__bottom">
          <p>
            {{ $t("programs.programStart.text") }}
            <br />
            <span
              class="program-start__span"
              :data-cy="`program-${this.program.id}-title`"
            >
              {{ title }}
            </span>
          </p>
          <div>
            <button
              class="btn btn-light-gray p-2 mt-4"
              data-cy="program-start-button"
              type="button"
              @click="onOfferButtonClick(offer.id)"
            >
              <span>
                <i class="program-start__icon__play__circle icon-play-circle mr-2"></i>
                {{ $t("programs.programStart.start") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramMixin from "../mixins/ProgramMixin"
import ProgramContentHeader from "./ProgramContentHeader"
import { mapGetters } from "vuex"
export default {
  name: "ProgramStart",
  components: { ProgramContentHeader },
  props: {
    title: { type: String, required: true },
    program: { type: Object, required: true },
    offer: { type: Object, required: true },
  },
  mixins: [ProgramMixin],
  computed: {
    ...mapGetters("auth", ["getRouteName"]),
    ...mapGetters("offer", ["isOfferExternal", "listProgramProducts"]),
  },
  methods: {
    async onOfferButtonClick(offerId) {
      if (this.isOfferExternal(offerId)) {
        await this.$router.push({
          name: this.getRouteName("programProduct"),
          params: {
            offerId: offerId,
            programProductId: this.offer.program_products[0].id,
          },
        })
      } else {
        await this.$router.push({
          name: this.getRouteName("programOffer"),
          params: { offerId: offerId },
        })
      }
    },
  },
  async created() {
    await this.$store.dispatch("offer/setCurrentOfferId", this.offer.id)
  },
}
</script>

<style lang="scss" scoped>
p {
  font-family: "Effra-light", sans-serif;
}
.program-start-page {
  background-color: $background-program;
  .program-start-div {
    background-color: #fff;
    margin-bottom: 2rem;
    .program-start__top {
      margin-top: 5rem;
      .program-start__title {
        letter-spacing: 1.5px;
      }
      .program-start__icon__film__play {
        font-size: 10em;
        color: $bleucaarea;
      }
      .program-start__bottom {
        padding-bottom: 9rem;
        .program-start__span {
          font-family: "Gilroy SemiBold", sans-serif;
          font-weight: bold;
          text-transform: uppercase;
          color: $bleucaarea;
          letter-spacing: 1.5px;
        }
        .program-start__icon__play__circle {
          grid-area: icon;
          justify-self: start;
          font-weight: bold;
        }
      }
    }
    .program-start__start_configuration {
      font-family: "Effra-light", sans-serif;
      font-weight: normal;
      text-transform: uppercase;
      color: $bleuc100;
      border-left: solid 1px $bleucaarea;
      padding: 0 0 0 5px;
    }
  }
}

button {
  grid-area: button;
  font-family: "Gilroy SemiBold", sans-serif;
  line-height: 1 !important;
}

button span {
  vertical-align: middle;
  font-size: 15px;
}
</style>

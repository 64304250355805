import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import i18n from "../i18n"

const MAX_FILE_SIZE = 3145728 // 3MiB

// STATES (snake_case)
const state = {
  documents: {},
  upload_error: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents.reduce((acc, doc) => {
      acc[doc.id] = doc
      return acc
    }, {})
  },
  SET_DOCUMENT(state, document) {
    Vue.set(state.documents, document.id, document)
  },
  SET_UPLOAD_ERROR(state, error) {
    state.upload_error = error
  },
  RESET_UPLOAD_ERROR(state) {
    state.upload_error = null
  },
  DELETE_DOCUMENT(state, documentId) {
    if (state.documents.hasOwnProperty(documentId)) {
      Vue.delete(state.documents, documentId)
    }
  },
}

// ACTIONS (camelCase)
const actions = {
  _getDocumentId({ state }, payload) {
    let id = null
    // Browse documents until the document with the provided type and program id is found
    for (const [key, value] of Object.entries(state.documents)) {
      if (value.program_id === payload.programId && value.type === payload.type) {
        id = key
        break
      }
    }
    return id
  },
  async _deletePreviousDocument({ commit, dispatch, state }, payload) {
    const previousDocumentId = await dispatch("_getDocumentId", payload)
    if (previousDocumentId) {
      commit("DELETE_DOCUMENT", previousDocumentId)
    }
  },
  async upload({ commit, dispatch, state }, payload) {
    commit("RESET_UPLOAD_ERROR")

    let formData = new FormData()
    formData.append("file", payload.file)
    formData.append("type", payload.type)
    formData.append("program", payload.programId)

    if (payload.file.size > MAX_FILE_SIZE) {
      const errorDetail = i18n.t("error.oversize")
      commit("SET_UPLOAD_ERROR", [errorDetail])
      throw new Error()
    }

    try {
      const response = await HttpService.post(
        UrlService.render("programDocuments"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // Now that the new document has been uploaded,
      // delete the previous one in the store (if any)
      await dispatch("_deletePreviousDocument", payload)

      commit("SET_DOCUMENT", response)
      return response
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_UPLOAD_ERROR", e.data)
        }
      }
      console.error("upload failure", e)
      throw e
    }
  },

  async getDocuments({ commit }) {
    try {
      const response = await HttpService.get(UrlService.render("programDocuments"))
      commit("SET_DOCUMENTS", response)
    } catch (e) {
      console.error("getDocuments failure", e)
      throw e
    }
  },

  resetUploadError({ commit }) {
    commit("RESET_UPLOAD_ERROR")
  },
}

// GETTERS (camelCase)
const getters = {
  getDocumentInfoByTypeAndProgramId: (state) => (type, programId) => {
    const docInfo = Object.values(state.documents).find(
      (value) => value.type === type && value.program_id === programId
    )
    return docInfo ? JSON.parse(JSON.stringify(docInfo)) : null
  },
  areAllDocsUploadedForProgram:
    (state, getters, rootState, rootGetters) => (programId) => {
      if (rootGetters["program/isExternalProgram"](programId)) {
        return true
      } else {
        return (
          getters.getDocumentInfoByTypeAndProgramId("IPID_SHEET", programId) !== null &&
          getters.getDocumentInfoByTypeAndProgramId(
            "WELCOME_PACK_CONCESSION",
            programId
          ) !== null &&
          getters.getDocumentInfoByTypeAndProgramId(
            "WELCOME_PACK_BENEFICIARY",
            programId
          ) !== null
        )
      }
    },
  getUploadError: (state) => state.upload_error,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

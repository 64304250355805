<template>
  <header class="users-header">
    <h1 class="users-header__title">
      {{ $t("users.title.main") }}
    </h1>
    <router-link
      v-if="$router.currentRoute.name === 'usersList'"
      class="btn btn-primary"
      :to="{ name: 'usersNew' }"
      data-cy="new-user-button"
    >
      {{ $t("users.new") }}
    </router-link>
  </header>
</template>

<script>
export default {
  name: "HeaderUsers",
}
</script>

<style scoped lang="scss">
.users-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 100px;
  margin-bottom: 25px;
  padding: 0 38px;

  &__title {
    font-family: "Effra", sans-serif;
    font-size: 24px;
  }
}
</style>

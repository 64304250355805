<template>
  <div data-cy="basket-sidebar">
    <ModalProgramCreation
      v-if="showProgramCreationModal"
      @close="showProgramCreationModal = false"
    ></ModalProgramCreation>
    <div class="bg-grey-6 p-1">
      <span class="d-flex align-items-center justify-content-around">
        <i class="icon-cart font-size-50 basket-icon" aria-hidden="true"></i>
        <h5
          class="p-2 m-0 ml-1 text-uppercase primary-color font-effra-bold font-size-15 text-center"
        >
          {{ programName }}
        </h5>
      </span>
    </div>
    <div data-cy="offer-list">
      <div
        v-for="(offer, index) in offersList"
        :key="index"
        class="drop-zone"
        :data-cy="`drop-zone-${index + 1}`"
        @drop="onDrop($event, index)"
        @dragover.prevent
        @dragenter.prevent
      >
        <div>
          <div
            class="d-flex justify-content-between align-items-center py-2 px-2 bg-bleu-c50 font-size-20"
          >
            <h5 class="primary-color m-0">
              {{ $t("products.basket.number") }} {{ index + 1 }}
            </h5>
            <span
              v-if="index !== 0"
              class="icon-trash2 basket__icon-trash"
              :data-cy="`icon-trash-${index + 1}`"
              @click="onDeleteOfferClick(index)"
            ></span>
          </div>
          <div>
            <ul class="w-100" data-cy="basket-list">
              <li
                v-for="product in listNonAssuranceAutomobileProducts(offer)"
                :key="product.id"
                :data-cy="`basket-offer-${index + 1}-${product.code}`"
                class="d-flex basket__item"
                draggable="true"
                @dragstart="onStartDrag($event, index, product)"
              >
                <span class="font-size-14">{{ product.name }}</span>
                <span
                  class="ml-auto text-right align-self-center"
                  @click="onDeleteClick(product, index)"
                  :title="$t('products.basket.remove')"
                >
                  <div
                    class="icon-cross close-cross"
                    :data-cy="`icon-cross-${index + 1}-${product.code}`"
                    aria-hidden="true"
                  ></div>
                </span>
              </li>
              <li
                v-for="product in listAssuranceAutomobileProducts(offer)"
                :key="product.id"
                :data-cy="`basket-offer-${index + 1}-${product.code}`"
                class="d-flex basket__item-non-draggable"
              >
                <span class="font-size-14">{{ product.name }}</span>
                <span
                  class="ml-auto text-right align-self-center"
                  @click="onDeleteClick(product, index)"
                  :title="$t('products.basket.remove')"
                >
                  <div
                    class="icon-cross close-cross"
                    :data-cy="`icon-cross-${index + 1}-${product.code}`"
                    aria-hidden="true"
                  ></div>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-100 mt-5 mb-3 d-flex flex-md-column justify-content-center align-items-center basket-buttons"
    >
      <button
        type="button"
        class="btn btn-primary opacity-70 mb-2 btn-centered btn__add-offer"
        data-cy="button-add-offer"
        :disabled="isButtonDisabled"
        @click.stop="onAddOfferClick"
      >
        {{ $t("button.add_offer") }}
      </button>
      <button
        type="button"
        class="btn btn-primary btn-centered"
        data-cy="button-create-program"
        :disabled="isButtonDisabled"
        @click.prevent="showProgramCreationModal = true"
        data-toggle="modal"
        data-target="#modalProgramCreation"
      >
        {{ $t("button.create_program") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ModalProgramCreation from "@/components/products/ModalProgramCreation"
import ProgramService from "../../services/business/ProgramService"

export default {
  name: "Basket",
  components: { ModalProgramCreation },
  data() {
    return {
      showProgramCreationModal: false,
    }
  },
  computed: {
    ...mapGetters("basket", ["tagsList", "offersList", "isItemInOffer"]),
    ...mapGetters("product", ["getProducts"]),
    programName() {
      let programType = ProgramService.getProgramType(this.tagsList)
      return programType
        ? this.$t("products.basket." + programType)
        : this.$t("products.basket.title")
    },
    isButtonDisabled() {
      return this.offersList.some((offer) => offer.length === 0)
    },
  },
  methods: {
    async onDeleteClick(product, offerIndex) {
      await this.$store.dispatch("basket/del", {
        basketId: product._basketId,
        offerIndex: offerIndex,
      })
    },
    async onDeleteOfferClick(offerIndex) {
      await this.$store.dispatch("basket/deleteOffer", offerIndex)
    },
    async onAddOfferClick() {
      await this.$store.dispatch("basket/addNewOffer")
    },
    onStartDrag(event, offerIndex, product) {
      event.dataTransfer.dropEffect = "move"
      event.dataTransfer.effectAllowed = "move"
      event.dataTransfer.setData("offerIndex", offerIndex)
      event.dataTransfer.setData("product", JSON.stringify(product))
    },
    async onDrop(event, offerIndex) {
      const dragOfferIndex = parseInt(event.dataTransfer.getData("offerIndex"))
      const product = JSON.parse(event.dataTransfer.getData("product"))
      if (
        offerIndex !== dragOfferIndex &&
        !this.isItemInOffer(product.id, offerIndex) &&
        !this.offersList[offerIndex].find(
          (product) => product.code === "assurance_automobile"
        )
      ) {
        await this.$store.dispatch("basket/addDraggableItem", {
          offerIndex: offerIndex,
          item: product,
        })
        await this.$store.dispatch("basket/del", {
          basketId: product._basketId,
          offerIndex: dragOfferIndex,
        })
      }
    },
    listAssuranceAutomobileProducts(offer) {
      return offer.filter((product) => product.code === "assurance_automobile")
    },
    listNonAssuranceAutomobileProducts(offer) {
      return offer.filter((product) => product.code !== "assurance_automobile")
    },
  },
  async created() {
    await this.$store.dispatch("basket/updateOffersProductName", this.getProducts)
  },
}
</script>

<style scoped lang="scss">
p {
  padding: 20px;
  font-size: 12px;
}

li {
  padding: 10px 15px 10px 15px;
  font-size: 13px;
}

li:nth-child(even) {
  background: $backgroundsecondary;
}

.basket-icon {
  color: #dadada;
  border-radius: 43px;
  background-color: white;
  padding: 10px;
}

.basket__icon-trash {
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.drop-zone {
  min-height: 7rem;
}

.basket__item {
  cursor: pointer;
}

.basket__item-non-draggable {
  cursor: not-allowed;
}

.btn__add-offer {
  background: #c0c8d7;
  border-color: #c0c8d7;
}

.basket-buttons {
  padding-left: 1rem;
  padding-right: 1rem;

  button {
    width: 100%;
  }
}
</style>

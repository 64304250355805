import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

/* This store is used to handle search criteria dynamic content */

// STATE (snake_case)
const state = {
  makes: {},
  models: {},
  versions: {},
  trim_levels: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_MAKES: (state, makes) => {
    state.makes = makes.reduce((acc, make) => {
      acc[make.id] = make.local_name
      return acc
    }, {})
  },
  SET_SELECTED_MODEL: (state, model) => {
    state.selected_model = model
  },
  SET_MODELS: (state, models) => {
    state.models = models.reduce((acc, model) => {
      acc[model.id] = model.local_name
      return acc
    }, {})
  },
  SET_TRIM_LEVELS: (state, trimLevels) => {
    state.trim_levels = trimLevels.reduce((acc, curr) => {
      Object.assign(acc, curr)
      return acc
    }, {})
  },
  RESET_MODELS: (state) => {
    state.models = {}
  },
  RESET_VERSIONS: (state) => {
    state.versions = {}
  },
}

// ACTIONS (camelCase)
const actions = {
  async setMakes({ commit }, payload = null) {
    if (payload) {
      commit("SET_MAKES", payload)
    } else {
      try {
        const makes = await HttpService.get(UrlService.render("cvdbMake"))
        commit("SET_MAKES", makes)
      } catch (e) {
        console.error("setMakes failed: ", e)
        throw e
      }
    }
  },
  async setModels({ commit }, payload) {
    commit("SET_MODELS", payload)
  },
  selectMake({ commit }) {
    commit("RESET_MODELS")
    commit("RESET_VERSIONS")
  },
  selectModel({ commit }, models) {
    commit("SET_SELECTED_MODEL", models)
    commit("RESET_VERSIONS")
  },
  async retrieveModels({ commit }, makes) {
    try {
      const models = await HttpService.get(
        UrlService.render("cvdbModel", {}, { make: makes.join(",") })
      )
      commit("SET_MODELS", models)
    } catch (e) {
      console.error("getModels failed: ", e)
      throw e
    }
  },
  async retrieveTrimLevels({ commit }, models) {
    try {
      const trimLevels = await HttpService.get(
        UrlService.render("cvdbTrimLevel", {}, { model: models.join(",") })
      )
      commit("SET_TRIM_LEVELS", trimLevels)
    } catch (e) {
      console.error("getTrimLevels failed: ", e)
      throw e
    }
  },
}

// GETTERS
const getters = {
  listMakes: (state) => state.makes,
  listModels: (state) => state.models,
  listTrimLevels: (state) => state.trim_levels,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <spinner v-if="isLoading"></spinner>
  <div v-else-if="canDisplayProgram" class="program-layout h-100">
    <ProgramTimeLine class="program-timeline" :program="getProgram" />
    <transition name="fade" mode="out-in">
      <router-view class="layout__program-router" />
    </transition>
  </div>
</template>

<script>
import ProgramTimeLine from "@/components/presales/ProgramTimeLine"
import { mapGetters } from "vuex"
export default {
  name: "LayoutProgram",
  watch: {
    async $route() {
      await this.refreshOffer()
    },
  },
  data() {
    return { currentOffer: null, isLoading: true }
  },
  props: {
    id: {
      type: [String, Number],
      // required: true,
    },
    sharedToken: {
      type: String,
    },
  },
  components: { ProgramTimeLine },
  computed: {
    ...mapGetters("program", ["getProgram"]),
    canDisplayProgram() {
      return this.getProgram !== null
    },
  },
  methods: {
    async refreshProgram() {
      if (this.id) {
        await this.$store.dispatch("program/getProgram", this.id)
      } else {
        await this.$store.dispatch("program/getSharedProgram", this.sharedToken)
      }
    },
    async refreshOffer() {
      if (
        this.$route.params.hasOwnProperty("offerId") === false ||
        this.$route.params.offerId === this.currentOffer
      ) {
        return null
      }
      // Get offer and the program products it contains
      await this.$store.dispatch("offer/getOffer", this.$route.params.offerId)
      this.currentOffer = this.$route.params.offerId
    },
  },
  async created() {
    if (!this.id && !this.sharedToken) {
      console.error("props id or sharedToken are needed")
      this.$router.push({ name: 500 })
    }
    await this.refreshProgram()
    this.isLoading = false
  },
}
</script>

<style lang="scss">
.program-layout {
  display: flex;
  flex-direction: row;
  background-color: $background-program;
}
.program-timeline {
  width: 20%;
  padding-top: 35px;
  background-color: #fff;
}
.layout__program-router {
  width: 77.5%;
  margin: 15px auto 0;
}
.layout__program-router > * {
  background-color: #fff;
}
</style>

<template>
  <div class="dropdown show">
    <div
      class="btn btn-light-gray btn-block dropdown-toggle"
      role="button"
      id="dropdownMenuLink"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :data-cy="`dropdown-button-criteria-${productLineId}`"
    >
      {{ $t("button.modify_criteria") }}
    </div>

    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <div
        class="dropdown-item"
        :data-cy="`show-guarantee-modal-${productLineId}`"
        @click="openModal('GUARANTEES')"
        data-toggle="modal"
        data-target="#modalProgramCriteria"
      >
        {{ $t("button.modify_guarantees") }}
      </div>
      <div
        class="dropdown-item"
        :data-cy="`show-commissions-and-taxes-modal-${productLineId}`"
        @click="openModal('COMMISSIONS_AND_TAXES')"
        data-toggle="modal"
        data-target="#modalCommissionsAndTaxes"
      >
        {{ $t("button.modify_commission_tax") }}
      </div>
      <div
        class="dropdown-item"
        :data-cy="`show-frequency-modal-${productLineId}`"
        @click="openModal('FREQUENCY')"
        data-toggle="modal"
        data-target="#modalPaymentFrequency"
      >
        {{ $t("button.modify_frequency") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productLineId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openModal(modalName) {
      return this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName,
        productLineId: this.productLineId,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown-item {
  cursor: pointer;
}
</style>

const localStorageNamePrefix = "cs"
export default {
  basket: `${localStorageNamePrefix}-basket`,
  currentOfferIndex: `${localStorageNamePrefix}-current-offer-index`,
  lang: `${localStorageNamePrefix}-lang`,
  programs: `${localStorageNamePrefix}-programs`,
  programTags: `${localStorageNamePrefix}-programTags`,
  selectedNetwork: `${localStorageNamePrefix}-selectedNetwork`,
  pricing: {
    vn: {
      variableCriteria: `${localStorageNamePrefix}-vn-variable-criteria`,
      commercialCriteria: `${localStorageNamePrefix}-vn-commercial-criteria`,
    },
    vo: {
      variableCriteria: `${localStorageNamePrefix}-vo-variable-criteria`,
      commercialCriteria: `${localStorageNamePrefix}-vo-commercial-criteria`,
    },
  },
}

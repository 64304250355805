// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

const state = {
  product_domains: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PRODUCT_DOMAINS(state, domains) {
    state.product_domains = domains
  },
}

// ACTIONS (camelCase)
const actions = {
  async loadProductDomains({ commit }) {
    try {
      const list_domains = await HttpService.get(UrlService.render("refProductDomains"))
      let domains = list_domains.reduce((acc, d) => {
        d.subdomains = d.subdomains.reduce((subdomains, sd) => {
          subdomains[sd.code] = sd
          return subdomains
        }, {})
        acc[d.code] = d
        return acc
      }, {})
      commit("SET_PRODUCT_DOMAINS", domains)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  defaultProductDomain: () => "vehicule",
  getProductDomains: (state) => state.product_domains,
  getDomainSubDomains: (state) => (domainKey) =>
    state.product_domains[domainKey].subdomains,
  listDomainSubDomains: (state) => (domainKey) =>
    Object.keys(state.product_domains[domainKey].subdomains),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

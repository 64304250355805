<template>
  <div>
    <OfferDescription :offer-in-progress="offerInProgressB2cContent"></OfferDescription>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OfferDescription from "@/components/offer/OfferDescription"

export default {
  name: "ProgramDescription",
  components: { OfferDescription },
  computed: {
    ...mapGetters("offer", ["getCurrentOffer"]),
    offerInProgressB2cContent() {
      let offerInProgress = { ...this.getCurrentOffer }
      for (const content in this.getCurrentOffer.offer_contents) {
        if (this.getCurrentOffer.offer_contents[content].type === "B2C") {
          offerInProgress.description =
            this.getCurrentOffer.offer_contents[content].description
          offerInProgress.name = this.getCurrentOffer.offer_contents[content].name
          offerInProgress.content_id = this.getCurrentOffer.offer_contents[content].id
        }
      }
      return offerInProgress
    },
  },
}
</script>

<style lang="scss">
.product-description__container {
  padding: 50px 50px 0 15px;
  .product-description__input-div {
    margin-bottom: 50px;
  }
  .product-description__label label {
    font-family: "Effra_semi_bold", sans-serif;
    font-size: 18px;
    color: $bleucaarea;
    font-weight: 400;
  }
  .product-description__input-div input,
  .product-description__input-div textarea {
    font-family: "Effra-light", sans-serif;
    font-size: 14px;
    color: $primarybutton;
  }
}
</style>

export default {
  editUserProfile: "/user-profiles/{id}/",
  createUserProfile: "/user-profiles/",
  fetchLoggedUser: "/users/me/",
  userAcceptCookies: "/users/{id}/cookies/",
  userLang: "/users/{id}/lang/",
  userList: "/users/{id}/list-users/",
  user: "/users/{id}/",
  pricingMatricesVn: "pricing-vn/matrices/",
  pricingMatricesVo: "pricing-vo/matrices/",
  pricingBase100VN: "pricing-vn/base100/",
  pricingBase100VO: "pricing-vo/base100/",
  pricingTpp2VN: "pricing-vn/tpp2/",
  pricingTpp2VO: "pricing-vo/tpp2/",
  products: "products/",
  usedVehiclesSearch: "used-vehicles/search/",
  velasticSearch: "velastic/search/",
  velasticSearchModelsByMake: "velastic/models/",
  velasticSearchVersionsByModel: "velastic/versions/",
  velasticSearchTrimLevelsByVersion: "velastic/trim-levels/",
  productLineCoefficientCriteria: "product-lines/{id}/coefficient-criteria/",
  productLinePricing: "prices/",
  productLinePrices: "product-lines/prices/",
  productLinePricingRange: "product-lines/pricing-range/",
  productLineById: "product-lines/{id}/",
  programProductById: "program-products/{id}/",
  programProductCoefficientCriteria: "program-products/{id}/coefficient-criteria",
  programProductMutualAgreement: "program-products/{id}/mutual-agreement/",
  programProductActivation: "program-products/{id}/activate/",
  programProductEligibleProductLines: "program-products/{id}/eligible-product-lines/",
  programDocuments: "program-documents/",
  offerDocuments: "offer-documents/",
  programs: "programs/",
  programById: "programs/{id}/",
  productLineByProgramId: "programs/{id}/product-lines/",
  partnerByProgramId: "programs/{id}/partner/",
  networkByPartnerId: "partners/{id}/networks/",
  beneficiariesByProgramId: "programs/{id}/beneficiaries/",
  programSharedByProgramId: "programs/{id}/programs-shared/",
  programShared: "program-shared/",
  programSharedById: "program-shared/{id}/",
  programSharedRenewById: "program-shared/{id}/renew/",
  programSharedRevokeById: "program-shared/{id}/revoke/",
  programSharedReactivateById: "program-shared/{id}/reactivate/",
  programSharedEmail: "program-shared-email/",
  programSharedByToken: "program-shared/{id}/",
  programSharedTestUrl: "program-shared/{id}/program_shared_test_url/",
  networkSharedTestUrl: "program-shared/{id}/network_shared_test_url/",
  refProductDomains: "reference/product-domains/",
  networks: "networks/",
  networkById: "networks/{id}/",
  downloadNetworkFileById: "networks/{id}/download/",
  networkSendEmails: "networks/{id}/send-emails/",
  networkSendResetEmails: "networks/{id}/send-reset-emails/",
  listMakes: "reference/makes/",
  listEnergies: "reference/energies/",
  cloudinarySignature: "cloudinary/signature/",
  offerById: "offers/{id}/",
  offerContent: "offer-content/{id}/",
  offerActivation: "offers/{id}/activate/",
  offerCoeffCriteria: "offers/{id}/coefficient-criteria/",
  coverageDurationKmCouples: "coverage/couples/",
  offerContentById: "offer-content/{id}/",
  partners: "partners/",
  partnersTypes: "partners/types",
  cvdbIsVn: "vehicles/is-vn/",
  cvdbMake: "vehicles/makes/",
  cvdbModel: "vehicles/models/",
  cvdbTrimLevel: "vehicles/trim-levels/",
  cvdbYear: "vehicles/years/",
  cvdbPower: "vehicles/powers/",
  cvdbCylinders: "vehicles/cylinders/",
  cvdbEngineType: "vehicles/engine-types/",
  vehicleSearch: "vehicles/search/",
  vehicleVariations: "vehicles/variations/",
  companies: "/companies/",
  roles: "/users/assignable-groups/",
}

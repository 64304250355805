<template>
  <div>
    <ProgramStart
      v-if="!areAllOffersActive"
      class="w-100 h-100"
      :title="this.program.name"
      :program="this.program"
      :offer="this.program.offers[0]"
    ></ProgramStart>
    <ProgramEnd
      v-if="areAllOffersActive"
      class="w-100 h-100"
      :title="this.program.name"
      :program="this.program"
    ></ProgramEnd>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProgramStart from "@/components/presales/components/ProgramStart"
import ProgramEnd from "@/components/presales/components/ProgramEnd"

export default {
  name: "ProgramState",
  components: { ProgramEnd, ProgramStart },
  props: {
    program: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("offer", ["isOfferActive"]),
    areAllOffersActive() {
      return this.program.offers.every((offer) => this.isOfferActive(offer.id))
    },
  },
}
</script>

<style scoped></style>

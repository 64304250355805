<template>
  <ModalBootstrap
    class="criteria"
    :title="title"
    :show-close-button="false"
    modal-size="large"
    data-cy="feedback-modal"
  >
    <template #body>
      <div class="mx-2 mb-2 d-flex flex-column justify-content-center">
        <slot name="report">
          <p>Modal body text goes here.</p>
        </slot>
        <div class="d-flex justify-content-center mt-4">
          <button
            class="btn btn-primary w-25 px-3 mb-1"
            @click.prevent="onConfirm"
            data-cy="validate-button"
          >
            {{ $t("button.validate") }}
          </button>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"

export default {
  name: "ModalValidate",
  components: { ModalBootstrap },
  props: ["message", "title"],
  methods: {
    onConfirm() {
      this.$emit("confirm")
    },
  },
}
</script>

<template>
  <div :class="[...buttonClass]">
    <button
      :disabled="disabled"
      :class="btnClass"
      :data-cy="buttonDataCy"
      @click="onButtonClick(optionalArg)"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    disabled: {
      type: Boolean,
    },
    buttonClass: {
      type: Array,
    },
    buttonText: {
      type: String,
      required: true,
    },
    btnClass: {
      type: String,
      default: "btn btn-primary btn-block",
    },
    buttonDataCy: {
      type: String,
      default: "validate-button",
    },
    optionalArg: {
      default: () => "",
    },
  },
  methods: {
    onButtonClick(optionalArg) {
      this.$emit("click-action", optionalArg)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  width: 260px;
  padding: 0.375rem 1rem;
}
.centered-button {
  button {
    margin: 50px auto;
  }
}
</style>

import { render, staticRenderFns } from "./ProductLineConfigCriterion.vue?vue&type=template&id=fbc46456&scoped=true&"
import script from "./ProductLineConfigCriterion.vue?vue&type=script&lang=js&"
export * from "./ProductLineConfigCriterion.vue?vue&type=script&lang=js&"
import style0 from "./ProductLineConfigCriterion.vue?vue&type=style&index=0&id=fbc46456&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc46456",
  null
  
)

export default component.exports
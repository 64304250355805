import AbstractService from "@/services/AbstractService"
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import i18n from "@/i18n"

class FileUpload extends AbstractService {
  /**
   * Upload provided file.
   *
   * @param {Object} file file to upload
   * @param {Number} resourceId id of the resource
   * @param {String} urlName url name to use
   * @param {Number} partnerId partner id
   *
   * @returns {Promise<unknown>}
   */
  async upload(file, resourceId, urlName, partnerId) {
    let formData = new FormData()
    formData.append("file", file)
    formData.append("partner", partnerId)
    formData.append("name", file.name)

    try {
      return await HttpService.post(
        UrlService.render(urlName, {
          id: resourceId,
        }),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    } catch (e) {
      console.error("File upload failure : ", e)
      throw e
    }
  }

  /**
   * Indicate whether upload errors contain import errors.
   *
   * @param {Object} uploadErrors errors retrieved from file upload response
   *
   * @returns {Boolean}
   */
  hasImportErrors(uploadErrors) {
    return uploadErrors && uploadErrors.hasOwnProperty("import")
  }

  /**
   * Return the right errors to display.
   *
   * @param {Object} uploadErrors errors retrieved from file upload response
   *
   * @returns {String}
   */
  getUploadErrors(uploadErrors) {
    if (this.hasImportErrors(uploadErrors)) {
      return i18n.t("programParameters.uploadErrorModal.importError")
    } else {
      return Object.values(uploadErrors)[0]
    }
  }

  /**
   * Return the detailed import errors to display.
   *
   * @param {Object} uploadErrors errors retrieved from file upload response
   *
   * @returns {Array}
   */
  listImportErrors(uploadErrors) {
    let errors = []
    if (!this.hasImportErrors(uploadErrors)) {
      return errors
    }
    // For all the sheet names
    Object.entries(uploadErrors.import).forEach(([sheetName, info]) => {
      // For all the rows in error
      info.errors.forEach((rowError) => {
        // Build a list of errors with each error containing "sheet name", "row", "error description"
        let rowErrors = rowError.errors.reduce((acc, fieldError) => {
          acc.push({
            sheetName: sheetName,
            row: rowError.num_row,
            desc: fieldError,
          })
          return acc
        }, [])
        // Add the list of errors for the current row to the final list of errors
        errors = errors.concat(rowErrors)
      })
    })
    return errors
  }
}

let FileUploadService = new FileUpload()
export default FileUploadService

<template>
  <div>
    <ModalVehicleSearch
      v-if="showVehicleSearchModal"
      @close="onCloseVehicleSearchModal()"
      @vehicle-selected="onVehicleSelected"
      :program-product-id="programProductId"
      :product-line-id="productLineId"
    ></ModalVehicleSearch>
    <FormRow
      class="font-size-14"
      :class="formRowClass"
      :label="$t('programs.form.beneficiaryCriteria.vehicle.label')"
      for-id="program-link"
      name="vehicle"
      :inline="false"
    >
      <a
        id="program-link"
        class="link mouse-pointer font-size-18 d-block"
        @click="() => (showVehicleSearchModal = true)"
        data-cy="showModalVehicleSearch"
        data-toggle="modal"
        data-target="#modalVehicleSearch"
      >
        {{ vehicleInfo }}
      </a>
    </FormRow>
  </div>
</template>

<script>
import ModalVehicleSearch from "@/components/presales/modal/ModalVehicleSearch"
import { mapGetters } from "vuex"

export default {
  name: "VehicleChoice",
  components: {
    ModalVehicleSearch,
  },
  props: {
    productLineId: {
      type: [Number, String],
    },
    programProductId: {
      type: [Number, String],
      required: true,
    },
    vehicleInfo: {
      type: String,
    },
    eligibilitySearchFilter: {
      type: Object,
      required: true,
    },
    formRowClass: {
      type: Array,
    },
  },
  data() {
    return {
      showUsedVehicleSearchModal: false,
      showVehicleSearchModal: false,
    }
  },
  computed: {
    ...mapGetters("programProduct", ["getProgramProductById"]),
    ...mapGetters("searchVehicle", ["getIsVnFilterValueFromProgramVehicleTag"]),
    ...mapGetters("program", ["getProgram"]),
    showModalDataCy() {
      const baseValue = "show-vehicle-search-modal"
      if (this.productLineId) {
        return baseValue + `-${this.productLineId}`
      }
      return baseValue
    },
  },
  methods: {
    onVehicleSelected(vehicle) {
      this.$emit("vehicle-selected", vehicle)
    },
    async onCloseVehicleSearchModal() {
      this.showVehicleSearchModal = false
    },
  },
}
</script>

<style lang="scss" scoped></style>

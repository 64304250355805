// MUTATIONS (SNAKE_CASE)
import Vue from "vue"

export const mutations = {
  SET_PROGRAM_PRODUCT_ID(state, programProductId) {
    state.program_product_id = programProductId
  },
  SET_PRODUCT_LINES_IDS(state, productLinesIds) {
    state.product_lines_ids = productLinesIds
  },
  SET_CUSTOM_PROGRAM_CRITERIA_LIST_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.custom_program_criteria_list_by_product_line_id,
      payload.productLineId,
      payload.customProgramCriteriaList
    )
  },
  DELETE_CUSTOM_PROGRAM_CRITERIA_LIST_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.custom_program_criteria_list_by_product_line_id, productLineId)
  },
  SET_PROGRAM_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.program_criteria_values_by_product_line_id,
      payload.productLineId,
      payload.programCriteriaValues
    )
  },
  DELETE_PROGRAM_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.program_criteria_values_by_product_line_id, productLineId)
  },
  SET_USER_SELECTABLE_VALUES_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.user_selectable_values_by_product_line_id,
      payload.productLineId,
      payload.userSelectableValues
    )
  },
  DELETE_USER_SELECTABLE_VALUES_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.user_selectable_values_by_product_line_id, productLineId)
  },
  SET_PAYMENT_FREQUENCY_VALUE_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.payment_frequency_value_by_product_line_id,
      payload.productLineId,
      payload.paymentFrequencyValue
    )
  },
  DELETE_PAYMENT_FREQUENCY_VALUE_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.payment_frequency_value_by_product_line_id, productLineId)
  },
  SET_BENEFICIARY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.beneficiary_criteria_values_by_product_line_id,
      payload.productLineId,
      payload.beneficiaryCriteriaValues
    )
  },
  DELETE_BENEFICIARY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.beneficiary_criteria_values_by_product_line_id, productLineId)
  },
  SET_CUSTOM_ELIGIBILITY_CRITERIA_LIST_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.custom_eligibility_criteria_list_by_product_line_id,
      payload.productLineId,
      payload.customEligibilityCriteriaList
    )
  },
  DELETE_CUSTOM_ELIGIBILITY_CRITERIA_LIST_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.custom_eligibility_criteria_list_by_product_line_id, productLineId)
  },
  SET_ELIGIBILITY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.eligibility_criteria_values_by_product_line_id,
      payload.productLineId,
      payload.eligibilityCriteriaValues
    )
  },
  DELETE_ELIGIBILITY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.eligibility_criteria_values_by_product_line_id, productLineId)
  },
  SET_VEHICLE_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(state.vehicle_by_product_line_id, payload.productLineId, payload.vehicle)
  },
  DELETE_VEHICLE_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.vehicle_by_product_line_id, productLineId)
  },
  SET_COMMERCIAL_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.commercial_criteria_values_by_product_line_id,
      payload.productLineId,
      payload.commercialCriteriaValues
    )
  },
  DELETE_COMMERCIAL_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.commercial_criteria_values_by_product_line_id, productLineId)
  },
  SET_IS_PROGRAM_PRODUCT_CUSTOMIZATION_LOADING(state, isLoading) {
    state.is_program_product_customization_loading = isLoading
  },
  SET_IS_PRICING_IN_PROGRESS_FOR_PRODUCT_LINE_ID(state, payload) {
    Vue.set(
      state.is_pricing_in_progress_by_product_line_id,
      payload.productLineId,
      payload.isInProgress
    )
  },
  SET_IS_PRICING_IN_PROGRESS_FOR_ALL_PRODUCT_LINES(state, isInProgress) {
    for (const productLineId in state.is_pricing_in_progress_by_product_line_id) {
      state.is_pricing_in_progress_by_product_line_id[productLineId] = isInProgress
    }
  },
  DELETE_IS_PRICING_IN_PROGRESS_FOR_PRODUCT_LINE_ID(state, productLineId) {
    Vue.delete(state.is_pricing_in_progress_by_product_line_id, productLineId)
  },
  SET_IS_VEHICLE_UPDATE_WITHIN_PROGRAM_IN_PROGRESS(state, isInProgress) {
    state.is_vehicle_update_within_program_in_progress = isInProgress
  },
}

<template>
  <div class="container-fluid px-2 pt-4">
    <div class="product-filters">
      <h3 class="mb-3 product-title mr-2" data-cy="page-title">
        {{ getPageTitle }}
      </h3>
      <FormRowSelect
        v-for="(filter, name) of filters"
        :key="name"
        class="mb-3 px-0"
        :label-inline="false"
        :label-options-order="false"
        :selected-option.sync="getProgramTags[name]"
        :select-options="filter.options"
        :name="`product-list-filter-${name}`"
        label-select-attr="key"
        :disabled="areFiltersDisabled"
        :errors="{}"
        :placeholder="$t(`products.filter_names.${name}`)"
        @update:selected-option="onFilterUpdate(name)"
        :multiple="true"
        :allow-empty="true"
      ></FormRowSelect>
    </div>
    <h5 class="mt-5 text-center w-100" v-if="!hasFilteredProducts">
      {{ $t("products.empty") }}
    </h5>
    <ul v-else class="product-list">
      <div
        v-for="productByDomainList in listProductsByDomain"
        :key="productByDomainList.label"
      >
        <h6 class="text-uppercase bold product-domain-title">
          {{ getDomainTitle(productByDomainList) }}
        </h6>
        <div v-for="product in productByDomainList.products" :key="product.id">
          <product-item
            class="moving"
            :product="product"
            :data-cy="`product-${product.code}`"
            :disable="!hasFiltersSet"
            @not-chargeable-product="$emit('not-chargeable-product', $event)"
            @icon-eye="$emit('icon-eye', $event)"
          ></product-item>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import ProductItem from "@/components/products/ProductItem"
import { mapGetters } from "vuex"

export default {
  name: "ProductList",
  components: {
    ProductItem,
  },
  data() {
    return {
      filters: {
        vehicle: { options: null },
        engine: { options: null },
      },
    }
  },
  computed: {
    ...mapGetters("product", [
      "hasFilteredProducts",
      "getProgramTags",
      "listProductTagsByVehicleType",
      "listProductTagsByEngine",
      "listProductsFilteredByProgramTags",
    ]),
    ...mapGetters("basket", ["basketIsEmpty"]),
    ...mapGetters("config", ["getCurrentLang"]),
    getPageTitle() {
      let title = this.$t("products.title")
      let filters = []
      if (this.getProgramTags.vehicle) {
        filters.push(this.$t(`products.filterTitles.${this.getProgramTags.vehicle}`))
      }
      if (this.getProgramTags.engine) {
        filters.push(this.$t(`products.filterTitles.${this.getProgramTags.engine}`))
      }
      if (filters.length > 0) {
        title = `${this.$t("products.dynamicTitle")} '${filters.join(" / ")}'`
      }
      return title
    },
    hasFiltersSet() {
      return this.getProgramTags.engine && this.getProgramTags.vehicle
    },
    vehicleTypeFilterOptions() {
      const vehicleTypeFilterOptions = this.listProductTagsByVehicleType.reduce(
        (filterOptions, productTag) => {
          filterOptions[productTag] = this.$t(`products.filters.${productTag}`)
          return filterOptions
        },
        {}
      )
      return vehicleTypeFilterOptions
    },
    engineFilterOptions() {
      const engineFilterOptions = this.listProductTagsByEngine.reduce(
        (filterOptions, productTag) => {
          filterOptions[productTag] = this.$t(`products.filters.${productTag}`)
          return filterOptions
        },
        {}
      )
      return engineFilterOptions
    },
    listProductsByDomain() {
      let productByDomainsList = []
      this.listProductsFilteredByProgramTags.forEach((product) => {
        if (productByDomainsList.hasOwnProperty(product.subdomain.domain.code)) {
          productByDomainsList[product.subdomain.domain.code].products.push(product)
        } else {
          productByDomainsList[product.subdomain.domain.code] = {
            label: product.subdomain.domain.label,
            products: [],
          }
          productByDomainsList[product.subdomain.domain.code].products.push(product)
        }
      })
      return Object.values(productByDomainsList)
    },
    areFiltersDisabled() {
      return !this.basketIsEmpty
    },
  },
  methods: {
    getDomainTitle(productByDomainList) {
      if (this.getCurrentLang === "en")
        return `${productByDomainList.label} ${this.$t("products.domainTitle")}`
      return `${this.$t("products.domainTitle")} ${productByDomainList.label}`
    },
    onFilterUpdate(name) {
      const programTagValue = this.getProgramTags[name].at(-1)
      this.$store.dispatch("product/setProgramTag", {
        name: name,
        value: programTagValue ?? "all",
      })
    },
  },
  created() {
    // Build options for each filter
    this.filters.vehicle.options = this.vehicleTypeFilterOptions
    this.filters.engine.options = this.engineFilterOptions
  },
}
</script>

<style lang="scss">
.product-filters {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr repeat(2, 1fr);
  gap: 32px;
}

.Product-title {
  font-size: 35px;
  color: $bleucaarea;
}

.product-list {
  div:not(:first-child) {
    .product-domain-title {
      margin-top: 3rem;
    }
  }
}

.product-domain-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>

<template>
  <div>
    <div class="criterion-opt" @click="showLinkedCritOptions = !showLinkedCritOptions">
      <div
        :class="criterionOptValueClass"
        :data-cy="`criterion-option-value-${optionKey}`"
      >
        <i
          class="criterion-opt__icon mr-2"
          :class="'icon-chevron-' + (showLinkedCritOptions ? 'down' : 'right')"
        ></i>
        {{ $t("criteria.durations." + optionKey) }}
      </div>
      <div class="criterion-opt__checkbox">
        <input
          type="checkbox"
          class="crit-option-selection__checkmark"
          :checked="isCriterionOptionSelected"
          v-indeterminate="isIndeterminate"
          @click.stop="onCheck($event.target.checked)"
          :data-cy="`criterion-option-${optionKey}-checkbox`"
        />
      </div>
    </div>
    <div v-if="showLinkedCritOptions" class="criterion-opt__linked-opt">
      <div
        v-for="(value, key) in linkedCriterionOptions"
        :key="key"
        class="criterion-opt__linked-opt__row"
      >
        <CriterionOptionSelection
          class="criterion-opt__linked-opt__item"
          :option="{ [key]: value }"
          :selected.sync="option[linkedValue][key]"
          :id="`id-${optionKey}-${key}`"
          :criteria-type="criteriaType"
          @update:selected="onSelection()"
        ></CriterionOptionSelection>
      </div>
    </div>
  </div>
</template>

<script>
import CriterionOptionSelection from "@/components/presales/modal/components/CriterionOptionSelection"
import { mapGetters } from "vuex"

export default {
  name: "CriterionOptionAccordion",
  components: { CriterionOptionSelection },
  props: {
    option: { type: Object, required: true },
    linkedValue: { type: String, required: true },
    linkedCriterionOptionsSelection: { type: Object, required: true },
    productLineId: { type: Number, required: true },
    criteriaType: { type: String, required: true },
  },
  data() {
    return {
      showLinkedCritOptions: false,
    }
  },
  directives: {
    indeterminate(el, binding) {
      el.indeterminate = Boolean(binding.value)
    },
  },
  computed: {
    ...mapGetters("productLine", ["getCoefCritOptions"]),
    optionValue() {
      return Object.values(this.option)[0]
    },
    optionKey() {
      return Object.keys(this.option)[0]
    },
    criterionOptValueClass() {
      return [
        "criterion-opt__name",
        "font-size-16",
        { unselectedColor: this.useUnselectedColor },
      ]
    },
    linkedCriterionOptions() {
      let filteredCritOptions = Object.entries(
        Object.assign({}, this.option[this.linkedValue])
      )
      filteredCritOptions.sort((a, b) => {
        // a[0] and b[0] are the string values ("+20 000km") not the keys ("+20000km")
        return a[0].localeCompare(b[0], undefined, {
          numeric: true,
          sensitivity: "base",
        })
      })
      return Object.fromEntries(filteredCritOptions)
    },
    isCriterionOptionSelected() {
      return Object.values(this.linkedCriterionOptionsSelection).some((v) => v === true)
    },
    useUnselectedColor() {
      return !this.isCriterionOptionSelected
    },
    isIndeterminate() {
      let criterionOptionsSelectionValues = Object.values(
        this.linkedCriterionOptionsSelection
      )
      return !(
        criterionOptionsSelectionValues.every((value) => value) ||
        criterionOptionsSelectionValues.every((value) => value === false)
      )
    },
  },
  methods: {
    onSelection() {
      this.$emit("update:selected")
    },
    onCheck(checked) {
      if (checked) {
        for (const key of Object.keys(this.linkedCriterionOptions)) {
          // eslint-disable-next-line vue/no-mutating-props
          this.linkedCriterionOptionsSelection[key] = true
        }
      } else {
        for (const key of Object.keys(this.linkedCriterionOptions)) {
          // eslint-disable-next-line vue/no-mutating-props
          this.linkedCriterionOptionsSelection[key] = false
        }
      }
      this.$emit("linked-criterion-options-selection-update")
    },
  },
}
</script>

<style scoped lang="scss">
.criterion-opt {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-areas: "name checkbox";
  align-items: center;
  background-color: $caarealightgray;
  height: 3rem;
  cursor: pointer;
  position: relative;
}

.criterion-opt__name {
  grid-area: name;
  padding-left: 1rem;
  font-family: "Gilroy", sans-serif;
}

.criterion-opt__icon {
  font-size: 0.875rem;
  font-weight: 600;
}

.criterion-opt__checkbox {
  grid-area: checkbox;
  padding-right: 1rem;
  justify-self: end;
  font-weight: bold;
}

.unselectedColor {
  color: $buttonstroke;
}

.criterion-opt__linked-opt {
  margin-top: 0.5rem;
}

.criterion-opt__linked-opt__row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "space item";
}

.criterion-opt__linked-opt__item {
  grid-area: item;
  margin-bottom: 0.5rem;
}
</style>

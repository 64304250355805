<template>
  <header data-cy="top_bar" class="header">
    <div class="container-fluid mt-2 mb-2">
      <div class="row align-items-center justify-content-between ml-5">
        <div class="col-auto">
          <a href="/">
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-header_ef6uoh.png"
              alt="Caarea Solutions"
            />
          </a>
        </div>
        <div
          class="col-auto slide"
          :class="{
            'slide-start': expanded,
          }"
        >
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div
                    v-if="isLoggedIn"
                    class="username text-truncate text-center"
                    data-cy="username"
                  >
                    <span class="fullname">{{ fullname }}</span>
                  </div>
                </div>
                <div class="col-auto p-0">
                  <img :src="avatarUrl" :alt="fullname" :title="fullname" />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div
                v-if="showCart"
                @click.stop="onShoppingCartClick"
                class="mouse-pointer cart-basket"
                :title="$t(getShoppingCartTitle)"
                data-cy="topbar-basket"
              >
                <i class="icon-cart font-size-36" aria-hidden="true"></i>
                <span
                  class="badge badge-pill badge-primary badge-basket"
                  data-cy="topbar-basket-badge"
                >
                  {{ basketNbItems }}
                </span>
              </div>
            </div>
            <div class="col-auto dropdown show">
              <button
                class="invisible-button dropdown-btn dropdown-toggle lang-dropdown"
                role="button"
                id="languageDropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ currentLang }}
              </button>

              <div class="dropdown-menu" aria-labelledby="languageDropdownMenuButton">
                <button
                  v-for="lang in languages"
                  class="dropdown-item dropdown-btn"
                  :data-cy="`change-language-btn-${lang}`"
                  :key="lang"
                  :disabled="lang === getCurrentLang"
                  @click.prevent="onChangeCurrentLanguage(lang)"
                >
                  {{ lang }}
                </button>
              </div>
            </div>
            <div class="col-auto" v-if="areCookiesAccepted">
              <a
                @click="toggleVisibility()"
                id="open-sidenav"
                class="burger-menu"
                href="#"
                data-cy="burger_icon"
              >
                <div
                  class="icon-menu font-size-36 align-self-center rotate"
                  :class="{
                    'rotate-start': expanded,
                  }"
                  aria-hidden="true"
                ></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$router.currentRoute.name !== 'home'">
      <div class="bg-header"></div>
      <div class="breadcrumb-header">
        <breadcrumb :key="$router.currentRoute.meta.breadcrumb"></breadcrumb>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex"
import Breadcrumb from "@/layouts/components/Breadcrumb"
import i18n from "@/i18n/index"
import SsoService from "../../services/technical/SsoService"

export default {
  name: "TopBar",
  components: { Breadcrumb },
  props: ["expanded"],
  computed: {
    ...mapGetters("auth", [
      "getCurrentUser",
      "isLoggedInSharingMode",
      "areCookiesAccepted",
    ]),
    ...mapGetters("basket", ["basketIsEmpty", "basketNbItems"]),
    ...mapGetters("gui", ["sidebarBasketIsVisible"]),
    ...mapGetters("config", ["getCurrentLang", "isLangAvailable"]),
    currentLang() {
      return this.getCurrentLang
    },
    languages() {
      let languages = []
      for (const lang of Object.keys(i18n.messages)) {
        if (lang === this.getCurrentLang) {
          languages.unshift(lang)
        } else {
          languages.push(lang)
        }
      }
      return languages
    },
    fullname() {
      if (this.getCurrentUser.first_name || this.getCurrentUser.last_name) {
        return this.getCurrentUser.first_name + " " + this.getCurrentUser.last_name
      }
      return this.getCurrentUser.username
    },
    avatarUrl() {
      let fullname = this.fullname
      if (!this.getCurrentUser.first_name && !this.getCurrentUser.last_name) {
        fullname = fullname.substring(0, fullname.indexOf("@"))
        fullname = fullname.replaceAll(/[-, .]/gm, " ")
      }
      return (
        "https://eu.ui-avatars.com/api/?rounded=true" +
        "&size=32&bold=true&color=ffffff&background=1c2b4e" +
        "&name=" +
        encodeURIComponent(fullname)
      )
    },
    showCart() {
      // TODO supprimer cette ligne commentee quand refonte page products terminee
      // return this.$route.name === "productsHome" && !this.basketIsEmpty
      return this.$route.name === "productsHome" && !this.basketIsEmpty
    },
    getShoppingCartTitle() {
      return this.sidebarBasketIsVisible
        ? "products.basket.hide"
        : "products.basket.show"
    },
    isLoggedIn() {
      return this.isLoggedInSharingMode || SsoService.isAuthenticated()
    },
  },
  methods: {
    toggleVisibility() {
      this.$emit("toggle-menu-visibility")
    },
    onShoppingCartClick() {
      this.$store.dispatch("gui/toggleSidebarBasket")
    },
    async onChangeCurrentLanguage(newLang) {
      await this.$store.dispatch("config/setCurrentLang", newLang)
      const payload = {
        userId: this.getCurrentUser.id,
        lang: newLang,
      }
      await this.$store.dispatch("auth/updateLang", payload)
      const to = this.$router.resolve({ params: { lang: newLang } })
      await this.$router.push(to.location)
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate {
  transition: all 0.3s;
}
.rotate-start {
  transform: rotate(90deg);
  opacity: 0;
}
.slide {
  transition: all 0.3s;
}
.slide-start {
  transform: translateX(-100%);
}

.cart-basket {
  position: relative;
  .badge-basket {
    position: absolute;
    top: 0;
    left: calc(100% - 12px);
    font-size: 10px;
  }
}

div.username {
  line-height: 0.9rem;
}

.fullname {
  font-weight: bold;
  font-size: 13px;
}

.company {
  font-size: 10px;
}
.dropdown-btn {
  text-transform: uppercase;
}

.cart-basket,
.lang-dropdown,
.burger-menu {
  display: inline-block;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: $grey6;
    border-radius: 4px;
  }
}
</style>

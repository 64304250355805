import AbstractService from "../AbstractService"
import ProgramTypes from "../../conf/program_types"
import ArrayService from "../technical/ArrayService"
import i18n from "@/i18n"

class ProgramUtils extends AbstractService {
  /**
   * Determines the type of program from the provided tags list.
   *
   * @param {Array} tags tags list
   * @returns {[String, null]}
   */
  getProgramType(tags) {
    for (const program in ProgramTypes) {
      if (ArrayService.areValuesInArray(tags, ProgramTypes[program])) {
        return program
      }
    }
    return null
  }
  listEngineCodesFromEngineTag(tag) {
    // See CSO_1370 for this business rule
    return tag === "thermique" ? ["C"] : ["E", "F", "H", "M", "P", "R"]
  }
  getIsVnFilterValueFromProgramVehicleTag(vehicleTag) {
    return vehicleTag === "VNPJ" ? "vn_only" : "all_vehicles"
  }
  isVnContextValueFromProgramVehicleTag(vehicleTag) {
    return vehicleTag === "VNPJ"
  }
  getVehicleTypeByBool(boolean) {
    return boolean === true ? "vn_only" : "all_vehicles"
  }
}

let ProgramService = new ProgramUtils()
export default ProgramService

<template>
  <div :data-cy="`button-select-file-${inputId}`">
    <div v-if="isLoading">
      <span
        class="spinner-border spinner-border-sm mr-1"
        v-show="isLoading"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div v-else>
      <div v-if="uploadedFile">
        <div>
          <label title="Logo" :data-cy="`uploaded-file-name-${inputId}`" class="row">
            <span class="col-sm-4 text-right">
              {{ labelText }}
              <sup v-if="required">*</sup>
            </span>
            <div class="col-sm-8">
              <img
                alt="uploaded file"
                :src="uploadedFile"
                class="mouse-pointer link img-button"
                width="110px"
                @click="callCloudinaryComponent"
                data-cy="logo-preview"
              />
              <CloudinaryUploadWidget
                :upload-preset="preset_name"
                :button-text="$t('button.updateLogo')"
                @image-uploaded="onUploadedLogo($event)"
                ref="cloudinaryUploadWidgetComponent"
                style="display: none"
              />
            </div>
          </label>
        </div>
      </div>
      <div v-else class="row">
        <label class="col-sm-4 text-right">
          <span>
            {{ labelText }}
            <sup v-if="required">*</sup>
          </span>
        </label>
        <CloudinaryUploadWidget
          div-class="col-sm-8"
          :button-text="$t('button.chooseLogo')"
          @image-uploaded="onUploadedLogo($event)"
          ref="cloudinaryUploadWidgetComponent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import i18n from "@/i18n"
import CloudinaryUploadWidget from "@/components/utils/CloudinaryUploadWidget"

export default {
  name: "SelectFileButton",
  components: { CloudinaryUploadWidget },
  data() {
    return {
      preset_name: `${process.env.VUE_APP_CLOUDINARY_PARTNER_CREATE_PRESET_NAME}`,
    }
  },
  props: {
    inputId: { type: String },
    name: { type: String, default: "file" },
    accept: { type: String },
    buttonLabel: { type: String, default: i18n.t("button.selectFile") },
    uploadedFile: { type: String },
    isLoading: { type: Boolean, default: false },
    // btn-primary or btn-secondary or btn-tertiary
    btnClass: { type: String, default: "btn-secondary" },
    withIcon: { type: Boolean, default: false },
    divClass: { type: String },
    labelClass: { type: String },
    imgClass: { type: String },
    labelText: { type: String },
    required: { type: Boolean, default: false },
  },
  computed: {
    buttonClass() {
      return `btn ${this.btnClass} font-roboto-medium`
    },
  },
  methods: {
    onChange() {
      // Avoid emitting event if cancel was clicked
      if (this.$refs.file.files.length >= 1) {
        this.$emit("input-file", this.$refs.file.files[0])
      }
    },
    // To emit onchange event even if same file is selected
    onClick(event) {
      event.target.value = ""
    },
    onUploadedLogo(event) {
      this.$emit("input-file", event)
    },
    callCloudinaryComponent() {
      this.$refs.cloudinaryUploadWidgetComponent.onButtonChoosePhoto()
    },
  },
  mounted() {
    // To enable a Cypress test to emit an event on CloudinaryUploadWidget component
    if (window.Cypress) {
      window.vueCloudinaryUploadWidget = this
    }
  },
}
</script>

<style scoped lang="scss">
.hiddenClass {
  position: absolute;
  visibility: hidden;
}

label.link:hover {
  text-decoration: none;
}

label.link {
  text-decoration: underline;
}

label {
  // my friend bootstrap...
  margin-bottom: 0;
}

.button-label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.icon-upload2 {
  font-weight: 500;
}

.img-button {
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.25s ease-in-out;
  &:hover {
    transform: translateY(-4px);
  }
}
</style>

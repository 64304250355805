<template>
  <div class="horizontal-tabs__container">
    <div
      v-for="(tab, index) in tabs"
      :key="tab.id"
      :class="[...tabClass, { 'horizontal-tabs__tab--active': isTabActive(index) }]"
      @click="onTabClick(tab.id, index)"
      :data-cy="`tab_offer-${tab.id}`"
    >
      <div
        :class="[
          ...tabContentClass,
          { 'horizontal-tabs__content--active': isTabActive(index) },
        ]"
      >
        <slot></slot>
        {{ index + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalTabs",
  props: {
    tabs: { type: Array, default: () => [] },
    tabClass: { type: Array, default: () => ["horizontal-tabs__tab"] },
    tabContentClass: { type: Array, default: () => ["horizontal-tabs__content"] },
    tabContentActiveClass: {
      type: Array,
      default: () => ["horizontal-tabs__content--active"],
    },
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  methods: {
    isTabActive(tabIndex) {
      return this.activeTab === tabIndex
    },
    onTabClick(tabId, tabIndex) {
      this.activeTab = tabIndex
      this.$emit("tab-click", tabId)
    },
  },
}
</script>

<style scoped lang="scss">
.horizontal-tabs {
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 130px));
    border-bottom: 1px solid $bleuc50;
    height: 60px;
  }
  &__tab {
    display: grid;
    align-items: center;
    padding-left: 25px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    &:hover,
    &--active {
      border-color: $bleucaarea;
      .horizontal-tabs__content {
        color: $bleucaarea;
      }
    }
  }
  &__content {
    color: $bleuc100;
    font-family: "Gilroy SemiBold", sans-serif;
    font-size: 20px;
  }
}
</style>

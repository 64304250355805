import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import ProgramService from "@/services/business/ProgramService"
import VehicleSearchService from "@/services/business/VehicleSearchService"

// STATES (snake_case)
const state = {
  filters: {
    vehicle_type: {
      rank: 1,
      data: "all_vehicles",
      isDisabled: true,
    },
    make: {
      rank: 2,
      data: [],
      isDisabled: false,
    },
    model: {
      rank: 3,
      data: [],
      isDisabled: true,
    },
    engine_type: {
      rank: 8,
      data: [],
      isDisabled: false,
    },
  },
  selectable_values: {
    vehicle_type: [
      { id: "vn_only", local_name: "vehicleChoice.vn_only" },
      { id: "all_vehicles", local_name: "vehicleChoice.all_vehicles" },
    ],
    makes: [],
    models: [],
    engine_types: [],
  },
  values_allowed_by_offer_eligibility_criteria: {
    makes: [],
    models: [],
    engine_types: [],
  },
  // List of filters to take into account for each API call, depending on the context :
  // - vehicle_type : determined from program's vehicle tag
  // - engine_type : determined from program's engine tag
  context_filters: ["vehicle_type", "engine_type"],
  program_context_values: {
    engine_type: [],
    is_vn: "",
  },
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  INIT_PROGRAM_CONTEXT_VALUES(state, payload) {
    state.program_context_values.engine_type = payload.engine_types
    state.program_context_values.is_vn = payload.is_vn
  },
  SET_MAKES_SELECTABLE_VALUES(state, makes) {
    state.selectable_values.makes = makes
  },
  SET_MODELS_SELECTABLE_VALUES(state, models) {
    state.selectable_values.models = models
  },
  SET_ENGINE_TYPES_SELECTABLE_VALUES(state, engineTypes) {
    state.selectable_values.engine_types = engineTypes
  },
  SET_ENGINE_TYPE_FILTER_VALUES(state, engineTypes) {
    state.filters.engine_type.data = engineTypes
  },
  SET_MAKE_FILTER_VALUES(state, makes) {
    state.filters.make.data = makes
  },
  SET_MODEL_FILTER_VALUES(state, models) {
    state.filters.model.data = models
  },
  SET_MODEL_FILTER_DISABLED_PROP(state, value) {
    state.filters.model.isDisabled = value
  },
  SET_VEHICLE_TYPE_FILTER_VALUES(state, value) {
    state.filters.vehicle_type.data = value
  },
  RESET_ALL_FILTERS(state) {
    for (const filter in state.filters) {
      if (filter === "vehicle_type") {
        state.filters[filter].data = "all_vehicles"
        state.filters[filter].isDisabled = false
      } else {
        state.filters[filter].data = []
      }
    }
    state.filters.model.isDisabled = true
  },
  RESET_ALL_SELECTABLE_VALUES(state) {
    for (const item in state.selectable_values) {
      if (item === "vehicle_type") {
        state.selectable_values[item] = [
          { id: "vn_only", local_name: "vehicleChoice.vn_only" },
          { id: "all_vehicles", local_name: "vehicleChoice.all_vehicles" },
        ]
      } else {
        state.selectable_values[item] = []
      }
    }
  },
  SET_MAKE_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA(state, makes) {
    state.values_allowed_by_offer_eligibility_criteria.makes = makes
  },
  SET_MODEL_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA(state, models) {
    state.values_allowed_by_offer_eligibility_criteria.models = models
  },
  SET_ENGINE_TYPE_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA(state, engineTypes) {
    state.values_allowed_by_offer_eligibility_criteria.engine_types = engineTypes
  },
  RESET_ALL_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA(state) {
    for (const item in state.values_allowed_by_offer_eligibility_criteria) {
      state.values_allowed_by_offer_eligibility_criteria[item] = []
    }
  },
}

// ACTIONS (camelCase)
const actions = {
  async initFiltersAndSelectableValues(
    { state, getters, commit, dispatch, rootGetters },
    payload
  ) {
    const productLineId = payload.productLineId
    const offerId = payload.offerId

    dispatch("_resetAllFiltersAndValues")
    dispatch("_initValuesAllowedByOffer", offerId)
    dispatch("_retrieveProgramContext")

    let engineTypesValuesSelected =
      VehicleSearchService.retrieveProductLineEligibilityCriterion(
        "engine_code",
        productLineId
      )
    let makesValuesSelected =
      VehicleSearchService.retrieveProductLineEligibilityCriterion(
        "make",
        productLineId
      )
    let modelsValuesSelected =
      VehicleSearchService.retrieveProductLineEligibilityCriterion(
        "model",
        productLineId
      )

    const hasEngineTypesValuesSelected = engineTypesValuesSelected.length > 0
    const hasMakesValuesSelected = makesValuesSelected.length > 0
    const hasModelsValuesSelected = modelsValuesSelected.length > 0

    // Engine Type
    if (hasEngineTypesValuesSelected) {
      commit("SET_ENGINE_TYPE_FILTER_VALUES", engineTypesValuesSelected)
    }
    const allowedEngineTypeValues = getters.listAllowedEngineTypeValues
    await dispatch("_initEngineTypeSelectableValues", allowedEngineTypeValues)

    let makesSelectableValues = []
    if (hasMakesValuesSelected) {
      // 1. Set filters
      const makesFilterValues = makesValuesSelected.map((make) => make.id.toString())
      commit("SET_MAKE_FILTER_VALUES", makesFilterValues)
      commit("SET_MODEL_FILTER_DISABLED_PROP", false)

      if (hasModelsValuesSelected) {
        const modelsFilterValues = modelsValuesSelected.map((model) =>
          model.id.toString()
        )
        commit("SET_MODEL_FILTER_VALUES", modelsFilterValues)
      }

      // 2. Set selectable values
      if (getters.hasMakeValuesAllowedByOffer) {
        makesSelectableValues = getters.listMakeValuesAllowedByOffer
      } else {
        let selectableValuesPayload = {
          engine_type: allowedEngineTypeValues,
        }
        if (state.program_context_values.is_vn) {
          selectableValuesPayload["is_vn"] = state.program_context_values.is_vn
        }
        makesSelectableValues = await HttpService.get(
          UrlService.render("cvdbMake", {}, selectableValuesPayload)
        )
      }
      commit("SET_MAKES_SELECTABLE_VALUES", makesSelectableValues)
      await dispatch("_updateModelSelectableValues")
    } else {
      //  Note : if no make selected, it's impossible to have model selected
      // No values to set in the filter => set make selectable values only
      let selectableValuesPayload = {
        engine_type: getters.hasSelectedEngineTypeValues
          ? getters.listSelectedEngineTypeValues
          : allowedEngineTypeValues,
      }
      if (state.program_context_values.is_vn) {
        selectableValuesPayload["is_vn"] = state.program_context_values.is_vn
      }
      makesSelectableValues = await HttpService.get(
        UrlService.render("cvdbMake", {}, selectableValuesPayload)
      )
      commit("SET_MAKES_SELECTABLE_VALUES", makesSelectableValues)
    }
  },
  _resetAllFiltersAndValues({ commit }) {
    commit("RESET_ALL_FILTERS")
    commit("RESET_ALL_SELECTABLE_VALUES")
    commit("RESET_ALL_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA")
  },
  _initValuesAllowedByOffer({ commit }, offerId) {
    commit(
      "SET_ENGINE_TYPE_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA",
      VehicleSearchService.retrieveOfferEligibilityCriterion("engine_code", offerId)
    )
    commit(
      "SET_MAKE_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA",
      VehicleSearchService.retrieveOfferEligibilityCriterion("make", offerId)
    )
    commit(
      "SET_MODEL_VALUES_ALLOWED_BY_OFFER_ELIGIBILITY_CRITERIA",
      VehicleSearchService.retrieveOfferEligibilityCriterion("model", offerId)
    )
  },
  _retrieveProgramContext({ commit, getters, rootGetters }) {
    let engineTypes = []
    if (getters.hasEngineTypeValuesAllowedByOffer) {
      engineTypes = [...getters.listEngineTypeValuesAllowedByOffer]
    } else {
      engineTypes = ProgramService.listEngineCodesFromEngineTag(
        rootGetters["program/getProgram"].engine_tag.label
      )
    }
    const is_vn = ProgramService.isVnContextValueFromProgramVehicleTag(
      rootGetters["program/getProgram"].vehicle_tag.label
    )
    if (is_vn) {
      commit("SET_VEHICLE_TYPE_FILTER_VALUES", "vn_only")
    }
    commit("INIT_PROGRAM_CONTEXT_VALUES", {
      engine_types: engineTypes,
      is_vn: is_vn,
    })
  },
  async _initEngineTypeSelectableValues(
    { state, commit, getters },
    allowedEngineTypeValues
  ) {
    const payload = state.program_context_values.is_vn
      ? { is_vn: state.program_context_values.is_vn }
      : {}
    const engineTypesSelectableValues = await HttpService.get(
      UrlService.render("cvdbEngineType", {}, payload)
    )
    const filteredSelectableValues = engineTypesSelectableValues.filter(
      (engineTypeSelectableValue) => {
        return allowedEngineTypeValues.includes(
          Object.keys(engineTypeSelectableValue)[0]
        )
      }
    )
    commit("SET_ENGINE_TYPES_SELECTABLE_VALUES", filteredSelectableValues)
  },
  async setMakeFilterValues({ commit, getters, dispatch }, makes) {
    commit("SET_MAKE_FILTER_VALUES", makes)
    if (makes.length === 0) {
      commit("SET_MODEL_FILTER_DISABLED_PROP", true)
      commit("SET_MODEL_FILTER_VALUES", [])
    } else {
      commit("SET_MODEL_FILTER_DISABLED_PROP", false)
    }
    await dispatch("_updateModelSelectableValues")
    dispatch("_keepOnlyModelsOfCurrentMakes")
  },
  async _updateModelSelectableValues({ commit, getters, dispatch }) {
    await dispatch("_retrieveNewSelectableValues", "make")

    if (getters.hasModelValuesAllowedByOffer) {
      const listAllowedModelIds = getters.listModelValuesAllowedByOffer.map(
        (model) => model.id
      )
      const filteredSelectableValues = state.selectable_values.models.filter((model) =>
        listAllowedModelIds.includes(model.id)
      )
      commit("SET_MODELS_SELECTABLE_VALUES", filteredSelectableValues)
    }
  },
  _keepOnlyModelsOfCurrentMakes({ commit, getters }) {
    if (state.filters.model.data.length > 0) {
      const selectableModelIds = getters.listSelectableModelIds.map((modelId) =>
        modelId.toString()
      )
      const results = state.filters.model.data.filter((modelId) =>
        selectableModelIds.includes(modelId)
      )
      commit("SET_MODEL_FILTER_VALUES", results)
    }
  },
  async setModelFilterValues({ commit, dispatch }, models) {
    commit("SET_MODEL_FILTER_VALUES", models)
    await dispatch("_retrieveNewSelectableValues", "model")
  },
  async _retrieveNewSelectableValues({ commit }, filterUpdated) {
    const selectableValuesToUpdate = ["engine_type"]
    if (filterUpdated === "make") {
      selectableValuesToUpdate.push("model")
    }
    for (const select of selectableValuesToUpdate) {
      const queryParams = VehicleSearchService.createQueryParams(
        select,
        state.filters,
        state.context_filters
      )
      if (
        !VehicleSearchService.isFilter(select, "engine_type") &&
        VehicleSearchService.isFilterEmpty(state.filters, "engine_type")
      ) {
        Object.assign(queryParams, {
          engine_type: state.program_context_values.engine_type,
        })
      }
      const selectableValues = await HttpService.get(
        UrlService.render(
          VehicleSearchService.urlFilterNameMatching[select],
          {},
          queryParams
        )
      )
      commit(
        `SET_${VehicleSearchService.uppercaseSelectableNameMatching[select]}_SELECTABLE_VALUES`,
        selectableValues
      )
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getMakeObjectFromId: (state) => (makeId) => {
    return state.selectable_values.makes.filter(
      (make) => make.id === parseInt(makeId)
    )[0]
  },
  getModelObjectFromId: (state) => (modelId) => {
    return state.selectable_values.models.filter(
      (model) => model.id === parseInt(modelId)
    )[0]
  },
  getMakeSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.makes
    )
  },
  getModelSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.models
    )
  },
  getEngineTypeSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.engine_types
    )
  },
  isModelDisabled: (state) => {
    return state.filters.model.isDisabled
  },
  listSelectableModelIds: (state) =>
    state.selectable_values.models.map((model) => model.id),
  listFilterModelValues: (state) => state.filters.model.data,
  listEngineTypeValuesAllowedByOffer: (state) => [
    ...state.values_allowed_by_offer_eligibility_criteria.engine_types,
  ],
  listMakeValuesAllowedByOffer: (state) => [
    ...state.values_allowed_by_offer_eligibility_criteria.makes,
  ],
  listModelValuesAllowedByOffer: (state) => [
    ...state.values_allowed_by_offer_eligibility_criteria.models,
  ],
  hasEngineTypeValuesAllowedByOffer: (state, getters) =>
    getters.listEngineTypeValuesAllowedByOffer.length > 0,
  hasMakeValuesAllowedByOffer: (state, getters) =>
    getters.listMakeValuesAllowedByOffer.length > 0,
  hasModelValuesAllowedByOffer: (state, getters) =>
    getters.listModelValuesAllowedByOffer.length > 0,
  listAllowedEngineTypeValues: (state, getters) => {
    return getters.hasEngineTypeValuesAllowedByOffer
      ? getters.listEngineTypeValuesAllowedByOffer
      : [...state.program_context_values.engine_type]
  },
  listSelectedEngineTypeValues: (state) => state.filters.engine_type.data,
  hasSelectedEngineTypeValues: (state, getters) =>
    getters.listSelectedEngineTypeValues.length > 0,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

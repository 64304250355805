<template>
  <LayoutHeader></LayoutHeader>
</template>

<script>
import LayoutHeader from "@/layouts/components/LayoutHeader"
export default {
  components: { LayoutHeader },
  name: "ContentHeader",
}
</script>

<style scoped></style>

<template>
  <div data-cy="offer-pricing" class="offer-pricing">
    <ModalWarning
      v-if="warningModal.show"
      :title="warningModal.title"
      :reason="warningModal.reason"
      :advice="warningModal.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <spinner v-if="isLoading"></spinner>
    <div v-else class="summary__page">
      <ProgramContentHeader
        class="offer-page__header"
        :program="program"
        :title="program.name"
        :offer-number="offerNumber"
        :desc="$t('offers.description')"
        :other-informations="$t('programs.timeline.synthesis')"
        :is-share-button-visible="false"
      ></ProgramContentHeader>
      <div class="offer-pricing__details">
        <div class="offer-pricing__title">
          <p class="font-effra-normal font-size-15 text-uppercase">
            {{ offerPricingTitle }}
          </p>
        </div>
        <div class="summary__header bg-grey-6 p-3">
          <h4
            class="summary__header__title text-uppercase font-size-20 font-effra-normal mb-0"
          >
            {{ $t("offers.pricing_summary.subtitle") }}
          </h4>
          <VehicleChoice
            class="summary__vehicle-choice"
            :program-product-id="getProgramProduct.id"
            :vehicle-info="vehicleInfo"
            :eligibility-search-filter="{}"
            :class="['product-line-edition__criteria__vehicle']"
            @vehicle-selected="onVehicleSelected"
          ></VehicleChoice>
        </div>
        <div class="summary__product-line">
          <div v-for="product of listProducts" :key="product.id">
            <OfferPricingSummary
              :program-product="product"
              :vehicle="form.config.vehicle"
              @isTotalLoading="isTotalLoading"
            ></OfferPricingSummary>
          </div>
        </div>
        <ProductsPriceTotal
          :component-class="['summary__total-price']"
          :amount="totalPrice"
          :offer-id="offerId"
          :is-price-total-loading="isPriceTotalLoading"
        ></ProductsPriceTotal>
        <ActionButton
          :button-text="$t('button.validate')"
          :button-class="['offer-page__validate-button']"
          @click-action="onValidateButtonClick"
        ></ActionButton>
        <div class="summary__pricing-range-button" v-if="!hasSharingToken">
          <button class="btn" @click="onPricingRangeClick">
            {{ $t("breadcrumb.pricingRange") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OfferPricingSummary from "@/components/presales/components/OfferPricingSummary"
import VehicleChoice from "@/components/presales/components/VehicleChoice"
import ActionButton from "@/components/button/ActionButton"
import ModalWarning from "@/components/modal/ModalWarning"
import ProductsPriceTotal from "@/components/presales/ProductsPriceTotal"
import ProgramContentHeader from "@/components/presales/components/ProgramContentHeader"

export default {
  name: "OfferPricing",
  components: {
    OfferPricingSummary,
    VehicleChoice,
    ActionButton,
    ProductsPriceTotal,
    ProgramContentHeader,
    ModalWarning,
  },
  props: {
    id: { type: [Number, String], required: true },
    offerId: { type: [Number, String], required: true },
  },
  data() {
    return {
      isLoading: false,
      listProducts: [],
      program: null,
      form: {
        config: {
          vehicle: null,
        },
      },
      warningModal: {
        show: false,
        title: this.$t("offers.warning_modal.title"),
        advice: this.$t("offers.warning_modal.advice"),
      },
      isPriceTotalLoading: false,
    }
  },
  computed: {
    ...mapGetters("offer", [
      "getOffer",
      "lastSelectedVehicle",
      "getOfferNumberInProgram",
      "isOfferExternal",
    ]),
    ...mapGetters("programProduct", ["getConfig"]),
    ...mapGetters("productLine", ["getTotalProductLinesPrice"]),
    ...mapGetters("program", ["getProgramByOfferId", "getProgram"]),
    ...mapGetters("auth", ["getRouteName", "hasSharingToken"]),
    offerNumber() {
      return this.getOfferNumberInProgram(parseInt(this.offerId))
    },
    offerPricingTitle() {
      return `${this.$t("offers.pricing_summary.title")}${this.offerNumber}`
    },
    isVehicleEmpty() {
      return this.form.config.vehicle
        ? Object.keys(this.form.config.vehicle).length === 0
        : true
    },
    vehicleInfo() {
      return !this.isVehicleEmpty
        ? `${this.form.config.vehicle.make} ${this.form.config.vehicle.model} ${this.form.config.vehicle.version}`
        : this.$t("programs.form.beneficiaryCriteria.vehicle.action")
    },
    totalPrice() {
      return this.getTotalProductLinesPrice
    },
    getProgramProduct() {
      const requiredDynamicInputs = this.listProducts.reduce((acc, product) => {
        const requiredDynamicInputs = product.config.required_dynamic_inputs
        if (requiredDynamicInputs && !acc.includes(requiredDynamicInputs)) {
          acc = acc.concat(requiredDynamicInputs)
        }
        return acc
      }, [])
      const config = requiredDynamicInputs.filter(
        (item, pos, self) => self.indexOf(item) === pos
      )
      let programProduct = this.listProducts[0]
      programProduct.config.required_dynamic_inputs = config
      return programProduct
    },
  },
  methods: {
    async onVehicleSelected(vehicle) {
      // List eligible product lines before saving new vehicle in store
      // (=> watch in OfferPricingSummary needs to be done after eligible product lines have been retrieved)
      for (const programProduct of this.listProducts) {
        await this.updateProgramProduct(programProduct, vehicle)
        await this.retrieveEligibleProductLinesOfProgramProduct(programProduct, vehicle)
      }
      await this.storeVehicle(vehicle)
    },
    async updateProgramProduct(programProduct, vehicle) {
      const payload = {
        id: programProduct.id,
        data: {
          config: {
            ...programProduct.config,
            vehicle: vehicle,
          },
        },
      }
      await this.$store.dispatch("programProduct/updateAndSaveInStore", payload)
    },
    async retrieveEligibleProductLinesOfProgramProduct(programProduct, vehicle) {
      const payload = {
        programProductId: programProduct.id,
        vehicle,
      }
      await this.$store.dispatch("programProduct/listEligibleProductLines", payload)
    },
    async storeVehicle(vehicle) {
      this.form.config.vehicle = vehicle
      await this.$store.dispatch("productLine/resetProductLinesPrices")
      await this.$store.dispatch("vehicle/setVelasticVehicleFromConfig", vehicle)
      await this.$store.dispatch("offer/setLastSelectedVehicle", {
        offerId: this.offerId,
        vehicle,
      })
    },
    onPricingRangeClick() {
      // envoyer vers le router de pricing range
      this.$router.push({
        name: this.getRouteName("pricingRange_2"),
        params: {
          offerId: this.offerId,
          products: this.listProducts,
          programId: this.program.id,
          vehicle: this.form.config.vehicle,
        },
      })
    },
    hasAnotherOfferToConfigure() {
      const numberOfOffers = this.program.offers.length
      return (
        numberOfOffers > 1 &&
        this.program.offers[numberOfOffers - 1].id !== parseInt(this.offerId)
      )
    },
    async onValidateButtonClick() {
      await this.$store.dispatch("offer/activate", this.offerId)
      this.program = this.getProgramByOfferId(parseInt(this.offerId))

      if (this.hasAnotherOfferToConfigure()) {
        const nextOfferId = parseInt(this.offerId) + 1
        if (this.isOfferExternal(nextOfferId)) {
          await this.$store.dispatch("offer/setCurrentOfferId", nextOfferId)
          await this.$router.push({
            name: this.getRouteName("programProduct"),
            params: {
              offerId: nextOfferId,
              programProductId: this.getOffer(nextOfferId).program_products[0].id,
            },
          })
        } else {
          await this.$router.push({
            name: this.getRouteName("programOffer"),
            params: { offerId: nextOfferId },
          })
        }
      } else {
        await this.$router.push({
          name: this.getRouteName("programHome"),
          params: { id: this.id },
        })
      }
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
    isTotalLoading(val) {
      this.isPriceTotalLoading = val
    },
  },
  async created() {
    this.isLoading = true
    this.listProducts = this.getOffer(this.offerId).program_products
    // case of a page reload : current program is not stored anymore in the store
    // => get its id from the offer id then retrieve its content and update store
    if (this.getProgram === null) {
      await this.$store.dispatch("program/getProgram", this.id)
    }
    this.program = this.getProgramByOfferId(parseInt(this.offerId))

    await this.$store.dispatch("productLine/resetProductLinesPrices")
    // If a vehicle has already been selected, retrieve it
    const config = this.getConfig(this.listProducts[0].id)
    let vehicle = {}
    if (config.hasOwnProperty("vehicle")) {
      vehicle = config.vehicle
    } else {
      vehicle = this.lastSelectedVehicle(this.offerId)
    }
    if (vehicle) {
      // deep copy
      await this.onVehicleSelected(JSON.parse(JSON.stringify(vehicle)))
    }

    await this.$store.dispatch("productLine/resetProductLinesPrices")
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
.offer-pricing {
  .summary__page {
    background-color: $background-program;
    .offer-page__header {
      margin-bottom: 0.3rem;
    }
    .offer-pricing__details {
      background-color: #fff;
      padding: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr) 110px 310px;
      .offer-pricing__title {
        border-left: 1px solid #1c2b4e;
        padding-left: 0.5rem;
        grid-column: 1 / span 4;
        margin-bottom: 1rem;
        p {
          margin-bottom: 0;
        }
      }
      .summary__header {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 365px;
        grid-column: 1 / span 4;
        .summary__header__title {
          grid-column: 1 / span 2;
          align-self: center;
        }
        .summary__vehicle-choice {
          grid-column: 5;
          align-self: center;
          background-color: white;
          margin-bottom: 0;
        }
      }
      .summary__product-line {
        grid-column: 1 / span 4;
        margin-top: 0.3rem;
      }
      .summary__pricing-range-button,
      .offer-page__validate-button {
        margin-top: 30px;
      }
      .summary__total-price {
        grid-column: 3 / span 2;
        border: 1px solid $bleuc100;
        margin-top: 30px;
      }
      .summary__pricing-range-button,
      .offer-page__validate-button {
        grid-column: 4;
        justify-self: end;
      }
      .summary__pricing-range-button {
        margin-top: 15px;
        button {
          background-color: $caarealightgray;
          border: 0.5px solid rgba(31, 32, 65, 0.25);
          border-radius: 0.25rem;
          width: 260px;
          padding: 0.375rem 1rem;
        }
      }
    }
  }
}
</style>

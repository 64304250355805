<template>
  <div class="network-item" :data-cy="`network-item-${network.id}`">
    <div class="d-flex flex-row justify-content-between py-2 px-2 align-items-center">
      <div class="col-4 network-name">
        <div v-if="!isEditingInProgress">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <div class="font-size-18" :data-cy="`network-name-${network.id}`">
              {{ networkName }}
            </div>
            <div class="font-size-20 pl-2 pt-1">
              <i
                class="text-primary icon-pen mouse-pointer"
                aria-hidden="true"
                @click.prevent="onEditingIconClick"
                :data-cy="`icon-pen-${network.id}`"
              ></i>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex flex-row justify-content-start align-items-center">
            <FormInput
              class="pr-2"
              :name="`name-edition-${network.id}`"
              :max-length="128"
              :errors="getFormattedNetworkNameUpdateErrors"
              v-model="networkName"
              @keyboard-enter="onValidateIconClick"
            ></FormInput>
            <div class="font-size-25 pl-2 pt-2">
              <i
                class="text-primary icon-cross-circle mouse-pointer"
                aria-hidden="true"
                @click.prevent="onCancelIconClick"
                :data-cy="`icon-cancel-${network.id}`"
              ></i>
            </div>
            <div class="font-size-25 pl-2 pt-2">
              <i
                class="text-primary icon-checkmark-circle mouse-pointer"
                aria-hidden="true"
                @click.prevent="onValidateIconClick"
                :data-cy="`icon-validate-${network.id}`"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="font-size-18" :data-cy="`network-users-${network.id}`">
        {{ network.nb_users }}
        {{ $tc("programParameters.network.users", getNbUserImportedWording) }}
      </div>
      <div class="font-size-18" :data-cy="`network-companies-${network.id}`">
        {{ network.companies.length }}
        {{ $tc("programParameters.network.companies", getNbCompanieImportedWording) }}
      </div>
      <div class="d-flex">
        <span
          class="font-size-36 icon-cloud-download mr-4 mouse-pointer"
          :data-cy="`icon-download-${network.id}`"
          @click.prevent="onDownloadIconClick"
        ></span>
        <ActionButton
          :button-text="$t('button.select')"
          btn-class="btn btn-blue-c-700 font-roboto-medium"
          :button-data-cy="`select-network-${network.id}-button`"
          @click-action="onSelectButtonClick"
        ></ActionButton>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/button/ActionButton"
import { mapGetters } from "vuex"

export default {
  name: "NetworkItem",
  components: { ActionButton },
  props: {
    network: { type: Object, required: true },
  },
  data() {
    return {
      isEditingInProgress: false,
      networkName: "",
    }
  },
  computed: {
    ...mapGetters("network", ["getNetworkUpdateErrors", "getNetworkFile"]),
    getNbUserImportedWording() {
      return this.network.nb_users === 1 ? 1 : 2
    },
    getNbCompanieImportedWording() {
      return this.network.companies.length === 1 ? 1 : 2
    },
    getFormattedNetworkNameUpdateErrors() {
      let formattedError = {}
      if (this.getNetworkUpdateErrors) {
        formattedError[`name-edition-${this.network.id}`] =
          this.getNetworkUpdateErrors["name"]
      }
      return formattedError
    },
  },
  methods: {
    onEditingIconClick() {
      this.$store.dispatch("network/resetNetworkUpdateErrors")
      this.isEditingInProgress = true
    },
    onCancelIconClick() {
      this.isEditingInProgress = false
      this.initNetworkName()
    },
    async onValidateIconClick() {
      const payload = {
        networkId: this.network.id,
        data: {
          name: this.networkName,
        },
      }
      await this.$store.dispatch("network/update", payload)
      this.isEditingInProgress = !!this.getNetworkUpdateErrors
    },
    async onDownloadIconClick() {
      await this.$store.dispatch("network/download", this.network.id)
      const blob = new Blob([this.getNetworkFile])
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = `network_${this.network.id}.xlsx`
      link.click()
      URL.revokeObjectURL(link.href)
    },
    initNetworkName() {
      this.networkName = this.network.name
    },
    onSelectButtonClick() {
      this.$emit("network-selected")
    },
  },
  created() {
    this.initNetworkName()
  },
}
</script>

<style lang="scss" scoped>
.network-item:nth-child(2n + 1) {
  border-width: 0.1rem 0;
  border-style: solid;
  border-color: $light-gray;
  border-radius: 0;
  background-color: $caarealightgray;
}

.network-name {
  overflow-wrap: anywhere;
}
</style>

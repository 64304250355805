// STATES (snake_case)
import Vue from "vue"
import BeneficiaryCriteriaService from "../services/business/BeneficiaryCriteriaService"
import i18n from "../i18n"

const state = {
  showSidebarBasket: true,
  coverage_duration_options: {},
  coverage_km_options: {},
  show_sharing_status_modal: false,
  sharing_status_modal_info: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SHOW_SIDEBAR(state) {
    state.showSidebarBasket = true
  },
  HIDE_SIDEBAR(state) {
    state.showSidebarBasket = false
  },
  SET_COVERAGE_DURATION_OPTIONS(state, payload) {
    Vue.set(state.coverage_duration_options, payload.productLineId, payload.options)
  },
  SET_COVERAGE_KM_OPTIONS(state, payload) {
    Vue.set(state.coverage_km_options, payload.productLineId, payload.options)
  },
  SHOW_SHARING_STATUS_MODAL(state) {
    state.show_sharing_status_modal = true
  },
  SET_SHARING_STATUS_MODAL_INFO(state, payload) {
    state.sharing_status_modal_info = payload
  },
}

// ACTIONS (camelCase)
const actions = {
  showSidebarBasket({ commit }) {
    commit("SHOW_SIDEBAR")
  },
  hideSidebarBasket({ commit }) {
    commit("HIDE_SIDEBAR")
  },
  toggleSidebarBasket({ commit }) {
    commit(`${state.showSidebarBasket ? "HIDE" : "SHOW"}_SIDEBAR`)
  },

  async updateCoverageOptions({ commit, dispatch }, payload) {
    const critNames = BeneficiaryCriteriaService.getCoverageCritNames(payload.values)

    if (BeneficiaryCriteriaService.hasCoverageDurationKmCouple(payload.values)) {
      const durationOptions = BeneficiaryCriteriaService.getCoverageDurationOptions(
        payload.values,
        payload.config.user_selectable_values
      )
      const kmOptions = BeneficiaryCriteriaService.getCoverageKmOptionsForDuration(
        payload.config.user_selectable_values,
        payload.values[critNames.duration]
      )
      commit("SET_COVERAGE_DURATION_OPTIONS", {
        productLineId: payload.productLineId,
        options: durationOptions,
      })
      commit("SET_COVERAGE_KM_OPTIONS", {
        productLineId: payload.productLineId,
        options: kmOptions,
      })
    } else {
      const durationOptions = BeneficiaryCriteriaService.getCoverageDurationOptions(
        payload.values,
        payload.config.user_selectable_values
      )
      commit("SET_COVERAGE_DURATION_OPTIONS", {
        productLineId: payload.productLineId,
        options: durationOptions,
      })
    }
  },

  showSharingStatusModal({ commit }, status) {
    let modalInfo = {
      status: "",
      reason: "",
      advice: i18n.t("programs.sharingStatusModal.advice"),
      greetings: "",
    }
    if (status === "revoked") {
      modalInfo.status = i18n.t("programs.sharingStatusModal.revoke_status")
      modalInfo.reason = i18n.t("programs.sharingStatusModal.revoke_reason")
      modalInfo.greetings = i18n.t("programs.sharingStatusModal.greetings")
    } else if (status === "expired") {
      modalInfo.status = i18n.t("programs.sharingStatusModal.expired_status")
      modalInfo.reason = i18n.t("programs.sharingStatusModal.expired_reason")
      modalInfo.greetings = i18n.t("programs.sharingStatusModal.greetings")
    } else {
      modalInfo.status = i18n.t("programs.sharingStatusModal.quota_status")
      modalInfo.reason = i18n.t("programs.sharingStatusModal.quota_reason")
      modalInfo.advice = i18n.t("programs.sharingStatusModal.quota_advice")
    }
    commit("SET_SHARING_STATUS_MODAL_INFO", modalInfo)
    commit("SHOW_SHARING_STATUS_MODAL")
  },
}

// GETTERS (camelCase)
const getters = {
  sidebarBasketIsVisible: (state, getters, rootState, rootGetters) =>
    state.showSidebarBasket && !rootGetters["basket/basketIsEmpty"],

  getCoverageDurationOptions: (state) => (productLineId) =>
    state.coverage_duration_options.hasOwnProperty(productLineId)
      ? state.coverage_duration_options[productLineId]
      : null,

  getCoverageKmOptions: (state) => (productLineId) =>
    state.coverage_km_options.hasOwnProperty(productLineId)
      ? state.coverage_km_options[productLineId]
      : null,

  // Warning : keep the 'rootState' parameter even if it's not used, otherwise rootGetters won't work !
  getCoverageOptions:
    (state, getters, rootState, rootGetters) => (productLineId, coverageCritName) => {
      if (
        coverageCritName.includes("duration") &&
        !coverageCritName.includes("ew_duration")
      ) {
        return getters.getCoverageDurationOptions(productLineId)
      }
      if (coverageCritName.includes("km")) {
        return getters.getCoverageKmOptions(productLineId)
      }
      return rootGetters["productLine/getCoefCritOptions"](
        productLineId,
        coverageCritName
      )
    },

  hasSharingStatusModalToBeDisplayed: (state) => state.show_sharing_status_modal,
  getSharingStatusModalInfo: (state) => state.sharing_status_modal_info,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

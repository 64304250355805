import default_criteria from "@/conf/default_criteria"
import local_storage_names from "@/conf/local_storage_names"
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

// STATES (snake_case)
const state = () => ({
  type: null,
  final_price: null,
  computed_base100: null,
  computed_tpp2: null,
  base100_criteria: null,
  tpp2_criteria: null,
  variable_criteria: null,
  commercial_criteria: null,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  RESET_COMMERCIAL_CRITERIA(state) {
    state.commercial_criteria = { ...default_criteria[state.type].commercial_criteria }
    localStorage.removeItem(local_storage_names.pricing[state.type].commercialCriteria)
  },
  RESET_VARIABLE_CRITERIA(state, criteria) {
    state.variable_criteria = { ...default_criteria[state.type].variable_criteria }
    localStorage.removeItem(local_storage_names.pricing[state.type].variableCriteria)
  },
  RESET_BASE100_COMPUTED(state) {
    state.computed_base100 = null
  },
  RESET_TTP2_COMPUTED(state) {
    state.computed_tpp2 = null
    state.final_price = null
  },
  SET_STORE_TYPE(state, type) {
    state.type = type
  },
  SET_COMPUTED_BASE100(state, computed) {
    state.computed_base100 = computed
  },
  SET_BASE100_CRITERIA(state, computed) {
    state.base100_criteria = computed
  },
  SET_COMPUTED_TPP2(state, computed) {
    state.computed_tpp2 = computed
  },
  SET_TPP2_CRITERIA(state, computed) {
    state.tpp2_criteria = computed
  },
  SET_COMMERCIAL_CRITERIA(state, criteria) {
    state.commercial_criteria = criteria
    localStorage.setItem(
      local_storage_names.pricing[state.type].commercialCriteria,
      JSON.stringify(criteria)
    )
  },
  SET_VARIABLE_CRITERIA(state, criteria) {
    state.variable_criteria = criteria
    localStorage.setItem(
      local_storage_names.pricing[state.type].variableCriteria,
      JSON.stringify(criteria)
    )
  },
  SET_FINAL_PRICE(state, price) {
    state.final_price = price
  },
}

// ACTIONS (camelCase)
const actions = {
  /**
   * init store
   * @param commit
   * @param type {string}: vn|vo
   */
  init({ commit }, type) {
    commit("SET_STORE_TYPE", type)
    let criteria = JSON.parse(
      localStorage.getItem(local_storage_names.pricing[type].variableCriteria)
    ) || {
      ...default_criteria[type].variable_criteria,
    }
    commit("SET_VARIABLE_CRITERIA", criteria)
    criteria = JSON.parse(
      localStorage.getItem(local_storage_names.pricing[type].commercialCriteria)
    ) || {
      ...default_criteria[type].commercial_criteria,
    }
    commit("SET_COMMERCIAL_CRITERIA", criteria)
  },
  initVn({ dispatch }) {
    dispatch("init", "vn")
  },
  initVo({ dispatch }) {
    dispatch("init", "vo")
  },
  resetBase100Computed({ commit }) {
    commit("RESET_BASE100_COMPUTED")
  },
  resetTpp2Computed({ commit }) {
    commit("RESET_TTP2_COMPUTED")
  },
  async computeBase100({ commit, state }, payload) {
    commit("RESET_BASE100_COMPUTED")
    commit("RESET_TTP2_COMPUTED")
    try {
      let computed = await HttpService.post(
        UrlService.render("pricingBase100" + state.type.toUpperCase()),
        payload
      )
      commit("SET_COMPUTED_BASE100", computed)
      commit("SET_BASE100_CRITERIA", payload)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async computeTpp2({ commit, state }, payload) {
    commit("RESET_TTP2_COMPUTED")
    try {
      const data = {
        base100: {
          ...{ price: state.computed_base100.price },
          ...state.base100_criteria,
        },
        fixed_variable: payload,
        variable: state.variable_criteria,
        commercial: state.commercial_criteria,
      }
      let computed = await await HttpService.post(
        UrlService.render("pricingTpp2" + state.type.toUpperCase()),
        data
      )
      commit("SET_FINAL_PRICE", computed.price)
      commit("SET_COMPUTED_TPP2", computed.tpp2)
      commit("SET_TPP2_CRITERIA", payload)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  resetCommercialCriteria({ commit }) {
    commit("RESET_COMMERCIAL_CRITERIA")
  },
  resetVariableCriteria({ commit }) {
    commit("RESET_VARIABLE_CRITERIA")
  },
  setCommercialCriteria({ commit }, criteria) {
    commit("SET_COMMERCIAL_CRITERIA", criteria)
  },
  setVariableCriteria({ commit }, criteria) {
    commit("SET_VARIABLE_CRITERIA", criteria)
  },
}

// GETTERS (camelCase)
const getters = {
  hasFinalPrice: (state) => state.final_price !== null,
  getFinalPrice: (state) => state.final_price,
  /**
   * Base 100
   */
  getBase100Criteria: (state) => state.base100_criteria,
  hasBase100Computed: (state) => state.computed_base100 !== null,
  getBase100Price: (state) =>
    state.computed_base100 ? state.computed_base100.price : null,
  listBase100Coefficients: (state) =>
    state.computed_base100 ? state.computed_base100.coefficients : null,
  getBase100Coefficient: (state, getter) => (name) => {
    if (
      !getter.hasBase100Computed ||
      !state.computed_base100.coefficients.hasOwnProperty(name)
    ) {
      return null
    }
    return state.computed_base100.coefficients[name]
  },
  /**
   * Tpp2
   */
  getTpp2Criteria: (state) => state.tpp2_criteria,
  hasTpp2Computed: (state) => state.computed_tpp2 !== null,
  getTpp2Price: (state) => (state.computed_tpp2 ? state.computed_tpp2.price : null),
  listTpp2Coefficients: (state) =>
    state.computed_tpp2 ? state.computed_tpp2.coefficients : null,
  getTpp2Coefficient: (state, getter) => (name) => {
    if (
      !getter.hasTpp2Computed ||
      !state.computed_tpp2.coefficients.hasOwnProperty(name)
    ) {
      return null
    }
    return state.computed_tpp2.coefficients[name]
  },
  /**
   * variable criteria
   */
  getVariableCriteria: (state) => state.variable_criteria,
  getVariableCriteriaCoefficient: (state, getter) => (name) => {
    return getter.getTpp2Coefficient(name)
  },
  hasVariableUsageTaxi: (state) => state.variable_criteria.usage === "taxi",
  /**
   * commercial criteria
   */
  getCommercialCriteria: (state) => state.commercial_criteria,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div :class="['product-line-edition__criteria__benef', ...componentClass]">
    <div class="benef-criteria" v-if="hasProductLine(productLineId)">
      <FormRowSelect
        v-for="criterion in getConfig(productLineId).coefficient.beneficiary"
        :key="criterion"
        class="font-size-14 white_space_nowrap"
        :label-inline="false"
        :label="$t('criteria.' + criterion)"
        :for-id="`program-context-${criterion}`"
        :name="`${productLineId}-${criterion}`"
        :selected-option="beneficiaryCriteria[criterion]"
        label-select-attr="label"
        :select-options="getCoverageOptions(productLineId, criterion)"
        :errors="{}"
        :disabled="disabled"
        @update:selected-option="onUpdateCriterion(criterion, $event)"
      ></FormRowSelect>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CoverageDurationDistance",
  props: {
    productLineId: {
      type: Number,
    },
    beneficiaryCriteria: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    componentClass: {
      type: Array,
      default: () => [""],
    },
  },
  computed: {
    ...mapGetters("productLine", ["getConfig", "hasProductLine"]),
    ...mapGetters("gui", ["getCoverageOptions"]),
  },
  methods: {
    onUpdateCriterion(criterion, value) {
      this.$emit("coverage-update", { [criterion]: value })
    },
  },
}
</script>

<style scoped lang="scss">
.white_space_nowrap {
  white-space: nowrap;
}
</style>

<template>
  <div>
    <div class="about text-center mt-5">
      <img
        src="https://res.cloudinary.com/caarea/image/upload/v1617955698/cs/assets/404_qktbda.png"
        alt="404 not found"
      />
    </div>
    <div class="nav-next text-center">
      <p class="bold">{{ $t("error.404") }}</p>
      <p v-if="message" class="text-danger">{{ message }}</p>
      <p>{{ $t("error.continue") }}</p>
    </div>

    <div class="nav-next text-center">
      <button class="btn btn-primary" data-cy="home-btn" @click.prevent="onClick">
        {{ $t("button.home") }}
      </button>
    </div>
  </div>
</template>
<script>
import ErrorPageMixin from "@/mixins/ErrorPageMixin"
import { mapGetters } from "vuex"
import SsoService from "../../services/technical/SsoService"

export default {
  name: "Page404",
  mixins: [ErrorPageMixin],
  props: { message: { type: String, default: null } },
  computed: {
    ...mapGetters("auth", ["isLoggedInSharingMode"]),
    isLoggedIn() {
      return this.isLoggedInSharingMode || SsoService.isAuthenticated()
    },
  },
  methods: {
    onClick() {
      this.pushI18nRoute({ name: this.getUrlName })
    },
  },
}
</script>

// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import DateService from "@/services/technical/DateService"

const state = {
  partners: null,
  partners_formatted_list: [],
  partner_types: {},
  partner_creation_errors: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PARTNERS(state, partners) {
    state.partners = partners
  },
  SET_PARTNERS_FORMATTED_LIST(state, partnersListFromApi) {
    state.partners_formatted_list = partnersListFromApi
  },
  SET_PARTNERS_TYPES(state, types) {
    state.partner_types = types
  },
  SET_PARTNER_CREATION_ERRORS(state, errors) {
    state.partner_creation_errors = errors
  },
  REMOVE_PARTNER_CREATION_ERRORS(state) {
    state.partner_creation_errors = null
  },
}

// ACTIONS (camelCase)
const actions = {
  async initPartners({ commit, dispatch }) {
    try {
      let partners = await HttpService.get(UrlService.render("partners"))
      commit("SET_PARTNERS", partners)
      dispatch("setPartnersFormattedList", partners)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  setPartnersFormattedList({ commit, rootGetters }, partnersList) {
    const partnersListToDisplay = partnersList.map((partner) => {
      const created_at = partner.created_at
        ? DateService.format(
            partner.created_at,
            "DD MMMM YYYY",
            rootGetters["config/getCurrentLang"]
          )
        : ""
      const created_by =
        partner.created_by.first_name + " " + partner.created_by.last_name
      return { ...partner, created_by, created_at }
    })
    commit("SET_PARTNERS_FORMATTED_LIST", partnersListToDisplay)
  },
  async setPartnersTypes({ commit }) {
    const partners_types = await HttpService.get(UrlService.render("partnersTypes"))
    commit("SET_PARTNERS_TYPES", partners_types["types"])
  },
  async createPartner({ dispatch, commit }, payload) {
    commit("REMOVE_PARTNER_CREATION_ERRORS")
    try {
      await HttpService.post(UrlService.render("partners"), payload)
      dispatch("initPartners")
    } catch (e) {
      commit("SET_PARTNER_CREATION_ERRORS", e.data)
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getPartners: (state) => state.partners,
  getPartnersFormattedList: (state) => state.partners_formatted_list,
  partnerTypes: (state) => state.partner_types,
  getCreatedPartnerErrors: (state) => state.partner_creation_errors,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import AbstractService from "../AbstractService"
import i18n from "@/i18n"
import store from "../../store"
import CriteriaService from "@/services/business/CriteriaService"
import BeneficiaryCriteriaService from "@/services/business/BeneficiaryCriteriaService"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

class ProgramProductCustomizationService extends AbstractService {
  /**
   * Initialize the "ProgramProduct", "ProductLine" and "ProgramProductCustomization" stores with everything
   * required to be able to customize the program product.
   *
   * @param {Number} programProductId id of the program product
   */
  async initStores(programProductId) {
    this._setIsLoadingInProgress()
    store.commit("programProductCustomization/SET_PROGRAM_PRODUCT_ID", programProductId)
    await this._refreshProgramProduct()
    this._setIsLoadingDone()
  }

  /**
   * Update the program criteria of the specified product line :
   * - save new values in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - compute price
   *
   * If a criterion shared within the offer is modified, program product is refreshed and prices of all its product
   * lines are updated.
   *
   * @param {Number} productLineId id of the product line
   * @param {Array} customProgramCriteriaList list of the customized program criteria
   * @param {Object} programCriteriaValues values of all the program criteria
   */
  async updateProgramCriteria(
    productLineId,
    customProgramCriteriaList,
    programCriteriaValues
  ) {
    // Warning: _hasAtLeastOneSharedCritChanged() must be called before updating values
    const _hasAtLeastOneSharedCritChanged = this._hasAtLeastOneSharedCritChanged(
      productLineId,
      customProgramCriteriaList,
      programCriteriaValues
    )

    store.dispatch("programProductCustomization/updateProgramCriteria", {
      productLineId: productLineId,
      customProgramCriteriaList: customProgramCriteriaList,
      programCriteriaValues: programCriteriaValues,
    })

    await this._updateProductLine(productLineId)

    if (_hasAtLeastOneSharedCritChanged) {
      this._setPricingStatusToInProgressForAllProductLines()
      await this._refreshProgramProduct()
      await this.computeAllPrices()
    } else {
      await this._computeProductLinePrice(productLineId)
    }
  }

  /**
   * Update the user selectable values of the specified product line :
   * - save new values in the "ProgramProductCustomization" store
   * - if necessary, update the beneficiary criteria values in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - compute price
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} userSelectableValues user selectable values
   */
  async updateUserSelectableValues(productLineId, userSelectableValues) {
    store.dispatch("programProductCustomization/updateUserSelectableValues", {
      productLineId: productLineId,
      userSelectableValues: userSelectableValues,
    })

    this._updateBeneficiaryCriteriaValuesOnUserSelectableValuesChange(
      productLineId,
      userSelectableValues
    )
    await this._updateProductLine(productLineId)
    await this._computeProductLinePrice(productLineId)
  }

  /**
   * Update the program criteria and the user selectable values of the specified product line :
   * - save new values in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - compute price
   *
   * If a criterion shared within the offer is modified, program product is refreshed and prices of all its product
   * lines are updated.
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} programCriteria program criteria
   * @param {Object} userSelectableValues user selectable values
   */
  async updateProgramCriteriaAndUserSelectableValues(
    productLineId,
    programCriteria,
    userSelectableValues
  ) {
    //  TODO jlm: find a way to avoid code duplication
    //   (we cannot call updateProgramCriteria() and then updateUserSelectableValues() otherwise we'll do 2 successive
    //   product line updates with 2 API calls)

    // 1. Update program criteria in the "ProgramProductCustomization" store
    // Warning: _hasAtLeastOneSharedCritChanged() must be called before updating values
    const _hasAtLeastOneSharedCritChanged = this._hasAtLeastOneSharedCritChanged(
      productLineId,
      programCriteria.custom,
      programCriteria.values
    )

    store.dispatch("programProductCustomization/updateProgramCriteria", {
      productLineId: productLineId,
      customProgramCriteriaList: programCriteria.custom,
      programCriteriaValues: programCriteria.values,
    })

    // 2. Update user selectable values in the "ProgramProductCustomization" store
    store.dispatch("programProductCustomization/updateUserSelectableValues", {
      productLineId: productLineId,
      userSelectableValues: userSelectableValues,
    })

    this._updateBeneficiaryCriteriaValuesOnUserSelectableValuesChange(
      productLineId,
      userSelectableValues
    )

    // 3. Update product line
    await this._updateProductLine(productLineId)

    if (_hasAtLeastOneSharedCritChanged) {
      this._setPricingStatusToInProgressForAllProductLines()
      await this._refreshProgramProduct()
      await this.computeAllPrices()
    } else {
      await this._computeProductLinePrice(productLineId)
    }
  }

  /**
   * Update the payment frequency value of the specified product line :
   * - save new value in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - as payment frequency value is shared within offer, program product is refreshed and prices of all its product
   * lines are updated.
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} paymentFrequencyValue value of the payment frequency
   */
  async updatePaymentFrequency(productLineId, paymentFrequencyValue) {
    const payload = {
      productLineId: productLineId,
      paymentFrequencyValue: paymentFrequencyValue,
    }
    this._setPricingStatusToInProgressForAllProductLines()
    store.dispatch("programProductCustomization/updatePaymentFrequency", payload)
    await this._updateProductLine(productLineId)
    await this._refreshProgramProduct()
    await this.computeAllPrices()
  }

  /**
   * Update the beneficiary criterion value of the specified product line :
   * - save new value in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - compute price
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} beneficiaryCriterion beneficiary criterion
   */
  async updateBeneficiaryCriterion(productLineId, beneficiaryCriterion) {
    const newBeneficiaryCriteriaValues = this._buildNewBeneficiaryCriteriaValues(
      productLineId,
      beneficiaryCriterion
    )
    store.dispatch("programProductCustomization/updateBeneficiaryCriteriaValues", {
      productLineId: productLineId,
      beneficiaryCriteriaValues: newBeneficiaryCriteriaValues,
    })
    await this._updateProductLine(productLineId)
    await this._computeProductLinePrice(productLineId)
  }

  /**
   * Update the eligibility criteria values of the specified product line :
   * - save new values in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - compute price
   *
   * @param {Number} productLineId id of the product line
   * @param {Array} customEligibilityCriteriaList list of the customized eligibility criteria
   * @param {Object} eligibilityCriteriaValues values of all the eligibility criteria
   */
  async updateEligibilityCriteria(
    productLineId,
    customEligibilityCriteriaList,
    eligibilityCriteriaValues
  ) {
    // if eligibility criteria have changed, remove selected vehicle
    // (to avoid a 422 error if selected vehicle becomes ineligible)
    const haveEligibilityCriteriaValuesChanged =
      this.haveEligibilityCriteriaValuesChanged(
        productLineId,
        eligibilityCriteriaValues
      )
    if (haveEligibilityCriteriaValuesChanged) {
      store.dispatch("programProductCustomization/updateVehicle", {
        productLineId: productLineId,
        vehicle: {},
      })
    }

    store.dispatch("programProductCustomization/updateEligibilityCriteria", {
      productLineId: productLineId,
      customEligibilityCriteriaList: customEligibilityCriteriaList,
      eligibilityCriteriaValues: eligibilityCriteriaValues,
    })

    await this._updateProductLine(productLineId)
    await this._computeProductLinePrice(productLineId)
  }

  /**
   * Update the selected vehicle of the specified product line :
   * - save new vehicle in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - compute price
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} vehicle vehicle selected for this product line
   */
  async updateVehicle(productLineId, vehicle) {
    const payload = {
      productLineId: productLineId,
      vehicle: vehicle,
    }
    store.dispatch("programProductCustomization/updateVehicle", payload)
    await this._updateProductLine(productLineId)

    // TODO jlm : specific case EW Duration produit Battery --- à revoir !!!
    if (
      this._hasEwDurationCriterion(productLineId) &&
      this._hasEwDurationValueToBeEnforced(productLineId)
    ) {
      this._enforceEwDurationValue(productLineId)
    } else {
      await this._computeProductLinePrice(productLineId)
    }
  }

  /**
   * Update the commercial criteria values of the specified product line :
   * - save new values in the "ProgramProductCustomization" store
   * - call PUT productLine API
   * - save returned product line in the "ProductLine" store
   * - reinitialize product line data in the "ProgramProductCustomization" store from returned product line
   * - compute price
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} commercialCriteriaValues values of all the commercial criteria
   */
  async updateCommercialCriteriaValues(productLineId, commercialCriteriaValues) {
    store.dispatch("programProductCustomization/updateCommercialCriteriaValues", {
      productLineId: productLineId,
      commercialCriteriaValues: commercialCriteriaValues,
    })

    await this._updateProductLine(productLineId)
    await this._computeProductLinePrice(productLineId)
  }

  /**
   * Delete the product line by calling the DEL productLine API then removing its data from the "ProductLine" and
   * "ProgramProductCustomization" stores.
   * A refresh of the program product is finally done to keep things consistent between the "ProgramProduct",
   * "ProductLine" and "ProgramProductCustomization" stores.
   *
   * @param {Number} productLineId id of the product line
   */
  async deleteProductLine(productLineId) {
    try {
      await HttpService.delete(
        UrlService.render("productLineById", { id: productLineId })
      )
      store.commit("productLine/DEL_PRODUCT_LINE", productLineId)
      store.dispatch("programProductCustomization/removeProductLineId", productLineId)
      store.dispatch(
        "programProductCustomization/deleteAllProductLineData",
        productLineId
      )
      await this._refreshProgramProduct()
      await this.computeAllPrices()
    } catch (e) {
      // TODO jlm: gerer les erreurs !! (n'etait pas fait avant)
      console.error("deleteProductLine() failed : ", e)
      throw e
    }
  }

  /**
   * Add a product line by calling the ADD productLine API then refreshing program product to keep things consistent
   * between the "ProgramProduct", "ProductLine" and "ProgramProductCustomization" stores.
   *
   * @param {Number} programId id of the program
   * @param {Number} programProductId id of the program product
   *
   * @returns {Number} id of the product line just added
   */
  async addProductLine(programId, programProductId) {
    try {
      await HttpService.post(
        UrlService.render("productLineByProgramId", { id: programId }),
        {
          product_id: programProductId,
        }
      )
      await this._refreshProgramProduct()
      await this.computeAllPrices()
      return store.getters["programProductCustomization/getLastAddedProductLineId"]
    } catch (e) {
      // TODO jlm: gerer les erreurs !! (n'etait pas fait avant)
      console.error("addProductLine() failed : ", e)
      throw e
    }
  }

  /**
   * Compute price of each product line.
   */
  async computeAllPrices() {
    // TODO jlm: improvement : use getProductLinePrices action instead ?
    const productLinesIds =
      store.getters["programProductCustomization/listProductLinesIds"]
    for (const productLineId of productLinesIds) {
      await this._computeProductLinePrice(productLineId)
    }
  }

  /**
   * Indicate whether at least one shared criterion is among the customized program criteria and its value has just
   * changed.
   * A shared criterion is a criterion whose value is shared within the offer.
   *
   * @param {Number} productLineId id of the product line
   * @param {Array} customProgramCriteriaList list of the customized program criteria
   * @param {Object} programCriteriaValues values of all the program criteria
   *
   * @returns {Boolean}
   */
  _hasAtLeastOneSharedCritChanged(
    productLineId,
    customProgramCriteriaList,
    programCriteriaValues
  ) {
    const productLineConfig = store.getters["productLine/getConfig"](productLineId)
    const currentProgramCriteriaValues =
      store.getters["programProductCustomization/getProgramCriteriaValues"](
        productLineId
      )
    for (const criterion of customProgramCriteriaList) {
      if (
        CriteriaService.isSharedCriterion(productLineConfig, criterion) &&
        programCriteriaValues[criterion] !== currentProgramCriteriaValues[criterion]
      ) {
        return true
      }
    }
    return false
  }

  /**
   * Wrap all the product line update process :
   * - prepare update (reset indicators, build new config)
   * - call PUT API to update product line in the backend
   * - manage the result of PUT API call, success or failure (update 'ProductLine' and 'ProgramProductCustomization'
   * stores in particular)
   *
   * @param {Number} productLineId id of the product line
   */
  async _updateProductLine(productLineId) {
    const config = this._prepareProductLineUpdate(productLineId)
    try {
      const updatedProductLine = await this._updateProductLineInBackend(
        productLineId,
        config
      )
      await this._manageProductLineUpdateSuccess(updatedProductLine)
    } catch (e) {
      this._manageProductLineUpdateFailure(productLineId, e)
    }
  }

  /**
   * Do all the things required before a product line update :
   * - reset several indicators
   * - build the new product line's config for the update
   *
   * @param {Number} productLineId id of the product line
   *
   * @returns {Object} config of the product line
   */
  _prepareProductLineUpdate(productLineId) {
    store.commit("productLine/RESET_PRODUCT_LINE_UPDATE_ERRORS", productLineId)
    store.commit("productLine/RESET_PRODUCT_LINE_CHANGE_MESSAGES", productLineId)
    store.commit("productLine/RESET_PRODUCT_LINE_PRICE", productLineId)
    store.dispatch("programProductCustomization/updatePricingStatus", {
      productLineId: productLineId,
      isInProgress: true,
    })
    return this._buildConfig(productLineId)
  }

  /**
   * Build the product line's config by adding the customized elements to the base config.
   *
   * @param {Number} productLineId id of the product line
   *
   * @returns {Object}
   */
  _buildConfig(productLineId) {
    let config = store.getters["productLine/getConfig"](productLineId)
    // No need to make deep copies as getters are already deep copies
    config.coefficient.custom =
      store.getters["programProductCustomization/listCustomProgramCriteria"](
        productLineId
      )
    config.coefficient.values = {
      ...store.getters["programProductCustomization/getProgramCriteriaValues"](
        productLineId
      ),
      ...store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
        productLineId
      ),
    }
    config.user_selectable_values =
      store.getters["programProductCustomization/getUserSelectableValues"](
        productLineId
      )
    config.payment_frequency.value =
      store.getters["programProductCustomization/getPaymentFrequencyValue"](
        productLineId
      )
    config.eligibility.custom =
      store.getters["programProductCustomization/listCustomEligibilityCriteria"](
        productLineId
      )
    config.eligibility.values =
      store.getters["programProductCustomization/getEligibilityCriteriaValues"](
        productLineId
      )
    config.vehicle =
      store.getters["programProductCustomization/getVehicle"](productLineId)
    config.commercial.values =
      store.getters["programProductCustomization/getCommercialCriteriaValues"](
        productLineId
      )
    return config
  }

  /**
   * Call the PUT product-lines/{id}/ API.
   *
   * @param {Number} id id of the product line
   * @param {Object} config config of the product line
   *
   * @returns {Object} Updated product line (response from the backend), on success
   * @throws exception on failure
   */
  async _updateProductLineInBackend(id, config) {
    try {
      const data = { config: config }
      return await HttpService.put(
        UrlService.render("productLineById", { id: id }),
        data
      )
    } catch (e) {
      console.error(`PUT product-lines/${id}/ failed : ${e}`)
      throw e
    }
  }

  /**
   * Do all the things required after a successful product line update.
   *
   * @param {Object} updatedProductLine product line just updated
   */
  async _manageProductLineUpdateSuccess(updatedProductLine) {
    const productLineId = updatedProductLine.id
    const config = updatedProductLine.config

    store.commit("productLine/SET_PRODUCT_LINE", updatedProductLine)
    store.commit("productLine/SET_PRODUCT_LINE_CHANGE_MESSAGES", {
      id: productLineId,
      change_messages: config.change_messages,
    })

    store.dispatch(
      "programProductCustomization/initAllProductLineDataFromProductLineConfig",
      {
        productLineId: productLineId,
        config: config,
      }
    )

    const beneficiaryCriteriaValues =
      store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
        productLineId
      )
    if (BeneficiaryCriteriaService.hasCoverageCriteria(beneficiaryCriteriaValues)) {
      this._updateCoverageOptions(productLineId, config)
    }

    await store.dispatch("productLine/getCoefficientCriteria", productLineId)
  }

  /**
   * Do all the things required after a failed product line update.
   *
   * @param {Number} productLineId id of the product line
   * @param {*} exception exception thrown when updating product line in the backend
   *
   */
  _manageProductLineUpdateFailure(productLineId, exception) {
    let hasExceptionToBeThrown = true
    if (exception instanceof HttpError) {
      if (exception.status === 422 || exception.status === 400) {
        if (
          exception.data.config.vehicle &&
          store.getters[
            "programProductCustomization/isVehicleUpdateWithinProgramInProgress"
          ]
        ) {
          store.commit(
            "productLine/SET_PRODUCT_LINE_VEHICLE_CHOICE_ERROR",
            i18n.t("productLines.vehicle_non_eligible")
          )
        } else {
          store.commit("productLine/SET_PRODUCT_LINE_UPDATE_ERRORS", {
            id: productLineId,
            errors: exception.data,
          })
        }
        // No need to rethrow exception
        hasExceptionToBeThrown = false
      }
    }

    // Reinit product line data with previous config (that doesn't contain last update)
    store.dispatch(
      "programProductCustomization/initAllProductLineDataFromProductLineConfig",
      {
        productLineId: productLineId,
        config: store.getters["productLine/getConfig"](productLineId),
      }
    )
    if (hasExceptionToBeThrown) {
      throw exception
    }
  }

  /**
   * Build the new beneficiary criteria values upon change of one beneficiary criterion.
   * If beneficiary criteria don't contain a duration/km couple, nothing has to be done.
   * If beneficiary criteria contain a duration/km couple, we must ensure to use a valid couple (depending on what
   * user selectable values allow).
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} newBeneficiaryCriterion beneficiary criterion that has just changed
   *
   * @returns {Object}
   */
  _buildNewBeneficiaryCriteriaValues(productLineId, newBeneficiaryCriterion) {
    const currentBeneficiaryCriteriaValues =
      store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
        productLineId
      )
    let newBeneficiaryCriteriaValues = {
      ...currentBeneficiaryCriteriaValues,
      ...newBeneficiaryCriterion,
    }

    // No coverage duration/km couple => no need to go further
    if (
      !BeneficiaryCriteriaService.hasCoverageDurationKmCouple(
        currentBeneficiaryCriteriaValues
      )
    ) {
      return newBeneficiaryCriteriaValues
    }

    // Coverage duration/km couple
    // => in case duration has changed, ensure to use a km value selectable for this duration
    const durationCriterionName = BeneficiaryCriteriaService.getCoverageCritNames(
      currentBeneficiaryCriteriaValues
    ).duration
    const newBeneficiaryCriterionName = Object.keys(newBeneficiaryCriterion)[0]
    const isDuration = newBeneficiaryCriterionName === durationCriterionName

    if (isDuration) {
      const currentDuration = currentBeneficiaryCriteriaValues[durationCriterionName]
      const hasDurationChanged =
        newBeneficiaryCriterion[durationCriterionName] !== currentDuration
      if (hasDurationChanged) {
        const userSelectableValues =
          store.getters["programProductCustomization/getUserSelectableValues"](
            productLineId
          )
        newBeneficiaryCriteriaValues =
          BeneficiaryCriteriaService.getValidCoverageCoupleOnDurationChange(
            newBeneficiaryCriteriaValues,
            userSelectableValues
          )
      }
    }
    return newBeneficiaryCriteriaValues
  }

  // -------
  // TODO jlm : specific case EW Duration produit Battery --- à revoir !!!
  _hasEwDurationCriterion(productLineId) {
    const beneficiaryCriteriaValues =
      store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
        productLineId
      )
    return Object.keys(beneficiaryCriteriaValues).includes("ew_duration")
  }

  _hasEwDurationValueToBeEnforced(productLineId) {
    const beneficiaryCriteriaValues =
      store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
        productLineId
      )
    const options = store.getters["gui/getCoverageOptions"](
      productLineId,
      "ew_duration"
    )
    return !BeneficiaryCriteriaService.isCriterionValueInCovDurationOptions(
      beneficiaryCriteriaValues,
      options,
      "ew_duration"
    )
  }

  async _enforceEwDurationValue(productLineId) {
    const options = store.getters["gui/getCoverageOptions"](
      productLineId,
      "ew_duration"
    )
    const defaultValue =
      store.getters["productLine/getBeneficiaryCriteriaDefaultValues"](productLineId)[
        "ew_duration"
      ]
    const newValue =
      BeneficiaryCriteriaService.getValidCoverageDurationWithoutSelectValues(
        defaultValue,
        options
      )
    await this.updateBeneficiaryCriterion(productLineId, { ew_duration: newValue })
  }
  // -------

  /**
   * Update the coverage options (beneficiary criteria options).
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} productLineConfig config of the product line
   */
  _updateCoverageOptions(productLineId, productLineConfig) {
    const updateCoverageOptionsPayload = {
      productLineId: productLineId,
      config: productLineConfig,
      values:
        store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
          productLineId
        ),
    }
    store.dispatch("gui/updateCoverageOptions", updateCoverageOptionsPayload)
  }

  /**
   * Wrap all the product line pricing process :
   * - prepare pricing (reset indicators, build payload for API call)
   * - call POST API to compute price of product line in the backend
   * - manage the result of API call, success or failure (update 'ProductLine' and 'ProgramProductCustomization'
   * stores in particular)
   *
   * @param {Number} productLineId id of the product line
   */
  async _computeProductLinePrice(productLineId) {
    if (!this._canPriceBeComputed(productLineId)) {
      store.dispatch("programProductCustomization/updatePricingStatus", {
        productLineId: productLineId,
        isInProgress: false,
      })
      return
    }
    const payload = this._prepareProductLinePricing(productLineId)
    try {
      const response = await this._getProductLinePriceFromBackend(payload)
      await this._manageProductLinePricingSuccess(
        productLineId,
        response.price,
        response.currency_code
      )
    } catch (e) {
      this._manageProductLinePricingFailure(productLineId, e)
    } finally {
      store.dispatch("programProductCustomization/updatePricingStatus", {
        productLineId: productLineId,
        isInProgress: false,
      })
    }
  }

  /**
   * Indicate whether the price of the provided product line can be computed.
   *
   * @param {Number} productLineId id of the product line
   *
   * @returns {Boolean}
   */
  _canPriceBeComputed(productLineId) {
    return store.getters["programProductCustomization/isVehicleSelected"](productLineId)
  }

  /**
   * Do all the things required before a product line pricing :
   * - reset several indicators
   * - build the payload for the API call
   *
   * @param {Number} productLineId id of the product line
   *
   * @returns {Object} payload for the API call
   */
  _prepareProductLinePricing(productLineId) {
    store.commit("productLine/RESET_PRODUCT_LINE_PRICE", productLineId)
    store.commit("productLine/RESET_PRICING_ERROR", productLineId)
    return this._buildPricingPayload(productLineId)
  }

  /**
   * Build the payload for the product line pricing API call.
   *
   * @param {Number} productLineId id of the product line
   *
   * @returns {Object} payload
   */
  _buildPricingPayload(productLineId) {
    const beneficiaryCriteria =
      store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
        productLineId
      )
    return {
      product_line_id: productLineId,
      beneficiary_criteria: beneficiaryCriteria,
    }
  }

  /**
   * Call the POST prices/ API.
   *
   * @param {Object} payload payload for the API call
   *
   * @returns {Number} price of the product line
   * @throws exception on failure
   */
  async _getProductLinePriceFromBackend(payload) {
    try {
      const response = await HttpService.post(
        UrlService.render("productLinePricing"),
        payload
      )
      return response
    } catch (e) {
      console.error(`POST price/ failed : ${e}`)
      throw e
    }
  }

  /**
   * Do all the things required after a successful product line pricing.
   *
   * @param {Number} productLineId id of the product line
   * @param {Number} price price of the product line
   * @param {String} currency_code currency_code used to format price
   */
  _manageProductLinePricingSuccess(productLineId, price, currency_code) {
    store.commit("productLine/SET_PRODUCT_LINE_PRICE", {
      productLineId: productLineId,
      price: price,
      currency_code: currency_code,
    })
  }

  /**
   * Do all the things required after a failed product line pricing.
   *
   * @param {Number} productLineId id of the product line
   * @param {*} exception exception thrown when updating product line in the backend
   *
   */
  _manageProductLinePricingFailure(productLineId, exception) {
    if (
      exception.status === 400 &&
      exception.data === "pricing_attempts_quota_exceeded"
    ) {
      store.dispatch("gui/showSharingStatusModal", "pricing_attempts_quota_exceeded")
    } else if (exception.status === 400 || exception.status === 422) {
      store.commit("productLine/SET_PRICING_ERROR", {
        productLineId: productLineId,
        error: exception.data,
      })
    }
    store.commit("productLine/SET_PRODUCT_LINE_PRICE", {
      productLineId: productLineId,
      price: null,
      currency_code: "EUR",
    })
  }

  /**
   * Update the beneficiary criteria values of the specified product line, depending on the user selectable values.
   * New beneficiary criteria values are saved in the "ProgramProductCustomization" store.
   * This function is meant to be called upon each change of the user selectable values : beneficiary criteria values
   * must always be values that are allowed in the user selectable values.
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} userSelectableValues user selectable values
   */
  _updateBeneficiaryCriteriaValuesOnUserSelectableValuesChange(
    productLineId,
    userSelectableValues
  ) {
    let beneficiaryCriteriaValues =
      store.getters["programProductCustomization/getBeneficiaryCriteriaValues"](
        productLineId
      )
    if (
      BeneficiaryCriteriaService.hasCoverageDurationKmCouple(beneficiaryCriteriaValues)
    ) {
      beneficiaryCriteriaValues = BeneficiaryCriteriaService.getValidCoverageCouple(
        beneficiaryCriteriaValues,
        store.getters["productLine/getBeneficiaryCriteriaDefaultValues"](productLineId),
        userSelectableValues
      )
    } else {
      beneficiaryCriteriaValues = BeneficiaryCriteriaService.getValidCoverageDuration(
        beneficiaryCriteriaValues,
        store.getters["productLine/getBeneficiaryCriteriaDefaultValues"](productLineId),
        userSelectableValues
      )
    }
    store.dispatch("programProductCustomization/updateBeneficiaryCriteriaValues", {
      productLineId: productLineId,
      beneficiaryCriteriaValues: beneficiaryCriteriaValues,
    })
  }

  /**
   * Refresh the program product that contains the concerned product line.
   * This method is meant to be called whenever an action on a product line requires an update of its program product
   * (ex: product line deletion, update of a shared criterion, ...)
   */
  async _refreshProgramProduct() {
    const programProductId =
      store.getters["programProductCustomization/getProgramProductId"]
    await store.dispatch("programProduct/retrieve", programProductId)

    const programProduct =
      store.getters["programProduct/getProgramProductById"](programProductId)
    let productLineIds = []
    for (const productLine of programProduct.product_lines) {
      productLineIds.push(productLine.id)
      await store.dispatch("productLine/getCoefficientCriteria", productLine.id)
      store.dispatch(
        "programProductCustomization/initAllProductLineDataFromProductLineConfig",
        {
          productLineId: productLine.id,
          config: productLine.config,
        }
      )
    }
    store.commit(
      "programProductCustomization/SET_PRODUCT_LINES_IDS",
      JSON.parse(JSON.stringify(productLineIds))
    )
  }

  /**
   * Indicate whether eligibility criteria have changed.
   *
   * @param {Number} productLineId id of the product line
   * @param {Object} newEligibilityCriteriaValues new values of all the eligibility criteria
   */
  haveEligibilityCriteriaValuesChanged(productLineId, newEligibilityCriteriaValues) {
    const currentEligibilityCriteriaValues =
      store.getters["programProductCustomization/getEligibilityCriteriaValues"](
        productLineId
      )
    const stringifiedCurrentEligibilityCriteriaValues = JSON.stringify(
      currentEligibilityCriteriaValues
    )
    const stringifiedNewEligibilityCriteriaValues = JSON.stringify(
      newEligibilityCriteriaValues
    )
    return (
      stringifiedNewEligibilityCriteriaValues !==
      stringifiedCurrentEligibilityCriteriaValues
    )
  }

  /**
   * Set "is loading" indicator to true in the "ProgramProductCustomization" store.
   */
  _setIsLoadingInProgress() {
    store.commit(
      "programProductCustomization/SET_IS_PROGRAM_PRODUCT_CUSTOMIZATION_LOADING",
      true
    )
  }

  /**
   * Set "is loading" indicator to false in the "ProgramProductCustomization" store.
   */
  _setIsLoadingDone() {
    store.commit(
      "programProductCustomization/SET_IS_PROGRAM_PRODUCT_CUSTOMIZATION_LOADING",
      false
    )
  }

  /**
   * Set "pricing in progress" indicator to true in the "ProgramProductCustomization" store, for all the product lines.
   */
  _setPricingStatusToInProgressForAllProductLines() {
    store.dispatch(
      "programProductCustomization/updatePricingStatusForAllProductLines",
      true
    )
  }

  /**
   * Update "vehicle update within program in progress" indicator in the "ProgramProductCustomization" store.
   *
   * @param {Number} isInProgress indicator whether it's in progress or not
   */
  setIsVehicleUpdateWithinProgramInProgress(isInProgress) {
    store.commit(
      "programProductCustomization/SET_IS_VEHICLE_UPDATE_WITHIN_PROGRAM_IN_PROGRESS",
      isInProgress
    )
  }
}

export default new ProgramProductCustomizationService()

<template>
  <div class="user-form">
    <div class="image-form">
      <ImageUsers />
      <div class="form">
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.firstname") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.first_name }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.lastname") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.last_name }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.email") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.login") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.username }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.company") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.company }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.partner") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.partner }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.status") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.status }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-right">
            <label class="mb-0">
              <b>{{ $t("users.account.role") }}</b>
            </label>
          </div>
          <div class="col-sm-8">
            {{ user.role }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-actions">
      <router-link class="btn btn-blue-c-100 back-button" :to="{ name: 'usersList' }">
        {{ $t("users.back") }}
      </router-link>
      <router-link
        class="btn btn-primary btn-block"
        :to="{
          name: 'usersEdit',
          params: { userId: this.$router.currentRoute.params.userId },
        }"
      >
        {{ $t("users.modify") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ImageUsers from "@/components/users/ImageUsers"

export default {
  name: "UsersAccount",
  components: {
    ImageUsers,
  },
  data() {
    return {
      user: {},
    }
  },
  computed: {
    ...mapGetters("users", ["getUsers"]),
  },
  methods: {
    saveUser() {},
  },
  async created() {
    this.user = await this.getUsers.find(
      (user) => user.id === parseInt(this.$router.currentRoute.params.userId)
    )
  },
}
</script>

<style lang="scss" scoped>
.back-button:hover {
  background-color: lighten($bleuc100, 5);
  transition: background-color 200ms;
}
.image-form {
  .form {
    padding: 80px;
  }
}
</style>

<template>
  <div class="config-criterion">
    <div
      class="config-criterion__name font-size-16 m-1"
      :data-cy="`config-criterion-${name}`"
    >
      {{ name }}
    </div>
    <div class="config-criterion__points font-size-16">:</div>
    <div
      class="config-criterion__value font-size-16 m-1"
      :data-cy="`config-criterion-${value}`"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductLineConfigCriterion",
  props: {
    name: { type: String, required: true },
    value: { type: [String, Number], required: true },
  },
}
</script>

<style scoped lang="scss">
.config-criterion {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: minmax(3rem, auto);
  grid-template-areas: "name points value";
  grid-column-gap: 0.5rem;
  background-color: $backgroundsecondary;
  width: 100%;
}

.config-criterion__name {
  grid-area: name;
  justify-self: end;
  align-self: center;
  color: $bleucaarea;
  font-family: $font-family-base;
  // justify-self end doesn't work well if text overlaps
  // text-align right fixes this
  text-align: right;
}

.config-criterion__points {
  grid-area: points;
  justify-self: center;
  align-self: center;
  color: $bleucaarea;
  font-family: $font-family-base;
}

.config-criterion__value {
  grid-area: value;
  justify-self: start;
  align-self: center;
  color: $bleucaarea;
  font-family: "Gilroy SemiBold", sans-serif;
  font-weight: bold;
}
</style>

<template>
  <ModalWarning :title="title" :reason="reason" @close="$emit('close')">
    <table
      v-if="hasImportErrors"
      class="table table-striped"
      :aria-describedby="`{name} import errors`"
    >
      <thead>
        <tr>
          <th id="sheet_name">
            {{ $t("programParameters.uploadErrorModal.sheet") }}
          </th>
          <th id="line">{{ $t("programParameters.uploadErrorModal.line") }}</th>
          <th id="error">{{ $t("programParameters.uploadErrorModal.error") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(error, index) in importErrorsList" :key="error.desc">
          <td :data-cy="`error-sheetName-${index}`">{{ error.sheetName }}</td>
          <td :data-cy="`error-row-${index}`">{{ error.row }}</td>
          <td :data-cy="`error-desc-${index}`">{{ error.desc }}</td>
        </tr>
      </tbody>
    </table>
  </ModalWarning>
</template>

<script>
import ModalWarning from "@/components/modal/ModalWarning"
export default {
  name: "ModalFileUploadError",
  components: { ModalWarning },
  props: {
    name: { type: String, required: true },
    title: { type: String, required: true },
    reason: { type: String, required: true },
    hasImportErrors: { type: Boolean, required: true },
    importErrorsList: { type: Array, default: () => [] },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped></style>

// ACTIONS (camelCase)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

export const actions = {
  updateProgramCriteria({ commit }, payload) {
    commit("SET_CUSTOM_PROGRAM_CRITERIA_LIST_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      customProgramCriteriaList: payload.customProgramCriteriaList,
    })

    commit("SET_PROGRAM_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      programCriteriaValues: payload.programCriteriaValues,
    })
  },
  updateUserSelectableValues({ commit }, payload) {
    commit("SET_USER_SELECTABLE_VALUES_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      userSelectableValues: payload.userSelectableValues,
    })
  },
  updatePaymentFrequency({ commit }, payload) {
    commit("SET_PAYMENT_FREQUENCY_VALUE_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      paymentFrequencyValue: payload.paymentFrequencyValue,
    })
  },
  updateBeneficiaryCriteriaValues({ commit }, payload) {
    commit("SET_BENEFICIARY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      beneficiaryCriteriaValues: payload.beneficiaryCriteriaValues,
    })
  },
  updateEligibilityCriteria({ commit }, payload) {
    commit("SET_CUSTOM_ELIGIBILITY_CRITERIA_LIST_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      customEligibilityCriteriaList: payload.customEligibilityCriteriaList,
    })

    commit("SET_ELIGIBILITY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      eligibilityCriteriaValues: payload.eligibilityCriteriaValues,
    })
  },
  updateVehicle({ state, commit }, payload) {
    commit("SET_VEHICLE_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      vehicle: payload.vehicle,
    })
  },
  updateCommercialCriteriaValues({ commit, dispatch }, payload) {
    commit("SET_COMMERCIAL_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID", {
      productLineId: payload.productLineId,
      commercialCriteriaValues: payload.commercialCriteriaValues,
    })
  },
  updatePricingStatus({ commit }, payload) {
    commit("SET_IS_PRICING_IN_PROGRESS_FOR_PRODUCT_LINE_ID", { ...payload })
  },
  updatePricingStatusForAllProductLines({ commit }, isInProgress) {
    commit("SET_IS_PRICING_IN_PROGRESS_FOR_ALL_PRODUCT_LINES", isInProgress)
  },
  initAllProductLineDataFromProductLineConfig({ dispatch }, payload) {
    // Program criteria
    dispatch("updateProgramCriteria", {
      productLineId: payload.productLineId,
      customProgramCriteriaList: payload.config.coefficient.custom,
      programCriteriaValues: payload.config.coefficient.values,
    })

    // User selectable values
    dispatch("updateUserSelectableValues", {
      productLineId: payload.productLineId,
      userSelectableValues: payload.config.user_selectable_values,
    })

    // Payment frequency
    dispatch("updatePaymentFrequency", {
      productLineId: payload.productLineId,
      paymentFrequencyValue: payload.config.payment_frequency.value,
    })

    // Beneficiary criteria
    const beneficiaryCriteriaValues = payload.config.coefficient.beneficiary.reduce(
      (values, criterion) => {
        values[criterion] = payload.config.coefficient.values[criterion]
        return values
      },
      {}
    )
    dispatch("updateBeneficiaryCriteriaValues", {
      productLineId: payload.productLineId,
      beneficiaryCriteriaValues: beneficiaryCriteriaValues,
    })

    // Eligibility criteria
    dispatch("updateEligibilityCriteria", {
      productLineId: payload.productLineId,
      customEligibilityCriteriaList: payload.config.eligibility.custom,
      eligibilityCriteriaValues: payload.config.eligibility.values,
    })

    // Vehicle
    dispatch("updateVehicle", {
      productLineId: payload.productLineId,
      vehicle: payload.config.vehicle,
    })

    // Commercial criteria
    dispatch("updateCommercialCriteriaValues", {
      productLineId: payload.productLineId,
      commercialCriteriaValues: payload.config.commercial.values,
    })
  },
  deleteAllProductLineData({ commit }, productLineId) {
    const mutations = [
      "DELETE_CUSTOM_PROGRAM_CRITERIA_LIST_FOR_PRODUCT_LINE_ID",
      "DELETE_PROGRAM_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID",
      "DELETE_USER_SELECTABLE_VALUES_FOR_PRODUCT_LINE_ID",
      "DELETE_PAYMENT_FREQUENCY_VALUE_FOR_PRODUCT_LINE_ID",
      "DELETE_BENEFICIARY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID",
      "DELETE_CUSTOM_ELIGIBILITY_CRITERIA_LIST_FOR_PRODUCT_LINE_ID",
      "DELETE_ELIGIBILITY_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID",
      "DELETE_VEHICLE_FOR_PRODUCT_LINE_ID",
      "DELETE_COMMERCIAL_CRITERIA_VALUES_FOR_PRODUCT_LINE_ID",
      "DELETE_IS_PRICING_IN_PROGRESS_FOR_PRODUCT_LINE_ID",
    ]
    mutations.forEach((mutation) => commit(mutation, productLineId))
  },
  removeProductLineId({ state, commit }, productLineIdToRemove) {
    const updatedProductLinesIds = state.product_lines_ids.filter(
      (productLineId) => productLineId !== productLineIdToRemove
    )
    commit("SET_PRODUCT_LINES_IDS", updatedProductLinesIds)
  },
}

<template>
  <div class="user-form">
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <UserForm
      v-else
      v-show="isFormReady"
      :user="user"
      data-cy="new-user-form"
      :validation-loading="validationLoading"
      @update-validation-loading="handleValidationLoading"
      @update-is-form-ready="setFormReady"
      @submit="saveUser"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import UserForm from "@/components/users/UserForm.vue"

export default {
  name: "UsersEdit",
  components: {
    UserForm,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        group_ids: null,
        partner_id: null,
        company_id: null,
      },
      isLoading: true,
      isFormReady: false,
      validationLoading: false,
    }
  },
  computed: {
    ...mapGetters("users", ["getSelectedUser", "errors"]),
  },
  methods: {
    async setInitialData() {
      this.user = await this.getSelectedUser

      const higherGroupId = this.user.group.id
      this.role = higherGroupId.toString()

      this.user.company_id = this.getSelectedUser.company_id?.toString()
      this.user.partner_id = this.getSelectedUser.partner_id?.toString()
    },
    async saveUser(payload) {
      await this.$store.dispatch("users/editUser", payload)

      if (!this.errors) {
        await this.$router.push({ name: "usersList" })
      }

      this.validationLoading = false
    },
    handleValidationLoading(boolean) {
      this.validationLoading = boolean
    },
    setFormReady() {
      this.isFormReady = true
    },
  },
  async created() {
    const userId = parseInt(this.$router.currentRoute.params.userId)
    await this.$store.dispatch("users/retrieveUser", userId)
    await this.setInitialData()
    this.isLoading = false
  },
}
</script>

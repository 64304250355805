<template>
  <div
    class="product-line__header mt-2 w-100"
    :data-cy="`product-line-${product.name}`"
  >
    <div class="product-line__title">
      <p class="text-uppercase font-size-12">
        {{ $t("pricingRange.subscription_variable") }}
      </p>
      <h3 class="text-uppercase font-size-18">{{ product.name }}</h3>
    </div>
    <CoverageDurationDistance
      :product-line-id="product.product_lines[0].id"
      :beneficiary-criteria="form.beneficiaryCriteria"
      @coverage-update="onUpdateBeneficiaryCriterion"
    ></CoverageDurationDistance>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CoverageDurationDistance from "@/components/presales/components/CoverageDurationDistance"
import BeneficiaryCriteriaService from "../../services/business/BeneficiaryCriteriaService"

export default {
  name: "ProductLine",
  components: { CoverageDurationDistance },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        config: {},
        beneficiaryCriteria: {},
      },
    }
  },
  computed: {
    ...mapGetters("productLine", [
      "getCoefCritOptions",
      "getConfig",
      "listCoefCritCustom",
      "hasProductLine",
      "getBeneficiaryCriteriaValues",
      "getUserSelectableValues",
    ]),
  },
  async created() {
    this.form.config = this.getConfig(this.product.product_lines[0].id, this.product.id)
    this.form.beneficiaryCriteria = this.getBeneficiaryCriteriaValues(
      this.product.product_lines[0].id
    )
  },
  methods: {
    async onUpdateBeneficiaryCriterion(criterion) {
      this.form.beneficiaryCriteria = { ...this.form.beneficiaryCriteria, ...criterion }
      const criterionName = Object.keys(criterion)[0]
      // In case duration has changed, ensure to use a km value selectable for this duration
      if (
        criterionName.includes("coverage_duration") &&
        BeneficiaryCriteriaService.hasCoverageDurationKmCouple(
          this.form.beneficiaryCriteria
        )
      ) {
        this.form.beneficiaryCriteria =
          BeneficiaryCriteriaService.getValidCoverageCoupleOnDurationChange(
            this.form.beneficiaryCriteria,
            this.getUserSelectableValues(this.product.product_lines[0].id)
          )
      }

      this.form.config.coefficient.values = {
        ...this.form.config.coefficient.values,
        ...this.form.beneficiaryCriteria,
      }
      const payload = {
        // TODO : use the product line with the smallest id among the eligible product lines (CSO_1357)
        productLineId: this.product.product_lines[0].id,
        data: {
          config: { ...this.form.config },
        },
      }
      await this.$store.dispatch("productLine/update", payload)
      this.$emit("product-line-updated")
    },
  },
}
</script>

<style scoped lang="scss">
.product-line__header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  background-color: $backgroundsecondary;
  padding: 16px;
  .product-line__title {
    p {
      margin-bottom: 0.4rem;
      font-family: $font-family-title;
      font-weight: lighter;
    }
    h3 {
      font-family: $font-family-title;
    }
  }
  .product-line-edition__criteria__benef {
    padding: 0;
  }
}
</style>

<template>
  <div>
    <DocUpload
      :document="document"
      :uploaded-file-name="getUploadedFilename"
      :is-upload-in-progress="isUploadInProgress"
      @file-selected="onFileSelected"
    ></DocUpload>
  </div>
</template>

<script>
import DocUpload from "@/components/programParameters/DocUpload"
import { mapGetters } from "vuex"

export default {
  name: "ProgramDocUpload",
  components: { DocUpload },
  props: {
    document: { type: Object, required: true },
    program: { type: Object, required: true },
  },
  data() {
    return {
      uploadedFile: null,
      isUploadInProgress: false,
    }
  },
  computed: {
    ...mapGetters("programDocument", [
      "getDocumentInfoByTypeAndProgramId",
      "getUploadError",
    ]),
    getUploadedFilename() {
      return this.uploadedFile !== null ? this.uploadedFile.initial_filename : ""
    },
  },
  methods: {
    async onFileSelected(file) {
      this.isUploadInProgress = true

      try {
        const payload = {
          file: file,
          type: this.document.type,
          programId: this.program.id,
        }

        this.uploadedFile = await this.$store.dispatch(
          "programDocument/upload",
          payload
        )
      } catch (e) {
        this.$emit("upload-error", this.getUploadError)
      } finally {
        this.isUploadInProgress = false
      }
    },
  },
  created() {
    this.uploadedFile = this.getDocumentInfoByTypeAndProgramId(
      this.document.type,
      this.program.id
    )
  },
}
</script>

<style lang="scss" scoped></style>

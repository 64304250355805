<template>
  <ModalBootstrap
    class="criteria"
    :title="$t('programs.sharingStatusModal.title')"
    modal-size="large"
    data-cy="modal-sharing-status"
    :show-close-button="false"
  >
    <template #body>
      <div class="mx-0 mt-4 mb-3 d-flex flex-column justify-content-center">
        <div class="row pb-4">
          <div>
            <i
              class="p-2 text-primary icon-prohibited prohibited"
              aria-hidden="true"
            ></i>
          </div>
          <div class="pt-2 ml-3 font-size-36" data-cy="sharing-status">
            <p>{{ getSharingStatusModalInfo.status }}</p>
          </div>
          <div class="ml-4 sad">
            <i class="text-primary icon-sad font-size-36" aria-hidden="true"></i>
          </div>
        </div>
        <div class="font-size-16 text-center">
          <p>{{ getSharingStatusModalInfo.reason }}</p>
        </div>
        <div class="font-size-16 text-center">
          <p>{{ getSharingStatusModalInfo.advice }}</p>
        </div>
        <div class="font-size-16 text-center">
          <p>{{ getSharingStatusModalInfo.greetings }}</p>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"

export default {
  name: "ModalSharingStatus",
  components: { ModalBootstrap },
  computed: {
    ...mapGetters("gui", ["getSharingStatusModalInfo"]),
  },
}
</script>

<style lang="scss" scoped>
.prohibited {
  font-size: 60px;
  font-weight: bold;
}
.sad {
  padding-top: 0.8rem;
}
</style>

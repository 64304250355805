<template>
  <main class="new-partner">
    <ModalWarning
      v-if="warningModal.show"
      :title="warningModal.title"
      :reason="warningModal.reason"
      :advice="warningModal.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <div class="new-partner__header">
      <h2>{{ $t("partners.new_partner") }}</h2>
    </div>
    <div class="new-partner__form" data-cy="new-partner-form">
      <FormRowInput
        :label="$t('partners.name')"
        name="partner_name"
        v-model="partner.name"
        required
      />
      <FormRowSelect
        :label="$t('partners.type')"
        :label-select-attr="$t('partners.select_type')"
        :select-options="partnerTypes"
        :selected-option.sync="partner.type"
        @update:selected-option="onUpdate($event)"
        name="partner_type"
        required
      />
      <SelectFilePreviewButton
        input-id="partner_file"
        :button-label="$t('partners.select_file')"
        name="partner_file"
        accept="image/*"
        :uploaded-file="partnerLogo"
        :is-loading="isLoading"
        @input-file="onFileChange"
        div-class="row"
        label-class="col-sm-4 text-right"
        img-class="col-sm-8"
        :label-text="$t('partners.logo')"
        required
      />
    </div>
    <ActionButton
      :button-text="$t('partners.save')"
      class="new-partner__button"
      @click-action="savePartner"
    ></ActionButton>
  </main>
</template>

<script>
import { mapGetters } from "vuex"
import ActionButton from "@/components/button/ActionButton"
import SelectFilePreviewButton from "@/components/button/SelectFilePreviewButton"
import ModalWarning from "@/components/modal/ModalWarning"
export default {
  name: "FormNewPartner",
  components: { ActionButton, SelectFilePreviewButton, ModalWarning },

  data() {
    return {
      partner: {
        name: "",
        type: null,
        image: "",
      },
      isLoading: false,
      warningModal: {
        show: false,
        title: this.$t("partners.partnerFailedModal.title"),
        advice: this.$t("partners.partnerFailedModal.advice"),
        reason: "",
      },
    }
  },
  computed: {
    ...mapGetters("partner", ["partnerTypes", "getCreatedPartnerErrors"]),
    partnerLogo() {
      return this.partner.image !== ""
        ? this.partner.image
        : "https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-header_ef6uoh.png"
    },
  },
  methods: {
    async savePartner() {
      await this.$store.dispatch("partner/createPartner", this.partner)
      if (this.getCreatedPartnerErrors !== null) {
        this.fillErrorReason()
        this.warningModal.show = true
      } else {
        await this.$router.push({ name: "partners" })
      }
    },
    onFileChange(e) {
      this.partner.image = e.secure_url
    },
    onUpdate(e) {
      this.partner.type = e
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
    fillErrorReason() {
      this.warningModal.reason = ""
      for (const [key, value] of Object.entries(this.getCreatedPartnerErrors)) {
        this.warningModal.reason += `<i>${key}</i> : ${value}<br/>`
      }
    },
  },
  async created() {
    await this.$store.dispatch("partner/setPartnersTypes")
  },
}
</script>

<style scoped lang="scss">
.new-partner {
  background-color: white;
  padding-bottom: 55px;
  .new-partner__header {
    background-color: $caareagray;
    padding: 20px;
    h2 {
      font-family: "Effra_semi_bold", sans-serif;
      font-size: 21px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
  .new-partner__form {
    width: 70%;
    padding: 20px;
    margin-bottom: 50px;
    .row {
      margin-bottom: 25px;
    }
    .form-row {
      margin-bottom: 20px;
    }
  }
  .new-partner__button {
    display: grid;
    grid-template-columns: 24% 2fr 1fr;
  }
}
.partners-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 100px;
  margin-bottom: 25px;
  padding: 0 38px;

  &__title {
    font-family: "Effra", sans-serif;
    font-size: 24px;
  }
}
</style>

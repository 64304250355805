import Vue from "vue"
import Vuex from "vuex"
import auth from "@/store/auth"
import config from "@/store/config"
import matrix from "@/store/matrix"
import old_pricing from "@/store/old_pricing"
import product from "@/store/product"
import productLine from "@/store/product_line"
import productLineModal from "@/store/product_line_modal"
import programProductModal from "@/store/program_product_modal"
import programShared from "@/store/program_shared"
import basket from "@/store/basket"
import gui from "@/store/gui"
import vehicle from "@/store/vehicle"
import program from "@/store/program"
import reference from "@/store/reference"
import offer from "@/store/offer"
import programProduct from "@/store/program_product"
import programDocument from "@/store/program_document"
import offerDocument from "@/store/offer_document"
import network from "@/store/network"
import pricing from "@/store/pricing"
import repository from "@/store/repository"
import cloudinary from "@/store/cloudinary"
import partner from "@/store/partner"
import programProductCustomization from "./pages/programProductCustomization"
import users from "@/store/users"
import searchVehicleModal from "@/store/search_vehicle_modal"
import offerContext from "@/store/offer_context"
import eligibilityCriteriaModal from "@/store/eligibility_criteria_modal"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    config,
    matrix,
    old_pricing,
    product,
    productLine,
    productLineModal,
    programProductModal,
    programShared,
    basket,
    gui,
    vehicle,
    program,
    reference,
    offer,
    programProduct,
    programDocument,
    offerDocument,
    network,
    pricing,
    repository,
    cloudinary,
    partner,
    programProductCustomization,
    users,
    searchVehicleModal,
    offerContext,
    eligibilityCriteriaModal,
  },
})

<template>
  <main class="partners-list" data-cy="partners-main-block">
    <div v-if="getPartnersFormattedList.length === 0">
      <h3 class="text-center" data-cy="no-partner">
        {{ $t("partners.no_partner") }}
      </h3>
    </div>
    <div v-else>
      <div
        class="partners-list__row"
        v-for="partner in getPartnersFormattedList"
        :key="partner.id"
      >
        <div class="partners-list__item" v-for="label in labelsList" :key="label">
          <div class="partners-list__item--line1">
            {{ $t(`partners.${label}`) }}
          </div>
          <div
            class="partners-list__item--line2"
            :data-cy="`partner-${partner.id}-${label}`"
          >
            <span>
              {{ partner[label] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "ListPartners",
  data() {
    return {
      labelsList: ["name", "created_by", "created_at", "type"],
    }
  },
  computed: {
    ...mapGetters("partner", ["getPartnersFormattedList"]),
  },
}
</script>

<style scoped lang="scss">
.partners-list {
  background-color: white;
  padding: 0 20px;
  margin-bottom: 50px;

  &__row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    height: 109px;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid grey;
    }
  }

  &__item {
    color: $bleucaarea;

    &:first-child .partners-list__item--line2 {
      text-decoration: underline;
    }

    &--line1 {
      font-size: 11px;
      font-family: "Effra-light", sans-serif;
    }

    &--line2 {
      font-family: "Gilroy SemiBold", sans-serif;
      font-size: 18px;
    }
  }
}
</style>

import { mapGetters } from "vuex"
export default {
  data() {
    return {
      eligibilityCriteriaSelect: ["make", "model", "energy", "engine_code"],
    }
  },
  computed: {
    ...mapGetters("productLine", ["listEligibilityCriteria"]),
    ...mapGetters("program", ["getAgeUnityOptions"]),
  },
  methods: {
    listEligibilityCriteriaFiltered(id) {
      // CSO_1370: energy is temporarily removed and will completely be removed when CsSales will use new cvdb
      const criteriaToRemove = ["max_age_unity", "energy"]
      return this.listEligibilityCriteria(id).filter(
        (c) => !criteriaToRemove.includes(c)
      )
    },
    isEligibilityCriterionSelect(criterion) {
      return this.eligibilityCriteriaSelect.includes(criterion)
    },
    getEligibilityCriterionLabel(criterion) {
      return this.$t("criteria.eligibility." + criterion)
    },
    getMaxAgeEligibilityCriterionLabel(maxAgeUnity) {
      return this.$t(`criteria.eligibility.max_age_${maxAgeUnity}`)
    },
    getEligibilityInputName(criterion, id) {
      return `${id}-eligibility-${criterion}`
    },
    isEligibilityCriterionDisabled(criterion, form) {
      return (
        (criterion === "model" && (!form || this.modelOptionsLoading)) ||
        (criterion === "engine_code" && (!form || this.engineCodeOptionsLoading)) ||
        this.isDisabled
      )
    },
  },
}

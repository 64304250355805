<template>
  <spinner v-if="isLoading" :no-message="true"></spinner>
  <div v-else class="pb-4 w-90 mx-auto" data-cy="network-content">
    <ModalValidate
      v-if="showConfirmNetworkModal && !isUploadInProgress"
      :title="$t('programParameters.feedbackUploadNetworkModal.title')"
      @confirm="onConfirmClick"
      data-cy="confirm-network-modal"
    >
      <template #report>
        <p
          class="modal-network-upload-report p-2 mb-2 font-size-20 font-gilroy"
          data-cy="network-report-users"
        >
          {{ getNbUserImported }}
          {{
            $tc(
              "programParameters.feedbackUploadNetworkModal.nbUsersUpload",
              getNbUserImportedWording
            )
          }}
        </p>
        <p
          class="modal-network-upload-report p-2 mb-2 font-size-20 font-gilroy"
          data-cy="network-report-companies"
        >
          {{ getNbCompanieImported }}
          {{
            $tc(
              "programParameters.feedbackUploadNetworkModal.nbCompaniesUpload",
              getNbCompanieImportedWording
            )
          }}
        </p>
      </template>
    </ModalValidate>
    <div v-if="!hasNetworks">
      <div class="d-flex flex-column align-items-center my-5">
        <spinner v-if="isUploadInProgress" :no-message="true"></spinner>
        <SelectFileButton
          v-else
          input-id="network"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :button-label="$t('button.importNetwork')"
          btn-class="btn-blue-c-700 px-4 py-3 rounded"
          @input-file="onNetworkInputFile"
        ></SelectFileButton>
        <a
          class="d-flex align-items-center mouse-pointer p-2 font-size-24 font-effra-light"
          style="color: #828282"
          data-cy="button-download-example"
          :href="getUrlImportExample"
        >
          <span class="mr-2">{{ $t("button.downloadExampleFile") }}</span>
          <span class="font-size-36 icon-cloud-download mb-1"></span>
        </a>
      </div>
    </div>
    <div v-else>
      <NetworkItem
        v-for="network in listNetworks"
        :key="network.id"
        :network="network"
        @network-selected="onNetworkSelected(network)"
      ></NetworkItem>
      <SelectFileButton
        v-if="hasNetworks"
        class="mt-4"
        input-id="network"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :button-label="$t('button.uploadNetwork')"
        btn-class="btn-blue-c-700 px-3"
        @input-file="onNetworkInputFile"
      ></SelectFileButton>
    </div>
    <ModalFileUploadError
      v-if="hasNetworkUploadErrors"
      name="network"
      :title="$t('programParameters.network.uploadError')"
      :reason="getNetworkUploadErrors"
      :has-import-errors="hasNetworkImportErrors"
      :import-errors-list="listNetworkImportErrors"
      @close="onCloseModal"
    ></ModalFileUploadError>
  </div>
</template>

<script>
import SelectFileButton from "@/components/button/SelectFileButton"
import ModalFileUploadError from "@/components/programParameters/modal/ModalFileUploadError"
import { mapGetters } from "vuex"
import NetworkItem from "@/components/programParameters/NetworkItem"
import ModalValidate from "@/components/modal/ModalValidate"

export default {
  name: "ProgramNetwork",
  components: {
    SelectFileButton,
    ModalFileUploadError,
    NetworkItem,
    ModalValidate,
  },
  data() {
    return {
      isUploadInProgress: false,
      isLoading: false,
      showConfirmNetworkModal: false,
    }
  },
  computed: {
    ...mapGetters("program", ["getProgramPartnerId"]),
    ...mapGetters("network", [
      "listNetworks",
      "hasNetworks",
      "getNetworkById",
      "hasNetworkUploadErrors",
      "getNetworkUploadErrors",
      "listNetworkImportErrors",
      "hasNetworkImportErrors",
    ]),

    getLastProgramNetwork() {
      return this.listNetworks[this.listNetworks.length - 1]
    },
    getNbUserImported() {
      return this.getLastProgramNetwork.nb_users
    },
    getNbUserImportedWording() {
      return this.getNbUserImported === 1 ? 1 : 2
    },
    getNbCompanieImported() {
      return this.getLastProgramNetwork.companies.length
    },
    getNbCompanieImportedWording() {
      return this.getNbCompanieImported === 1 ? 1 : 2
    },
    getUrlImportExample() {
      return process.env.VUE_APP_ENDPOINT.replace(
        process.env.VUE_APP_ENDPOINT.includes("/api/") ? "/api/" : "/api",
        "/static/imports/examples/network_import_example.xlsx"
      )
    },
  },
  methods: {
    onConfirmClick() {
      this.showConfirmNetworkModal = false
    },
    async onNetworkInputFile(file) {
      this.isUploadInProgress = true
      await this.$store.dispatch("network/upload", file)
      this.isUploadInProgress = false
      if (!this.hasNetworkUploadErrors) {
        await this.$store.dispatch("network/getNetworks", this.getProgramPartnerId)
        this.showConfirmNetworkModal = true
      }
    },
    onCloseModal() {
      this.$store.dispatch("network/resetNetworkUploadErrors")
    },
    onNetworkSelected(network) {
      this.$emit("network-selected", network)
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("network/getNetworks", this.getProgramPartnerId)
    this.isLoading = false
  },
}
</script>
<style scoped lang="scss">
.modal-network-upload-report {
  background-color: $caarealightgray;
}
</style>

// STATES (snake_case)

const state = {
  id_program_product_commissions_and_taxes_open: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_COMMISSIONS_AND_TAXES_MODAL_STATE(state, programProductId) {
    state.id_program_product_commissions_and_taxes_open = programProductId
  },
  DELETE_STORE(state) {
    state.id_program_product_commissions_and_taxes_open = null
  },
}

// ACTIONS (camelCase)
const actions = {
  handleModalOpening({ commit }, payload) {
    commit(`SET_${payload.modalName}_MODAL_STATE`, payload.programProductId)
  },
  deleteStore({ commit }) {
    commit("DELETE_STORE")
  },
}

// GETTERS (camelCase)
const getters = {
  isCommissionsAndTaxesModalOpen: (state) => (id) =>
    state.id_program_product_commissions_and_taxes_open &&
    state.id_program_product_commissions_and_taxes_open === id,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

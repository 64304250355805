<template>
  <div>
    <div tabindex="0"></div>
    <div
      id="modalMemo"
      class="modal fade"
      :class="{ show: show }"
      @click="onClose"
      tabindex="-1"
      role="dialog"
      data-backdrop="false"
      @keyup.esc="onClose"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="getModalSizeClass"
        role="document"
        @click.stop
      >
        <div class="modal-content">
          <div class="modal-header">
            <div class="d-flex flex-column">
              <p class="modal-title" data-cy="modal-title">{{ title }}</p>
              <p class="mb-0" data-cy="modal-sub-title">{{ subTitle }}</p>
            </div>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <slot name="body">
                <p>Modal body text goes here.</p>
              </slot>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              data-cy="memo-modal-close"
              @click="onClose"
            >
              {{ $t("modal.close") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :title="getTitle"
              :disabled="!hasFiltersSet"
              data-dismiss="modal"
              data-cy="memo-modal-atc"
              @click="onAddToCart"
            >
              {{ $t("modal.add_to_cart") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: show }"></div>
    <div tabindex="0"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ModalMemo",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    showOnlyConfirmButton: {
      type: Boolean,
      default: false,
    },
    modalSize: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapGetters("product", ["getProgramTags"]),
    getModalSizeClass() {
      if (this.modalSize === "small") return "modal-sm"
      if (this.modalSize === "large") return "modal-lg"
      if (this.modalSize === "extraLarge") return "modal-xl"
      return null
    },
    hasFiltersSet() {
      return this.getProgramTags.vehicle && this.getProgramTags.engine
    },
    getTitle() {
      return !this.hasFiltersSet ? this.$t("products.item_disable_title") : ""
    },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
    onAddToCart() {
      this.$emit("add-to-cart")
    },
  },
  async mounted() {
    const el = document.body
    el.classList.add("modal-open")
    el.style["padding-right"] = "15px"
    setTimeout(() => (this.show = true), 50)
  },
  destroyed() {
    const el = document.body
    el.classList.remove("modal-open")
    el.style["padding-right"] = null
  },
}
</script>

<style scoped lang="scss">
.modal {
  display: block;
}
.modal-icon {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.modal-header {
  background-color: #d9d9e5;
  color: $primary;
  border-left: 5px solid $primary;
  .modal-title {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.modal-footer {
  border: none;
}
</style>

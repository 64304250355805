import AbstractService from "@/services/AbstractService"

class NumberUtils extends AbstractService {
  /**
   * Returns true if str param is a number.
   * @param str
   * @returns {boolean}
   */
  isNumber(str) {
    return !isNaN(str)
  }
}

let NumberService = new NumberUtils()
export default NumberService

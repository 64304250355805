<template>
  <div>
    <div v-if="canDisplayProgram" data-cy="program-content">
      <div>
        <ProgramState :program="this.getProgram"></ProgramState>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProgramState from "@/components/presales/components/ProgramState"

export default {
  name: "Program",
  components: { ProgramState },
  computed: {
    ...mapGetters("program", ["getProgram"]),
    canDisplayProgram() {
      return this.getProgram !== null
    },
  },
}
</script>

<style scoped lang="scss"></style>

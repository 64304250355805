<template>
  <div>
    <div class="offer-sheet" :data-cy="`offer-sheet-${offerIndex + 1}`">
      <div class="offer-sheet__image">
        <img
          :src="imageUrl"
          alt="Offer image"
          :data-cy="`offer-image-${offerIndex + 1}`"
        />
      </div>
      <div class="offer-sheet__details">
        <div
          class="offer-sheet__description"
          :data-cy="`offer-description-${offerIndex + 1}`"
        >
          {{ offerContent.description }}
        </div>
        <div
          class="offer-sheet__name font-size-25 text-uppercase font-gilroy-regular"
          :data-cy="`offer-name-${offerIndex + 1}`"
        >
          {{ offerContent.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageMixin from "@/mixins/ImageMixin"
import { mapGetters } from "vuex"

export default {
  name: "OfferSheet",
  mixins: [ImageMixin],
  props: {
    offer: { type: Object, required: true },
    offerIndex: { type: Number, required: true },
  },
  computed: {
    ...mapGetters("offer", ["getB2COfferContent"]),
    offerContent() {
      return this.getB2COfferContent(this.offer.id)
    },
    imageUrl() {
      return this.offerContent.image
        ? this.fitToSizeWithAspectRatioRetained(this.offerContent.image, 130, 110)
        : "https://via.placeholder.com/130x110"
    },
  },
}
</script>

<style scoped lang="scss">
.offer-sheet {
  display: grid;
  grid-template-columns: 130px auto;
  border-left: 4px solid $body-color;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 1px 3px rgba(0, 0, 0, 0.2);
  background: white;
}

.offer-sheet__details {
  padding-left: 1rem;
}

.offer-sheet__description {
  margin: 4px 0 16px;
  font-family: "Effra-light", sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
}

.offer-sheet__name {
  margin-bottom: 8px;
  font-size: 1.5625rem;
  line-height: 30px;
  text-transform: uppercase;
}

.offer-sheet__image img {
  padding: 0.5rem;
  width: 130px;
  height: 110px;
  overflow: hidden;
}
</style>

export default {
  computed: {
    activeStep() {
      return this.steps[this.activeStepName]
    },
    mappingStepUrlName() {
      return Object.entries(this.mappingUrlNameStep).reduce((acc, [urlName, step]) => {
        acc[step] = urlName
        return acc
      }, {})
    },
  },
  methods: {
    isStepClickable(stepName) {
      return this.isStepComplete(stepName) || this.isNextStepToComplete(stepName)
    },
    onStepClick(stepName) {
      if (this.isStepClickable(stepName)) {
        this.changeStep(stepName)
      }
    },
    async changeStep(stepName) {
      this.activeStepName = stepName
      await this.$router.push(this.getStepUrlRoute(stepName))
    },
    isStepActive(stepName) {
      return this.activeStepName === stepName
    },
    isStepComplete(stepName) {
      return this.listProgramSettingStatusComplete.includes(
        this.steps[stepName].status.end
      )
    },
    isNextStepToComplete(stepName) {
      const previousStepName = this.steps[stepName].previousStep
      return (
        previousStepName === null ||
        !this.steps.hasOwnProperty(previousStepName) ||
        this.isStepComplete(previousStepName)
      )
    },
    getStepUrlRoute(stepName) {
      return {
        name: this.mappingStepUrlName[stepName],
        params: { ...this.$route.params },
      }
    },
  },
  created() {
    // Redirect if current step is not complete or next to complete
    if (
      !this.isStepComplete(this.activeStepName) &&
      !this.isNextStepToComplete(this.activeStepName)
    ) {
      this.pushI18nRoute({ name: "403" })
    }
  },
}

export const state = {
  program_product_id: null,
  product_lines_ids: [],
  custom_program_criteria_list_by_product_line_id: {},
  program_criteria_values_by_product_line_id: {},
  user_selectable_values_by_product_line_id: {},
  payment_frequency_value_by_product_line_id: {},
  beneficiary_criteria_values_by_product_line_id: {},
  custom_eligibility_criteria_list_by_product_line_id: {},
  eligibility_criteria_values_by_product_line_id: {},
  vehicle_by_product_line_id: {},
  commercial_criteria_values_by_product_line_id: {},
  is_program_product_customization_loading: false,
  is_pricing_in_progress_by_product_line_id: {},
  is_vehicle_update_within_program_in_progress: false,
}

<template>
  <ModalBootstrap
    id-modal="modalPaymentFrequency"
    class="criteria"
    :title="$t('programs.payment_frequency_modal.title')"
    modal-size="extraLarge"
    @close="closeModal"
    data-cy="presales-payment-frequency-modal"
  >
    <template #body class="mx-3">
      <div class="modal-field">
        <FormRowSelect
          :select-options="getPaymentFrequencyOptionsTranslated(productLineId)"
          :label="$t('programs.payment_frequency_modal.frequency_prime_label')"
          :label-class="['h5', 'mb-0', 'text-left', 'col-sm-8', 'align-self-center']"
          :control-class="['col-sm-4']"
          name="frequency_prime"
          :selected-option.sync="newSelectedOption"
          label-select-attr="label"
        ></FormRowSelect>
      </div>
      <div class="modal-field__button">
        <SpinnerButton
          class="btn btn-primary"
          data-cy="form-validate"
          :is-loading="isLoading"
          @click="onSubmitButton"
        ></SpinnerButton>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"
import ProgramProductCustomizationService from "../../../services/pages/ProgramProductCustomizationService"
import SpinnerButton from "@/components/button/SpinnerButton"
import OfferService from "../../../services/business/OfferService"

export default {
  name: "ModalPaymentFrequency",
  components: { ModalBootstrap, SpinnerButton },
  props: {
    productLineId: { type: Number, required: true },
  },
  data() {
    return {
      currentSelectedOptionInStore: null,
      newSelectedOption: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("productLine", ["getPaymentFrequencyOptionsTranslated"]),
    ...mapGetters("programProductCustomization", ["getPaymentFrequencyValue"]),
  },
  methods: {
    async onSubmitButton() {
      const hasSelectedOptionChanged =
        this.newSelectedOption !== this.currentSelectedOptionInStore
      if (hasSelectedOptionChanged) {
        await this.managePaymentFrequencyChange()
      }
      this.closeModal()
    },
    async managePaymentFrequencyChange() {
      this.isLoading = true
      await ProgramProductCustomizationService.updatePaymentFrequency(
        this.productLineId,
        this.newSelectedOption
      )
      const mustModalWarningBeOpen =
        await OfferService.hasAtLeastOneCustomFlatCommission(this.$route.params.offerId)
      if (mustModalWarningBeOpen) {
        await this.$store.dispatch(
          "productLineModal/setIsPaymentFrequencyModalWarningOpen",
          true
        )
      }
      this.isLoading = false
    },
    closeModal() {
      this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName: "FREQUENCY",
        productLineId: null,
      })
    },
  },

  created() {
    this.currentSelectedOptionInStore = this.getPaymentFrequencyValue(
      this.productLineId
    )
    this.newSelectedOption = this.currentSelectedOptionInStore
  },
}
</script>

<style lang="scss" scoped>
.modal-field {
  margin: 40px 20px 60px 20px;
  padding: 5px 15px;
  background-color: $caarealightgray;
  border: {
    top: 1px solid $bleuc100;
    bottom: 1px solid $bleuc100;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin: 40px;
  }
}
</style>

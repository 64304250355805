<template>
  <table class="table table-sm table-striped" data-cy="pricing-range-table">
    <thead>
      <tr>
        <th scope="col" width="5%">#</th>
        <th class="text-uppercase" scope="col" :width="colWidth">
          {{ $t("pricingRange.make") }}
        </th>
        <th class="text-uppercase" scope="col" :width="colWidth">
          {{ $t("pricingRange.model") }}
        </th>
        <th class="text-uppercase" scope="col" :width="colWidth">
          {{ $t("pricingRange.finition") }}
        </th>
        <th class="text-uppercase" scope="col" :width="colWidth">
          {{ $t("pricingRange.year") }}
        </th>
        <th
          v-for="product in products"
          :key="product.id"
          class="text-uppercase"
          scope="col"
          :data-cy="`program-product-${product.id}-code`"
          :width="colWidth"
        >
          {{ $t(`pricingRange.products.${product.code}`) }}
          <span class="font-size-11">({{ $t("pricingRange.after_tax") }})</span>
        </th>
        <th class="text-uppercase" scope="col" v-if="isTotalVisible" :width="colWidth">
          {{ $t("pricingRange.total") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(vehicle, index) in listVehicles" :key="vehicle.id">
        <td>{{ getIndex(index) }}</td>
        <td :data-cy="`vehicle-${index + 1}-make`">{{ vehicle.local_make }}</td>
        <td>{{ vehicle.local_model }}</td>
        <td>{{ vehicle.local_trim_level[0] }}</td>
        <td>{{ vehicle.model_year }}</td>
        <td
          v-for="product in products"
          :key="product.id"
          :data-cy="`program-product-${product.id}-vehicle-${index + 1}-price`"
        >
          {{ getFormattedProductLinePriceByVehicle(product.product_lines, vehicle) }}
        </td>
        <td v-if="isTotalVisible" :data-cy="`total-price-vehicle-${index + 1}`">
          {{ getFormattedTotalPriceByVehicle(vehicle) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex"
import NumberService from "@/services/technical/NumberService"
import PricingService from "../../services/business/PricingService"

export default {
  name: "PricingRangeTable",
  props: {
    listVehicles: { type: Array, required: true },
    products: { type: Array, required: true },
    isProgramProductPricingRange: { type: Boolean, required: true },
    isPaymentFrequencyMonthly: { type: Boolean, required: true },
  },
  data() {
    return {
      noPrice: "--",
    }
  },
  computed: {
    ...mapGetters("productLine", ["listPricingRangeVehicles", "getCurrentPage"]),
    isTotalVisible() {
      return this.products.length > 1
    },
    colWidth() {
      const nbProducts = this.products.length === 1 ? 1 : this.products.length + 1
      return 100 / (nbProducts + 4) + "%"
    },
  },
  methods: {
    getIndex(index) {
      return (this.getCurrentPage - 1) * 10 + index + 1
    },
    getProductLinePriceByVehicle(productLineId, vehicle) {
      let price = null
      if (vehicle.product_line_price.id.includes(productLineId)) {
        const index = vehicle.product_line_price.id.indexOf(productLineId)
        // TODO make backend send None instead of "--" to avoid this weird check
        price = NumberService.isNumber(vehicle.product_line_price.price[index].price)
          ? vehicle.product_line_price.price[index]
          : null
      }
      return price
    },
    getTotalPriceByVehicle(vehicle) {
      return this.products.reduce(
        (totalPrice, product) => {
          const price = this.getProductLinePriceByVehicle(
            product.product_lines[0].id,
            vehicle
          )
          if (price) {
            totalPrice.price += price.price
            totalPrice.currency_code = price.currency_code
          }
          return totalPrice
        },
        { price: 0, currency_code: "" }
      )
    },
    getFormattedPrice(price, currency_code) {
      if (price) {
        return PricingService.getFormattedPriceToDisplay(
          price,
          this.isPaymentFrequencyMonthly,
          currency_code
        )
      }
      return this.noPrice
    },
    getFormattedProductLinePriceByVehicle(productLines, vehicle) {
      let productLineId = null
      productLines.forEach((productLine) => {
        if (vehicle.product_line_price.id.includes(productLine.id)) {
          productLineId = productLine.id
        }
      })
      const price = this.getProductLinePriceByVehicle(productLineId, vehicle)
      if (price) {
        return this.getFormattedPrice(price.price, price.currency_code)
      }
      return this.getFormattedPrice(price)
    },
    getFormattedTotalPriceByVehicle(vehicle) {
      const totalPrice = this.getTotalPriceByVehicle(vehicle)
      return this.getFormattedPrice(totalPrice.price, totalPrice.currency_code)
    },
  },
}
</script>

<style scoped lang="scss">
.table {
  th {
    height: 40px;
    vertical-align: middle;
    line-height: 16px;
  }
}
</style>

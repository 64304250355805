<template>
  <ModalBootstrap
    id-modal="modalCaareaAndPartnerTaxes"
    class="criteria"
    :title="$t('commercial.commissions_and_tax_modal.title')"
    modal-size="extraLarge"
    @close="closeModal"
    data-cy="commissions-and-taxes-modal"
  >
    <template #body class="mx-3">
      <div class="mb-5">
        <CommissionsAndTaxesInfo
          v-for="(tax, index) in taxes"
          :key="`${index}-${taxType}-${index}`"
          :content="tax"
          :index="index"
          :content-type="taxType"
          :product-line-id="productLineId"
          :errors="nameErrors"
          @update-caarea-fees="onUpdateCaareaFee($event, index)"
          @update-other-fees="onUpdateOtherFee($event, index)"
          @remove-caarea-fees="onRemoveCaareaFee(index)"
          @remove-other-fees="onRemoveOtherFee(index)"
        ></CommissionsAndTaxesInfo>
        <ActionButton
          class="mt-2 d-flex align-items-center justify-content-end"
          :button-text="$t('programs.commissions_and_taxes_modal.add_commission_btn')"
          btn-class="btn btn-blue-c-100 font-roboto-medium"
          button-data-cy="add-commission-btn"
          @click-action="onAddTaxClick"
        ></ActionButton>
      </div>
      <ActionButton
        class="mt-2 d-flex align-items-center justify-content-end"
        :button-text="$t('programs.commissions_and_taxes_modal.validate')"
        btn-class="btn btn-primary font-roboto-medium section__validate"
        button-data-cy="commercial-validate-button"
        @click-action="onSubmitButton"
      ></ActionButton>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"
import ActionButton from "@/components/button/ActionButton"
import CommissionsAndTaxesInfo from "@/components/presales/modal/components/CommissionsAndTaxesInfo"

export default {
  name: "ModalCaareaAndPartnerTaxes",
  components: { ModalBootstrap, ActionButton, CommissionsAndTaxesInfo },
  props: {
    productLineId: { type: Number, required: true },
    taxType: { type: String, required: true },
  },
  computed: {
    ...mapGetters("productLine", ["getConfig", "getUpdateErrors"]),
    nameErrors() {
      const errors = this.getUpdateErrors(this.productLineId)
      if (
        errors.hasOwnProperty("config") &&
        errors.config.hasOwnProperty("commercial") &&
        errors.config.commercial.hasOwnProperty("values")
      ) {
        const commercialValues = errors.config.commercial.values
        let updateErrors = {}
        for (const [commercialTypeKey, commercialTypeValue] of Object.entries(
          commercialValues
        )) {
          let newErrors = {}
          for (const [commercialErrorKey, commercialErrorValue] of Object.entries(
            commercialTypeValue
          )) {
            for (const [errorKey, errorValue] of Object.entries(commercialErrorValue)) {
              newErrors[
                `${commercialTypeKey}-${parseInt(commercialErrorKey) + 1}-${errorKey}`
              ] = errorValue
            }
          }
          updateErrors = { ...updateErrors, ...newErrors }
        }
        return updateErrors
      }
      return {}
    },
    hasUpdateFailed() {
      return Object.keys(this.nameErrors).length > 0
    },
  },
  data() {
    return {
      taxes: null,
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch("productLineModal/deleteStore")
      this.$store.dispatch("productLine/resetUpdateErrors", this.productLineId)
      this.$emit("close")
    },
    onAddTaxClick() {
      this.taxes.push({
        name: " ",
        type: "percent",
        value: 0,
      })
    },
    onRemoveCaareaFee(index) {
      this.taxes.splice(index, 1)
      this.$store.dispatch("productLineModal/updateCaareaFees", this.taxes)
    },
    onRemoveOtherFee(index) {
      this.taxes.splice(index, 1)
      this.$store.dispatch("productLineModal/updateOtherFees", this.taxes)
    },
    onUpdateCaareaFee(tax, index) {
      this.taxes[index] = tax
      this.$store.dispatch("productLineModal/updateCaareaFees", this.taxes)
    },
    onUpdateOtherFee(tax, index) {
      this.taxes[index] = tax
      this.$store.dispatch("productLineModal/updateOtherFees", this.taxes)
    },
    async onSubmitButton() {
      await this.$store.dispatch(
        "productLineModal/updateProductLine",
        this.productLineId
      )
      if (!this.hasUpdateFailed) {
        await this.$store.dispatch("productLineModal/deleteStore")
        this.$emit("close")
      }
    },
  },
  created() {
    this.$store.dispatch("productLineModal/initTaxesAndFees", this.productLineId)
    const config = this.getConfig(this.productLineId)
    this.taxes = config["commercial"].values[this.taxType]
  },
}
</script>

<style lang="scss" scoped>
.section__header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "title";
  align-items: center;
  border-top: 1px solid $bleuc100;
  border-bottom: 1px solid $bleuc100;
  background-color: $caarealightgray;
  padding: 0.5rem 1rem;
  label {
    margin-bottom: 0;
  }
}
.section__header__title {
  grid-area: title;
  justify-self: start;
  font-size: 20px;
}
.section__validate {
  width: 0.4rem !important;
}
</style>

<template>
  <div class="commission__taxes">
    <FormInput
      :name="`${contentType}-${index + 1}-name`"
      :id="`${contentType}-${index + 1}-name`"
      input-class="commission__taxes__name"
      :disabled="isDefaultTax"
      v-model="form.content.name"
      :errors="errors"
      @input="onContentUpdate"
    ></FormInput>
    <div class="commission__taxes__type">
      <FormRowSelect
        v-if="!isDefaultTax"
        :label-inline="false"
        :selected-option.sync="form.content.type"
        :select-options="choices"
        :name="`${contentType}-${index + 1}-choice`"
        label-select-attr="key"
        :errors="{}"
        :disabled="isTypeChoiceEnforced"
        @update:selected-option="onContentUpdate"
      ></FormRowSelect>
    </div>
    <FormInput
      :name="`${contentType}-${index + 1}-value`"
      :id="`${contentType}-${index + 1}-value`"
      type="number"
      input-class="commission__taxes__value"
      :disabled="isDefaultTax"
      v-model="form.content.value"
      :errors="errors"
      @input="onContentUpdate"
    ></FormInput>
    <span :data-cy="`${contentType}-${index + 1}-unit`" class="commission__taxes__unit">
      {{ taxCommissionUnit }}
    </span>
    <div
      v-if="!isDefaultTax"
      class="commission__taxes__trash-icon"
      :data-cy="`${contentType}-${index + 1}-icon-trash2`"
      @click="onDeleteClick"
    >
      <span class="icon-trash2"></span>
    </div>
  </div>
</template>

<script>
import CurrencyService from "@/services/business/CurrencyService"

export default {
  name: "CommissionsAndTaxesInfo",
  props: {
    content: { type: Object, required: true },
    index: { type: Number, required: true },
    contentType: { type: String, required: true },
    productLineId: { type: Number, required: true },
    errors: { type: Object },
  },
  watch: {
    // Update form as soon as prop content changes (ex: case of a deletion in the commissions/taxes array)
    content(value) {
      this.form.content = JSON.parse(JSON.stringify(value))
    },
  },
  data() {
    return {
      form: { content: {} },
    }
  },
  computed: {
    isATax() {
      return this.contentType === "taxes"
    },
    isCaareaFees() {
      return this.contentType === "caarea_fees"
    },
    isDefaultTax() {
      return this.isATax && !this.content.is_editable
    },
    taxCommissionUnit() {
      return this.form.content.type === "flat"
        ? CurrencyService.getCurrencySymbol()
        : "%"
    },
    choices() {
      const percentChoice = {
        percent: this.$t("programs.commissions_and_taxes_modal.percent_select"),
      }
      return this.isATax || this.isCaareaFees
        ? percentChoice
        : {
            ...percentChoice,
            flat: this.$t("programs.commissions_and_taxes_modal.flat_select"),
          }
    },
    isTypeChoiceEnforced() {
      return Object.keys(this.choices).length === 1
    },
  },
  methods: {
    onContentUpdate() {
      switch (this.contentType) {
        case "taxes":
        case "commissions":
          this.$emit(`${this.contentType}-update`, this.form.content)
          break
        case "caarea_fees":
          this.$emit("update-caarea-fees", this.form.content)
          break
        case "other_fees":
          this.$emit("update-other-fees", this.form.content)
          break
        default:
          break
      }
    },
    onDeleteClick() {
      switch (this.contentType) {
        case "taxes":
        case "commissions":
          this.$emit(`${this.contentType}-deletion`)
          break
        case "caarea_fees":
          this.$emit("remove-caarea-fees", this.form.content)
          break
        case "other_fees":
          this.$emit("remove-other-fees", this.form.content)
          break
        default:
          break
      }
    },
    updateCommissionOrFee(contentType) {
      this.$store.dispatch("productLineModal/" + contentType, {
        id: this.index,
        value: this.form.content,
      })
    },
  },
  created() {
    this.form.content = JSON.parse(JSON.stringify(this.content))
  },
}
</script>

<style lang="scss">
.commission__taxes {
  display: grid;
  grid-template-columns: repeat(2, 4fr) auto auto 0.5fr;
  grid-template-areas: "name type  value";
  grid-column-gap: 0.5rem;
  align-items: center;
  border-bottom: 1px solid $bleuc100;
  padding: 0.5rem 1rem;
}

.commission__taxes__name {
  grid-area: name;
  max-width: 15rem;
  border: 1px solid $bleuc100;
  border-radius: 0.1rem;
  padding-left: 0.8rem;
  height: 38px !important;
}

.commission__taxes__type {
  grid-area: type;
  justify-self: end;
}

.commission__taxes__value {
  grid-area: value;
  justify-self: end;
  max-width: 6rem;
  text-align: center;
  border: 1px solid $bleuc100;
  border-radius: 0.1rem;
  height: 38px !important;
}

.commission__taxes__trash-icon {
  justify-self: end;
  font-size: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.commission__taxes__unit {
  font-size: 1.2rem;
  line-height: 1.2rem;
}
</style>

<template>
  <div>
    <ModalEligibilityCriteria
      v-if="isEligibilityModalOpen(productLineId)"
      :product-line-id="productLineId"
      :disabled="disabled"
    ></ModalEligibilityCriteria>
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <div v-else class="product-line-edition">
      <ProductLineConfigHeader
        v-if="isFirstProductLine"
        class="product-line-edition__header"
        icon="icon-car"
        :title="$t('programs.form.vehicleFilter.title')"
        :sub-title="$t('programs.form.vehicleFilter.help')"
      />
      <div class="product-line-edition__criteria">
        <div v-for="criterion in criteriaToDisplay" :key="criterion">
          <ProductLineConfigCriterion
            class="product-line-edition__criterion"
            v-if="criterion === 'make'"
            :name="getEligibilityCriterionLabel(criterion).toUpperCase()"
            :value="getMakeCriterionValueToDisplay(productLineId)"
            :data-cy="`${productLineId}-${criterion}`"
          ></ProductLineConfigCriterion>
          <ProductLineConfigCriterion
            class="product-line-edition__criterion"
            v-else-if="criterion === 'model'"
            :name="getEligibilityCriterionLabel(criterion).toUpperCase()"
            :value="getModelCriterionValueToDisplay(productLineId)"
            :data-cy="`${productLineId}-${criterion}`"
          ></ProductLineConfigCriterion>
          <ProductLineConfigCriterion
            class="product-line-edition__criterion"
            v-else-if="criterion === 'engine_code'"
            :name="getEligibilityCriterionLabel(criterion).toUpperCase()"
            :value="getEngineCodeCriterionValueToDisplay(productLineId)"
            :data-cy="`${productLineId}-${criterion}`"
          ></ProductLineConfigCriterion>
          <ProductLineConfigCriterion
            v-else-if="criterion === 'max_age'"
            class="product-line-edition__criterion"
            :name="
              getMaxAgeEligibilityCriterionLabel(
                getEligibilityCriterionValue(productLineId, 'max_age_unity')
              ).toUpperCase()
            "
            :value="getMaxAgeCriterionValueToDisplay(productLineId)"
            :data-cy="`${productLineId}-${criterion}`"
          ></ProductLineConfigCriterion>
          <ProductLineConfigCriterion
            v-else
            class="product-line-edition__criterion"
            :name="getEligibilityCriterionLabel(criterion).toUpperCase()"
            :value="getEligibilityCriterionValueToDisplay(productLineId, criterion)"
            :data-cy="`${productLineId}-${criterion}`"
          ></ProductLineConfigCriterion>
        </div>
      </div>
      <div class="product-line-edition__footer">
        <button
          v-if="!disabled"
          class="btn btn-light-gray btn-block"
          @click="onShowEligibilityCriteriaModal"
          :data-cy="`show-vehicle-filter-modal-${productLineId}`"
          data-toggle="modal"
          data-target="#modalEligibilityCriteria"
        >
          {{ $t("button.modify_vehicles") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProductLineConfigHeader from "@/components/presales/components/ProductLineConfigHeader"
import ProductLineConfigCriterion from "@/components/presales/components/ProductLineConfigCriterion"
import ModalEligibilityCriteria from "@/components/presales/modal/ModalEligibilityCriteria"
import FormProductLineMixin from "@/components/presales/mixins/FormProductLineMixin"
import ArrayService from "../../../services/technical/ArrayService"
import EligibilityCriteriaMixin from "@/components/presales/mixins/EligibilityCriteriaMixin"

export default {
  name: "FormProductLineEligibility",
  components: {
    ProductLineConfigHeader,
    ProductLineConfigCriterion,
    ModalEligibilityCriteria,
  },
  mixins: [FormProductLineMixin, EligibilityCriteriaMixin],
  props: {
    productLineId: { type: [String, Number], required: true },
    disabled: { type: Boolean, default: false },
    index: Number,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("productLine", ["listMandatoryEligibilityCriteria"]),
    ...mapGetters("productLineModal", ["isEligibilityModalOpen"]),
    ...mapGetters("programProductCustomization", [
      "getCriterionValueToDisplay",
      "listCustomEligibilityCriteria",
      "getMakeCriterionValueToDisplay",
      "getModelCriterionValueToDisplay",
      "getEngineCodeCriterionValueToDisplay",
      "getMaxAgeCriterionValueToDisplay",
      "getEligibilityCriterionValueToDisplay",
      "getEligibilityCriterionValue",
    ]),
    ...mapGetters("program", ["getProgram"]),
    criteriaToDisplay() {
      // No need to display max_age_unity, its value is displayed with max_age
      const criteriaToRemove = ["max_age_unity"]
      // List of criteria to display is a concatenation between custom and mandatory criteria
      // ("custom" are criteria with value different from default value,
      // "mandatory" are criteria with value different from init value)
      let criteriaToDisplay = ArrayService.concatWithoutDuplicates(
        this.listMandatoryEligibilityCriteria(this.productLineId),
        this.listCustomEligibilityCriteria(this.productLineId)
      )
      return criteriaToDisplay.filter((c) => !criteriaToRemove.includes(c))
    },
  },
  methods: {
    onShowEligibilityCriteriaModal() {
      return this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName: "ELIGIBILITY",
        productLineId: this.productLineId,
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div class="container-fluid frieze h-100 pt-5 background-caarea">
    <div class="row justify-content-center">
      <h1 class="text-white absolute welcome" data-cy="welcome">
        {{ $t("home.welcome") }}
      </h1>
      <h2 class="text-white absolute caarea-space" data-cy="caarea-space">
        {{ $t("home.caareaSpace") }}
      </h2>
    </div>
    <transition appear name="slide">
      <div
        id="overlay"
        data-cy="cookies-overlay"
        class="text-white"
        v-if="!user.profile.accepted_cookies"
      >
        <h1 class="pl-4 pt-3" data-cy="cookies-title">
          {{ $t("home.cookies.title") }}
        </h1>
        <div class="pt-2 px-5">
          <p class="mr-3" data-cy="cookies-description">
            {{ $t("home.cookies.description") }}
          </p>
          <div class="row">
            <div class="col-10">
              <p data-cy="cookies-description-2">
                {{ $t("home.cookies.description2") }}
              </p>
            </div>
            <div class="col-2 pt-2 pr-5 text-right">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
                @click="onClickAcceptCookies"
                data-cy="accept-cookies"
              >
                {{ $t("button.accept") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Home",
  data() {
    return {
      user: null,
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser", "getUserLang"]),
    ...mapGetters("config", ["getCurrentLang"]),
  },
  created() {
    this.user = this.getCurrentUser
    this.$store.dispatch("config/setUserLang")
    const to = this.$router.resolve({ params: { lang: this.getUserLang } })
    if (this.$route.params.lang !== to.location.params.lang) {
      this.$router.push(to.location)
    }
  },
  methods: {
    async onClickAcceptCookies() {
      await this.$store.dispatch("auth/acceptCookies", this.user.id)
      this.user = this.getCurrentUser
    },
  },
}
</script>

<style lang="scss" scoped>
.background-caarea {
  background-image: url("https://res.cloudinary.com/caarea/image/upload/v1617955698/cs/assets/splash-screen-caarea_i7zhaa.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.absolute {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
}
.welcome {
  top: 40%;
  font-weight: normal;
  font-size: 65px;
}
.caarea-space {
  top: 63%;
  font-weight: normal;
}
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 102, 94, 0.7);
  z-index: 2;
}
.slide-enter-active {
  transition: all 1.2s ease-in-out;
}
.slide-leave-active {
  transition: all 1.2s ease-out;
}
</style>

<template>
  <div class="mainlayout">
    <div class="mainlayout-header">
      <top-bar-sharing></top-bar-sharing>
    </div>
    <div
      v-if="!isFullLayout"
      :style="layoutBgColor"
      class="mainlayout-content container-fluid frieze h-100"
    >
      <ModalSharingStatus
        v-if="hasSharingStatusModalToBeDisplayed"
      ></ModalSharingStatus>
      <transition v-else name="fade" mode="out-in">
        <router-view class="h-100" :key="$route.fullPath"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import TopBarSharing from "@/layouts/components/TopBarSharing"
import ModalSharingStatus from "@/components/presales/modal/ModalSharingStatus"
import LayoutMixin from "../mixins/LayoutMixin"
import { mapGetters } from "vuex"

export default {
  name: "LayoutSharing",
  components: { TopBarSharing, ModalSharingStatus },
  mixins: [LayoutMixin],
  computed: {
    ...mapGetters("gui", ["hasSharingStatusModalToBeDisplayed"]),
  },
}
</script>

<template>
  <div>
    <modal-confirmation
      v-if="showConfirmationModal"
      :title="titleModalConfirmation"
      :message="messageModalConfirmation"
      @close="onCloseConfirmationModal"
      @confirm="onConfirmButtonClick"
      modal-size="large"
    ></modal-confirmation>
    <div class="users-filters">
      <div class="filters-title" @click="showFilters = !showFilters">
        <div>{{ $t("users.filter_users") }}</div>
        <i :class="[showFilters ? 'icon-chevron-up' : 'icon-chevron-down']"></i>
      </div>
      <div v-if="showFilters" class="filters-form">
        <FormRowInput
          :label-inline="false"
          :label="$t('users.label.name')"
          name="name"
          v-model="filters.name"
        />
        <FormRowInput
          :label-inline="false"
          :label="$t('users.label.email')"
          name="email"
          v-model="filters.email"
        />
        <FormRowInput
          :label-inline="false"
          :label="$t('users.label.company')"
          name="company"
          v-model="filters.company"
        />
        <FormRowInput
          :label-inline="false"
          :label="$t('users.label.partner')"
          name="partner"
          v-model="filters.partner"
        />
        <FormRowSelect
          :label-inline="false"
          :label="$t('users.label.status')"
          name="status"
          :placeholder="$t('users.all')"
          label-select-attr=""
          :select-options="getUsersStatus"
          :selected-option.sync="filters.status"
        />
        <FormRowSelect
          :label-inline="false"
          :label="$t('users.label.role')"
          name="role"
          :placeholder="$t('users.all')"
          label-select-attr=""
          :selected-option.sync="filters.role"
          :select-options="getUsersRole"
        />
        <div>
          <button class="btn btn-outline-secondary font-size-11" @click="removeFilters">
            {{ $t("users.remove_filters") }}
          </button>
        </div>
      </div>
    </div>
    <div class="users-list" data-cy="users-list">
      <ve-table
        :columns="fieldsList"
        :table-data="users"
        :sort-option="sortOption"
        :event-custom-option="eventCustomOption"
        :border-around="false"
        :border-x="true"
        :border-y="false"
      ></ve-table>
    </div>
    <div class="users-pagination" data-cy="users-pagination">
      <div>{{ $t("users.page") }}</div>
      <ve-pagination
        :total="totalCount"
        :page-index="pageIndex"
        :page-size="pageSize"
        @on-page-number-change="pageNumberChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import HeaderUsers from "@/components/users/HeaderUsers"
import ModalConfirmation from "@/components/modal/ModalConfirmation"
import { VeTable, VePagination, VeLocale } from "vue-easytable"

import frFR from "vue-easytable/libs/locale/lang/fr-FR.js"
import enUS from "vue-easytable/libs/locale/lang/en-US.js"
import koKR from "vue-easytable/libs/locale/lang/ko-KR.js"

export default {
  name: "UsersList",
  components: {
    ModalConfirmation,
    VeTable,
    VePagination,
  },
  data() {
    return {
      showConfirmationModal: false,
      titleModalConfirmation: "",
      selectedUser: {},
      messageModalConfirmation: "",
      showFilters: false,
      filters: {
        name: "",
        email: "",
        company: "",
        partner: "",
        status: "",
        role: "",
      },
      dbData: [],
      pageIndex: 1,
      pageSize: 10,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params)
        },
      },
      eventCustomOption: {
        bodyCellEvents: ({ row, column, rowIndex }) => {
          return {
            click: () => {
              if (column.field === "edit") {
                this.$router.push({ name: "usersEdit", params: { userId: row.id } })
              } else if (
                column.field === "block" &&
                row.id !== this.getCurrentUser.id
              ) {
                this.blockUser(row)
              } else {
                this.$router.push({ name: "usersAccount", params: { userId: row.id } })
              }
            },
          }
        },
      },
    }
  },
  computed: {
    ...mapGetters("users", [
      "fieldsList",
      "fieldsToSort",
      "getUsers",
      "getUsersStatus",
      "getUsersRole",
    ]),
    ...mapGetters("auth", ["getCurrentUser"]),
    filteredUsers() {
      return this.dbData.filter((user) => {
        return Object.entries(this.filters).every(
          ([filterName, filterValue]) =>
            filterValue === "" ||
            user[filterName]?.toLowerCase().includes(filterValue.toLowerCase())
        )
      })
    },
    users() {
      const { pageIndex, pageSize } = this
      return this.filteredUsers.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
    },
    totalCount() {
      return this.filteredUsers.length
    },
  },
  methods: {
    removeFilters() {
      this.filters.name = ""
      this.filters.email = ""
      this.filters.company = ""
      this.filters.partner = ""
      this.filters.status = ""
      this.filters.role = ""
    },
    blockUser(user) {
      this.selectedUser = user
      this.titleModalConfirmation = user.is_active
        ? this.$t("users.modal.block_title")
        : this.$t("users.modal.unblock_title")
      this.messageModalConfirmation = user.is_active
        ? this.$t("users.modal.block_message")
        : this.$t("users.modal.unblock_message")
      this.showConfirmationModal = true
    },
    onCloseConfirmationModal() {
      this.showConfirmationModal = false
    },
    async onConfirmButtonClick() {
      await this.$store.dispatch("users/toggleBlockUser", this.selectedUser)
      this.showConfirmationModal = false
      this.dbData = this.getUsers
    },
    sortChange(params) {
      const fieldToSort = this.fieldsToSort.find((field) => params[field])
      if (fieldToSort) {
        this.sortData(this.users, fieldToSort, params[fieldToSort])
      }
    },
    sortData(data, field, direction) {
      return data.sort((a, b) => {
        if (a[field] < b[field]) {
          return direction === "asc" ? -1 : 1
        } else if (a[field] > b[field]) {
          return direction === "asc" ? 1 : -1
        } else {
          return 0
        }
      })
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
    },
    initDatabase() {
      this.dbData = this.getUsers
    },
  },
  beforeCreate() {
    let tableLang = { fr: frFR, en: enUS, ko: koKR }
    VeLocale.use(tableLang[this.$route.params.lang])
  },
  async created() {
    await this.$store.dispatch("users/listUsers", this.getCurrentUser)
    this.initDatabase()
  },
}
</script>

<style scoped lang="scss">
.users-filters {
  margin-bottom: 25px;
  padding: 24px;
  background: #fff;
  .filters-title {
    display: inline-flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
    div {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .filters-form {
    display: grid;
    grid-template-columns: repeat(6, 0.4fr) 0.3fr;
    gap: 16px;
    margin-top: 12px;
    :last-child {
      align-self: flex-end;
    }
    .btn {
      min-height: 44px;
    }
  }
}

.users-list {
  margin-bottom: 25px;
  padding: 24px;
  background: #fff;
}

.users-pagination {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 25px;
  padding: 12px;
  background: #fff;
  font-size: 14px;
}
</style>

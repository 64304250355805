import AbstractService from "@/services/AbstractService"

class ObjectService extends AbstractService {
  /**
   * Return a string that can be used to print the content of an object.
   * An optional delimiter can be set to separate the properties in the string. Default delimiter is ", ".
   * Warning (limitation) : for now, only use this service if all the values are printable.
   *
   * @param {Object} object object to be treated
   * @param {String} [delimiter=", "] delimiter to separate properties
   *
   * @returns {String}
   */
  getPrintableContent(object, delimiter = ", ") {
    if (object === null) {
      return ""
    }
    const printableContent = Object.keys(object).map((key) => `${key}: ${object[key]}`)
    return printableContent.join(delimiter)
  }
}

export default new ObjectService()

export default {
  data: function () {
    return {
      eligibilityCriteriaSelect: ["make", "model", "energy", "engine_code"],
      listModelOptions: {},
    }
  },
  computed: {
    isFirstProductLine() {
      return this.index === 0
    },
  },
  methods: {
    isEligibilityCriterionSelect(criterion) {
      return this.eligibilityCriteriaSelect.includes(criterion)
    },
    getEligibilityEmptyLabel(criterion) {
      if (criterion === "model" && this.isModelDisabled)
        return this.$t(`criteria.eligibility.disabled.${criterion}`)
      return this.$t(`criteria.eligibility.empty.${criterion}`)
    },
  },
}

<template>
  <div :data-cy="`button-select-file-${inputId}`">
    <div v-if="isLoading">
      <span
        class="spinner-border spinner-border-sm mr-1"
        v-show="isLoading"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div v-else>
      <label
        v-if="uploadedFile"
        :title="$t('button.replaceFile')"
        class="mouse-pointer link font-effra-light"
        :for="inputId"
        :data-cy="`uploaded-file-name-${inputId}`"
      >
        {{ uploadedFile }}
      </label>
      <div v-else :class="buttonClass">
        <label :for="inputId" class="button-label">
          <span v-if="withIcon" class="icon-upload2"></span>
          <span>{{ buttonLabel }}</span>
        </label>
      </div>

      <input
        ref="file"
        type="file"
        :name="name"
        :id="inputId"
        :accept="accept"
        @change="onChange"
        @click="onClick"
        :data-cy="`file-input-${inputId}`"
      />
    </div>
  </div>
</template>
<script>
import i18n from "@/i18n"

export default {
  name: "SelectFileButton",
  props: {
    inputId: { type: String },
    name: { type: String, default: "file" },
    accept: { type: String },
    buttonLabel: { type: String, default: i18n.t("button.selectFile") },
    uploadedFile: { type: String },
    isLoading: { type: Boolean, default: false },
    // btn-primary or btn-secondary or btn-tertiary
    btnClass: { type: String, default: "btn-secondary" },
    withIcon: { type: Boolean, default: false },
  },
  computed: {
    buttonClass() {
      return `btn ${this.btnClass} font-roboto-medium`
    },
  },
  methods: {
    onChange() {
      // Avoid emitting event if cancel was clicked
      if (this.$refs.file.files.length >= 1) {
        this.$emit("input-file", this.$refs.file.files[0])
      }
    },
    // To emit onchange event even if same file is selected
    onClick(event) {
      event.target.value = ""
    },
  },
}
</script>

<style scoped lang="scss">
input {
  visibility: hidden;
  position: absolute;
}

label.link:hover {
  text-decoration: none;
}

label.link {
  text-decoration: underline;
}

label {
  // my friend bootstrap...
  margin-bottom: 0;
}

.button-label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.icon-upload2 {
  font-weight: 500;
}
</style>

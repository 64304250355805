<template>
  <ModalBootstrap
    id-modal="modalCommissionsAndTaxes"
    class="criteria"
    :title="$t('programs.commissions_and_taxes_modal.title')"
    modal-size="extraLarge"
    @close="closeModal"
    data-cy="commissions-and-taxes-modal"
  >
    <template #body class="mx-3">
      <div class="mb-5">
        <div class="section__header">
          <label class="section__header__title" data-cy="commissions">
            {{ $t("programs.commissions_and_taxes_modal.commissions_title") }}
          </label>
          <i18n
            v-if="paymentFrequency"
            path="programs.commissions_and_taxes_modal.commissions_frequency"
            tag="span"
            class="section__header__frequency"
            data-cy="payment-frequency"
          >
            <template #frequency>
              <b>
                {{
                  $t(
                    "programs.commissions_and_taxes_modal.commissions_frequency_" +
                      paymentFrequency
                  )
                }}
              </b>
            </template>
          </i18n>
        </div>
        <CommissionsAndTaxesInfo
          v-for="(commission, index) in commissions"
          :key="`${index}-commission`"
          :content="commission"
          :index="index"
          content-type="commissions"
          :product-line-id="productLineId"
          :errors="nameErrors"
          @commissions-update="onCommissionUpdate(index, $event)"
          @commissions-deletion="onCommissionDeletion(commission, index)"
        ></CommissionsAndTaxesInfo>
        <ActionButton
          class="mt-2 d-flex align-items-center justify-content-end"
          :button-text="$t('programs.commissions_and_taxes_modal.add_commission_btn')"
          btn-class="btn btn-blue-c-100 font-roboto-medium"
          button-data-cy="add-commission-btn"
          @click-action="onAddCommisssionClick"
        ></ActionButton>
      </div>
      <div class="mb-5">
        <div class="section__header">
          <label class="section__header__title" data-cy="taxes">
            {{ $t("programs.commissions_and_taxes_modal.taxes_title") }}
          </label>
        </div>
        <CommissionsAndTaxesInfo
          v-for="(tax, index) in taxes"
          :key="`${index}-tax`"
          :content="tax"
          :index="index"
          content-type="taxes"
          :product-line-id="productLineId"
          :errors="nameErrors"
          @taxes-update="onTaxUpdate(index, $event)"
          @taxes-deletion="onTaxDeletion(tax, index)"
        ></CommissionsAndTaxesInfo>
        <ActionButton
          class="mt-2 d-flex align-items-center justify-content-end"
          :button-text="$t('programs.commissions_and_taxes_modal.add_tax_btn')"
          btn-class="btn btn-blue-c-100 font-roboto-medium"
          button-data-cy="add-tax-btn"
          @click-action="onAddTaxClick"
        ></ActionButton>
      </div>
      <ActionButton
        class="mt-2 d-flex align-items-center justify-content-end"
        :button-text="$t('programs.commissions_and_taxes_modal.validate')"
        btn-class="btn btn-primary font-roboto-medium section__validate"
        button-data-cy="commercial-validate-button"
        @click-action="onSubmitButton"
      ></ActionButton>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"
import ActionButton from "@/components/button/ActionButton"
import CommissionsAndTaxesInfo from "@/components/presales/modal/components/CommissionsAndTaxesInfo"
import ProgramProductCustomizationService from "../../../services/pages/ProgramProductCustomizationService"

export default {
  name: "ModalCommissionsAndTaxes",
  components: { ModalBootstrap, ActionButton, CommissionsAndTaxesInfo },
  props: {
    productLineId: { type: Number, required: true },
  },
  data() {
    return {
      form: { values: null },
      paymentFrequency: null,
    }
  },
  computed: {
    ...mapGetters("productLine", ["getUpdateErrors"]),
    ...mapGetters("programProductCustomization", [
      "getCommercialCriteriaValues",
      "getPaymentFrequencyValue",
    ]),
    commissions() {
      return this.form.values.commissions
    },
    taxes() {
      return this.form.values.taxes
    },
    nameErrors() {
      const errors = this.getUpdateErrors(this.productLineId)
      if (
        errors.hasOwnProperty("config") &&
        errors.config.hasOwnProperty("commercial") &&
        errors.config.commercial.hasOwnProperty("values")
      ) {
        const commercialValues = errors.config.commercial.values
        let updateErrors = {}
        for (const [commercialTypeKey, commercialTypeValue] of Object.entries(
          commercialValues
        )) {
          let newErrors = {}
          for (const [commercialErrorKey, commercialErrorValue] of Object.entries(
            commercialTypeValue
          )) {
            for (const [errorKey, errorValue] of Object.entries(commercialErrorValue)) {
              newErrors[
                `${commercialTypeKey}-${parseInt(commercialErrorKey) + 1}-${errorKey}`
              ] = errorValue
            }
          }
          updateErrors = { ...updateErrors, ...newErrors }
        }
        return updateErrors
      }
      return {}
    },
    hasUpdateFailed() {
      return Object.keys(this.nameErrors).length > 0
    },
  },
  methods: {
    onCommissionUpdate(index, content) {
      // same as Vue.set()
      this.$set(this.form.values.commissions, index, { ...content })
    },
    onCommissionDeletion(tax, index) {
      // same as Vue.delete()
      this.$delete(this.form.values.commissions, index)
    },
    onTaxUpdate(index, content) {
      this.$set(this.form.values.taxes, index, { ...content })
    },
    onTaxDeletion(tax, index) {
      this.$delete(this.form.values.taxes, index)
    },
    onAddCommisssionClick() {
      this.form.values.commissions.push({
        name: "",
        type: "percent",
        value: 0,
      })
    },
    onAddTaxClick() {
      this.form.values.taxes.push({
        name: "",
        type: "percent",
        value: 0,
        is_editable: true,
      })
    },
    async onSubmitButton() {
      await ProgramProductCustomizationService.updateCommercialCriteriaValues(
        this.productLineId,
        JSON.parse(JSON.stringify(this.form.values))
      )
      if (!this.hasUpdateFailed) {
        this.closeModal()
      }
    },
    closeModal() {
      // We can reset update errors :
      // next time we open this modal, values will be the previous valid ones
      if (this.nameErrors)
        this.$store.dispatch("productLine/resetUpdateErrors", this.productLineId)
      this.$store.dispatch("productLineModal/handleModalOpening", {
        modalName: "COMMISSIONS_AND_TAXES",
        productLineId: null,
      })
    },
  },
  created() {
    this.form.values = this.getCommercialCriteriaValues(this.productLineId)
    this.paymentFrequency = this.getPaymentFrequencyValue(this.productLineId)
  },
}
</script>

<style lang="scss" scoped>
.section__header {
  display: grid;
  grid-template-columns: auto;
  grid-template-areas: "title frequency";
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $bleuc100;
  border-bottom: 1px solid $bleuc100;
  background-color: $caarealightgray;
  padding: 0.5rem 1rem;
  label {
    margin-bottom: 0;
  }
}
.section__header__title {
  grid-area: title;
  font-size: 20px;
}
.section__header__frequency {
  grid-area: frequency;
  font-size: 0.875rem;
}
.section__validate {
  width: 0.4rem !important;
}
</style>

<template>
  <ul>
    <li
      class="productitem d-flex"
      :class="{ isDisabled: productIsDisabled(product) }"
      @click.stop="onProductClick(product)"
      data-cy="product-item"
      :title="getLiTitle"
    >
      <img :src="product.image" alt="image" width="200px" />
      <div class="productitem-content flex-fill">
        <div class="row mouse-pointer">
          <span class="col">
            <div
              class="productitem-tagline mb-1"
              :class="{ isDisabled: productIsDisabled(product) }"
            >
              {{ product.catchphrase }}
            </div>
            <div
              class="productitem-name font-size-25 crop-text-1"
              :class="{ isDisabled: productIsDisabled(product) }"
              :title="product.name"
            >
              {{ product.name }}
            </div>
          </span>
          <span class="font-size-25 align-self-center">
            <i
              class="icon-eye align-self-center productitem-icon"
              :style="getIconEyeCursor(product)"
              :title="getIconEyeTitle(product)"
              aria-hidden="true"
              :data-cy="`icon-eye-${product.code}`"
              @click.stop="onIconEyeClick(product)"
            ></i>
            <i
              class="icon-cart align-self-center productitem-icon"
              :class="{ isDisabled: productIsDisabled(product) }"
              aria-hidden="true"
            ></i>
          </span>
        </div>
        <p
          class="font-size-13 mt-2 crop-text-2 text-justify"
          :title="product.description"
        >
          {{ product.description }}
        </p>
        <div
          v-if="product.code === 'assurance_automobile'"
          class="badge badge-pill badge-light"
        >
          {{ $t("products.flitter.by_flitter") }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ProductItem",
  props: {
    product: { Type: Object, required: true },
    disable: { Type: Boolean, required: true },
  },
  data() {
    return {
      isSelected: false,
    }
  },
  computed: {
    ...mapGetters("basket", [
      "basketIsEmpty",
      "offersList",
      "offersNb",
      "isItemInCurrentOffer",
      "isAssuranceAutomobileInBasket",
    ]),
    ...mapGetters("product", ["isProductChargeable", "hasDetails"]),
    getLiTitle() {
      return this.disable ? this.$t("products.item_disable_title") : ""
    },
  },
  methods: {
    productIsDisabled(product) {
      if (
        this.disable ||
        (this.isAssuranceAutomobileInBasket && product.code === "assurance_automobile")
      ) {
        return true
      }
      if (!this.basketIsEmpty) {
        const basketTags = this.offersList.reduce((acc, offer) => {
          const tags = offer.map((p) => p.tags)
          tags.forEach((tag) => acc.push(tag))
          return acc
        }, [])
        const basketTagsLabels = basketTags.map((tags) => tags.map((tag) => tag.label))
        const intersection = basketTagsLabels.reduce((a, b) =>
          a.filter((v) => b.includes(v))
        )
        const tagBasket = intersection.reduce((acc, label) => {
          if (label === "VNPJ" || label === "VNR" || label === "VO") {
            acc.push(label)
          }
          return acc
        }, [])
        const productTags = product.tags.map((p) => p.label)
        return tagBasket.every((tag) => !productTags.includes(tag))
      }
      return false
    },
    getIconEyeCursor(product) {
      return this.hasDetails(product.code) ? "" : "cursor: not-allowed"
    },
    getIconEyeTitle(product) {
      if (this.hasDetails(product.code))
        return this.$t("products.icon_eye_title.display_details")
      return this.$t("products.icon_eye_title.unavailable_details")
    },
    onIconEyeClick(product) {
      this.$emit("icon-eye", product)
    },
    async onProductClick(product) {
      if (
        (!this.isAssuranceAutomobileInBasket &&
          product.code === "assurance_automobile") ||
        (this.isProductChargeable(product.code) &&
          product.code !== "assurance_automobile")
      ) {
        if (!this.productIsDisabled(product)) {
          const currentOffer = this.offersList.at(-1)
          if (
            this.isItemInCurrentOffer(product.id) ||
            product.code === "assurance_automobile" ||
            currentOffer.some((e) => e.code === "assurance_automobile")
          ) {
            await this.$store.dispatch("basket/addNewOffer")
          }
          await this.$store.dispatch("basket/add", {
            ...product,
            ...{ _basketId: product.id },
          })
        }
      } else if (product.code !== "assurance_automobile") {
        this.$emit("not-chargeable-product", product)
      }
    },
  },
}
</script>

<style lang="scss">
.productitem {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid $light-gray;
  min-height: 180px;
  padding: 12px;
  margin-bottom: 10px;

  &:hover {
    background-color: $productselected;
  }

  img {
    object-fit: cover;
  }

  .productitem-content {
    position: relative;
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 16px;
    color: $primary;
    overflow: hidden;

    .productitem-tagline {
      text-transform: uppercase;
      font-size: 10px;
      margin-bottom: 0;
    }

    svg {
      color: $bleuc700;
    }

    i {
      color: $primary;

      &:hover {
        font-size: 1.7rem;
      }
    }

    .productitem-name {
      font-weight: 500;
    }

    .productitem-icon {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }

    .productitem-icon:not(:last-child) {
      border-right: 1px solid $border;
    }

    .crop-text-1 {
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .crop-text-3 {
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}

.isDisabled {
  background-color: $productselected;
  cursor: not-allowed;
}
</style>

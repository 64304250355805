import AbstractService from "../AbstractService"
import UrlService from "../technical/UrlService"
import HttpService from "../technical/HttpService"
import ArrayService from "../technical/ArrayService"

class OfferService extends AbstractService {
  /**
   * Retrieves the data of the offer whose id is provided then determines whether at least one custom flat commission
   * exists among all the product lines within this offer.
   *
   * @param {Number} offerId id of the offer
   *
   * @returns {boolean} whether at least one custom flat commission exists within the offer
   */
  async hasAtLeastOneCustomFlatCommission(offerId) {
    try {
      let offer = await HttpService.get(UrlService.render("offerById", { id: offerId }))
      // Exit as soon as possible
      for (const programProduct of offer.program_products) {
        for (const productLine of programProduct.product_lines) {
          const customFlatCommissions =
            this._listProductLineCustomFlatCommissions(productLine)
          if (customFlatCommissions.length > 0) {
            return true
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
    return false
  }

  /**
   * Returns the list of custom flat commissions of the provided product line.
   *
   * @param {Object} productLine product line
   *
   * @returns {Array} list of custom flat commissions
   */
  _listProductLineCustomFlatCommissions(productLine) {
    const commissions = productLine.config.commercial.values.commissions
    const defaultCommissions = productLine.config.commercial.default_values.commissions
    return commissions.filter((commission) => {
      return (
        commission.type === "flat" &&
        !ArrayService.isObjectWithSameValuesInArray(defaultCommissions, commission)
      )
    })
  }
}

export default new OfferService()

<template>
  <ModalBootstrap
    id-modal="modalUwr"
    class="criteria"
    :title="$t('commercial.uwr_modal.title')"
    modal-size="extraLarge"
    @close="onClose"
    data-cy="uwr-modal"
  >
    <template #body class="mx-3">
      <div class="modal-uwr-container">
        <div>
          <label>{{ $t("commercial.subscription_rate") }}</label>
        </div>
        <FormInput
          name="uwr_target"
          id="uwr-name"
          type="number"
          input-class="commission__taxes__name"
          v-model="form.uwr"
          data-cy="input-uwr-name"
          :errors="errors"
        ></FormInput>
        <span class="commission__taxes__unit">%</span>
      </div>
      <ActionButton
        class="mt-2 d-flex align-items-center justify-content-end"
        :button-text="$t('programs.commissions_and_taxes_modal.validate')"
        btn-class="btn btn-primary font-roboto-medium section__validate"
        button-data-cy="commercial-validate-button"
        @click-action="onSubmitButton"
      ></ActionButton>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"
import ActionButton from "@/components/button/ActionButton"

export default {
  name: "ModalCommissionsAndTaxes",
  components: { ModalBootstrap, ActionButton },
  props: {
    productLineId: { type: Number, required: true },
  },
  data() {
    return {
      form: {
        uwr: null,
      },
    }
  },
  computed: {
    ...mapGetters("productLine", ["getProductLineUwr", "getUpdateErrors"]),
    errors() {
      let errors = this.getUpdateErrors(this.productLineId)
      if (!Object.keys(errors).length) {
        return {}
      }
      return errors.config.commercial.values
    },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
    onSubmitButton() {
      this.$emit("update-product-line", this.form.uwr)
    },
  },
  created() {
    this.form.uwr = this.getProductLineUwr(this.productLineId)
  },
}
</script>

<style lang="scss" scoped>
.section__header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "title";
  align-items: center;
  border-top: 1px solid $bleuc100;
  border-bottom: 1px solid $bleuc100;
  background-color: $caarealightgray;
  padding: 0.5rem 1rem;
  label {
    margin-bottom: 0;
  }
}
.section__header__title {
  grid-area: title;
  justify-self: start;
  font-size: 20px;
}
.section__validate {
  width: 0.4rem !important;
}
.modal-uwr-container {
  display: grid;
  grid-template-columns: 80% 1fr 1fr;
  justify-content: space-between;
  grid-column-gap: 0.5rem;
  padding: 0.5rem 1rem;
}
.commission__taxes__unit {
  padding-top: 5px;
  font-size: 1.2rem;
}
</style>

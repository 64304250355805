<template>
  <transition v-if="visible" name="slide-fade">
    <div id="sidenav" data-cy="burger_menu">
      <div class="row mt-3">
        <div class="col text-right fixed-height">
          <button
            type="button"
            class="invisible-button close-cross icon-cross align-self-center"
            aria-hidden="true"
            @click.prevent="$emit('toggle-menu-visibility')"
            data-cy="basket-close"
          ></button>
        </div>
      </div>
      <nav>
        <ul class="text-right" @click="$emit('toggle-menu-visibility')">
          <li v-for="item in listBurgerItems" :key="item">
            <router-link
              :data-cy="`burger_${item}_link`"
              :to="{ name: item }"
              class="nav-item menu-item"
            >
              {{ $t(`burgermenu.${item}`) }}
            </router-link>
          </li>
          <li class="mb-3 mt-3"><span>&nbsp;</span></li>
          <li>
            <router-link
              data-cy="burger_disconnect_link"
              :to="{ name: 'logout' }"
              class="nav-item menu-item"
            >
              {{ $t("burgermenu.disconnect") }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex"
import GroupService from "@/services/business/GroupService"

export default {
  name: "BurgerMenu",
  props: ["visible"],
  data() {
    return {
      burgerItems: [
        "presale",
        "programs",
        "programs_demo",
        "parameters",
        "partners",
        "account",
        "usersList",
      ],
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    listBurgerItems() {
      return this.getCurrentUser.group.id >= GroupService.DISTRIBUTOR_ADMIN
        ? this.burgerItems.filter(
            (burgerItem) =>
              burgerItem === "presale" ||
              burgerItem === "programs" ||
              burgerItem === "partners" ||
              burgerItem === "usersList"
          )
        : this.burgerItems
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}

.menu-item:hover {
  font-weight: 500;
}

.menu-item {
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
}

.close-cross {
  color: white;
}
</style>

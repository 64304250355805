<template>
  <div>
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <div>
      <div v-if="programsToDisplay.length === 0">
        <h3 class="text-center" data-cy="noProgram">
          {{ $t("parameters.noProgram") }}
        </h3>
      </div>
      <div v-else>
        <div
          class="row-auto justify-content-center"
          v-for="program in programsToDisplay"
          :key="program.id"
          data-cy="programList"
        >
          <div :id="`accordion-programs-item-${program.id}`">
            <div
              class="card"
              :class="{ closed: !isProgramShow(program) }"
              :data-cy="`accordion-content-${program.id}`"
            >
              <a
                style="text-decoration: none"
                class="card-header d-flex justify-content-start align-items-center w-100 mouse-pointer"
                :id="`accordion-programs-item-header-${program.id}`"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                @click.prevent="onProgramClick(program.id)"
              >
                <div class="col-3">
                  <div class="text-secondary font-size-11 ml-1">
                    {{ $t("parameters.name") }}
                  </div>
                  <div
                    class="font-size-25 text-decoration-underline"
                    :data-cy="`protection-${program.id}`"
                  >
                    <span :data-cy="`program-name-${program.id}`">
                      {{ program.name + " #" + program.id }}
                    </span>
                  </div>
                </div>
                <div class="col-3">
                  <div class="text-secondary font-size-11 ml-1">
                    {{ $t("parameters.createdBy") }}
                  </div>
                  <div class="font-size-25" v-if="program.user.first_name">
                    {{ program.user.first_name + " " + program.user.last_name }}
                  </div>
                  <div class="font-size-25" v-else>
                    {{ program.user.username }}
                  </div>
                </div>
                <div class="col-2">
                  <div class="text-secondary font-size-11 ml-1">
                    {{ $t("parameters.createdAt") }}
                  </div>
                  <div class="font-size-25">
                    {{ format(program.created_at) }}
                  </div>
                </div>
                <div class="col-2">
                  <div class="text-secondary font-size-11 ml-1">
                    {{ $t("parameters.partner") }}
                  </div>
                  <div class="font-size-25">
                    {{ program.partner ? program.partner.name : "--" }}
                  </div>
                </div>
              </a>
              <transition name="slide">
                <div
                  :id="`accordion-programs-item-collapse-${program.id}`"
                  :class="['collapse', isProgramShow(program) ? 'show' : 'hide']"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                  v-show="isProgramShow(program)"
                  data-cy="programContent"
                  class="programListColor font-size-18"
                >
                  <div>
                    <div v-if="listProgramProductLines(program).length >= -0">
                      <ProductLineListItem
                        :product-line="listProgramProductLines(program)[0]"
                        :key="listProgramProductLines(program)[0].id"
                        @update="listPrograms"
                        @feedback="setFeedback"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductLineListItem from "@/components/ProductLineListItem"
import DateService from "@/services/technical/DateService"
import { mapGetters } from "vuex"

export default {
  name: "Parameters",
  components: {
    ProductLineListItem,
  },
  data() {
    return {
      isLoading: true,
      feedback: "",
      showProgramIds: [],
      showProgramCriteriaEditModal: false,
      currentProgram: null,
    }
  },
  computed: {
    ...mapGetters("program", ["getProgram", "listPrograms", "listProgramProductLines"]),
    ...mapGetters("config", ["getCurrentLang"]),
    programsToDisplay() {
      return this.listPrograms
    },
  },
  methods: {
    onProgramClick(id) {
      this.$router.push({ name: "programCommercialTaxes", params: { programId: id } })
    },
    isProgramShow(program) {
      return this.showProgramIds.includes(program.id)
    },
    format(date) {
      return DateService.format(date, "DD MMMM YYYY", this.getCurrentLang)
    },
    setFeedback(feedback) {
      this.feedback = feedback
      this.showFeedbackModal = true
    },
  },
  async created() {
    try {
      await this.$store.dispatch("program/listPrograms")
    } catch (e) {
      console.error("get Programs failed: ", e)
      throw e
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
.card {
  border: 0;
}
.listItem {
  background-color: #efede9 !important;
}
.card-header {
  background: white;
  border-width: 0 0 1px 0 !important; /* top right bottom left */
  border-style: solid;
  border-color: $light-gray;
  border-radius: 0;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: $productselected;
  }
}

.card-header:last-child {
  background: red;
  border: 0;
}

.programListColor {
  background: $quaternary;
}
.productitem-icon {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

/deep/ .list-group-item {
  background: none !important;
  padding: 15px 150px !important;
  border-width: 0 0 1px 0 !important; /* top right bottom left */
  border-style: solid;
  border-color: $light-gray;
}
</style>

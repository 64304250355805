import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import i18n from "@/i18n"

const MAX_FILE_SIZE = 3145728

// STATES (snake_case)
const state = {
  documents: {},
  upload_error: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents.reduce((acc, doc) => {
      acc[doc.id] = doc
      return acc
    }, {})
  },
  SET_DOCUMENT(state, document) {
    Vue.set(state.documents, document.id, document)
  },
  SET_UPLOAD_ERROR(state, error) {
    state.upload_error = error
  },
  RESET_UPLOAD_ERROR(state) {
    state.upload_error = null
  },
  DELETE_DOCUMENT(state, documentId) {
    if (state.documents.hasOwnProperty(documentId)) {
      Vue.delete(state.documents, documentId)
    }
  },
}

// ACTIONS (camelCase)
const actions = {
  _getDocumentId({ state }, payload) {
    let id = null
    // Browse documents until the document with the provided type and offer id is found
    for (const [key, value] of Object.entries(state.documents)) {
      if (value.offer_id === payload.offerId && value.type === payload.type) {
        id = key
        break
      }
    }
    return id
  },
  async _deletePreviousDocument({ commit, dispatch, state }, payload) {
    const previousDocumentId = await dispatch("_getDocumentId", payload)
    if (previousDocumentId) {
      commit("DELETE_DOCUMENT", previousDocumentId)
    }
  },
  async upload({ commit, dispatch, state }, payload) {
    commit("RESET_UPLOAD_ERROR")

    if (payload.file.size > MAX_FILE_SIZE) {
      const errorDetail = i18n.t("error.oversize")
      commit("SET_UPLOAD_ERROR", [errorDetail])
      throw new Error()
    }

    let formData = new FormData()
    formData.append("file", payload.file)
    formData.append("type", payload.type)
    formData.append("offer", payload.offerId)

    try {
      const response = await HttpService.post(
        UrlService.render("offerDocuments"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // Now that the new document has been uploaded,
      // delete the previous one in the store (if any)
      await dispatch("_deletePreviousDocument", payload)

      commit("SET_DOCUMENT", response)
      return response
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_UPLOAD_ERROR", e.data)
        }
      }
      console.error("upload failure", e)
      throw e
    }
  },

  async getDocuments({ commit }) {
    try {
      const response = await HttpService.get(UrlService.render("offerDocuments"))
      commit("SET_DOCUMENTS", response)
    } catch (e) {
      console.error("getDocuments failure", e)
      throw e
    }
  },

  resetUploadError({ commit }) {
    commit("RESET_UPLOAD_ERROR")
  },
}

// GETTERS (camelCase)
const getters = {
  getDocumentInfoByTypeAndOfferId: (state) => (type, offerId) => {
    const docInfo = Object.values(state.documents).find(
      (value) => value.type === type && value.offer_id === offerId
    )
    return docInfo ? JSON.parse(JSON.stringify(docInfo)) : null
  },
  areAllDocsUploadedForOffer: (state, getters) => (offerId) => {
    return (
      getters.getDocumentInfoByTypeAndOfferId("GENERAL_CONDITIONS", offerId) !== null &&
      getters.getDocumentInfoByTypeAndOfferId("PARTICULAR_CONDITIONS", offerId) !== null
    )
  },
  getUploadError: (state) => state.upload_error,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div class="program-timeline">
    <timeline v-if="program.offers.length > 0">
      <div
        v-for="(offer, index) in program.offers"
        :key="offer.id"
        data-cy="offer-content"
        class="program-timeline__div"
      >
        <timeline-title
          :bg-color="bgColor(offerStates[offer.id])"
          :line-color="lineColor(offerStates[offer.id])"
          icon-size="large"
          :data-cy="`title-offer-${offer.id}`"
        >
          <i
            v-if="
              offerStates[offer.id] === check_state ||
              offerStates[offer.id] === current_and_check_state
            "
            class="icon-check"
            :data-cy="`offer-${index + 1}-icon-check`"
          ></i>
          <router-link
            class="timeline-field"
            v-if="clickable(offerStates[offer.id])"
            :to="offerRouteLink(offer)"
            :data-cy="`offer-${index + 1}-${isObjectActive(offer)}-button`"
          >
            {{ offerTitle(index) }}
          </router-link>
          <p v-else class="timeline-field no-clickable" :data-cy="`offer-${index + 1}`">
            {{ offerTitle(index) }}
          </p>
        </timeline-title>
        <timeline-item
          :bg-color="bgColor(productStates[product.id])"
          :line-color="lineColor(productStates[product.id])"
          icon-size="medium"
          v-for="(product, indexPp) in offer.program_products"
          :key="product.id"
        >
          <i
            v-if="
              productStates[product.id] === check_state ||
              productStates[product.id] === current_and_check_state
            "
            class="icon-check"
            :data-cy="`product-id-${indexPp + 1}-icon-check`"
          ></i>
          <router-link
            class="timeline-field"
            v-if="clickable(productStates[product.id])"
            :to="programProductRouteLink(offer, product)"
            :data-cy="`product-id-${product.id}-${isObjectActive(product)}-button`"
          >
            {{ product.name }}
          </router-link>
          <p
            v-else
            class="timeline-field no-clickable"
            :data-cy="`product-id-${product.id}`"
          >
            {{ product.name }}
          </p>
        </timeline-item>
        <timeline-item
          :bg-color="bgColor(synthesisStates[offer.id])"
          :line-color="lineColor(synthesisStates[offer.id])"
          icon-size="medium"
          v-if="!isOfferExternal(offer.id)"
        >
          <i
            v-if="
              synthesisStates[offer.id] === check_state ||
              synthesisStates[offer.id] === current_and_check_state
            "
            class="icon-check"
            :data-cy="`offer-synthesis-${index + 1}-icon-check`"
          ></i>
          <router-link
            class="timeline-field"
            v-if="clickable(synthesisStates[offer.id])"
            :to="pricingRangeRouteLink(offer)"
            :data-cy="`offer-synthesis-${index + 1}-button`"
          >
            {{ $t("programs.timeline.synthesis").toUpperCase() }}
          </router-link>
          <p
            v-else
            class="timeline-field no-clickable"
            :data-cy="`offer-synthesis-${index + 1}`"
          >
            {{ $t("programs.timeline.synthesis").toUpperCase() }}
          </p>
        </timeline-item>
      </div>
    </timeline>
  </div>
</template>

<script>
import { Timeline, TimelineItem, TimelineTitle } from "vue-cute-timeline"
import colors from "@/assets/scss/_colorstimeline.scss"
import StringService from "@/services/technical/StringService"
import { mapGetters } from "vuex"
import pricingRange from "@/views/pricing/PricingRange.vue"

export default {
  props: {
    program: { type: Object, required: true },
  },
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle,
  },
  data: function () {
    return {
      bleu_clair: colors.bleu_clair,
      bleu: colors.bleu,
      blanc: colors.blanc,
      check_state: "check",
      current_state: "current",
      later_state: "later",
      current_and_check_state: "current_and_check",
      currentOfferId: null,
      currentProductId: null,
      offerStates: {},
      productStates: {},
      synthesisStates: {},
    }
  },
  watch: {
    async $route() {
      this.refreshStates()
    },
  },
  computed: {
    pricingRange() {
      return pricingRange
    },
    ...mapGetters("program", ["getProgramByOfferId"]),
    ...mapGetters("auth", ["getRouteName"]),
    ...mapGetters("offer", ["getOffer", "isOfferExternal"]),
    ...mapGetters("programProduct", ["getProgramProductById"]),
    isPageSynthesis() {
      return this.$route.name === "offerPricingRange"
    },
  },
  methods: {
    offerTitle(index) {
      return StringService.upperFirst(this.$t("offers.offer")) + " #" + (index + 1)
    },
    clickable(state) {
      return state === this.check_state || state === this.current_and_check_state
    },
    isCurrentOffer(offer) {
      // use '==' instead of '===' because sometimes one of both is a string instead of a number
      // noinspection EqualityComparisonWithCoercionJS
      return this.currentOfferId == offer.id
    },
    isCurrentProduct(product) {
      // use '==' instead of '===' because sometimes one of both is a string instead of a number
      // noinspection EqualityComparisonWithCoercionJS
      return this.currentProductId == product.id
    },
    hasOneProductActive(products) {
      for (let product of products) {
        if (this.isActive(product)) {
          return true
        }
      }
      return false
    },
    getStateByOffer(offer) {
      if (this.isActive(offer) || this.hasOneProductActive(offer.program_products)) {
        return ["programOffer", "shared_programOffer"].includes(this.$route.name) &&
          this.isCurrentOffer(offer)
          ? this.current_and_check_state
          : this.check_state
      }
      if (
        ["programOffer", "shared_programOffer"].includes(this.$route.name) &&
        this.isCurrentOffer(offer)
      ) {
        return this.current_state
      }
      return this.later_state
    },
    getStateByProduct(product) {
      if (this.isActive(product)) {
        return this.isCurrentProduct(product)
          ? this.current_and_check_state
          : this.check_state
      }
      if (this.isCurrentProduct(product)) return this.current_state
      return this.later_state
    },
    getStateSynthesisByOffer(offer) {
      if (this.isActive(offer)) {
        return this.isPageSynthesis && this.isCurrentOffer(offer)
          ? this.current_and_check_state
          : this.check_state
      }
      if (this.isPageSynthesis && this.isCurrentOffer(offer)) return this.current_state
      return this.later_state
    },
    bgColor(state) {
      switch (state) {
        case this.current_and_check_state:
        case this.current_state:
          return this.bleu
        case this.check_state:
          return this.bleu_clair
        default:
          return this.blanc
      }
    },
    lineColor(state) {
      if (state === this.later_state) return this.bleu
      return this.bgColor(state)
    },
    isObjectActive(object) {
      if (this.isActive(object)) {
        return "modify"
      }
      return "configure"
    },
    isActive(object) {
      return object && object.status === "ACTIVE"
    },
    refreshStates() {
      this.currentOfferId = this.$route.params.offerId
      this.currentProductId = this.$route.params.programProductId
      this.offerStates = this.program.offers.reduce((acc, offer) => {
        // set offers state from offer store to have latest version
        offer = this.getOffer(offer.id)
        acc[offer.id] = this.getStateByOffer(offer)
        // set products state from programProduct store to have latest version
        this.productStates = offer.program_products.reduce((acc, product) => {
          product = this.getProgramProductById(product.id)
          acc[product.id] = this.getStateByProduct(product)
          return acc
        }, this.productStates)
        // set synthesis state
        this.synthesisStates[offer.id] = this.getStateSynthesisByOffer(offer)
        return acc
      }, {})
    },
    offerRouteLink(offer) {
      if (this.isOfferExternal(offer.id)) {
        return {
          name: this.getRouteName("programProduct"),
          params: { offerId: offer.id, programProductId: offer.program_products[0].id },
        }
      } else {
        return {
          name: this.getRouteName("programOffer"),
          params: { offerId: offer.id },
        }
      }
    },
    programProductRouteLink(offer, product) {
      return {
        name: this.getRouteName("programProduct"),
        params: { offerId: offer.id, programProductId: product.id },
      }
    },
    pricingRangeRouteLink(offer) {
      if (this.isOfferExternal(offer.id)) {
        return {
          name: this.getRouteName("programHome"),
          params: {
            id: this.getProgramByOfferId(offer.id).id,
          },
        }
      } else {
        return {
          name: this.getRouteName("offerPricingRange"),
          params: { id: this.getProgramByOfferId(offer.id).id, offerId: offer.id },
        }
      }
    },
  },
  async created() {
    this.refreshStates()
  },
}
</script>

<style lang="scss">
.program-timeline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 35px;

  .program-timeline__div {
    border-left: 1px solid var(--timelineTheme);
    padding-bottom: 0.1px;
  }

  .program-timeline__div:last-child .timeline-item:last-child {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    position: relative;
  }

  .program-timeline__div:last-child .timeline-item:last-child a {
    position: absolute;
    top: 0;
  }
}

.program-timeline i {
  position: absolute;
  font-weight: 900;
  z-index: 2;
  color: #fff;
}

.timeline-title i {
  font-size: 16px;
  top: 6px;
  left: calc(-28px - 8px);
}

.timeline-item i {
  font-size: 10px;
  top: 7px;
  left: calc(-28px - 5px);
}

.timeline-field {
  margin: 0;
  padding: 0;
}

.no-clickable {
  color: $primarybutton;
}

.timeline {
  padding: 0;
  position: relative;
  list-style: none;
  font-family: PingFangSC-light, Avenir, Helvetica, Arial, Hiragino Sans GB,
    Microsoft YaHei, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.timeline:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  /* background-color: var(--timelineTheme); */
}

.timeline-item {
  position: relative;
  margin: 1em 0 0 28px;
  padding-bottom: 1.5em;
}

.timeline-item > a,
.timeline-item > p {
  font-size: 0.9em;
}

.timeline-item:last-child {
  margin-bottom: 1em;
}

.timeline-circle {
  position: absolute;
  top: 0.28em;
  left: -34px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid var(--timelineTheme);
  background-color: var(--timelineTheme);
  z-index: 1;
  box-sizing: content-box;
}

.timeline-circle.hollow {
  background-color: var(--timelineBg);
}

.timeline-title {
  position: relative;
  display: inline-block;
  margin: -0.15em 0 15px 28px;
}

.timeline-title > a,
.timeline-title > p {
  font-size: 1.25em;
  font-weight: 900;
}

.timeline-title:not(:first-child) {
  margin-top: 28px;
}

.timeline-title-circle {
  left: -36px;
  top: -0.1em !important;
  width: 16px;
  height: 16px;
}

.timeline-others {
  width: 40px;
  height: auto;
  top: 0.2em;
  left: -48px;
  line-height: 1;
  padding: 2px 0;
  text-align: center;
  border: none;
  background-color: var(--timelineBg);
}
</style>

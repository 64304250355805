<template>
  <div class="container-fluid frieze h-100 pt-5" data-cy="account-content">
    <div class="row justify-content-center">
      <h3>{{ $t("account.title") }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",
}
</script>

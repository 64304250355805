<template>
  <div>
    <div class="program-content__header p-3">
      <div>
        <div
          class="program-content__header__program-type font-size-15 tertiary-color text-uppercase"
          data-cy="program-type"
        >
          {{ getProgramTypeToDisplay(this.program) }} -
          <span
            class="program-content__header__program-name-offer font-effra-bold"
            data-cy="program-title"
          >
            {{ title }}
          </span>
        </div>
        <div
          class="primary-color font-size-20 text-uppercase font-effra-normal"
          data-cy="description"
        >
          {{ desc }} {{ offerNumber }}
          <span v-if="otherInformations !== undefined" data-cy="other-information">
            {{ ` - ${otherInformations}` }}
          </span>
        </div>
      </div>
      <template v-if="isOfferExternal(getCurrentOffer.id)">
        <a
          v-if="isShareButtonVisible"
          class="program-content--header__share-button btn btn-light-gray p-2 text-left text-center"
          :href="flitterSubscriptionArea"
          data-cy="share-program-button"
          type="button"
          target="_blank"
        >
          <span>
            {{ $t("offers.flitter.share") }}
          </span>
        </a>
      </template>
      <template v-else>
        <button
          v-if="isShareButtonVisible && !hasSharingToken"
          class="program-content--header__share-button btn btn-light-gray p-2 text-left text-center"
          data-cy="share-program-button"
          type="button"
          @click.prevent="showShareModal = true"
          data-toggle="modal"
          data-target="#modalShare"
        >
          <span>
            <i
              class="program-content__icon icon-reply mr-3 mb-1 flip btn-group-vertical"
            />
            {{ $t("button.share") }}
          </span>
        </button>
      </template>
    </div>
    <ModalShare
      v-if="showShareModal"
      @close="showShareModal = false"
      :program-id="program.id"
    ></ModalShare>
  </div>
</template>

<script>
import ProgramMixin from "../mixins/ProgramMixin"
import ModalShare from "@/components/presales/modal/ModalShare"
import { mapGetters } from "vuex"
export default {
  name: "ProgramContentHeader",
  components: {
    ModalShare,
  },
  props: {
    program: { type: Object, required: true },
    title: { type: String, required: true },
    desc: { type: String, required: true },
    otherInformations: { type: String },
    offerNumber: { type: [Number, String], required: false },
    isShareButtonVisible: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [ProgramMixin],
  data() {
    return {
      showShareModal: false,
      flitterSubscriptionArea: process.env.VUE_APP_FLITTER_SUBSCRIPTION_AREA,
    }
  },
  computed: {
    ...mapGetters("auth", ["hasSharingToken"]),
    ...mapGetters("offer", ["isOfferExternal", "getCurrentOffer"]),
  },
}
</script>

<style lang="scss" scoped>
.program-content__header {
  border-left-style: solid;
  border-left-color: $bleucaarea;
  padding-left: 1em;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .program-content__header__program-type {
    letter-spacing: 0.09rem;
  }
  .program-content__header__program-name-offer {
    font-family: "Effra", sans-serif;
    font-weight: bold;
    color: $bleucaarea;
  }
  .program-content__icon {
    grid-area: icon;
    justify-self: start;
    align-self: start;
    font-weight: bold;
    font-size: 15px;
  }
}

.program-content--header__share-button {
  grid-area: button;
  font-family: "Gilroy SemiBold", sans-serif;
  font-weight: bold;
  min-width: 15%;
  line-height: 1 !important;
  span {
    vertical-align: middle !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    .flip {
      transform: scaleX(-1) !important;
    }
  }
}
</style>

<template>
  <button
    type="button"
    class="btn btn-primary mr-3 text-center"
    data-cy="spinner-button"
    @click="$emit('click', $event)"
  >
    <span
      class="spinner-border spinner-border-sm mr-1"
      v-show="isLoading"
      role="status"
      aria-hidden="true"
    ></span>
    <slot>{{ buttonText }}</slot>
  </button>
</template>

<script>
export default {
  name: "SpinnerButton",
  props: {
    isLoading: { type: Boolean, required: true },
    text: { type: String },
  },
  computed: {
    buttonText() {
      return this.text ? this.text : this.$t("button.validate")
    },
  },
}
</script>

<style scoped></style>

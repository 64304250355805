<template>
  <div>
    <ModalVehicleSearch
      v-if="showVehicleSearchModal"
      @close="showVehicleSearchModal = false"
      @vehicle-selected="onVehicleSelected"
      :program-product-id="programProductId"
      :product-line-id="productLineId"
    ></ModalVehicleSearch>
    <button
      class="btn btn-light-gray btn-block w-100"
      @click="() => (showVehicleSearchModal = true)"
      :data-cy="showModalDataCy"
      data-toggle="modal"
      data-target="#modalVehicleSearch"
    >
      {{ $t("button.modify_exemple") }}
    </button>
  </div>
</template>

<script>
import ModalVehicleSearch from "@/components/presales/modal/ModalVehicleSearch"
import { mapGetters } from "vuex"

export default {
  name: "VehicleChoiceButton",
  components: {
    ModalVehicleSearch,
  },
  props: {
    productLineId: {
      type: [Number, String],
    },
    programProductId: {
      type: [Number, String],
      required: true,
    },
    eligibilitySearchFilter: {
      type: Object,
      required: true,
    },
    offerId: { type: [Number, String] },
    programId: { type: [Number, String] },
  },
  data() {
    return {
      showVehicleSearchModal: false,
    }
  },
  computed: {
    showModalDataCy() {
      const baseValue = "show-vehicle-search-modal-example"
      if (this.productLineId) {
        return baseValue + `-${this.productLineId}`
      }
      return baseValue
    },
  },
  methods: {
    onVehicleSelected(vehicle) {
      this.$emit("vehicle-selected", vehicle)
    },
  },
}
</script>

import AbstractService from "../AbstractService"
import DateService from "../technical/DateService"
import ArrayService from "../technical/ArrayService"
import store from "../../store"

class VehicleSearch extends AbstractService {
  DEFAULT_VNPJ_KM = 0
  DEFAULT_VNR_KM = 5000
  DEFAULT_VNR_FIRST_RELEASE_DATE_DELTA_IN_MONTHS = 3
  HYBRID_ENGINE_CODES = ["H", "M", "P", "R"]
  urlFilterNameMatching = {
    vehicle_type: "cvdbIsVn",
    make: "cvdbMake",
    model: "cvdbModel",
    trim_level: "cvdbTrimLevel",
    year: "cvdbYear",
    power: "cvdbPower",
    cylinders: "cvdbCylinders",
    engine_type: "cvdbEngineType",
  }
  uppercaseSelectableNameMatching = {
    vehicle_type: "VEHICLE_TYPE",
    make: "MAKES",
    model: "MODELS",
    trim_level: "TRIM_LEVELS",
    year: "YEARS",
    power: "POWERS",
    cylinders: "CYLINDERS",
    engine_type: "ENGINE_TYPES",
  }
  uppercaseFilterNameMatching = {
    vehicle_type: "VEHICLE_TYPE",
    make: "MAKE",
    model: "MODEL",
    trim_level: "TRIM_LEVEL",
    year: "YEAR",
    power: "POWER",
    cylinders: "CYLINDERS",
    engine_type: "ENGINE_TYPE",
  }
  filterAndSelectableValueNameMatching = {
    vehicle_type: "vehicle_type",
    make: "makes",
    model: "models",
    trim_level: "trim_levels",
    year: "years",
    power: "powers",
    cylinders: "cylinders",
    engine_type: "engine_types",
  }
  hybridTypes = ["H", "M", "P", "R"]

  createQueryParams(filterName, filters, contextFilters) {
    const rankOfTheFilterToUpdate = filters[filterName].rank
    let query_params = {}
    for (let [key, value] of Object.entries(filters)) {
      if (key === "vehicle_type") {
        if (value.data === "vn_only") Object.assign(query_params, { ["is_vn"]: true })
      } else {
        const isNotTheFilterToUpdate = key !== filterName
        const isAContextFilter = contextFilters.includes(key)
        const isValueNotEmpty = value.data.length !== 0
        const isOfLowerRank = value.rank < rankOfTheFilterToUpdate
        if (
          isNotTheFilterToUpdate &&
          isValueNotEmpty &&
          (isAContextFilter || isOfLowerRank)
        ) {
          Object.assign(query_params, { [key]: value.data })
        }
      }
    }
    return query_params
  }
  transformArraySelectableValuesObjectsToObjectSelectableValues(values) {
    let selectableValues = {}
    values.forEach((value) => {
      Object.assign(selectableValues, { [value.id]: value.local_name })
    })
    return selectableValues
  }
  transformArraySelectableValuesToObjectSelectableValues(values) {
    let selectableValues = {}
    values.forEach((value) => Object.assign(selectableValues, value))
    return selectableValues
  }
  listMakes(makes) {
    const listMakes = []
    makes.forEach((make) => listMakes.push(make.id.toString()))
    return listMakes
  }
  listQueryFilter(filters, programContextValues) {
    let queryFilters = {}
    for (const filter in filters) {
      if (filters[filter].data.length > 0) {
        if (filter === "vehicle_type") {
          if (filters[filter].data === "vn_only")
            Object.assign(queryFilters, { ["is_vn"]: true })
        } else {
          queryFilters[filter] = filters[filter].data
        }
      } else if (filter === "engine_type") {
        // No engine type selected => use the ones of the context
        queryFilters[filter] = programContextValues[filter]
      }
    }
    return queryFilters
  }
  listFilterValuesWithHigherRank(filtersStructure, filterToCompare) {
    return Object.keys(filtersStructure).filter(
      (filterName) =>
        filtersStructure[filterName].rank > filtersStructure[filterToCompare].rank
    )
  }
  isFilter(filter, filterCompared) {
    return filterCompared === filter
  }
  isFilterEmpty(filters, filterSearched) {
    return filters[filterSearched].data.length === 0
  }
  mustFilterValuesBeUpdated(filters, selectableValues, filterSearched) {
    const isFilterModel = this.isFilter("model", filterSearched)
    const isFilterMakeNotEmpty = !this.isFilterEmpty(filters, "make")
    const isFilterTrimLevel = this.isFilter("trim_level", filterSearched)
    const areFiltersMakeAndModelNotEmpty =
      !this.isFilterEmpty(filters, "model") && !this.isFilterEmpty(filters, "make")
    const hasModelOnlyOneSelectableValue = selectableValues["models"].length === 1
    const isFilterNotModelNorTrimLevel =
      !this.isFilter("model", filterSearched) &&
      !this.isFilter("trim_level", filterSearched)
    const isFilterNotDisabled = !filters[filterSearched].isDisabled
    return (
      (isFilterModel && isFilterMakeNotEmpty) ||
      (isFilterTrimLevel &&
        (areFiltersMakeAndModelNotEmpty || hasModelOnlyOneSelectableValue)) ||
      (isFilterNotModelNorTrimLevel && isFilterNotDisabled)
    )
  }

  /**
   * Returns the vehicle's dynamic inputs initialized according to the provided vehicle type.
   *
   * @param {String} vehicleType vehicle type ("VNPJ", "VNR" or "VO")
   * @param {Object} vehicle vehicle data
   * @returns {Object}
   */
  getInitializedDynamicInputs(vehicleType, vehicle) {
    let dynamicInputs = {
      firstReleaseDate: null,
      km: null,
      retailPrice: this._getInitializedRetailPriceDynamicInput(vehicle),
    }

    if (vehicleType === "VNPJ") {
      dynamicInputs.firstReleaseDate = DateService.now().toDate()
      dynamicInputs.km = this.DEFAULT_VNPJ_KM
    } else if (vehicleType === "VNR") {
      const initializedFirstReleaseDate = DateService.getFormattedDateMonthsAgoFromNow(
        this.DEFAULT_VNR_FIRST_RELEASE_DATE_DELTA_IN_MONTHS
      )
      dynamicInputs.firstReleaseDate = new Date(initializedFirstReleaseDate)
      dynamicInputs.km = this.DEFAULT_VNR_KM
    }

    return dynamicInputs
  }

  /**
   * Returns the vehicle's retail price dynamic input initialized according to the vehicle's retail price from BDD.
   *
   * @param {Object} vehicle vehicle data
   * @returns {Number}
   */
  _getInitializedRetailPriceDynamicInput(vehicle) {
    if (vehicle.retail_price === null) {
      return null
    }
    if (ArrayService.isArray(vehicle.retail_price)) {
      return vehicle.retail_price.length === 1
        ? Math.trunc(vehicle.retail_price[0])
        : null
    }
    return Math.trunc(vehicle.retail_price)
  }

  /**
   * Returns the vehicle's oldest eligible date according to the age eligibility criteria.
   *
   * @param {Number} maxEligibleAge max eligible age value
   * @param {String} maxEligibleAgeUnity unity of the max eligible age value
   * @returns {String, null} formatted oldest eligible date if valid otherwise null
   */
  getOldestEligibleDate(maxEligibleAge, maxEligibleAgeUnity) {
    const supportedAgeUnities = ["day", "month", "year"]
    if (maxEligibleAge === null || !supportedAgeUnities.includes(maxEligibleAgeUnity)) {
      return null
    }
    if (maxEligibleAgeUnity === "day") {
      return DateService.getFormattedDateDaysAgoFromNow(maxEligibleAge)
    } else if (maxEligibleAgeUnity === "month") {
      return DateService.getFormattedDateMonthsAgoFromNow(maxEligibleAge)
    }
    return DateService.getFormattedDateYearsAgoFromNow(maxEligibleAge)
  }
  /**
   * Return product line eligibility criterion.
   *
   * @param {String} criterion criterion to retrieve
   * @param {Number} productLineId
   * @returns {Array} Array of values if not empty or empty array
   */
  retrieveProductLineEligibilityCriterion(criterion, productLineId) {
    return store.getters["programProductCustomization/getEligibilityCriteriaValues"](
      productLineId
    )[criterion]
  }
  /**
   * Return offer eligibility criterion.
   *
   * @param {String} criterion criterion to retrieve
   * @param {Number} offerId
   * @returns {Array} Array of values if not empty or empty array
   */
  retrieveOfferEligibilityCriterion(criterion, offerId) {
    return store.getters["offer/listEligibilityCriteriaValues"](offerId)[criterion]
  }
}

let VehicleSearchService = new VehicleSearch()
export default VehicleSearchService

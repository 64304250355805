<template>
  <div data-cy="partners-content">
    <HeaderPartner />
    <ListPartners v-if="$router.currentRoute.name === 'partners'" />
    <FormNewPartner v-else />
  </div>
</template>

<script>
import HeaderPartner from "@/components/partners/HeaderPartner"
import ListPartners from "@/components/partners/ListPartners"
import FormNewPartner from "@/components/partners/FormNewPartner"

export default {
  components: { ListPartners, HeaderPartner, FormNewPartner },
  async created() {
    await this.$store.dispatch("partner/initPartners")
  },
}
</script>

<style lang="scss" scoped></style>

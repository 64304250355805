// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

const state = {
  list_shared: [],
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_LIST_SHARED(state, listShared) {
    state.list_shared = listShared
  },
}

// ACTIONS (camelCase)
const actions = {
  async loadByProgramId({ commit }, programId) {
    try {
      const listShared = await HttpService.get(
        UrlService.render("programSharedByProgramId", { id: programId })
      )
      commit("SET_LIST_SHARED", listShared)
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async sendSharingLinkEmail({ commit, state }, programShared) {
    try {
      return await HttpService.post(UrlService.render("programSharedEmail"), {
        id: programShared.id,
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async revokeSharedProgram({ commit, state }, programShared) {
    try {
      return await HttpService.put(
        UrlService.render("programSharedRevokeById", { id: programShared.id })
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async reactivateSharedProgram({ commit }, programShared) {
    try {
      return await HttpService.put(
        UrlService.render("programSharedReactivateById", { id: programShared.id })
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async renewSharedProgram({ commit }, programShared) {
    try {
      return await HttpService.put(
        UrlService.render("programSharedRenewById", { id: programShared.id })
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  listProgramSharedByProgramId: (state) => (programId) =>
    state.list_shared.filter((s) => s.program.id === programId),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

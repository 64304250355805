import AbstractService from "@/services/AbstractService"

class StringUtils extends AbstractService {
  /**
   * Returns a string where the first character in every word is upper case.
   * @param str
   * @returns {string}
   */
  title(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word[0].toUpperCase() + word.substr(1)
      })
      .join(" ")
  }

  upperFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  removeUnderscores(string) {
    return string.replaceAll("_", " ")
  }
}

let StringService = new StringUtils()
export default StringService

<template>
  <div>
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <div v-else class="program-diffusion_container">
      <div
        class="program-diffusion-container__title font-effra-light"
        data-cy="title-program"
      >
        {{ $t("programParameters.parameters_start_page.program_launch") }}
        <span :data-cy="`program-name-${currentProgram.id}`">
          {{ currentProgram.name }}
        </span>
      </div>
      <div class="program-diffusion-container__content">
        <StepProgram :status="currentProgram.status"></StepProgram>
        <div v-if="currentProgram.status === 'ACTIVE'" class="program-broadcast__div">
          <div
            class="program-timeline__state font-effra-light font-size-18"
            data-cy="program-content"
          >
            <span>
              {{ $t("programParameters.parameters_start_page.programBroadcast") }}
            </span>
            <span class="font-effra-bold title-uppercase">
              {{ currentProgram.name }}
            </span>
            <span>
              {{
                currentProgram.publishing_type === "IMMEDIATE"
                  ? $t("programParameters.parameters_start_page.isEffective")
                  : $t("programParameters.parameters_start_page.isScheduled")
              }}
            </span>
          </div>
        </div>
        <div class="program-diffusion-container__button col text-center">
          <button
            class="font-roboto-medium btn btn-light-gray m-3"
            type="button"
            :data-cy="buttonDataCy"
            @click="onButtonClick"
          >
            <span>
              <i class="mr-2" :class="iconClassIfActive" data-cy="icon"></i>
              {{ textButton }}
            </span>
          </button>
        </div>
      </div>
      <div class="program-diffusion-container__picture">
        <img
          class="img-fluid cover"
          :src="urlPicture"
          alt="Caarea Solutions"
          data-cy="status-program-image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import StepProgram from "@/components/programParameters/StepProgram"

export default {
  name: "ProgramParametersStartpage",
  components: {
    StepProgram,
  },
  data() {
    return {
      isLoading: true,
      currentProgram: null,
      iconButton: null,
      textButton: "",
      pictureRight: "",
      buttonDataCy: "",
    }
  },
  props: {
    programId: {
      type: [String, Number],
    },
    program: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("program", ["getProgram"]),
    ...mapGetters("auth", ["getRouteName"]),
    iconClassIfActive() {
      return this.currentProgram.status !== "ACTIVE"
        ? "icon-play-circle program-start__icon__play__circle"
        : null
    },
    urlPicture() {
      return this.currentProgram.status !== "ACTIVE"
        ? "https://res.cloudinary.com/caarea/image/upload/v1651051847/cs/assets/caarea_man_brhjay.jpg"
        : "https://res.cloudinary.com/caarea/image/upload/v1651051848/cs/assets/caarea-landscape_iowdgl.jpg"
    },
  },
  methods: {
    async onButtonClick() {
      if (this.currentProgram.status === "SETTING_IDENTITY") {
        await this.$router.push({
          name: this.getRouteName("programNetwork"),
        })
      } else if (this.currentProgram.status === "ACTIVE") {
        await this.$router.push({
          name: this.getRouteName("programs"),
        })
      } else {
        await this.$router.push({
          name: this.getRouteName("programParameters"),
          params: { programId: this.programId, program: this.program },
        })
      }
    },
  },
  async created() {
    let programId = this.programId
      ? this.programId
      : this.$router.currentRoute.params.programId
    this.currentProgram = this.program
    if (!this.program) {
      await this.$store.dispatch("program/getProgram", programId)
      this.currentProgram = this.getProgram
    }
    const buttonStep =
      this.currentProgram.status === "DRAFT"
        ? "start"
        : this.currentProgram.status === "SETTING_IDENTITY"
        ? "continue"
        : "return"

    this.textButton = this.$t(`programParameters.parameters_start_page.${buttonStep}`)
    this.buttonDataCy = `${buttonStep}-button`

    this.isLoading = false
  },
}
</script>
<style lang="scss" scoped>
.program-diffusion-container__content {
  background-color: #fff;
  grid-row-start: 2;
  padding: 40px 40px 30px 60px;
  display: grid;
  font-size: 22px;
  letter-spacing: 0.09em;
  line-height: 40px;
}

.program-diffusion_container {
  max-width: 1200px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 50% 50%;
  grid-template-rows: minmax(125px, auto) auto;
  gap: 7px 0;

  .program-diffusion-container__title {
    border-left: 5px solid $bleuc700;
    font-size: 35px;
    color: $bleucaarea;
    letter-spacing: 0.09em;
    padding: 10px 20px;
    background-color: #fff;
    text-transform: uppercase;
  }

  .program-diffusion-container__picture {
    grid-row: 1 / span 2;
    justify-self: center;

    & .cover {
      object-fit: cover;
      height: 100%;
    }
  }
}

.program-broadcast__div {
  .program-timeline__state {
    text-align: left;
    padding-left: 50px;
    font-size: 1rem;
    margin-bottom: 2rem;

    .title-uppercase {
      text-transform: uppercase;
    }
  }
}
</style>

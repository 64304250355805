import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import ProgramService from "@/services/business/ProgramService"
import VehicleSearchService from "@/services/business/VehicleSearchService"
import i18n from "@/i18n"

// STATES (snake_case)
const state = {
  filters: {
    vehicle_type: {
      rank: 1,
      data: "all_vehicles",
      isDisabled: false,
    },
    make: {
      rank: 2,
      data: [],
      isDisabled: false,
    },
    model: {
      rank: 3,
      data: [],
      isDisabled: true,
    },
    trim_level: {
      rank: 4,
      data: [],
      isDisabled: true,
    },
    year: {
      rank: 5,
      data: [],
      isDisabled: false,
    },
    power: {
      rank: 6,
      data: [],
      isDisabled: false,
    },
    cylinders: {
      rank: 7,
      data: [],
      isDisabled: false,
    },
    engine_type: {
      rank: 8,
      data: [],
      isDisabled: false,
    },
  },
  selectable_values: {
    vehicle_type: [
      { id: "vn_only", local_name: "vehicleChoice.vn_only" },
      { id: "all_vehicles", local_name: "vehicleChoice.all_vehicles" },
    ],
    makes: [],
    models: [],
    trim_levels: [],
    years: [],
    powers: [],
    cylinders: [],
    engine_types: [],
  },
  values_allowed_by_product_line_eligibility_criteria: {
    makes: [],
    models: [],
    engine_types: [],
  },
  // List of filters to take into account for each API call, depending on the context :
  // - vehicle_type : determined from program's vehicle tag
  // - engine_type : determined from program's engine tag
  context_filters: ["vehicle_type", "engine_type"],
  program_context_values: {
    engine_type: [],
    is_vn: "",
  },
  vehicles: null,
  show_vehicle_infos: {
    is_shown: false,
    vehicle_id: null,
  },
  vehicle_variations: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  INIT_PROGRAM_CONTEXT_VALUES(state, payload) {
    state.program_context_values.engine_type = payload.engine_types
    state.program_context_values.is_vn = payload.is_vn
  },
  SET_VEHICLE_TYPE_SELECTABLE_VALUES(state, vehicle_type) {
    state.selectable_values.vehicle_type = vehicle_type
  },
  SET_MAKES_SELECTABLE_VALUES(state, makes) {
    state.selectable_values.makes = makes
  },
  SET_MODELS_SELECTABLE_VALUES(state, models) {
    state.selectable_values.models = models
  },
  SET_TRIM_LEVELS_SELECTABLE_VALUES(state, trim_levels) {
    state.selectable_values.trim_levels = trim_levels
  },
  SET_YEARS_SELECTABLE_VALUES(state, years) {
    state.selectable_values.years = years
  },
  SET_POWERS_SELECTABLE_VALUES(state, powers) {
    state.selectable_values.powers = powers
  },
  SET_CYLINDERS_SELECTABLE_VALUES(state, cylinders) {
    state.selectable_values.cylinders = cylinders
  },
  SET_ENGINE_TYPES_SELECTABLE_VALUES(state, engine_types) {
    state.selectable_values.engine_types = engine_types
  },
  SET_VEHICLE_TYPE_FILTER_VALUES(state, value) {
    state.filters.vehicle_type.data = value
  },
  SET_MAKE_FILTER_VALUES(state, makes) {
    state.filters.make.data = makes
  },
  SET_MODEL_FILTER_VALUES(state, models) {
    state.filters.model.data = models
  },
  SET_TRIM_LEVEL_FILTER_VALUES(state, value) {
    state.filters.trim_level.data = value
  },
  SET_YEAR_FILTER_VALUES(state, value) {
    state.filters.year.data = value
  },
  SET_POWER_FILTER_VALUES(state, value) {
    state.filters.power.data = value
  },
  SET_CYLINDERS_FILTER_VALUES(state, value) {
    state.filters.cylinders.data = value
  },
  SET_ENGINE_TYPE_FILTER_VALUES(state, value) {
    state.filters.engine_type.data = value
  },
  SET_MODEL_FILTER_DISABLED_PROP(state, value) {
    state.filters.model.isDisabled = value
  },
  SET_IS_DISABLE_FILTER(state, payload) {
    state.filters[payload.filterName].isDisabled = payload.isDisabled
  },
  REMOVE_PRODUCT_LINE_FILTER_VALUE(state, payload) {
    const index_to_remove = state.filters[payload.name].data.indexOf(payload.value)
    if (index_to_remove !== -1) {
      state.filters[payload.name].data.splice(index_to_remove, 1)
    }
  },
  RESET_ALL_FILTERS(state) {
    for (const filter in state.filters) {
      if (filter === "vehicle_type") {
        state.filters[filter].data = "all_vehicles"
        state.filters[filter].isDisabled = false
      } else {
        state.filters[filter].data = []
      }
    }
    state.filters.model.isDisabled = true
    state.filters.trim_level.isDisabled = true
  },
  RESET_ALL_SELECTABLE_VALUES(state) {
    for (const item in state.selectable_values) {
      if (item === "vehicle_type") {
        state.selectable_values[item] = [
          { id: "vn_only", local_name: "vehicleChoice.vn_only" },
          { id: "all_vehicles", local_name: "vehicleChoice.all_vehicles" },
        ]
      } else {
        state.selectable_values[item] = []
      }
    }
  },
  SET_SHOW_VEHICLE_INFOS(state, id) {
    state.show_vehicle_infos.is_shown = true
    state.show_vehicle_infos.vehicle_id = id
  },
  RESET_SHOW_VEHICLE_INFOS(state) {
    state.show_vehicle_infos.is_shown = false
    state.show_vehicle_infos.vehicle_id = null
  },
  SET_VEHICLES(state, vehicles) {
    state.vehicles = vehicles
  },
  SET_VEHICLE_VARIATIONS(state, vehicle_variations) {
    state.vehicle_variations = vehicle_variations
  },
  SET_MAKE_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA(state, makes) {
    state.values_allowed_by_product_line_eligibility_criteria.makes = makes
  },
  SET_MODEL_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA(state, models) {
    state.values_allowed_by_product_line_eligibility_criteria.models = models
  },
  SET_ENGINE_TYPE_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA(
    state,
    engineTypes
  ) {
    state.values_allowed_by_product_line_eligibility_criteria.engine_types = engineTypes
  },
  RESET_ALL_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA(state) {
    for (const item in state.values_allowed_by_product_line_eligibility_criteria) {
      state.values_allowed_by_product_line_eligibility_criteria[item] = []
    }
  },
}

// ACTIONS (camelCase)
const actions = {
  async initFiltersAndSelectableValues(
    { state, getters, commit, dispatch, rootGetters },
    productLineId
  ) {
    dispatch("_resetAllFiltersAndValues")
    dispatch("_initValuesAllowedByProductLine", productLineId)
    dispatch("_retrieveProgramContext", productLineId)

    let engineTypesSelectableValues = []
    let makesSelectableValues = []

    // Engine Type
    await dispatch("_initEngineTypeSelectableValues")

    if (getters.hasMakeValuesAllowedByProductLine) {
      // 1. Set filters
      const makesFilterValues = getters.listMakeValuesAllowedByProductLine.map((make) =>
        make.id.toString()
      )
      commit("SET_MAKE_FILTER_VALUES", makesFilterValues)
      commit("SET_MODEL_FILTER_DISABLED_PROP", false)

      if (getters.hasModelValuesAllowedByProductLine) {
        const modelsFilterValues = getters.listModelValuesAllowedByProductLine.map(
          (model) => model.id.toString()
        )
        commit("SET_MODEL_FILTER_VALUES", modelsFilterValues)
        commit("SET_IS_DISABLE_FILTER", { filterName: "trim_level", isDisabled: false })
      }

      // 2. Set selectable values
      makesSelectableValues = getters.listMakeValuesAllowedByProductLine
      commit("SET_MAKES_SELECTABLE_VALUES", makesSelectableValues)
      await dispatch("_updateModelSelectableValues")
    } else {
      //  Note : if no make selected, it's impossible to have model selected
      // No values to set in the filter => set selectable values only
      let selectableValuesPayload = {
        engine_type: getters.hasSelectedEngineTypeValues
          ? getters.listSelectedEngineTypeValues
          : allowedEngineTypeValues,
      }
      if (state.program_context_values.is_vn) {
        selectableValuesPayload["is_vn"] = state.program_context_values.is_vn
      }
      makesSelectableValues = await HttpService.get(
        UrlService.render("cvdbMake", {}, selectableValuesPayload)
      )
      commit("SET_MAKES_SELECTABLE_VALUES", makesSelectableValues)

      const yearsSelectableValues = await HttpService.get(
        UrlService.render("cvdbYear", {}, selectableValuesPayload)
      )
      commit("SET_YEARS_SELECTABLE_VALUES", yearsSelectableValues)
      const powersSelectableValues = await HttpService.get(
        UrlService.render("cvdbPower", {}, selectableValuesPayload)
      )
      commit("SET_POWERS_SELECTABLE_VALUES", powersSelectableValues)
      const cylindersSelectableValues = await HttpService.get(
        UrlService.render("cvdbCylinders", {}, selectableValuesPayload)
      )
      commit("SET_CYLINDERS_SELECTABLE_VALUES", cylindersSelectableValues)
    }
  },
  _resetAllFiltersAndValues({ commit }) {
    commit("RESET_ALL_FILTERS")
    commit("RESET_ALL_SELECTABLE_VALUES")
    commit("RESET_ALL_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA")
  },
  _initValuesAllowedByProductLine({ commit }, productLineId) {
    commit(
      "SET_ENGINE_TYPE_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA",
      VehicleSearchService.retrieveProductLineEligibilityCriterion(
        "engine_code",
        productLineId
      )
    )
    commit(
      "SET_MAKE_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA",
      VehicleSearchService.retrieveProductLineEligibilityCriterion(
        "make",
        productLineId
      )
    )
    commit(
      "SET_MODEL_VALUES_ALLOWED_BY_PRODUCT_LINE_ELIGIBILITY_CRITERIA",
      VehicleSearchService.retrieveProductLineEligibilityCriterion(
        "model",
        productLineId
      )
    )
  },
  async _retrieveProgramContext({ commit, rootGetters }, productLineId) {
    let engineTypes = []
    let engineTypeSelectableValues =
      VehicleSearchService.retrieveProductLineEligibilityCriterion(
        "engine_code",
        productLineId
      )
    const hasEngineTypeEligibilityCriteria = engineTypeSelectableValues.length > 0
    if (hasEngineTypeEligibilityCriteria) {
      engineTypes = [...engineTypeSelectableValues]
    } else {
      engineTypes = ProgramService.listEngineCodesFromEngineTag(
        rootGetters["program/getProgram"].engine_tag.label
      )
    }
    const is_vn = ProgramService.isVnContextValueFromProgramVehicleTag(
      rootGetters["program/getProgram"].vehicle_tag.label
    )
    if (is_vn) {
      commit("SET_VEHICLE_TYPE_FILTER_VALUES", "vn_only")
      commit("SET_IS_DISABLE_FILTER", { filterName: "vehicle_type", isDisabled: true })
    }
    if (engineTypes) {
      commit("SET_ENGINE_TYPE_FILTER_VALUES", engineTypes)
    }
    commit("INIT_PROGRAM_CONTEXT_VALUES", {
      engine_types: engineTypes,
      is_vn: is_vn,
    })
  },
  async _initEngineTypeSelectableValues({ state, commit, getters }) {
    const payload = state.program_context_values.is_vn
      ? { is_vn: state.program_context_values.is_vn }
      : {}
    const engineTypesSelectableValues = await HttpService.get(
      UrlService.render("cvdbEngineType", {}, payload)
    )
    const allowedEngineTypeValues = getters.listAllowedEngineTypeValues
    const filteredSelectableValues = engineTypesSelectableValues.filter(
      (engineTypeSelectableValue) => {
        return allowedEngineTypeValues.includes(
          Object.keys(engineTypeSelectableValue)[0]
        )
      }
    )
    commit("SET_ENGINE_TYPES_SELECTABLE_VALUES", filteredSelectableValues)
  },

  async _updateModelSelectableValues({ commit, getters, dispatch }) {
    // TODO: this will call several APIs for nothing as they will be called later again !!!
    //  (if call to _updateModelSelectableValues comes from _updateFilterAndSelectableIfNecessary)
    await dispatch("_retrieveNewSelectableValues", "make")

    if (getters.hasModelValuesAllowedByProductLine) {
      const listAllowedModelIds = getters.listModelValuesAllowedByProductLine.map(
        (model) => model.id
      )
      const filteredSelectableValues = state.selectable_values.models.filter((model) =>
        listAllowedModelIds.includes(model.id)
      )
      commit("SET_MODELS_SELECTABLE_VALUES", filteredSelectableValues)
    }
  },
  _keepOnlyModelsOfCurrentMakes({ commit, getters }) {
    if (state.filters.model.data.length > 0) {
      const selectableModelIds = getters.listSelectableModelIds.map((modelId) =>
        modelId.toString()
      )
      const filteredModels = state.filters.model.data.filter((modelId) =>
        selectableModelIds.includes(modelId)
      )
      commit("SET_MODEL_FILTER_VALUES", filteredModels)
    }
  },
  async _retrieveNewSelectableValues({ commit }, filterUpdated) {
    const selectableValuesToUpdate =
      VehicleSearchService.listFilterValuesWithHigherRank(state.filters, filterUpdated)
    for (const select of selectableValuesToUpdate) {
      const queryParams = VehicleSearchService.createQueryParams(
        select,
        state.filters,
        state.context_filters
      )
      const selectableValues = await HttpService.get(
        UrlService.render(
          VehicleSearchService.urlFilterNameMatching[select],
          {},
          queryParams
        )
      )
      if (select === "engine_type") {
        let engineTypeFilterValues = []
        selectableValues.forEach((value) => {
          engineTypeFilterValues.push(...Object.keys(value))
        })
        if (
          engineTypeFilterValues.length <
          state.program_context_values.engine_type.length
        ) {
          commit("SET_ENGINE_TYPE_FILTER_VALUES", engineTypeFilterValues)
        }
      }
      commit(
        `SET_${VehicleSearchService.uppercaseSelectableNameMatching[select]}_SELECTABLE_VALUES`,
        selectableValues
      )
    }
  },
  async searchVehicles({ state, commit }, productLineId) {
    const payload = {}
    Object.assign(payload, {
      ...VehicleSearchService.listQueryFilter(
        state.filters,
        state.program_context_values
      ),
      with_pricing_status: true,
      product_line_id: productLineId,
    })
    if (
      state.filters.make.data.length === 0 &&
      state.filters.vehicle_type.data.length === 0
    ) {
      payload["make"] = VehicleSearchService.listMakes(state.selectable_values.makes)
      payload["product_line_id"] = productLineId
    }
    try {
      const vehicles = await HttpService.post(
        UrlService.render("vehicleSearch", {}, { limit: 5 }),
        payload
      )
      commit("SET_VEHICLES", vehicles)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async updateVehicleTypesFilter({ state, commit, dispatch }, value) {
    commit("SET_VEHICLE_TYPE_FILTER_VALUES", value)
    const filterNames = Object.keys(state.filters)
    const vehicleTypeIndex = filterNames.indexOf("vehicle_type")
    filterNames.splice(vehicleTypeIndex, 1)
    for (const filterName of filterNames) {
      await dispatch("_updateFilterAndSelectableIfNecessary", {
        filterName: filterName,
      })
    }
  },
  async _updateSelectableValues({ state, commit }, payload) {
    const url = VehicleSearchService.urlFilterNameMatching[payload.filterName]
    const uppercaseSelectableName =
      VehicleSearchService.uppercaseSelectableNameMatching[payload.filterName]
    let queryParams = VehicleSearchService.createQueryParams(
      payload.filterName,
      state.filters,
      state.context_filters
    )
    try {
      const selectableValues = await HttpService.get(
        UrlService.render(url, {}, queryParams)
      )
      commit(`SET_${uppercaseSelectableName}_SELECTABLE_VALUES`, selectableValues)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async _updateFilterAndSelectableIfNecessary({ state, commit, dispatch }, payload) {
    const filterName = payload.filterName
    if (
      VehicleSearchService.mustFilterValuesBeUpdated(
        state.filters,
        state.selectable_values,
        filterName
      )
    ) {
      if (filterName === "model") {
        await dispatch("_updateModelSelectableValues")
        dispatch("_keepOnlyModelsOfCurrentMakes")
      } else {
        await dispatch("_updateSelectableValues", {
          filterName: filterName,
        })
      }
      if (
        filterName === "trim_level" ||
        (filterName === "model" && state.selectable_values.models.length !== 0)
      ) {
        await commit(`SET_IS_DISABLE_FILTER`, {
          filterName: filterName,
          isDisabled: false,
        })
      }
    } else {
      if (!state.context_filters.includes(filterName)) {
        await commit(
          `SET_${VehicleSearchService.uppercaseSelectableNameMatching[filterName]}_SELECTABLE_VALUES`,
          []
        )
        await commit(
          `SET_${VehicleSearchService.uppercaseFilterNameMatching[filterName]}_FILTER_VALUES`,
          []
        )
      }
      commit(`SET_IS_DISABLE_FILTER`, {
        filterName: filterName,
        isDisabled: true,
      })
    }
  },
  async removeFilterValuesIfNotInSelectableValues({ state, commit }, payload) {
    let filtersToControl = []
    for (let filter in state.filters) {
      if (
        state.filters[filter].rank > payload.rank &&
        state.filters[filter].data.length > 0
      ) {
        filtersToControl.push({
          [filter]: state.filters[filter],
        })
      }
    }
    filtersToControl.forEach((filter) => {
      for (let filterKey in filter) {
        const selectableValueKeyName =
          //  TODO: make a func, we should not access attribute directly !!
          VehicleSearchService.filterAndSelectableValueNameMatching[filterKey]
        filter[filterKey].data.forEach((filterValue) => {
          const isFilterInSelectableValues = state.selectable_values[
            selectableValueKeyName
          ].find((selectableValue) => Object.keys(selectableValue)[0] === filterValue)
          if (isFilterInSelectableValues === undefined) {
            commit("REMOVE_PRODUCT_LINE_FILTER_VALUE", {
              name: filterKey,
              value: filterValue,
            })
          }
        })
      }
    })
  },
  async retrieveVehicleVariations({ commit }, payload) {
    const vehicleVariations = await HttpService.get(
      UrlService.render(
        "vehicleVariations",
        {},
        {
          unique_vehicle_id: payload.uniqueVehicleId,
          product_line_id: payload.productLineId,
        }
      )
    )
    commit("SET_VEHICLE_VARIATIONS", vehicleVariations)
  },
  showVehicleInfos({ commit }, id) {
    commit("SET_SHOW_VEHICLE_INFOS", id)
  },
  closeVehicleInfos({ commit }) {
    commit("RESET_SHOW_VEHICLE_INFOS")
  },
  async displaySpecificPageVehicles({ commit, state }, payload) {
    const postPayload = {}
    if (
      state.filters.make.data.length === 0 &&
      state.filters.vehicle_type.data.length === 0
    ) {
      postPayload["make"] = VehicleSearchService.listMakes(
        state.selectable_values.makes
      )
      postPayload["with_pricing_status"] = true
    } else {
      Object.assign(postPayload, {
        ...VehicleSearchService.listQueryFilter(
          state.filters,
          state.program_context_values
        ),
        with_pricing_status: true,
      })
    }
    postPayload["product_line_id"] = payload.productLineId
    const vehicles = await HttpService.post(
      process.env.VUE_APP_ENDPOINT +
        "vehicles/search/?limit=5&page=" +
        payload.pageNumber,
      postPayload
    )
    commit("SET_VEHICLES", vehicles)
  },
  async updateFilter({ state, commit, dispatch }, payload) {
    const uppercaseFilterName =
      VehicleSearchService.uppercaseFilterNameMatching[payload.filter]
    commit(`SET_${uppercaseFilterName}_FILTER_VALUES`, payload.values)
    const filterNames = VehicleSearchService.listFilterValuesWithHigherRank(
      state.filters,
      payload.filter
    )
    for (const filterName of filterNames) {
      await dispatch("_updateFilterAndSelectableIfNecessary", {
        filterName: filterName,
      })
    }
    await dispatch("_setFiltersIfOnlyOneSelectableValue")
  },
  async _setFiltersIfOnlyOneSelectableValue({ commit, dispatch }) {
    for (const filterName of Object.keys(state.filters)) {
      if (filterName !== "vehicle_type") {
        const selectableValues =
          state.selectable_values[
            VehicleSearchService.filterAndSelectableValueNameMatching[filterName]
          ]
        const isFilterDisabled = state.filters[filterName].isDisabled
        if (!isFilterDisabled && selectableValues.length === 1) {
          let forcedValue = null
          if (selectableValues[0].hasOwnProperty("id")) {
            forcedValue = [selectableValues[0].id.toString()]
          } else {
            forcedValue = [Object.keys(selectableValues[0])[0]]
          }
          const uppercaseFilterName =
            VehicleSearchService.uppercaseFilterNameMatching[filterName]
          commit(`SET_${uppercaseFilterName}_FILTER_VALUES`, forcedValue)

          if (filterName === "make") {
            commit("SET_MODEL_FILTER_DISABLED_PROP", false)
            await dispatch("_updateModelSelectableValues")
            dispatch("_keepOnlyModelsOfCurrentMakes")
          }
        }
      }
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getFiltersData: (state) =>
    Object.keys(state.filters).reduce((result, param) => {
      let isHybridIncluded = false
      let filterValues = state.filters[param].data
      if (param === "engine_type") {
        filterValues = state.filters[param].data.filter((value) => {
          if (!isHybridIncluded) {
            isHybridIncluded = VehicleSearchService.hybridTypes.includes(
              Object.values(value)[0]
            )
          }
          return !VehicleSearchService.hybridTypes.includes(Object.values(value)[0])
        })
        if (isHybridIncluded) filterValues.push(i18n.t("vehicleChoice.hybrid"))
      }
      result[param] = filterValues
      return result
    }, {}),
  getIsFiltersDisabled: (state) =>
    Object.keys(state.filters).reduce((result, param) => {
      result[param] = state.filters[param].isDisabled
      return result
    }, {}),
  getVehicleTypesSelectableValues: (state) => {
    let selectableValues = {}
    state.selectable_values.vehicle_type.forEach((value) => {
      Object.assign(selectableValues, { [value.id]: i18n.t(value.local_name) })
    })
    return selectableValues
  },
  getMakeSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.makes
    )
  },
  getModelSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.models
    )
  },
  getTrimLevelSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.trim_levels
    )
  },
  getYearSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.years
    )
  },
  getPowerSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.powers
    )
  },
  getCylindersSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.cylinders
    )
  },
  getEngineTypeSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.engine_types
    )
  },
  listVehicles: (state) => {
    if (state.show_vehicle_infos.is_shown) {
      const vehicleCurrentlyShown = state.vehicles.results.find((result) => {
        return result.id === state.show_vehicle_infos.vehicle_id
      })
      return [vehicleCurrentlyShown]
    }
    return state.vehicles.results
  },
  hasPagination: (state) => {
    return state.vehicles.count > 5
  },
  hasAThirdPagePagination: (state) => {
    return state.vehicles.count > 10
  },
  hasVariations: (state) => Object.keys(state.vehicle_variations).length > 0,
  getFilterRank: (state) => (filterName) => {
    return state.filters[filterName].rank
  },
  areOneVehicleInfosShown: (state) => state.show_vehicle_infos.is_shown,
  areVehicleInfosShown: (state) => (vehicleId) =>
    state.show_vehicle_infos.is_shown &&
    state.show_vehicle_infos.vehicle_id === vehicleId,
  getIsFilterDisabled: (state) => (filterName) => state.filters[filterName].isDisabled,
  listVehicleDataWithVariations: (state) => Object.keys(state.vehicle_variations),
  getVehicleDatumVariations: (state) => (vehicleDatum) => {
    const variations = state.vehicle_variations[vehicleDatum]
    const variationsSortedByValue = Object.entries(variations).sort(([, a], [, b]) =>
      a.localeCompare(b)
    )
    return Object.fromEntries(variationsSortedByValue)
  },
  listSelectableModelIds: (state) =>
    state.selectable_values.models.map((model) => model.id),
  listEngineTypeValuesAllowedByProductLine: (state) => [
    ...state.values_allowed_by_product_line_eligibility_criteria.engine_types,
  ],
  listMakeValuesAllowedByProductLine: (state) => [
    ...state.values_allowed_by_product_line_eligibility_criteria.makes,
  ],
  listModelValuesAllowedByProductLine: (state) => [
    ...state.values_allowed_by_product_line_eligibility_criteria.models,
  ],
  hasEngineTypeValuesAllowedByProductLine: (state, getters) =>
    getters.listEngineTypeValuesAllowedByProductLine.length > 0,
  hasMakeValuesAllowedByProductLine: (state, getters) =>
    getters.listMakeValuesAllowedByProductLine.length > 0,
  hasModelValuesAllowedByProductLine: (state, getters) =>
    getters.listModelValuesAllowedByProductLine.length > 0,
  listAllowedEngineTypeValues: (state, getters) => {
    return getters.hasEngineTypeValuesAllowedByProductLine
      ? getters.listEngineTypeValuesAllowedByProductLine
      : [...state.program_context_values.engine_type]
  },
  listSelectedEngineTypeValues: (state) => state.filters.engine_type.data,
  hasSelectedEngineTypeValues: (state, getters) =>
    getters.listSelectedEngineTypeValues.length > 0,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

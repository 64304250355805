// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

const state = () => ({
  signature: null,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_SIGNATURE(state, signature) {
    state.signature = signature
  },
}

// ACTIONS (camelCase)
const actions = {
  async generateCloudinarySignature({ commit }, payload) {
    try {
      let signature = await HttpService.post(
        UrlService.render("cloudinarySignature"),
        payload
      )
      commit("SET_SIGNATURE", signature)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getSignature: (state) => state.signature,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

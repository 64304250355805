<template>
  <div class="program-product-synthesis_container">
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <div v-else>
      <div class="program-product-synthesis_container__header">
        <a
          href="#"
          :class="{ is_active: selectedMenuItemName === name }"
          v-for="(translatedName, name) in menuItems"
          :key="name"
          @click.prevent="onMenuItemSelection(name)"
          :data-cy="`pp-header-${name}`"
        >
          {{ translatedName }}
        </a>
      </div>
      <table
        v-if="!isMenuItemCoverageSelected"
        class="table table-striped"
        aria-describedby="program product synthesis"
      >
        <thead></thead>
        <tbody>
          <tr
            v-for="(criterion, i) in listCriteriaForSelectedMenuItem"
            :key="i"
            :data-cy="`criteria-list-${criterion}`"
          >
            <td>
              {{ $t(`criteria.${criterion}`) }}
            </td>
            <td>
              {{ getCriterionValue(criterion) }}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        v-else
        class="table table-striped"
        aria-describedby="program product synthesis"
      >
        <thead></thead>
        <tbody>
          <tr
            v-for="(value, key) in durations"
            :key="key"
            :data-cy="`coverage-duration-${key}`"
          >
            <td>
              {{ value }}
            </td>
            <td
              v-if="hasCoverageDurationKmCouple"
              :data-cy="`coverage-kms-for-duration-${key}`"
            >
              {{ getKmsToDisplay(key) }}
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BeneficiaryCriteriaService from "../../services/business/BeneficiaryCriteriaService"

export default {
  name: "ProgramProductSynthesis",
  props: {
    programProduct: {
      type: Object,
      required: true,
    },
    productLineId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      productLineConfig: null,
      hasCoverageDurationKmCouple: false,
      selectedMenuItemName: "product",
      sortedProgramItemsNames: ["product", "compensation", "reparations", "others"],
      menuItems: {},
    }
  },
  computed: {
    ...mapGetters("productLine", [
      "getConfig",
      "getBeneficiaryCriteriaValues",
      "getUserSelectableValues",
      "listCoefCritProgramByGroup",
      "getCoefCritValueLabel",
    ]),
    ...mapGetters("offer", ["listCurrentOfferSharedCoefCriteriaWithSpecificNames"]),
    criteriaNotToDisplay() {
      return this.listCurrentOfferSharedCoefCriteriaWithSpecificNames
    },
    productLine() {
      if (this.productLineId !== 0) {
        return this.programProduct.product_lines.find(
          (pl) => pl.id === this.productLineId
        )
      }
      return this.programProduct.product_lines[0]
    },
    isMenuItemCoverageSelected() {
      return this.selectedMenuItemName === "coverage"
    },
    listCriteriaForSelectedMenuItem() {
      return this.listCoefCritProgramByGroup(
        this.productLine.id,
        this.selectedMenuItemName
      ).filter((criterion) => !this.criteriaNotToDisplay.includes(criterion))
    },
    durations() {
      const bc = this.getBeneficiaryCriteriaValues(this.productLine.id)
      const userSelectableValues = this.getUserSelectableValues(this.productLine.id)
      return BeneficiaryCriteriaService.getCoverageDurationOptions(
        bc,
        userSelectableValues
      )
    },
  },
  methods: {
    init() {
      this.isLoading = true
      this.getProductLineConfig()
      this.hasCoverageDurationKmCouple =
        BeneficiaryCriteriaService.hasCoverageDurationKmCouple(
          this.getBeneficiaryCriteriaValues(this.productLine.id)
        )
      this.buildMenuItems()
      this.selectedMenuItemName = "product"
      this.isLoading = false
    },
    async getProductLineConfig() {
      this.productLineConfig = this.getConfig(this.productLine.id)
      await this.$store.dispatch(
        "productLine/getCoefficientCriteria",
        this.productLine.id
      )
    },
    buildMenuItems() {
      this.menuItems = { ...this.getNonEmptyProgramItems(), ...this.getCoverageItem() }
    },
    getNonEmptyProgramItems() {
      return this.sortedProgramItemsNames.reduce((nonEmptyProgramItems, itemName) => {
        const isNonEmpty = !this.isProgramItemEmpty(itemName)
        if (isNonEmpty) {
          nonEmptyProgramItems[itemName] = this.$t(
            `programParameters.synthesis.menu.${itemName}`
          )
        }
        return nonEmptyProgramItems
      }, {})
    },
    isProgramItemEmpty(itemName) {
      const programItems = this.productLineConfig.coefficient.program
      return (
        programItems.hasOwnProperty(itemName) &&
        (programItems[itemName].length === 0 ||
          programItems[itemName].every((criterion) =>
            this.criteriaNotToDisplay.includes(criterion)
          ))
      )
    },
    getCoverageItem() {
      const translationKey = this.hasCoverageDurationKmCouple
        ? "coverage_duration_mileage"
        : "coverage_duration"
      return { coverage: this.$t(`programParameters.synthesis.menu.${translationKey}`) }
    },
    onMenuItemSelection(itemName) {
      this.selectedMenuItemName = itemName
    },
    getCriterionValue(criterion) {
      return this.getCoefCritValueLabel(this.productLine.id, criterion)
    },
    getKmsToDisplay(duration) {
      const kms = Object.values(
        BeneficiaryCriteriaService.getCoverageKmOptionsForDuration(
          this.getUserSelectableValues(this.productLine.id),
          duration
        )
      )
      return kms.join(", ")
    },
  },
  watch: {
    // TODO Find a way not to use watch (surely needs a rework of all program parameters part)
    programProduct() {
      this.init()
    },
    productLineId() {
      this.init()
    },
  },
  async created() {
    this.init()
  },
}
</script>

<style scoped lang="scss">
.program-product-synthesis_container__header {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 45px;
  margin-bottom: 28px;
  border-bottom: 1px solid $bleucaarea;

  a {
    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 0;
    height: 100%;
    line-height: 45px;

    &.is_active {
      border-bottom: 3px solid $bleucaarea;
      font-family: "Gilroy SemiBold", sans-serif;
    }
  }
}
.program-product-synthesis_container tr > td {
  font-family: "Gilroy", sans-serif;
  text-transform: capitalize;
  font-size: 20px;
}
.program-product-synthesis_container tr > td:first-child {
  text-align: right;
  width: 50%;
}
.program-product-synthesis_container tr > td:last-child {
  font-family: "Gilroy SemiBold", sans-serif;
}
</style>

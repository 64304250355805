<template>
  <div>
    <Flitter
      v-if="isOfferExternal(getCurrentOffer.id)"
      :program-product-id="getCurrentOffer.program_products[0].id"
      is_customization_mode="false"
    ></Flitter>
    <div v-else>
      <OfferSynthesis
        v-if="itemType === 'offer'"
        :offer-in-progress="getCurrentOffer"
        :offer-config="offerConfig"
      ></OfferSynthesis>
      <ProgramProductSynthesis
        v-else-if="itemType === 'program_product'"
        :program-product="item"
        :product-line-id="productLineId"
      ></ProgramProductSynthesis>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OfferSynthesis from "@/components/offer/OfferSynthesis"
import ProgramProductSynthesis from "@/components/offer/ProgramProductSynthesis"
import Flitter from "@/components/presales/components/Flitter.vue"

export default {
  name: "ProgramSynthesis",
  components: { Flitter, ProgramProductSynthesis, OfferSynthesis },
  props: {
    item: { type: Object, required: true },
    itemType: { type: String, required: true },
    productLineId: { type: Number },
  },
  computed: {
    ...mapGetters("offer", ["getCurrentOffer", "isOfferExternal"]),
    ...mapGetters("productLine", ["getConfig"]),
    offerConfig() {
      // we take the config of a pl because they are common to all the pl of an offer
      return this.getConfig(
        this.getCurrentOffer.program_products[0].product_lines[0].id
      )
    },
  },
}
</script>

<style lang="scss"></style>

// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import Vue from "vue"
import i18n from "@/i18n/index"

const state = {
  current_program_id: null,
  programs: null,
  shared_program: null,
  shared_program_status: null,
  shared_program_test_url: null,
  shared_network_test_url: null,
  list_program_status: [
    "DRAFT",
    "SETTING_DESC",
    "SETTING_SYNTHESIS",
    "SETTING_DOCUMENTS",
    "SETTING_IDENTITY",
    "SETTING_NETWORK",
    "ACTIVE",
  ],
  partner_errors: null,
  network_upload_errors: null,
  selected_network: null,
  create_program_error: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PROGRAM(state, program) {
    state.current_program_id = program.id
    if (state.programs === null) {
      state.programs = {}
    }
    Vue.set(state.programs, program.id, program)
  },
  SET_PROGRAMS(state, programs) {
    state.programs = programs.reduce((acc, pl) => {
      acc[pl.id] = pl
      return acc
    }, {})
  },
  SET_SHARED_PROGRAM(state, program) {
    state.shared_program = program
  },
  SET_SHARED_PROGRAM_TEST_URL(state, url) {
    state.shared_program_test_url = url
  },
  SET_SHARED_NETWORK_TEST_URL(state, url) {
    state.shared_network_test_url = url
  },
  SET_SHARED_PROGRAM_STATUS(state, status) {
    state.shared_program_status = status
  },
  SET_SELECTED_NETWORK(state, network) {
    state.selected_network = network
  },
  SET_CURRENT_PROGRAM_ID(state, programId) {
    state.current_program_id = programId
  },
  SET_CREATE_PROGRAM_ERROR(state, error) {
    state.create_program_error = error
  },
  RESET_CREATE_PROGRAM_ERROR(state) {
    state.create_program_error = null
  },
}

// ACTIONS (camelCase)
const actions = {
  _initializeProgram({ commit, dispatch }, program) {
    commit("SET_PROGRAM", program)

    const program_products = program.offers
      .map((offer) => offer.program_products)
      .flat(1)

    const productLines = program_products.reduce((acc, product) => {
      for (const productLine in product.product_lines) {
        // noinspection JSUnfilteredForInLoop
        acc.push({ ...product.product_lines[productLine], product: { id: product.id } })
      }
      return acc
    }, [])
    dispatch("offer/setOffers", program.offers, { root: true })
    dispatch("programProduct/setProgramProducts", program_products, {
      root: true,
    })
    dispatch("productLine/setProductLines", [...productLines], { root: true })
  },

  async create({ commit }, payload) {
    try {
      const current_program = await HttpService.post(
        UrlService.render("programs"),
        payload
      )
      commit("SET_PROGRAM", current_program)
    } catch (e) {
      commit("SET_CREATE_PROGRAM_ERROR", e.data)
      console.error(e)
      throw e
    }
  },

  async getProgram({ dispatch }, id) {
    try {
      let program = await HttpService.get(UrlService.render("programById", { id: id }))
      await dispatch("_initializeProgram", program)
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async getSharedProgram({ commit, dispatch }, sharedToken) {
    try {
      let shared_program = await HttpService.get(
        UrlService.render("programSharedByToken", { id: sharedToken }, { by_token: 1 })
      )
      await dispatch("_initializeProgram", shared_program.program)
    } catch (e) {
      console.error("getSharedProgram failed: ", e)
      if (e && e.status && e.status === 400) {
        const sharedProgramStatus = e.data
        commit("SET_SHARED_PROGRAM_STATUS", sharedProgramStatus)
        await dispatch("gui/showSharingStatusModal", sharedProgramStatus, {
          root: true,
        })
      }
    }
    commit("SET_SHARED_PROGRAM_STATUS", "active")
  },

  async listPrograms({ commit }) {
    try {
      const programs = await HttpService.get(UrlService.render("programs"))
      commit("SET_PROGRAMS", programs)
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async share({ state }, email) {
    try {
      await HttpService.post(UrlService.render("programShared"), {
        email: email,
        program_id: state.current_program_id,
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  async createTestingProgram({ state, commit }) {
    try {
      const programShared = await HttpService.post(UrlService.render("programShared"), {
        program_id: state.current_program_id,
      })
      commit("SET_SHARED_PROGRAM", programShared)
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async getProgramSharedUrl({ commit }, shared_program_pk) {
    try {
      const url_test = await HttpService.get(
        UrlService.render("programSharedTestUrl", { id: shared_program_pk })
      )
      commit("SET_SHARED_PROGRAM_TEST_URL", url_test)
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async getNetworkSharedUrl({ commit }, shared_program_pk) {
    try {
      const url_test = await HttpService.get(
        UrlService.render("networkSharedTestUrl", { id: shared_program_pk })
      )
      commit("SET_SHARED_NETWORK_TEST_URL", url_test)
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async updateStatus({ dispatch, state }, status) {
    try {
      const program = await HttpService.put(
        UrlService.render("programById", { id: state.current_program_id }),
        {
          status: status,
          publishing_type: state.programs[state.current_program_id].publishing_type,
        }
      )
      await dispatch("_initializeProgram", program)
    } catch (e) {
      console.error("updateStatus failed : ", e)
      throw e
    }
  },

  async updateNetwork({ dispatch, state, commit }, payload) {
    try {
      const program = await HttpService.put(
        UrlService.render("programById", { id: state.current_program_id }),
        {
          status: payload.status,
          publishing_type: state.programs[state.current_program_id].publishing_type,
          network_id: payload.network_id,
        }
      )
      await dispatch("_initializeProgram", program)
      commit("SET_SELECTED_NETWORK", payload.network_id)
    } catch (e) {
      console.error("updateStatus failed : ", e)
      throw e
    }
  },

  async updateStatusAndPublishingType({ dispatch, state }, payload) {
    try {
      const program = await HttpService.put(
        UrlService.render("programById", { id: state.current_program_id }),
        payload
      )
      await dispatch("_initializeProgram", program)
    } catch (e) {
      console.error("updateStatus failed : ", e)
      throw e
    }
  },

  resetNetworkUploadErrors({ commit }) {
    commit("RESET_NETWORK_UPLOAD_ERRORS")
  },
  changeCurrentProgramId({ commit }, programId) {
    commit("SET_CURRENT_PROGRAM_ID", programId)
  },
  resetCreateProgramError({ commit }) {
    commit("RESET_CREATE_PROGRAM_ERROR")
  },
}

// GETTERS (camelCase)
const getters = {
  listProgramSettingStatusComplete: (state, getters) => {
    let listStatusCompleted = ["DRAFT"]
    if (state.current_program_id === null) {
      return listStatusCompleted
    }
    for (let s of state.list_program_status) {
      listStatusCompleted.push(s)
      if (s === getters.getProgramStatus) {
        break
      }
    }
    return listStatusCompleted
  },
  getProgram: (state) =>
    state.current_program_id !== null ? state.programs[state.current_program_id] : null,
  getSharedProgram: (state) => state.shared_program,
  getCurrentProgramId: (state) => state.current_program_id,
  getProgramByOfferId: (state, getters) => (offerId) => {
    for (const program of getters.listPrograms) {
      for (const offer of program.offers) {
        if (offer.id === offerId) {
          return program
        }
      }
    }
    return null
  },
  getProgramPartnerId: (state) => state.programs[state.current_program_id].partner.id,
  listPrograms: (state) => (state.programs ? Object.values(state.programs) : []),
  listProgramProductLines: (state) => (program) =>
    state.programs
      ? Object.values(
          state.programs[program.id].offers
            .map((p) => p.program_products)
            .flat(1)
            .reduce((acc, product) => {
              for (const productLine in product.product_lines) {
                // noinspection JSUnfilteredForInLoop
                acc[product.product_lines[productLine].id] =
                  product.product_lines[productLine]
              }
              return acc
            }, {})
        )
      : [],
  listProgramProductLinesWithoutVehicle: (state, getters) => {
    const programProductLines = getters.listProgramProductLines(getters.getProgram)
    return programProductLines.filter((pl) => {
      return pl.config !== null && Object.keys(pl.config.vehicle).length === 0
    })
  },
  getSharedProgramStatus: (state) => state.shared_program_status,
  getSharedProgramTestUrl: (state) => state.shared_program_test_url,
  getSharedNetworkTestUrl: (state) => state.shared_network_test_url,
  getProgramStatus: (state) => state.programs[state.current_program_id].status,
  isReadOnlyInPresale: (state) =>
    state.programs[state.current_program_id].status !== "DRAFT",
  getAgeUnityOptions: (state, getters) => {
    let options = { month: i18n.t("programs.month"), year: i18n.t("programs.year") }
    const program = getters.getProgram
    if (program && program.vehicle_tag.label === "VNPJ") {
      return { day: i18n.t("programs.day") }
    }
    return options
  },
  getSelectedNetworkId: (state) => state.selected_network,
  getCurrentProgramEngineTagLabel: (state) =>
    state.programs[state.current_program_id].engine_tag.label,
  getCurrentProgramVehicleTagLabel: (state) =>
    state.programs[state.current_program_id].vehicle_tag.label,
  listOffersByProgramId: (state) => (programId) => state.programs[programId].offers,
  hasCreateProgramError: (state) => state.create_program_error !== null,
  isExternalProgram: (state) => (programId) =>
    state.programs[programId].offers.length === 1 &&
    state.programs[programId].offers[0].program_products[0].product_lines[0]
      .is_external,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div class="product-search w-100 p-3">
    <div class="product-search__title">
      <h3 class="text-uppercase mt-3 font-size-25">
        {{ $t(`pricingRange.vehicles_var`) }}
      </h3>
    </div>
    <div class="product-search-form">
      <div
        v-for="criteria in Object.keys(value.filters)"
        :key="criteria"
        class="product-search__form__input"
      >
        <div class="my-1 product-search__label">
          {{ $t(`pricingRange.${criteria}`) }}
        </div>
        <div v-if="criteria === 'first_release_date'" class="required-inputs__item">
          <datepicker
            id="first_release_date"
            class="required-inputs__item__input"
            v-model="form.value.filters[criteria].value"
            data-cy="first-release-date"
            input-class="form-control"
            :language="language"
            :monday-first="isMondayFirst"
            :disabled-dates="{ from: new Date() }"
          ></datepicker>
          <div
            v-if="hasError(criteria)"
            class="text-danger font-size-14"
            data-cy="first-release-date-error"
          >
            {{ getFirstReleaseDateError }}
          </div>
        </div>
        <FormInput
          v-else
          v-model="form.value.filters[criteria].value"
          :label="$t(`pricingRange.${criteria}`)"
          :name="`filter-${criteria}`"
          :label-class="['row-2']"
          :type="value.filters[criteria].inputType"
          :placeholder="value.filters[criteria].placeholder"
          :errors="filterErrors(criteria)"
          class="date-picker-vehicle-input"
        ></FormInput>
      </div>
      <div class="product-search__form__input">
        <button
          class="btn btn-primary"
          data-cy="vehicle-on-filter"
          @click.prevent="onUpdateSubscriptionVar"
        >
          {{ $t("button.validate") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import { en, es, fr, ko } from "vuejs-datepicker/dist/locale"
import { mapGetters } from "vuex"

export default {
  name: "VehicleSearch",
  components: { Datepicker },
  // To update the data of the parent (v-model use)
  model: {
    prop: "value",
    event: "filter-input",
  },
  props: {
    value: { type: Object, required: true },
    errors: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      form: {
        value: { ...this.value },
      },
      languages: { en, es, fr, ko },
    }
  },
  computed: {
    ...mapGetters("config", ["getCurrentLang"]),
    language() {
      return this.languages[this.getCurrentLang]
    },
    isMondayFirst() {
      return this.getCurrentLang === "fr" || this.getCurrentLang === "es"
    },
    getFirstReleaseDateError() {
      return this.errors.filters?.first_release_date
    },
  },
  methods: {
    filterErrors(criteria) {
      if (this.errors.filters?.hasOwnProperty(criteria)) {
        return {
          [`filter-${criteria}`]: this.errors.filters[criteria],
        }
      }
      return {}
    },
    hasError(criteria) {
      return this.errors.filters?.hasOwnProperty(criteria)
    },
    onUpdateSubscriptionVar() {
      this.$emit("update-subscription-var", {
        first_release_date: this.form.value.filters.first_release_date.value,
        km: this.form.value.filters.km.value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-search {
  background-color: $background-program;
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 16px;

  .product-search__title {
    flex-shrink: 0;
    align-self: center;
  }

  .product-search__title > h3 {
    margin: 0;
    line-height: 1.5 !important;
    vertical-align: middle;
  }

  .product-search-form {
    display: flex;
  }

  .product-search__form__input {
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 1.9rem;
    }
  }

  .product-search__label {
    font-size: 14px;
    color: #9e9fbb;
  }

  /deep/ {
    .vdp-datepicker__calendar .cell {
      &.selected {
        background: $primary;
        color: #fff;
      }
      &:hover {
        border-color: $primary;
      }
      &.day-header,
      &.blank {
        &:hover {
          border-color: transparent;
        }
      }
      &:not(.blank):not(.disabled) {
        &.day:hover,
        &.month:hover,
        &.year:hover {
          border-color: $primary;
        }
      }
    }
  }
}
</style>

import HttpService, { HttpError } from "@/services/technical/HttpService"
import StringService from "@/services/technical/StringService"
import UrlService from "@/services/technical/UrlService"

// STATES (snake_case)
const state = () => ({
  search_loading: false,
  search_result: { used: null, velastic: null },
  search_errors: { used: {}, velastic: {} },
  velastic_selected: null,
  vehicle_details: null,
  velastic_filters: {},
  selected_vehicle: null,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_SEARCH_LOADING(state, value) {
    state.search_loading = value
  },
  SET_VELASTIC_FILTERS(state, filters) {
    state.velastic_filters = filters
  },
  SET_USED_VEHICLE_SEARCH_RESULT(state, result) {
    state.search_result.used = result
  },
  SET_VELASTIC_SEARCH_RESULT(state, result) {
    state.search_result.velastic = result
  },
  SET_VELASTIC_SEARCH_ERRORS(state, errors) {
    state.search_errors.velastic = errors
  },
  SET_VELASTIC_SELECTED(state, result) {
    state.velastic_selected = result
  },
  SET_VEHICLE_DETAILS(state, result) {
    state.vehicle_details = result
  },
  RESET_VELASTIC_SELECTED(state) {
    state.velastic_selected = null
  },
}

// ACTIONS (camelCase)
const actions = {
  _resetSearch({ commit }, searchName) {
    commit(`RESET_${searchName.toUpperCase()}_SEARCH_RESULT`)
    commit(`RESET_${searchName.toUpperCase()}_SEARCH_ERRORS`)
  },
  resetVelasticSearch({ dispatch }) {
    dispatch("_resetSearch", "velastic")
  },

  async velasticSearch({ commit, dispatch, state }, payload) {
    commit("SET_SEARCH_LOADING", true)
    dispatch("resetVelasticSearch")
    let api_args = null
    if (payload.searchType) {
      api_args = {
        [payload.searchType]: payload.searchText,
        filters: state.velastic_filters,
      }
    } else {
      api_args = {
        text: payload,
        filters: state.velastic_filters,
      }
    }

    try {
      let searchResult = await HttpService.post(
        UrlService.render("velasticSearch"),
        api_args
      )
      commit("SET_VELASTIC_SEARCH_RESULT", searchResult)
      commit("SET_USED_VEHICLE_SEARCH_RESULT", searchResult.vehicles[0])
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 404) {
          commit("SET_VELASTIC_SEARCH_ERRORS", e.data)
          return
        }
      }
      console.error("failed: ", e)
      throw e
    } finally {
      commit("SET_SEARCH_LOADING", false)
    }
  },

  async searchModelByMake(context, make) {
    try {
      return await HttpService.post(UrlService.render("velasticSearchModelsByMake"), {
        make: make,
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  setVelasticSearchResultSelected({ commit, dispatch }, result) {
    dispatch("resetVelasticSearch")
    commit("SET_VELASTIC_SELECTED", result)
  },
  setVehicleDetails({ commit, dispatch }, result) {
    commit("SET_VEHICLE_DETAILS", result)
  },
  setVelasticVehicleFromConfig({ commit, dispatch }, configVehicle) {
    const detailsKeys = ["km", "retail_price", "first_release_date"]
    const details = detailsKeys.reduce((acc, key) => {
      if (configVehicle.hasOwnProperty(key)) {
        acc[key] = configVehicle[key]
        delete configVehicle[key]
      }
      return acc
    }, {})
    commit("SET_VELASTIC_SELECTED", configVehicle)
    commit("SET_VEHICLE_DETAILS", details)
  },
}

// GETTERS (camelCase)
const getters = {
  isSearchLoading: (state) => state.search_loading,
  /**
   * Used Vehicle
   */
  getUsedVehicleFound: (state) => {
    const vehicle = state.search_result.used
    if (!vehicle) return null
    return {
      name: StringService.title(`${vehicle.make} ${vehicle.model}`),
      version: StringService.title(
        `${vehicle.transmission} ${vehicle.fiscal_hp} ${vehicle.energy}`
      ),
    }
  },
  hasVelasticVehiclesSearchResult: (state) => state.search_result.velastic !== null,
  getVehicleFound: (state) => {
    const vehicle = state.velastic_selected
    const vehicle_details = state.vehicle_details
    if (!vehicle) return null

    let vehicleFound = {
      ...vehicle,
      name: `${vehicle.make} ${vehicle.model}`,
      label: `${vehicle.make} ${vehicle.model} ${vehicle.version}`,
    }
    if (vehicle_details) {
      vehicleFound["km"] = vehicle_details.km
      vehicleFound["first_release_date"] = vehicle_details.first_release_date

      // As retail_price is originally provided by Velastic,
      // override it only if the user entered a new value manually (retail price is in vehicle_details)
      if (vehicle_details.hasOwnProperty("retail_price")) {
        vehicleFound["retail_price"] = vehicle_details.retail_price
      }
    }

    return vehicleFound
  },

  /**
   * Velastic
   */
  getVelasticSearchErrors: (state) => state.search_errors.velastic,
  hasVelasticSearchSelected: (state) =>
    state.velastic_selected && Object.keys(state.velastic_selected).length !== 0,
  listVelasticSearchResults: (state) =>
    state.search_result.velastic
      ? state.search_result.velastic.vehicles.map((v) => {
          return {
            ...v,
            ...{
              label: `${v.make} ${v.model} ${v.version}`,
            },
          }
        })
      : [],
  getVehiclePrice: (state) =>
    state.vehicle_details ? state.vehicle_details.retail_price : null,
  getVehicleKm: (state) => (state.vehicle_details ? state.vehicle_details.km : null),
  getVehicleFirstReleaseDate: (state) =>
    state.vehicle_details ? state.vehicle_details.first_release_date : null,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

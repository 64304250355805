// STATES (snake_case)
const state = {}

// MUTATIONS (SNAKE_CASE)
const mutations = {}

// ACTIONS (camelCase)
const actions = {}

// GETTERS (camelCase)
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

// STATES (snake_case)

const state = {
  id_product_line_guarantees_modal_open: null,
  id_product_line_commissions_and_taxes_Modal_open: null,
  id_product_line_frequency_modal_open: null,
  id_product_line_eligibility_modal_open: null,
  id_product_line_deletion_modal_open: null,
  is_payment_frequency_modal_warning_open: false,
  commissions: [],
  caarea_fees: [],
  taxes: [],
  other_fees: [],
  commissionId: 0,
  uwr_target: 0,
  discount: 0,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_GUARANTEES_MODAL_STATE(state, productLineId) {
    state.id_product_line_guarantees_modal_open = productLineId
  },
  SET_COMMISSIONS_AND_TAXES_MODAL_STATE(state, productLineId) {
    state.id_product_line_commissions_and_taxes_Modal_open = productLineId
  },
  SET_FREQUENCY_MODAL_STATE(state, productLineId) {
    state.id_product_line_frequency_modal_open = productLineId
  },
  SET_IS_PAYMENT_FREQUENCY_MODAL_WARNING_OPEN(state, value) {
    state.is_payment_frequency_modal_warning_open = value
  },
  SET_ELIGIBILITY_MODAL_STATE(state, productLineId) {
    state.id_product_line_eligibility_modal_open = productLineId
  },
  SET_PRODUCT_LINE_DELETION_MODAL_STATE(state, productLineId) {
    state.id_product_line_deletion_modal_open = productLineId
  },
  SET_CAAREA_FEES(state, caarea_fees) {
    state.caarea_fees = caarea_fees
  },
  SET_OTHER_FEES(state, other_fees) {
    state.other_fees = other_fees
  },
  SET_UWR_TARGET(state, uwr_target) {
    state.uwr_target = uwr_target
  },
  SET_DISCOUNT(state, discount) {
    state.discount = discount
  },
  DELETE_STORE(state) {
    state.id_product_line_guarantees_modal_open = null
    state.id_product_line_commissions_and_taxes_Modal_open = null
    state.id_product_line_frequency_modal_open = null
    state.commissions = []
    state.caarea_fees = []
    state.taxes = []
    state.other_fees = []
    state.commissionId = 0
    state.uwr_target = 0
    state.discount = 0
  },
}

// ACTIONS (camelCase)
const actions = {
  handleModalOpening({ commit }, payload) {
    commit(`SET_${payload.modalName}_MODAL_STATE`, payload.productLineId)
  },
  setIsPaymentFrequencyModalWarningOpen({ commit }, value) {
    commit("SET_IS_PAYMENT_FREQUENCY_MODAL_WARNING_OPEN", value)
  },
  initTaxesAndFees({ commit, rootGetters }, productLineId) {
    let productLineConfig = rootGetters["productLine/getConfig"](productLineId)
    commit("SET_CAAREA_FEES", productLineConfig.commercial.values.caarea_fees)
    commit("SET_OTHER_FEES", productLineConfig.commercial.values.other_fees)
    commit("SET_DISCOUNT", productLineConfig.commercial.values.discount)
    commit("SET_UWR_TARGET", productLineConfig.commercial.values.uwr_target)
  },
  updateCaareaFees({ commit }, caareaFees) {
    commit("SET_CAAREA_FEES", caareaFees)
  },
  updateOtherFees({ commit }, otherFees) {
    commit("SET_OTHER_FEES", otherFees)
  },
  deleteStore({ commit }) {
    commit("DELETE_STORE")
  },
  async updateProductLine({ state, dispatch, rootGetters }, productLineId) {
    let productLineConfig = rootGetters["productLine/getConfig"](productLineId)
    productLineConfig.commercial.values = {
      caarea_fees: state.caarea_fees,
      other_fees: state.other_fees,
      commissions: state.commissions,
      taxes: state.taxes,
      uwr_target: state.uwr_target,
      discount: state.discount,
    }
    const payload = {
      productLineId,
      data: {
        config: productLineConfig,
      },
    }
    await dispatch("productLine/update", payload, { root: true })
  },
}

// GETTERS (camelCase)
const getters = {
  isGuaranteesModalOpen: (state) => (id) =>
    state.id_product_line_guarantees_modal_open &&
    state.id_product_line_guarantees_modal_open === id,
  isCommissionsAndTaxesModalOpen: (state) => (id) =>
    state.id_product_line_commissions_and_taxes_Modal_open &&
    state.id_product_line_commissions_and_taxes_Modal_open === id,
  isFrequencyModalOpen: (state) => (id) =>
    state.id_product_line_frequency_modal_open &&
    state.id_product_line_frequency_modal_open === id,
  isEligibilityModalOpen: (state) => (id) =>
    state.id_product_line_eligibility_modal_open &&
    state.id_product_line_eligibility_modal_open === id,
  isProductLineDeletionModalOpen: (state) => (id) =>
    state.id_product_line_deletion_modal_open &&
    state.id_product_line_deletion_modal_open === id,
  isPaymentFrequencyModalWarningOpen: (state) =>
    state.is_payment_frequency_modal_warning_open,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

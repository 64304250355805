<template>
  <spinner v-if="isLoading" class="mt-5"></spinner>
  <div v-else>
    <div class="w-80 mx-auto container-fluid bg-white mb-4">
      <h4 class="text-center py-3 font-effra-light">
        {{ $t("programParameters.network.title") }}
      </h4>
      <div class="w-95 text-center container-fluid pt-3 pb-4">
        <div class="d-flex justify-content-around">
          <div v-for="(step, stepName) in steps" :key="stepName" class="">
            <a
              @click="onStepClick(stepName)"
              :data-cy="'step-' + stepName"
              :class="isStepClickable(stepName) ? 'mouse-pointer' : 'mouse-forbidden'"
            >
              <span
                class="font-size-25"
                :class="[
                  isStepComplete(stepName) ? 'icon-checkmark-circle' : 'icon-circle',
                  isStepActive(stepName) ? 'active-step' : 'inactive-step',
                ]"
                :data-cy="`program-parameters-step-${stepName}`"
              ></span>
              <div
                class="font-size-13"
                :class="[isStepActive(stepName) ? 'active-step' : 'inactive-step']"
              >
                {{ step.title }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="w-80 mx-auto container-fluid bg-white pt-3 pb-3 steps-container">
        <div class="row">
          <router-view
            class="col-12"
            @network-selected="onNetworkSelected"
            @publishing-done="finishCurrentStep"
          ></router-view>
        </div>
      </div>
    </div>
    <ModalFeedback
      v-if="feedbackModalShown"
      :title="$t('programParameters.feedbackNetworkModal.title')"
      :message="$t('programParameters.feedbackNetworkModal.message')"
      @close="onCloseFeedbackModal"
    ></ModalFeedback>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ModalFeedback from "@/components/modal/ModalFeedback"
import LayoutProgramParametersMixin from "@/mixins/LayoutProgramParametersMixin"

export default {
  name: "LayoutProgramNetworkParameters",
  components: { ModalFeedback },
  mixins: [LayoutProgramParametersMixin],
  data() {
    return {
      steps: {
        network: {
          status: { start: "SETTING_IDENTITY", end: "SETTING_NETWORK" },
          title: this.$t("programParameters.network.title2"),
          previousStep: "identity",
          nextStep: "publishing",
        },
        publishing: {
          status: { start: "SETTING_NETWORK", end: "ACTIVE" },
          title: this.$t("programParameters.publishing.title2"),
          previousStep: "network",
          nextStep: null,
        },
      },
      mappingUrlNameStep: {
        programNetwork: "network",
        programPublishing: "publishing",
      },
      activeStepName: "network",
      isLoading: true,
      feedbackModalShown: false,
    }
  },
  computed: {
    ...mapGetters("program", ["listProgramSettingStatusComplete", "getProgram"]),
    ...mapGetters("auth", ["getRouteName"]),
  },
  methods: {
    onCloseFeedbackModal() {
      this.feedbackModalShown = false
      this.$router.push({ name: "programs" })
    },
    async onNetworkSelected(network) {
      await this.finishCurrentStep(network, null)
    },
    async finishCurrentStep(network, publishing_type = null) {
      if (publishing_type !== null) {
        const payload = {
          status: this.activeStep.status.end,
          publishing_type,
        }
        await this.$store.dispatch("program/updateStatusAndPublishingType", payload)
      } else {
        const payload = {
          status: this.activeStep.status.end,
          network_id: network.id,
        }
        await this.$store.dispatch("program/updateNetwork", payload)
      }
      if (this.activeStep.nextStep) {
        await this.changeStep(this.activeStep.nextStep)
      } else {
        // This is the end...
        await this.$router.push({
          name: this.getRouteName("programParametersStartpage"),
          params: { program: this.getProgram },
        })
      }
    },
  },
  async created() {
    await this.$store.dispatch(
      "program/getProgram",
      this.$router.currentRoute.params.programId
    )
    this.activeStepName = this.mappingUrlNameStep.hasOwnProperty(
      this.$router.currentRoute.name
    )
      ? this.mappingUrlNameStep[this.$router.currentRoute.name]
      : "network"
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
.inactive-step {
  opacity: 0.5;
}

.active-step {
  font-weight: bold;
}

.card {
  border-radius: 0;
  border: none;
  background-color: transparent;
}

.layout-program-button {
  width: 80%;
  display: grid;

  button {
    justify-self: end;
  }
}

.w-80 {
  width: 80% !important;
}

.w-95 {
  width: 95% !important;
}

.vl {
  border-right: 1px solid lightgray;
}

.steps-container {
  min-height: 350px;
}
</style>

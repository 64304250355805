// STATES (snake_case)
import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import FileUploadService from "@/store/services/FileUploadService"

const state = {
  program_products: null,
  eligible_product_lines: null,
  coefficient_criteria: null,
  mutual_agreement_upload_errors: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PROGRAM_PRODUCT(state, programProduct) {
    if (state.program_products === null) state.program_products = {}
    Vue.set(state.program_products, programProduct.id, programProduct)
  },
  SET_COEFFICIENT_CRITERIA(state, payload) {
    if (state.coefficient_criteria === null) state.coefficient_criteria = {}
    Vue.set(
      state.coefficient_criteria,
      payload.programProductId,
      payload.coefficientCriteria
    )
  },
  RESET_COEFFICIENT_CRITERIA(state) {
    state.coefficient_criteria = null
  },
  SET_ELIGIBLE_PRODUCT_LINES(state, payload) {
    if (state.eligible_product_lines === null) state.eligible_product_lines = {}
    Vue.set(
      state.eligible_product_lines,
      payload.programProductId,
      payload.eligibleProductLines
    )
  },
  RESET_ELIGIBLE_PRODUCT_LINES(state) {
    state.eligible_product_lines = null
  },
  SET_MUTUAL_AGREEMENT_UPLOAD_ERRORS(state, errors) {
    state.mutual_agreement_upload_errors = errors
  },
  RESET_MUTUAL_AGREEMENT_UPLOAD_ERRORS(state) {
    state.mutual_agreement_upload_errors = null
  },
}

// ACTIONS (camelCase)
const actions = {
  setProgramProducts({ commit }, programProducts) {
    programProducts.forEach((programProduct) =>
      commit("SET_PROGRAM_PRODUCT", programProduct)
    )
    commit("RESET_COEFFICIENT_CRITERIA")
  },
  async retrieve({ commit, dispatch }, id) {
    try {
      const programProduct = await HttpService.get(
        UrlService.render("programProductById", { id: id })
      )
      commit("SET_PROGRAM_PRODUCT", programProduct)
      await dispatch("productLine/setProductLines", programProduct.product_lines, {
        root: true,
      })
    } catch (e) {
      console.error("program_product update failed: ", e)
      throw e
    }
  },
  async update({ commit, dispatch }, payload) {
    try {
      const programProduct = await HttpService.put(
        UrlService.render("programProductById", { id: payload.id }),
        payload.data
      )
      commit("SET_PROGRAM_PRODUCT", programProduct)
    } catch (e) {
      console.error("program_product update failed: ", e)
      throw e
    }
  },
  async updateAndSaveInStore({ commit, dispatch }, payload) {
    await dispatch("update", payload)
  },
  async getCoefficientCriteria({ commit, dispatch }, programProductId) {
    try {
      const coefficientCriteria = await HttpService.get(
        UrlService.render("programProductCoefficientCriteria", {
          id: programProductId,
        })
      )
      commit("SET_COEFFICIENT_CRITERIA", {
        programProductId: programProductId,
        coefficientCriteria: coefficientCriteria,
      })
    } catch (e) {
      console.error("program_product get coefficient criteria failed", e)
      throw e
    }
  },

  async uploadMutualAgreement({ commit, state, dispatch }, payload) {
    commit("RESET_MUTUAL_AGREEMENT_UPLOAD_ERRORS")
    try {
      const programProduct = await FileUploadService.upload(
        payload.file,
        payload.programProductId,
        "programProductMutualAgreement"
      )
      commit("SET_PROGRAM_PRODUCT", programProduct)
    } catch (e) {
      console.error("mutual agreement upload failure", e)
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 400) {
          commit("SET_MUTUAL_AGREEMENT_UPLOAD_ERRORS", e.data)
        }
        return
      }
      throw e
    }
  },

  resetUploadMutualUploadErrors({ commit }) {
    commit("RESET_MUTUAL_AGREEMENT_UPLOAD_ERRORS")
  },

  async activate({ commit }, programProductId) {
    try {
      const programProduct = await HttpService.post(
        UrlService.render("programProductActivation", { id: programProductId })
      )
      commit("SET_PROGRAM_PRODUCT", programProduct)
    } catch (e) {
      console.error("program_product activation failed: ", e)
      throw e
    }
  },

  async listEligibleProductLines({ commit }, payload) {
    try {
      const eligibleProductLines = await HttpService.post(
        UrlService.render("programProductEligibleProductLines", {
          id: payload.programProductId,
        }),
        { vehicle: payload.vehicle }
      )
      commit("SET_ELIGIBLE_PRODUCT_LINES", {
        programProductId: payload.programProductId,
        eligibleProductLines: eligibleProductLines,
      })
    } catch (e) {
      console.error("listEligibleProductLines failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  hasProgramProduct: (state) => (id) =>
    state.program_products !== null && id in state.program_products,
  getProgramProductById: (state) => (id) => {
    return state.program_products[id]
  },
  getProgramProductProductLines: (state) => (id) =>
    state.program_products[id].product_lines,
  getProgramProductProductLinesIds: (state) => (id) =>
    state.program_products[id].product_lines.map((pl) => pl.id),
  getProgramProductName: (state) => (id) => state.program_products[id].name,
  getProductCode: (state) => (id) =>
    JSON.parse(JSON.stringify(state.program_products[id].code)),
  isVn: (state, getters) => (id) =>
    getters.getProductCode(id) === "extension_garantie_vn" ||
    getters.getProductCode(id) === "extension_garantie_vn_elec",
  listProducts: (state) => {
    return Object.values(state.program_products)
  },
  isActive: (state) => (product) => {
    return product.status === "ACTIVE"
  },
  getCoefficientCriteria: (state) => (programProductId) => {
    return state.coefficient_criteria && programProductId in state.coefficient_criteria
      ? state.coefficient_criteria[programProductId]
      : null
  },
  getEligibleProductLines: (state) => (programProductId) => {
    return state.eligible_product_lines &&
      programProductId in state.eligible_product_lines
      ? state.eligible_product_lines[programProductId]
      : null
  },
  isPricingMutualAgreement: (state) => (id) => {
    return state.program_products[id].hasOwnProperty("config") &&
      state.program_products[id].config.hasOwnProperty("conditions") &&
      state.program_products[id].config.conditions.hasOwnProperty("pricing")
      ? state.program_products[id].config.conditions.pricing === "mutual_agreement"
      : false
  },
  listProductsWithMissingMutualAgreement: (state, getters) => {
    return getters.listProducts.filter(
      (product) =>
        getters.isPricingMutualAgreement(product.id) &&
        product.mutual_agreement_file === null
    )
  },
  getConfig: (state) => (id) => {
    return JSON.parse(JSON.stringify(state.program_products[id].config))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div>
    <HeaderUsers />
    <main class="card-user">
      <div class="user-header">
        <h2>{{ $t($router.currentRoute.meta.usersTitle) }}</h2>
      </div>
      <router-view />
    </main>
  </div>
</template>

<script>
import HeaderUsers from "@/components/users/HeaderUsers"

export default {
  name: "LayoutUser",
  components: {
    HeaderUsers,
  },
}
</script>

<style lang="scss" scoped>
.card-user {
  background: #fff;
  margin-bottom: 24px;
  .user-header {
    background-color: $caareagray;
    padding: 20px;
    h2 {
      font-family: "Effra_semi_bold", sans-serif;
      font-size: 21px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
  .user-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 80px 80px 40px;
  }
}
</style>

import i18n from "@/i18n"
import VehicleSearchService from "@/services/business/VehicleSearchService"
// GETTERS (camelCase)
export const getters = {
  /**
   * Program criteria
   */
  listCustomProgramCriteria: (state) => (productLineId) => {
    return state.custom_program_criteria_list_by_product_line_id.hasOwnProperty(
      productLineId
    )
      ? JSON.parse(
          JSON.stringify(
            state.custom_program_criteria_list_by_product_line_id[productLineId]
          )
        )
      : []
  },
  getProgramCriteriaValues: (state) => (productLineId) =>
    state.program_criteria_values_by_product_line_id.hasOwnProperty(productLineId)
      ? JSON.parse(
          JSON.stringify(
            state.program_criteria_values_by_product_line_id[productLineId]
          )
        )
      : null,
  getProgramCriterionValue: (state) => (productLineId, criterion) =>
    state.program_criteria_values_by_product_line_id.hasOwnProperty(productLineId)
      ? state.program_criteria_values_by_product_line_id[productLineId][criterion]
      : null,
  // Warning : keep the 'rootState' parameter even if it's not used, otherwise rootGetters won't work !
  getProgramCriterionValueToDisplay:
    (state, getters, rootState, rootGetters) => (productLineId, criterion) => {
      const value = getters.getProgramCriterionValue(productLineId, criterion)
      if (Array.isArray(value)) {
        const values = value.map(
          (v) =>
            rootGetters["productLine/getCoefCritOptions"](productLineId, criterion)[v]
        )
        return values.join(", ")
      }
      return rootGetters["productLine/getCoefCritOptions"](productLineId, criterion)[
        value
      ]
    },
  getProgramCriterionLabelToDisplay: (state) => (criterion) => {
    return i18n.t("criteria." + criterion)
  },
  /**
   * User selectable values
   */
  getUserSelectableValues: (state) => (productLineId) =>
    state.user_selectable_values_by_product_line_id.hasOwnProperty(productLineId)
      ? JSON.parse(
          JSON.stringify(state.user_selectable_values_by_product_line_id[productLineId])
        )
      : null,
  /**
   * Payment Frequency
   */
  getPaymentFrequencyValue: (state) => (productLineId) =>
    state.payment_frequency_value_by_product_line_id.hasOwnProperty(productLineId)
      ? state.payment_frequency_value_by_product_line_id[productLineId]
      : null,
  isPaymentFrequencyMonthly: (state, getters) => (productLineId) =>
    getters.getPaymentFrequencyValue(productLineId) !== null &&
    getters.getPaymentFrequencyValue(productLineId) === "monthly",
  /**
   * Beneficiary criteria
   */
  getBeneficiaryCriteriaValues: (state) => (productLineId) =>
    state.beneficiary_criteria_values_by_product_line_id.hasOwnProperty(productLineId)
      ? JSON.parse(
          JSON.stringify(
            state.beneficiary_criteria_values_by_product_line_id[productLineId]
          )
        )
      : null,
  /**
   * Eligibility criteria
   */
  listCustomEligibilityCriteria: (state) => (productLineId) => {
    return state.custom_eligibility_criteria_list_by_product_line_id.hasOwnProperty(
      productLineId
    )
      ? JSON.parse(
          JSON.stringify(
            state.custom_eligibility_criteria_list_by_product_line_id[productLineId]
          )
        )
      : []
  },
  getEligibilityCriteriaValues: (state) => (productLineId) => {
    return state.eligibility_criteria_values_by_product_line_id.hasOwnProperty(
      productLineId
    )
      ? JSON.parse(
          JSON.stringify(
            state.eligibility_criteria_values_by_product_line_id[productLineId]
          )
        )
      : null
  },
  getEligibilityCriterionValue: (state, getters) => (productLineId, criterion) => {
    return getters.getEligibilityCriteriaValues(productLineId).hasOwnProperty(criterion)
      ? getters.getEligibilityCriteriaValues(productLineId)[criterion]
      : null
  },
  //  TODO: refacto les 3 methodes ci-dessous ?
  getMakeCriterionValueToDisplay: (state, getters) => (productLineId) => {
    const criterion = "make"
    const values = getters.getEligibilityCriterionValue(productLineId, criterion)
    if (values.length > 0) {
      return values.map((make) => make.local_name).join(", ")
    }
    return i18n.t("criteria.eligibility.empty.make")
  },
  getModelCriterionValueToDisplay: (state, getters) => (productLineId) => {
    const criterion = "model"
    const values = getters.getEligibilityCriterionValue(productLineId, criterion)
    if (values.length > 0) {
      return values.map((model) => model.local_name).join(", ")
    }
    return i18n.t("criteria.eligibility.empty.model")
  },
  getEngineCodeCriterionValueToDisplay:
    (state, getters, rootState, rootGetters) => (productLineId) => {
      const criterion = "engine_code"
      const engineCodes = getters.getEligibilityCriterionValue(productLineId, criterion)
      if (engineCodes.length > 0) {
        let isHybridIncluded = false
        let values = []
        engineCodes.forEach((engineCode) => {
          if (VehicleSearchService.hybridTypes.includes(engineCode)) {
            isHybridIncluded = true
          } else {
            values.push(
              rootGetters["offerContext/getEngineTypeSelectableValues"][engineCode]
            )
          }
        })
        if (isHybridIncluded) values.push(i18n.t("vehicleChoice.hybrid"))
        return values.join(", ")
      }
      return i18n.t("criteria.eligibility.empty.engine_code")
    },
  getCriterionValueToDisplay:
    (state, getters, rootState, rootGetters) => (productLineId, criterion, getter) => {
      let names = []
      const values = getters.getEligibilityCriterionValue(productLineId, criterion)
      if (values.length > 0) {
        const objects = rootGetters[getter]
        names = Object.entries(objects).reduce((acc, [k, v]) => {
          if (values.includes(k)) acc.push(v)
          return acc
        }, [])
        return names.join(", ")
      }
      return (
        rootGetters["productLine/getCoefCritOptions"](productLineId, criterion)[
          value
        ] || i18n.t("criteria.eligibility.empty.make")
      )
    },
  getMaxAgeCriterionValueToDisplay: (state, getters) => (productLineId) => {
    const maxAgeValue = getters.getEligibilityCriterionValue(productLineId, "max_age")
    const maxAgeUnityValue = getters.getEligibilityCriterionValue(
      productLineId,
      "max_age_unity"
    )
    return `${maxAgeValue} ${i18n.t("programs." + maxAgeUnityValue)}`
  },
  getEligibilityCriterionValueToDisplay:
    (state, getters) => (productLineId, criterion) => {
      const value = getters.getEligibilityCriterionValue(productLineId, criterion)
      if (Array.isArray(value)) {
        return value.join(", ")
      }
      return value
    },
  /**
   * Vehicle
   */
  getVehicle: (state) => (productLineId) => {
    return state.vehicle_by_product_line_id.hasOwnProperty(productLineId)
      ? JSON.parse(JSON.stringify(state.vehicle_by_product_line_id[productLineId]))
      : null
  },
  isVehicleSelected: (state, getters) => (productLineId) => {
    const vehicle = getters.getVehicle(productLineId)
    // Csback init vehicle with {} not null...
    return vehicle !== null && Object.keys(vehicle).length !== 0
  },
  getVehicleInfoToDisplay: (state, getters) => (productLineId) => {
    const vehicle = getters.getVehicle(productLineId)
    return getters.isVehicleSelected(productLineId)
      ? `${vehicle.local_model} ${vehicle.local_version[0]}`
      : i18n.t("programs.form.beneficiaryCriteria.vehicle.action")
  },
  /**
   * Commercial criteria
   */
  getCommercialCriteriaValues: (state) => (productLineId) =>
    state.commercial_criteria_values_by_product_line_id.hasOwnProperty(productLineId)
      ? JSON.parse(
          JSON.stringify(
            state.commercial_criteria_values_by_product_line_id[productLineId]
          )
        )
      : null,
  hasDifferentCommercialConfigInProductLines: (state, getters) => {
    const firstProductLineId = getters.listProductLinesIds[0]
    const commissionsRef = JSON.stringify(
      state.commercial_criteria_values_by_product_line_id[firstProductLineId]
        .commissions
    )
    const taxesRef = JSON.stringify(
      state.commercial_criteria_values_by_product_line_id[firstProductLineId].taxes
    )
    for (const productLineId of getters.listProductLinesIds) {
      let commissions =
        state.commercial_criteria_values_by_product_line_id[productLineId].commissions
      let taxes =
        state.commercial_criteria_values_by_product_line_id[productLineId].taxes
      if (
        JSON.stringify(commissions) !== commissionsRef ||
        JSON.stringify(taxes) !== taxesRef
      ) {
        return true
      }
    }
    return false
  },
  /**
   * Others
   */
  listProductLinesIds: (state) => state.product_lines_ids,
  getLastAddedProductLineId: (state, getters) => {
    const productLinesIds = getters.listProductLinesIds
    return productLinesIds.reduce((maxId, productLineId) => {
      return productLineId > maxId ? productLineId : maxId
    }, productLinesIds[0])
  },
  getProgramProductId: (state) => state.program_product_id,
  isProgramProductCustomizationLoading: (state) =>
    state.is_program_product_customization_loading,
  isPricingInProgress: (state) => (productLineId) =>
    state.is_pricing_in_progress_by_product_line_id.hasOwnProperty(productLineId) &&
    state.is_pricing_in_progress_by_product_line_id[productLineId],
  hasProgramProductSeveralProductLines: (state, getters) =>
    getters.listProductLinesIds.length > 1,
  getModelLocalNameFromName: (state) => (modelName) => {
    const model = state.models.filter((model) => model.name === modelName)[0]
    return model?.local_name
  },
  getModelIdFromName: (state) => (modelName) => {
    const model = state.models.filter((model) => {
      return model.name === modelName
    })[0]
    return model.id.toString()
  },
  isVehicleUpdateWithinProgramInProgress: (state) =>
    state.is_vehicle_update_within_program_in_progress,
}

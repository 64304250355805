<template>
  <ModalBootstrap
    class="criteria"
    :title="title"
    modal-size="large"
    @close="onClose"
    data-cy="warning-modal"
  >
    <template #body>
      <div class="mx-0 mt-4 mb-3 d-flex flex-column justify-content-center">
        <div class="d-flex flex-row justify-content-center align-items-center pb-4">
          <div>
            <i class="p-2 text-primary icon-warning warning" aria-hidden="true"></i>
          </div>
          <div class="col-9 pt-2 ml-3 font-size-20" data-cy="warning-reason">
            <p v-html="reason" data-cy="warning-details"></p>
          </div>
        </div>
        <slot></slot>
        <div
          v-if="advice"
          class="font-size-16 text-center advice"
          data-cy="warning-advice"
        >
          <p>{{ advice }}</p>
        </div>
        <div class="pt-2 text-center">
          <button
            class="btn btn-primary btn-centered w-25 px-1 mb-1"
            @click.prevent="onClose"
            data-cy="warning-ok-button"
          >
            Ok
          </button>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"

export default {
  name: "ModalWarning",
  components: { ModalBootstrap },
  props: {
    title: { type: String, required: true },
    reason: { type: String, required: true },
    advice: { type: String, default: null },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.warning {
  font-size: 60px;
  font-weight: bold;
}

.advice {
  // To interpret line breaks
  white-space: pre-wrap;
}
</style>

<template>
  <div>
    <div v-if="itemType === 'program'">
      <div
        class="external-product-document"
        v-if="isExternalProgram(getCurrentProgramId)"
        data-cy="document-external-product"
      >
        {{ $t("programParameters.documents.noDocumentNeeded") }}
      </div>
      <div v-else v-for="document in documents" :key="document.name">
        <ProgramDocUpload
          class="mb-4"
          :document="document"
          :program="item"
          @upload-error="onUploadError"
        ></ProgramDocUpload>
      </div>
    </div>
    <div v-else>
      <div
        class="external-product-document"
        v-if="isOfferExternal(getCurrentOffer.id)"
        data-cy="document-external-product"
      >
        {{ $t("programParameters.documents.noDocumentNeeded") }}
      </div>
      <div v-else v-for="document in documents" :key="document.name">
        <OfferDocUpload
          class="mb-4"
          :document="document"
          :offer="item"
          @upload-error="onUploadError"
        ></OfferDocUpload>
      </div>
    </div>
    <ModalWarning
      v-if="uploadError"
      :title="$t('programParameters.documents.error_modal.title')"
      :reason="uploadError"
      @close="onCloseModal"
    ></ModalWarning>
  </div>
</template>

<script>
import ProgramDocUpload from "@/components/programParameters/ProgramDocUpload"
import OfferDocUpload from "@/components/programParameters/OfferDocUpload"
import { mapGetters } from "vuex"
import ModalWarning from "@/components/modal/ModalWarning"

export default {
  name: "ProgramDocuments",
  components: { ProgramDocUpload, OfferDocUpload, ModalWarning },
  props: {
    item: { type: Object, required: true },
    itemType: { type: String, required: true },
  },
  data() {
    return {
      uploadError: null,
    }
  },
  computed: {
    ...mapGetters("offer", [
      "getOfferNumberInProgram",
      "isOfferExternal",
      "getCurrentOffer",
    ]),
    ...mapGetters("program", ["isExternalProgram", "getCurrentProgramId"]),
    programDocuments() {
      return [
        {
          type: "IPID_SHEET",
          name: this.$t("programParameters.documents.ipid"),
        },
        {
          type: "WELCOME_PACK_CONCESSION",
          name: this.$t("programParameters.documents.welcome_pack_dealership"),
        },
        {
          type: "WELCOME_PACK_BENEFICIARY",
          name: this.$t("programParameters.documents.welcome_pack_beneficiary"),
        },
      ]
    },
    offerDocuments() {
      let docs = [
        {
          type: "GENERAL_CONDITIONS",
          name: this.$t("programParameters.documents.general_conditions"),
        },
        {
          type: "PARTICULAR_CONDITIONS",
          name: this.$t("programParameters.documents.particular_conditions"),
        },
      ]
      docs.map(
        (doc) =>
          (doc.name = `${doc.name} - ${this.$t(
            "programParameters.documents.offer_number"
          )}${this.getOfferNumber(this.item.id)}`)
      )
      return docs
    },
    documents() {
      return this.itemType === "program"
        ? this.programDocuments
        : this.itemType === "offer"
        ? this.offerDocuments
        : []
    },
  },
  methods: {
    getOfferNumber(offerId) {
      return this.getOfferNumberInProgram(parseInt(offerId))
    },
    onUploadError(error) {
      // Only display first error for now
      this.uploadError = Object.values(error)[0]
    },
    onCloseModal() {
      if (this.itemType === "program") {
        this.$store.dispatch("programDocument/resetUploadError")
      } else {
        this.$store.dispatch("offerDocument/resetUploadError")
      }
      this.uploadError = null
    },
  },
}
</script>
<style>
.external-product-document {
  text-align: center;
}
</style>

<template>
  <div
    class="list-group-item"
    :data-cy="`productline-list-item-${displayedProductLine.id}`"
    :style="[isModal ? borderNone : background, !isLast ? borderShareList : '']"
  >
    <div class="container">
      <div class="row justify-content-start text-right">
        <div
          class="col-4"
          :data-cy="`productline-${displayedProductLine.id}-uwr-target`"
        >
          <span>{{ $t("parameters.targetSubscriptionRate") }} :</span>
          <span class="text-secondary font-size-19 ml-3">
            {{
              displayedProductLine.config.commercial.values.uwr_target ||
              displayedProductLine.config.commercial.default_values.uwr_target
            }}
            %
          </span>
        </div>
        <div
          class="col-4"
          :data-cy="`productline-${displayedProductLine.id}-caarea-fees`"
        >
          <span>{{ $t("parameters.caareaFees") }} :</span>
          <span class="text-secondary font-size-19 ml-3">
            {{
              displayedProductLine.config.commercial.values.caarea_fees ||
              displayedProductLine.config.commercial.default_values.caarea_fees
            }}
            %
          </span>
        </div>
        <div
          class="col-4"
          :data-cy="`productline-${displayedProductLine.id}-other-fees`"
        >
          <span>{{ $t("parameters.otherFees") }} :</span>
          <span class="text-secondary font-size-19 ml-3">
            {{
              displayedProductLine.config.commercial.values.other_fees ||
              displayedProductLine.config.commercial.default_values.other_fees
            }}
            %
          </span>
        </div>
      </div>
      <div class="row justify-content-start text-right mt-3">
        <div class="col-4" :data-cy="`productline-${displayedProductLine.id}-taxes`">
          <span>{{ $t("parameters.taxes") }} :</span>
          <span class="text-secondary font-size-19 ml-3">
            {{
              displayedProductLine.config.commercial.values.taxes ||
              displayedProductLine.config.commercial.default_values.taxes
            }}
            %
          </span>
        </div>
        <div
          class="col-4"
          :data-cy="`productline-${displayedProductLine.id}-commission`"
        >
          <span>{{ $t("parameters.partnerCommission") }} :</span>
          <span class="text-secondary font-size-19 ml-3">
            {{
              displayedProductLine.config.commercial.values.commission ||
              displayedProductLine.config.commercial.default_values.commission
            }}
            %
          </span>
        </div>
        <div class="col-4" :data-cy="`productline-${displayedProductLine.id}-discount`">
          <span>{{ $t("parameters.discount") }} :</span>
          <span class="text-secondary font-size-19 ml-3">
            {{
              displayedProductLine.config.commercial.values.discount ||
              displayedProductLine.config.commercial.default_values.discount
            }}
            %
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  data: function () {
    return {
      borderNone: {
        border: "none",
      },
      borderShareList: {
        borderBottom: "1px solid #D8D8D8",
      },
      background: {
        backgroundColor: "#efede9 !important",
      },
    }
  },
  props: ["productLine", "isModal", "isLast"],
  computed: {
    displayedProductLine() {
      return this.productLine
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <div v-else>
      <ModalWarning
        v-if="warningModal.show"
        :title="warningModal.title"
        :reason="warningModal.reason"
        :advice="warningModal.advice"
        @close="onCloseWarningModal"
      ></ModalWarning>
      <div class="visual-identity">
        <div class="visual-identity__choices">
          <div
            v-for="(offer, index) in program.offers"
            :key="index"
            class="visual-identity__choices__item"
          >
            <label
              class="visual-identity__choices__item-label font-effra-medium font-size-18"
              :data-cy="`visual-label-${index + 1}`"
            >
              {{ getVisualLabel(offer, index) }}
            </label>
            <CloudinaryUploadWidget
              :upload-preset="preset_name"
              btn-style="width: 100%"
              :button-text="$t('button.choosePhoto')"
              :data-cy="`visual-upload-button-${index + 1}`"
              @image-uploaded="onUploadedImage(offer, $event)"
              :ref="`cloudinaryUploadWidgetComponent-${index + 1}`"
            ></CloudinaryUploadWidget>
          </div>
        </div>
        <div class="visual-identity__preview">
          <button
            class="btn btn-light-gray btn-block visual-identity__preview__button font-roboto-medium"
            :disabled="false"
            @click="onPreviewButtonClick"
            data-cy="visual-preview-button"
          >
            <em class="icon-play-circle"></em>
            <span>
              {{ $t("button.preview") }}
            </span>
          </button>
        </div>
        <p
          class="visual-identity__instructions font-effra-light font-size-18"
          data-cy="visual-instructions"
        >
          <span class="font-effra-light">
            {{ $t("programParameters.visual_identity.instructions.part1") }}
          </span>
          <span class="font-effra-medium">
            {{ $t("programParameters.visual_identity.instructions.part2") }}
          </span>
          <span class="font-effra-light">
            {{ $t("programParameters.visual_identity.instructions.part3") }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CloudinaryUploadWidget from "@/components/utils/CloudinaryUploadWidget"
import ModalWarning from "@/components/modal/ModalWarning"
import { mapGetters } from "vuex"

export default {
  name: "VisualIdentity",
  components: { CloudinaryUploadWidget, ModalWarning },
  data() {
    return {
      isLoading: true,
      program: null,
      warningModal: {
        show: false,
        title: this.$t("programParameters.visual_identity.warning_modal.title"),
        reason: this.$t("programParameters.visual_identity.warning_modal.reason"),
        advice: this.$t("programParameters.visual_identity.warning_modal.advice"),
      },
      preset_name: `${process.env.VUE_APP_CLOUDINARY_OFFER_UPDATE_PRESET_NAME}`,
    }
  },
  computed: {
    ...mapGetters("program", ["getProgram"]),
    ...mapGetters("offer", ["getB2COfferContent", "getUpdateContentErrors"]),
    instructions() {
      return this.$t("programParameters.visual_identity.instructions")
    },
  },
  methods: {
    getVisualLabel(offer, index) {
      return `${this.$t("programParameters.visual_identity.label")} #${index + 1}`
    },
    async onUploadedImage(offer, imageInfo) {
      // Update offer content
      const content = this.getB2COfferContent(offer.id)
      const payload = {
        offer_content_id: content.id,
        details: {
          name: content.name,
          description: content.description,
          image: imageInfo.secure_url,
        },
      }
      await this.$store.dispatch("offer/updateOfferContent", payload)

      if (this.getUpdateContentErrors(content.id) !== null) {
        this.warningModal.show = true
      }
    },

    onPreviewButtonClick() {
      // Get the url of the new tab to open
      const url = this.$router.resolve({ name: "programOffersPreview" }).href
      window.open(url, "_blank")
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
  },
  async created() {
    this.program = this.getProgram
    if (
      this.program === null ||
      this.program.id !== this.$router.currentRoute.params.programId
    ) {
      // retrieve concerned program
      await this.$store.dispatch(
        "program/getProgram",
        this.$router.currentRoute.params.programId
      )
      this.program = this.getProgram
    }
    this.isLoading = false
  },
  mounted() {
    // To enable a Cypress test to emit an event on CloudinaryUploadWidget component
    if (window.Cypress) {
      window.vueCloudinaryUploadWidget = this
    }
  },
}
</script>

<style scoped lang="scss">
.visual-identity {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 2fr 1fr 1fr;
  grid-template-areas:
    "choices none"
    "preview none"
    "instructions instructions";
  align-items: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.visual-identity__choices {
  grid-area: choices;
}

.visual-identity__choices__item {
  margin-bottom: 2rem;
}

.visual-identity__choices__item-label {
  color: $bleucaarea;
  margin-bottom: 0;
}

.visual-identity__preview {
  grid-area: preview;
}

.visual-identity__preview__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
  width: 100%;

  & span {
    // center
    margin-right: auto;
    margin-left: auto;
  }

  & em {
    padding-left: 1rem;
  }
}

.visual-identity__instructions {
  grid-area: instructions;
  white-space: pre-wrap;
  color: $bleucaarea;
  text-align: center;
}
</style>

import local_storage_names from "@/conf/local_storage_names"
import Vue from "vue"

// STATES (snake_case)
const state = {
  offers: JSON.parse(localStorage.getItem(local_storage_names.basket)) || [[]],
  current_offer_index:
    JSON.parse(localStorage.getItem(local_storage_names.currentOfferIndex)) || 0,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  ADD(state, item) {
    state.offers[state.current_offer_index].push(item)
    localStorage.setItem(local_storage_names.basket, JSON.stringify(state.offers))
  },
  ADD_DRAGGABLE_ITEM(state, { offerIndex, item }) {
    state.offers[offerIndex].push(item)
    localStorage.setItem(local_storage_names.basket, JSON.stringify(state.offers))
  },
  DEL(state, { basketId, offerIndex }) {
    const offer = state.offers[offerIndex].filter((item) => item._basketId !== basketId)
    Vue.set(state.offers, offerIndex, offer)
    localStorage.setItem(local_storage_names.basket, JSON.stringify(state.offers))
  },
  CLEAR(state) {
    state.offers = [[]]
    state.current_offer_index = 0
    localStorage.setItem(local_storage_names.basket, JSON.stringify(state.offers))
    localStorage.setItem(
      local_storage_names.currentOfferIndex,
      JSON.stringify(state.current_offer_index)
    )
  },
  DELETE_OFFER(state, offerIndex) {
    state.offers.splice(offerIndex, 1)
    state.current_offer_index--
    localStorage.setItem(
      local_storage_names.currentOfferIndex,
      JSON.stringify(state.current_offer_index)
    )
    localStorage.setItem(local_storage_names.basket, JSON.stringify(state.offers))
  },
  ADD_NEW_OFFER(state) {
    state.current_offer_index++
    Vue.set(state.offers, state.current_offer_index, [])
    localStorage.setItem(local_storage_names.basket, JSON.stringify(state.offers))
    localStorage.setItem(
      local_storage_names.currentOfferIndex,
      JSON.stringify(state.current_offer_index)
    )
  },
  UPDATE_OFFERS_PRODUCTS_NAME(state, products) {
    let offers = state.offers
    offers.forEach((offer, offerIndex, offerArray) => {
      offer.forEach((product, productIndex) => {
        offerArray[offerIndex][productIndex].name = products.find(
          (p) => p.id === product.id
        ).name
      })
    })
    state.offers = offers
    localStorage.setItem(local_storage_names.basket, JSON.stringify(state.offers))
  },
}

// ACTIONS (camelCase)
const actions = {
  add({ commit }, item) {
    if (!item.hasOwnProperty("_basketId")) {
      throw Error("You need to define `_basketId` property")
    }
    commit("ADD", item)
  },
  addDraggableItem({ commit }, { offerIndex, item }) {
    commit("ADD_DRAGGABLE_ITEM", { offerIndex, item })
  },
  del({ commit }, { basketId, offerIndex }) {
    commit("DEL", { basketId: basketId, offerIndex: offerIndex })
    if (state.offers[offerIndex].length === 0 && state.offers.length > 1) {
      commit("DELETE_OFFER", offerIndex)
    }
  },
  deleteOffer({ commit }, offerIndex) {
    commit("DELETE_OFFER", offerIndex)
  },
  clear({ commit }) {
    commit("CLEAR")
  },
  addNewOffer({ commit }) {
    if (state.offers[state.current_offer_index].length > 0) {
      commit("ADD_NEW_OFFER")
    }
  },
  updateOffersProductName({ commit }, products) {
    commit("UPDATE_OFFERS_PRODUCTS_NAME", products)
  },
}

// GETTERS (camelCase)
const getters = {
  basketHasItem: (state) => (basketId) =>
    state.offers.some((offer) => offer.find((i) => i._basketId === basketId)),
  isItemInOffer: (state) => (basketId, offerIndex) =>
    !!state.offers[offerIndex].find((i) => i._basketId === basketId),
  isItemInCurrentOffer: (state, getters) => (basketId) =>
    getters.isItemInOffer(basketId, state.current_offer_index),
  basketNbItems: (state) => state.offers.reduce((acc, offer) => offer.length + acc, 0),
  basketIsEmpty: (state) => state.offers[0] === undefined || state.offers[0].length < 1,
  offersList: (state) => state.offers,
  tagsList: (state) => {
    const offerTags = state.offers.reduce((acc, offer) => {
      const tags = offer.map((p) => p.tags)
      tags.forEach((tag) => acc.push(tag))
      return acc
    }, [])
    const offerTagsLabel = offerTags.map((tags) => tags.map((tag) => tag.label))
    return offerTagsLabel.reduce((tagLabelA, tagLabelB) =>
      tagLabelA.filter((labelA) => tagLabelB.includes(labelA))
    )
  },
  isAssuranceAutomobileInBasket(state) {
    return state.offers.some(
      (offer) =>
        offer.filter((product) => product.code === "assurance_automobile").length > 0
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div class="doc-upload" :data-cy="`doc-upload-${document.type}`">
    <div
      class="doc-upload__name font-effra-medium font-size-18"
      :data-cy="`doc-name-${document.type}`"
    >
      {{ document.name }}*
    </div>
    <div class="doc-upload__file-button">
      <spinner v-if="isUploadInProgress" :no-message="true"></spinner>
      <SelectFileButton
        v-else
        :input-id="document.type"
        :name="document.name"
        accept=".pdf, application/pdf"
        :button-label="$t('button.upload')"
        btn-class="btn-tertiary"
        :with-icon="true"
        :uploaded-file="uploadedFileName"
        @input-file="onInputFile"
      ></SelectFileButton>
    </div>
    <div v-show="showUploadedFile && !isUploadInProgress" class="doc-upload__status">
      <span class="icon-check" :data-cy="`upload-status-${document.type}`"></span>
    </div>
  </div>
</template>

<script>
import SelectFileButton from "@/components/button/SelectFileButton"

export default {
  name: "ProgramDocUpload",
  components: { SelectFileButton },
  props: {
    document: { type: Object, required: true },
    uploadedFileName: { type: String, required: true },
    isUploadInProgress: { type: Boolean, required: true },
  },
  computed: {
    showUploadedFile() {
      return this.uploadedFileName !== ""
    },
  },
  methods: {
    async onInputFile(file) {
      this.$emit("file-selected", file)
    },
  },
}
</script>

<style lang="scss" scoped>
.doc-upload {
  display: grid;
  grid-template-columns: 5fr 3fr max-content;
  grid-template-areas: "name file-button status";
  background-color: $caarealightgray;
  padding: 0.5rem 2rem;
  align-items: center;
  height: 3.5rem;
}

.doc-upload__name {
  grid-area: name;
  color: $bleucaarea;
  justify-self: start;
}
.doc-upload__file-button {
  grid-area: file-button;
  justify-self: center;
}

.doc-upload__status {
  grid-area: status;
  justify-self: end;
}
</style>

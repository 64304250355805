<template>
  <div>
    <spinner v-if="isLoading" class="mt-5"></spinner>
    <div v-else>
      <OfferSheet
        v-for="(offer, index) in program.offers"
        class="offer-sheet__item"
        :key="index"
        :offer="offer"
        :offer-index="index"
      ></OfferSheet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OfferSheet from "@/components//programParameters/OfferSheet"

export default {
  name: "OffersPreview",
  components: { OfferSheet },
  data() {
    return {
      isLoading: true,
      program: null,
    }
  },
  computed: {
    ...mapGetters("program", ["getProgram"]),
  },
  async created() {
    this.program = this.getProgram
    if (
      this.program === null ||
      this.program.id !== this.$router.currentRoute.params.programId
    ) {
      // retrieve concerned program
      await this.$store.dispatch(
        "program/getProgram",
        this.$router.currentRoute.params.programId
      )
      this.program = this.getProgram
    }
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.offer-sheet__item {
  margin-bottom: 1rem;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}
</style>

<template>
  <div class="config-header">
    <i :class="['config-header__icon', icon, 'font-size-25']"></i>
    <div class="config-header__title font-size-18">
      {{ title }}
    </div>
    <div class="config-header__subTitle font-size-11">
      {{ subTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductLineConfigHeader",
  props: {
    icon: { type: String, required: true },
    title: { type: String, required: true },
    subTitle: { type: String, required: true },
  },
}
</script>

<style scoped lang="scss">
.config-header {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "icon title" ". subTitle";
  grid-column-gap: 0.75rem;
  background-color: $bleucaarea;
  width: 100%;
}

.config-header__icon {
  grid-area: icon;
  justify-self: end;
  align-self: center;
  color: white;
  font-weight: bold;
}

.config-header__title {
  grid-area: title;
  justify-self: start;
  align-self: center;

  color: white;
  font-family: $font-family-title;
  font-weight: lighter;
}

.config-header__subTitle {
  grid-area: subTitle;
  justify-self: start;
  color: white;
  font-family: $font-family-title;
}
</style>

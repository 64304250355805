<template>
  <div aria-label="breadcrumb">
    <ol class="breadcrumb mb-0 pb-2 pt-2">
      <li
        class="breadcrumb-item"
        v-for="crumb in crumbs"
        :key="crumb.name"
        :class="{ active: crumb.active }"
        :aria-current="getAriaCurrent"
      >
        <router-link
          :to="crumb.to"
          v-if="!crumb.active && crumb.to.name === 'privateZone'"
          :data-cy="'breadcrumb-' + crumb.to.name"
        >
          <i class="icon-home mr-2" aria-hidden="true"></i>
        </router-link>
        <router-link
          :to="crumb.to"
          v-else-if="!crumb.active"
          :data-cy="'breadcrumb-' + crumb.to.name"
        >
          {{ $t(crumb.text) }}
        </router-link>
        <span v-else-if="crumb.to.name !== 'privateZone'">
          {{ $t(crumb.text) }}
        </span>
        <i class="icon-home mr-2" aria-hidden="true" v-else></i>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      active: "home",
    }
  },
  computed: {
    crumbs() {
      let pathArray = this.$route.path.split("/")
      // console.log("pathArray", pathArray)
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        const route = this.$route.matched[idx]
        // console.log("route", route, path, idx)
        // TODOFSC : comprendre pourquoi on est oblige de tester route pour le chemin /program/id
        if (route && route.meta.breadcrumb) {
          breadcrumbArray.push({
            to: {
              name: route.name,
              params: this.$route.params,
            },
            path: path,
            url: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: route.meta.breadcrumb || false,
            active: false,
          })
        }
        return breadcrumbArray
      }, [])
      // set last breadcrumbs as active
      breadcrumbs[Object.keys(breadcrumbs).slice(-1)].active = true
      return breadcrumbs
    },
    getAriaCurrent(crumb) {
      return crumb.active ? "page" : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  background: $primary;
  align-items: center;
  li {
    &.active {
      color: #fff;
    }
    &:first-child a {
      text-decoration: initial;
    }
    a {
      color: #fff;
      text-decoration: underline;
      transition: all 0.25s ease-in-out;
      &:hover {
        text-decoration-color: transparent;
      }
      i:before {
        font-weight: bold;
      }
    }
  }
}
</style>

import login from "./views/login.json"
import button from "./button.json"
import topbar from "./layouts/topbar.json"
import footer from "./layouts/footer"
import header from "./layouts/header"
import verticaltabs from "./layouts/verticaltabs.json"
import treeMenu from "./layouts/treeMenu.json"
import home from "./views/home.json"
import error from "./views/error.json"
import burgermenu from "./layouts/burgermenu.json"
import products from "./views/products.json"
import pricing from "./views/pricing.json"
import programs from "./views/programs.json"
import parameters from "./views/parameters.json"
import partners from "./views/partners.json"
import account from "./views/account.json"
import resume from "./views/resume.json"
import breadcrumb from "./breadcrumb.json"
import spinner from "./spinner.json"
import memo from "./memo.json"
import modal from "./modal.json"
import validation from "./validation.json"
import currency from "./currency.json"
import criteria from "./criteria.json"
import cloudinary from "./cloudinary.json"
import sharedPrograms from "./views/sharedPrograms.json"
import programParameters from "./views/programParameters.json"
import productLines from "./views/productLines.json"
import caareavlib from "../../../node_modules/caarea-vlib/src/i18n/es"
import pricingRange from "./views/pricingRange.json"
import offers from "./views/offers.json"
import commercial from "./views/commercial.json"
import vehicleChoice from "./views/vehicleChoice.json"
import users from "./views/users.json"

export default {
  caareavlib,
  button,
  login,
  topbar,
  footer,
  header,
  error,
  home,
  burgermenu,
  treeMenu,
  products,
  partners,
  pricing,
  programs,
  sharedPrograms,
  parameters,
  account,
  breadcrumb,
  spinner,
  memo,
  modal,
  validation,
  currency,
  criteria,
  cloudinary,
  productLines,
  programParameters,
  pricingRange,
  offers,
  resume,
  verticaltabs,
  commercial,
  vehicleChoice,
  users,
}

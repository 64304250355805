<template>
  <ModalBootstrap
    id-modal="modalProgramCreation"
    class="criteria"
    :title="$t('programs.programCreationModal.title')"
    modal-size="large"
    @close="onClose"
    data-cy="program-creation-modal"
  >
    <template #body>
      <div v-if="isLoading">
        <span
          class="spinner-border spinner-border-sm mr-1"
          v-show="isLoading"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
      <div v-else class="mx-5 mb-2 px-3">
        <div class="mx-4">
          <div class="row justify-content-between mb-3">
            <label for="program-name-input" class="my-auto">
              {{ $t("programs.programCreationModal.nameLabel") }}
            </label>
            <FormInput
              :placeholder="$t('programs.programCreationModal.namePlaceholder')"
              id="program-name-input"
              name="program-name-input"
              v-model="programName"
              data-cy="program-name-input"
              class="w-50"
              :focus="true"
              @keyboard-enter="onSubmitButton"
            ></FormInput>
          </div>
          <div class="row justify-content-between mb-3">
            <label for="program-partner-input" class="mt-2 pr-4">
              {{ $t("programs.programCreationModal.partnerLabel") }}
            </label>
            <FormSelect
              id="program-partner"
              name="program-partner"
              :select-options="partners"
              :selected-option.sync="partnerChosen"
              data-cy="program-partner"
              label-select-attr="label"
              label="label"
              class="w-50"
              :placeholder="$t('programs.program_creation.placeholder')"
              :label-options-order="false"
            ></FormSelect>
          </div>
        </div>
        <div v-if="hasCreateProgramError" class="row justify-content-center">
          <p class="warning">
            {{ $t("programs.programCreationModal.error") }}
          </p>
        </div>
        <div class="row justify-content-center">
          <div class="row justify-content-center col-md-8 px-2">
            <button
              class="btn btn-primary w-75 px-1 mb-3 btn-centered"
              :disabled="isDisabled"
              @click.prevent="onSubmitButton"
              data-cy="validate-button"
            >
              {{ $t("button.validate") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"

export default {
  name: "ModalProgramCreation",
  components: { ModalBootstrap },
  data() {
    return {
      programName: "",
      partnerChosen: null,
      partners: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("basket", ["offersList"]),
    ...mapGetters("program", ["getProgram", "hasCreateProgramError"]),
    ...mapGetters("product", ["getProgramTags"]),
    ...mapGetters("partner", ["getPartners"]),
    isDisabled: function () {
      return !this.programName || !this.partnerChosen || this.hasCreateProgramError
    },
  },
  methods: {
    onClose() {
      this.$store.dispatch("program/resetCreateProgramError")
      this.$emit("close")
    },
    async onSubmitButton() {
      const offers = this.offersList.reduce((acc, offer, index) => {
        let products = offer.map((product) => product.id)
        acc.push({
          products: products,
          name_b2b: `${this.programName} offer ${index + 1}`,
          description_b2b: `${this.programName} offer ${index + 1}`,
          name_b2c: `${this.programName} offer ${index + 1}`,
          description_b2c: `${this.programName} offer ${index + 1}`,
          name_v: `${this.programName} offer ${index + 1}`,
          description_v: `${this.programName} offer ${index + 1}`,
        })
        return acc
      }, [])
      await this.$store.dispatch("program/create", {
        name: this.programName,
        partner: this.partnerChosen,
        offers: offers,
        vehicle_tag: this.getProgramTags.vehicle,
        engine_tag: this.getProgramTags.engine,
      })

      await this.$store.dispatch("basket/clear")
      await this.$router.push({ name: "program", params: { id: this.getProgram.id } })
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("partner/initPartners")
    this.partners = this.getPartners.reduce((acc, partner) => {
      acc[partner.id] = partner.name
      return acc
    }, {})
    this.isLoading = false
  },
}
</script>
<style>
.warning {
  color: #b13607;
}
</style>

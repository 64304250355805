<template>
  <div class="col-3 pr-0 vl">
    <div class="list-group list-group-flush">
      <div
        :class="hasSubTabs ? 'mb-3' : 'mb-5'"
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <a
          v-if="isSpecificTab(tab)"
          :id="index"
          href="#"
          @click.prevent="onTabClick(tab, index)"
          class="list-group-item list-group-item-action py-2"
          :class="getTabClass(tab, index)"
          :data-cy="`${tab.type}-${tab.item.id}-vertical-tab`"
        >
          {{ tab.label }}
        </a>

        <p
          v-else-if="$route.name !== 'programDescription'"
          class="list-group-item list-group-item-action py-2 vertical-sub-tab"
          :data-cy="`${tab.type}-${tab.item.id}-vertical-tab`"
        >
          <span :class="getTabClass(tab, index)">{{ tab.label }}</span>
          <a
            v-for="(pl, i) in tab.productLines"
            :key="pl.id"
            :id="pl.id"
            href="#"
            @click.prevent="onTabClick(tab, index, pl.id)"
            class="list-group-pl"
            :class="getPlTabClass(pl.id)"
            :data-cy="`product_line-${pl.id}-vertical-tab`"
          >
            {{ $t("verticaltabs.case") + " " + (i + 1) }}
          </a>
        </p>
        <p
          v-else
          class="list-group-item list-group-item-action py-2 vertical-sub-tab"
          :data-cy="`${tab.type}-${tab.item.id}-vertical-tab`"
        >
          {{ tab.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "VerticalDistribTabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    isOfferMenu: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      activeProductLine: 0,
    }
  },
  computed: {
    ...mapGetters("offer", ["getOfferByProgramProductId"]),
    hasSubTabs() {
      return this.tabs.some((tab) => tab.isSubTab === true)
    },
  },
  methods: {
    getTabClass(tab, index) {
      let tabClass = []
      tabClass.push(this.isTabActive(index) ? "activeTab" : "")
      tabClass.push(tab.isSubTab ? "vertical-sub-tab" : "vertical-tab")
      return tabClass
    },
    getPlTabClass(productLineId) {
      return this.activeProductLine === productLineId ? "activeTab" : ""
    },
    isTabActive(index) {
      return index === this.activeTabIndex
    },
    isSpecificTab(tab) {
      return (
        tab.type === "offer" ||
        tab.type === "program" ||
        (tab.type === "program_product" &&
          !this.isOfferMenu &&
          tab.productLines.length === 0)
      )
    },
    onTabClick(tab, index, plId) {
      this.activeTabIndex = index
      this.activeProductLine = plId

      // Change id of current offer if "offer" or "program_product" tab type is clicked
      if (tab.type === "offer" || tab.type === "program_product") {
        const offerId =
          tab.type === "offer"
            ? tab.item.id
            : this.getOfferByProgramProductId(tab.item.id).id
        this.$store.dispatch("offer/setCurrentOfferId", offerId)
      }

      this.$emit("tab-change", index, plId)
    },
  },
}
</script>

<style scoped lang="scss">
.vertical-tab {
  border-left: 6px solid #fff;
  border-bottom: 0;
  border-right: 0;
  border-top: 0;
  font-size: 20px;
  text-align: right;
  background-color: $bleuc50;
}

.vertical-tab.list-group-item-action:hover,
.vertical-tab.list-group-item-action:focus {
  background-color: $bleuc50;
}

.vertical-sub-tab.list-group-item-action:hover,
.vertical-sub-tab.list-group-item-action:focus {
  background-color: transparent;
}

.activeTab {
  color: $bleucaarea;
  font-family: "Gilroy SemiBold", sans-serif;
}

.vertical-sub-tab {
  border: 0;
  text-align: right;
  font-size: 18px;
  color: $bleucaarea;
  letter-spacing: 0.25px;
}
.list-group-flush {
  height: 80%;
  border-right: 1px solid $bleuc50;
}
.list-group-pl {
  display: block;
}
</style>

<template>
  <div data-cy="presale-content">
    <div class="row justify-content-center">
      <RouterLink
        :to="{ name: 'products', query: { domain: 'vehicule' } }"
        class="products-cta col-3 d-flex flex-column justify-content-center cta-animation"
        data-cy="vehicle-selection"
      >
        <div
          class="d-flex flex-column align-self-center justify-content-center align-items-center"
          data-cy="vehicle"
          @click="onVehicleClick"
        >
          <i class="m-3 icon-caarea-car font-size-80" aria-hidden="true"></i>
          <label>{{ $t("programs.home.vehicle") }}</label>
        </div>
      </RouterLink>
      <RouterLink
        :to="{ name: 'products', query: { domain: 'capital' } }"
        class="products-cta col-3 d-flex flex-column justify-content-center cta-animation"
      >
        <div
          class="d-flex flex-column align-self-center justify-content-center align-items-center"
          data-cy="capital"
        >
          <i class="m-3 icon-caarea-finance font-size-80" aria-hidden="true"></i>
          <label>{{ $t("programs.home.capital") }}</label>
        </div>
      </RouterLink>
      <RouterLink
        :to="{ name: 'products', query: { domain: 'personnes' } }"
        class="products-cta col-3 d-flex flex-column justify-content-center cta-animation"
      >
        <div
          class="d-flex flex-column align-self-center justify-content-center align-items-center"
          data-cy="persons"
        >
          <i class="m-3 icon-caarea-people font-size-80" aria-hidden="true"></i>
          <label>{{ $t("programs.home.people") }}</label>
        </div>
      </RouterLink>
    </div>
    <h3 class="mt-3 text-center">{{ $t("programs.home.global_protection") }}</h3>
  </div>
</template>

<script>
export default {
  name: "PreSale",
  methods: {
    onVehicleClick() {
      this.$store.dispatch("product/resetProgramTags")
    },
  },
}
</script>

<style lang="scss">
.products-cta {
  &:hover {
    color: white;
    text-decoration: none;
  }

  color: white;
  background-color: $bleucaarea;
  margin-left: $cta-spacing;
  margin-right: $cta-spacing;
  height: 50vh;
  min-height: 200px;

  label {
    font-weight: bold;
    font-size: 2rem;
  }
}
</style>

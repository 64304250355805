<template>
  <div>
    <div class="offer-eligibility__title">
      <p class="font-effra-light font-size-15 text-uppercase">
        {{ eligibilityTitle }}
      </p>
    </div>
    <div class="vehicles-eligibility" data-cy="vehicles-eligibility">
      <div
        class="vehicles-eligibility__guidance"
        data-cy="vehicles-eligibility-guidance"
      >
        <i
          :class="[
            'vehicles-eligibility__guidance__icon',
            'icon-pushpin',
            'font-size-22',
          ]"
        ></i>
        <div>
          <p class="vehicles-eligibility__guidance__text1 font-size-16">
            {{ $t("offers.vehicles_eligibility.guidance_text_1") }}
          </p>
          <p class="vehicles-eligibility__guidance__text2 font-size-16">
            {{ $t("offers.vehicles_eligibility.guidance_text_2") }}
          </p>
        </div>
      </div>
      <spinner v-if="isLoading" class="mt-5"></spinner>
      <div
        v-else
        class="vehicles-eligibility__selects"
        data-cy="vehicles-eligibility-selects"
      >
        <div class="vehicles-eligibility__selects__values">
          <div v-for="criterion in eligibilityCriteriaFiltered" :key="criterion">
            <div v-if="isEligibilityCriterionSelect(criterion)">
              <FormRowSelect
                v-if="criterion === 'engine_code'"
                class="eligibility__criterion"
                :is-loading="engineCodeOptionsLoading"
                :key="criterion"
                :label="getEligibilityCriterionLabel(criterion)"
                :label-class="[
                  'text-right',
                  'col-sm-4',
                  'align-self-center',
                  'font-size-20',
                ]"
                :control-class="['col-sm-6', 'align-self-center']"
                :name="getEligibilityInputName(criterion, offerId)"
                :selected-option.sync="engineCodesSelectedOptions"
                @update:selected-option="onUpdate(criterion)"
                label-select-attr="label"
                :allow-empty="true"
                :placeholder="
                  form.eligibility_criteria[criterion].length === 0
                    ? getEligibilityEmptyLabel(criterion)
                    : ''
                "
                :select-options="getEligibilityCriterionOptions(criterion)"
                :multiple="true"
              ></FormRowSelect>
              <FormRowSelect
                v-else
                class="eligibility__criterion"
                :is-loading="criterion === 'model' && modelOptionsLoading"
                :key="criterion"
                :label="getEligibilityCriterionLabel(criterion)"
                :label-class="[
                  'text-right',
                  'col-sm-4',
                  'align-self-center',
                  'font-size-20',
                ]"
                :control-class="['col-sm-6', 'align-self-center']"
                :name="getEligibilityInputName(criterion, offerId)"
                :selected-option.sync="form.eligibility_criteria[criterion]"
                @update:selected-option="onUpdate(criterion)"
                label-select-attr="label"
                :allow-empty="true"
                :placeholder="
                  form.eligibility_criteria[criterion].length === 0
                    ? getEligibilityEmptyLabel(criterion)
                    : ''
                "
                :select-options="getEligibilityCriterionOptions(criterion)"
                :disabled="
                  (criterion === 'model' && isModelDisabled) ||
                  isEligibilityCriterionDisabled(
                    criterion,
                    form.eligibility_criteria.make
                  )
                "
                :multiple="true"
              ></FormRowSelect>
            </div>
            <div v-else>
              <FormRowInput
                class="eligibility__criterion"
                v-model="form.eligibility_criteria[criterion]"
                :label="getEligibilityCriterionLabel(criterion)"
                :label-class="[
                  'text-right',
                  'col-sm-4',
                  'align-self-center',
                  'font-size-20',
                ]"
                :control-class="['col-sm-6', 'align-self-center']"
                :name="getEligibilityInputName(criterion, offerId)"
                type="text"
                :errors="getEligibilityUpdateErrors"
                @input="onUpdate(criterion)"
                :disabled="
                  isEligibilityCriterionDisabled(
                    criterion,
                    form.eligibility_criteria.make
                  )
                "
              ></FormRowInput>
            </div>
          </div>
          <div v-if="isAccordionVisible">
            <div
              v-if="!showAccordion"
              class="accordion"
              data-cy="eligibility-criteria-see-more"
              @click="showAccordion = true"
            >
              <span class="font-effra-normal font-size-14">
                {{ $t("offers.vehicles_eligibility.see_more") }}
              </span>
              <span class="icon-chevron-down font-size-14 ml-1"></span>
            </div>
            <div v-else>
              <div
                v-for="criterionAccordion in eligibilityCriteriaHiddenByAccordion"
                :key="criterionAccordion"
              >
                <div v-if="criterionAccordion === 'max_age'">
                  <div class="eligibility__criterion__max-age">
                    <FormRowInput
                      class="eligibility__criterion__max-age-value"
                      v-model="form.eligibility_criteria[criterionAccordion]"
                      :label="
                        getMaxAgeEligibilityCriterionLabel(
                          form.eligibility_criteria['max_age_unity']
                        )
                      "
                      :label-class="[
                        'text-right',
                        'col-sm-6',
                        'align-self-center',
                        'font-size-20',
                      ]"
                      :control-class="['col-sm-4', 'align-self-center']"
                      :name="getEligibilityInputName(criterionAccordion, offerId)"
                      type="text"
                      :errors="getEligibilityUpdateErrors"
                      @input="onUpdate(criterionAccordion)"
                      :disabled="
                        isEligibilityCriterionDisabled(
                          criterionAccordion,
                          form.eligibility_criteria.make
                        )
                      "
                    ></FormRowInput>
                    <FormSelect
                      class="eligibility__criterion__max-age-unity text-left col-sm-6"
                      :selected-option.sync="form.eligibility_criteria['max_age_unity']"
                      :select-options="getAgeUnityOptions"
                      label-select-attr="label"
                      :disabled="
                        isEligibilityCriterionDisabled(
                          criterionAccordion,
                          form.eligibility_criteria.make
                        )
                      "
                      :name="getEligibilityInputName('max_age_unity', offerId)"
                    ></FormSelect>
                  </div>
                </div>
                <div v-else>
                  <FormRowInput
                    class="eligibility__criterion"
                    v-model="form.eligibility_criteria[criterionAccordion]"
                    :label="getEligibilityCriterionLabel(criterionAccordion)"
                    :label-class="[
                      'text-right',
                      'col-sm-4',
                      'align-self-center',
                      'font-size-20',
                    ]"
                    :control-class="['col-sm-6', 'align-self-center']"
                    :name="getEligibilityInputName(criterionAccordion, offerId)"
                    type="text"
                    :errors="getEligibilityUpdateErrors"
                    @input="onUpdate(criterionAccordion)"
                    :disabled="
                      isEligibilityCriterionDisabled(
                        criterionAccordion,
                        form.eligibility_criteria.make
                      )
                    "
                  ></FormRowInput>
                </div>
              </div>
              <div class="accordion" @click="showAccordion = false">
                <span class="font-effra-normal font-size-14">
                  {{ $t("offers.vehicles_eligibility.see_less") }}
                </span>
                <span class="icon-chevron-up font-size-14 ml-1"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EligibilityCriteriaMixin from "@/components/presales/mixins/EligibilityCriteriaMixin"
import ProgramService from "@/services/business/ProgramService"
import VehicleSearchService from "@/services/business/VehicleSearchService"
import i18n from "@/i18n"

export default {
  name: "OfferEligibilityCriteria",
  mixins: [EligibilityCriteriaMixin],
  // To update the data of the parent (v-model use)
  model: {
    prop: "eligibilityCriteria",
    event: "updated-criteria",
  },
  props: {
    offerId: { type: [Number, String], required: true },
    eligibilityCriteria: { type: Object, required: true },
    isDisabled: { type: Boolean, default: false },
    eligibilityTitle: { type: String, required: true },
  },
  data() {
    return {
      modelOptionsLoading: false,
      engineCodeOptionsLoading: false,
      showAccordion: false,
      form: {
        eligibility_criteria: { ...this.eligibilityCriteria },
      },
      listModelOptions: {},
      isLoading: false,
      eligibilityCriteriaHiddenByAccordion: ["max_km", "max_age", "max_power_kw"],
      engineCodesFromProgramEngineTag: [],
      engineCodesSelectedOptions: [],
    }
  },
  computed: {
    ...mapGetters("offer", [
      "listEligibilityCriteria",
      "getUpdateErrors",
      "getCoefCritOptions",
      "getFirstProductLineId",
    ]),
    ...mapGetters("offerContext", [
      "getMakeSelectableValues",
      "getModelSelectableValues",
      "getEngineTypeSelectableValues",
      "isModelDisabled",
    ]),
    ...mapGetters("program", ["getProgram"]),
    eligibilityCriteriaFiltered() {
      return this.listEligibilityCriteriaFiltered(this.offerId).filter(
        (criterion) => !this.eligibilityCriteriaHiddenByAccordion.includes(criterion)
      )
    },
    isAccordionVisible() {
      return this.eligibilityCriteriaHiddenByAccordion.length > 0
    },
    getEligibilityUpdateErrors() {
      const errors = this.getUpdateErrors(this.offerId)
      if (
        errors !== null &&
        errors.hasOwnProperty("eligibility_criteria") &&
        typeof errors.eligibility_criteria === "object"
      ) {
        return Object.entries(errors.eligibility_criteria).reduce(
          (updateErrors, [criterion_name, criterion_error]) => {
            updateErrors[this.getEligibilityInputName(criterion_name, this.offerId)] =
              criterion_error
            return updateErrors
          },
          {}
        )
      }
      return {}
    },
  },
  methods: {
    getEligibilityEmptyLabel(criterion) {
      if (criterion === "model" && this.isModelDisabled)
        return this.$t(`criteria.eligibility.disabled.${criterion}`)
      return this.$t(`criteria.eligibility.empty.${criterion}`)
    },
    getEligibilityCriterionOptions(criterion) {
      if (criterion === "make") {
        return this.getMakeSelectableValues
      }
      if (criterion === "model") {
        return this.getModelSelectableValues
      }
      if (criterion === "engine_code") {
        return this.getEngineCodeOptions()
      }
      return this.getCoefCritOptions(this.offerId, criterion)
    },
    async onUpdate(criterion) {
      if (criterion === "make") {
        this.modelOptionsLoading = true
        this.engineCodeOptionsLoading = true
        await this.$store.dispatch(
          "offerContext/setMakeFilterValues",
          this.form.eligibility_criteria["make"]
        )
        if (this.form.eligibility_criteria.make.length === 0) {
          this.form.eligibility_criteria.model = []
        }
        this.modelOptionsLoading = false
        this.engineCodeOptionsLoading = false
      } else if (criterion === "model") {
        this.engineCodeOptionsLoading = true
        await this.$store.dispatch(
          "offerContext/setModelFilterValues",
          this.form.eligibility_criteria["model"]
        )
        this.engineCodeOptionsLoading = false
      } else if (criterion === "engine_code") {
        let engineCodesValues = []
        this.engineCodesSelectedOptions.map((engineCode) => {
          if (engineCode === i18n.t("vehicleChoice.hybrid")) {
            engineCodesValues = engineCodesValues.concat(
              VehicleSearchService.hybridTypes
            )
          } else {
            engineCodesValues.push(engineCode)
          }
        })
        this.form.eligibility_criteria["engine_code"] = engineCodesValues
      }

      // Case of an input that was invalid then reset to "" => has to be null (otherwise 422 will happen)
      if (
        !this.isEligibilityCriterionSelect() &&
        !this.form.eligibility_criteria[criterion]
      ) {
        this.form.eligibility_criteria[criterion] = null
      }
      this.$emit("updated-criteria", this.form.eligibility_criteria)
    },
    getEngineCodesSelectedOptions() {
      let isHybridIncluded = false
      let engineCodeValues = []
      this.eligibilityCriteria.engine_code.forEach((engineCode) => {
        if (VehicleSearchService.hybridTypes.includes(engineCode)) {
          isHybridIncluded = true
        } else {
          engineCodeValues.push(engineCode)
        }
      })
      if (isHybridIncluded) {
        engineCodeValues.push(i18n.t("vehicleChoice.hybrid"))
      }
      return engineCodeValues
    },
    getEngineCodeOptions() {
      let isHybridIncluded = false
      const selectableValues = Object.entries(
        this.getEngineTypeSelectableValues
      ).reduce((acc, [engineCode, engineName]) => {
        if (this.engineCodesFromProgramEngineTag.includes(engineCode))
          if (!VehicleSearchService.HYBRID_ENGINE_CODES.includes(engineCode)) {
            acc[engineCode] = engineName
          } else {
            isHybridIncluded = true
          }
        return acc
      }, {})
      if (isHybridIncluded) {
        selectableValues[i18n.t("vehicleChoice.hybrid")] =
          i18n.t("vehicleChoice.hybrid")
      }
      return selectableValues
    },
  },
  async created() {
    this.isLoading = true
    this.engineCodesFromProgramEngineTag = ProgramService.listEngineCodesFromEngineTag(
      this.getProgram.engine_tag.label
    )
    this.engineCodesSelectedOptions = this.getEngineCodesSelectedOptions()
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.offer-eligibility__title {
  border-left: 1px solid #1c2b4e;
  padding-left: 0.5rem;
}
.accordion {
  display: flex;
  align-items: center;
  width: 5.1rem;
  cursor: pointer;
}
.vehicles-eligibility {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "guidance selects";
  grid-column-gap: 3rem;
  justify-items: center;
  align-items: center;
  .vehicles-eligibility__guidance {
    grid-area: guidance;
    justify-self: stretch;
    align-self: start;
    border: 1px solid #c0c8d7;
    padding: 20px 40px 30px;
    .vehicles-eligibility__guidance__icon {
      position: relative;
      top: -1rem;
      left: -2rem;
    }
    .vehicles-eligibility__guidance__text1 {
      grid-area: text1;
      justify-self: center;
      align-self: center;
      font-family: "Effra-light", sans-serif;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
    }
    .vehicles-eligibility__guidance__text2 {
      grid-area: text2;
      justify-self: center;
      align-self: center;
      font-family: "Effra-light", sans-serif;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
    }
  }
  .vehicles-eligibility__selects {
    grid-area: selects;
    justify-self: start;
    width: 100%;
  }
  .vehicles-eligibility__selects__values {
    width: 100%;
    .eligibility__criterion__max-age {
      min-height: 3.5rem;
      margin-bottom: 0.5rem;
      // due to f... bootstrap row
      margin-right: 0;
      margin-left: 0 !important;
      background-color: $backgroundsecondary;
      font-family: "Gilroy", sans-serif;
      display: grid;
      grid-template-columns: 66.5% 33.5%;
      grid-template-areas: "max_age unity";
      align-items: center;
      width: 100%;
      .eligibility__criterion__max-age-value {
        grid-area: max_age;
        padding-left: 0 !important;
      }
      .eligibility__criterion__max-age-unity {
        grid-area: unity;
      }
    }
    .eligibility__criterion {
      min-height: 3.5rem;
      margin-bottom: 0.5rem;
      // due to f... bootstrap row
      margin-right: 0;
      margin-left: 0 !important;
      background-color: $backgroundsecondary;
      font-family: "Gilroy", sans-serif;
    }
  }
}
</style>

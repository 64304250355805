<template>
  <ModalBootstrap
    id-modal="modalVehicleSearch"
    class="modal-vehicles"
    :title="$t('vehicleChoice.title')"
    modal-size="extraLarge"
    @close="onClose"
    data-cy="presales-vehicle-search-modal"
  >
    <template #body>
      <spinner v-if="isLoading" class="mt-5"></spinner>
      <div class="body-search" v-else>
        <div class="filters-list">
          <div class="title">
            {{ $t("vehicleChoice.title") }}
          </div>
          <div>
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.vehicle_type')"
              label-select-attr="label"
              name="vehicle_type"
              :select-options="getVehicleTypesSelectableValues"
              :selected-option.sync="filters.vehicle_type"
              :disabled="getIsFilterDisabled('vehicle_type')"
              @update:selected-option="onUpdateVehicleTypes"
            />
            <div v-for="(_, filterName) in filters" :key="filterName">
              <div v-if="filterName !== 'vehicle_type'">
                <div v-if="['make', 'model', 'engine_type'].includes(filterName)">
                  <FormRowSelect
                    :label-inline="false"
                    :label="$t(`vehicleChoice.${filterName}`)"
                    label-select-attr="label"
                    :name="filterName"
                    :multiple="true"
                    :allow-empty="true"
                    :select-options="getFilteredOptions(filterName)"
                    :selected-option.sync="filters[filterName]"
                    :disabled="getIsFilterDisabled(filterName)"
                    @update:selected-option="onUpdateFilter(filterName)"
                    :placeholder="getEligibilityEmptyLabel(productLineId, filterName)"
                  />
                </div>
                <div v-else>
                  <FormRowSelect
                    :label-inline="false"
                    :label="$t(`vehicleChoice.${filterName}`)"
                    label-select-attr="label"
                    :name="filterName"
                    :multiple="true"
                    :allow-empty="true"
                    :select-options="getSelectedOptions(filterName)"
                    :selected-option.sync="filters[filterName]"
                    :disabled="getIsFilterDisabled(filterName)"
                    @update:selected-option="onUpdateFilter(filterName)"
                    :placeholder="getEligibilityEmptyLabel(productLineId, filterName)"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-light-gray"
            @click="resetFilters()"
            :disabled="isResetButtonDisabled"
          >
            <i class="pr-2 icon-sync" aria-hidden="true"></i>
            {{ $t("button.reset") }}
          </button>
        </div>
        <spinner v-if="areVehiclesUpdating" class="mt-5"></spinner>
        <div v-else class="vehicles-list">
          <div class="vehicles" v-if="listVehicles.length > 0" data-cy="vehicles-list">
            <button
              v-if="areOneVehicleInfosShown"
              class="btn btn-light-gray"
              @click="showAllVehicles"
              data-cy="vehicle-show-all"
            >
              <i class="pr-2 icon-chevron-left" aria-hidden="true"></i>
              {{ $t("vehicleChoice.back") }}
            </button>
            <div
              class="vehicle"
              :class="{
                'hover-active': mustHoverBeActive(vehicle),
              }"
              v-for="vehicle in listVehicles"
              :key="vehicle.id"
              @click="showVehicleInfos(vehicle)"
              :data-cy="`vehicle-${vehicle.id}`"
            >
              <div class="vehicle-disabled" v-if="mustVehicleBeDisabled(vehicle)"></div>
              <img :src="vehicle.logo" alt="" />
              <div class="infos">
                <div class="model" :data-cy="`vehicle-model-${vehicle.id}`">
                  {{
                    `${vehicle.local_model} |
                    ${vehicle.local_version[0]}`
                  }}
                </div>
                <div
                  v-if="mustPricingStatusBeDisplayed(vehicle)"
                  :class="getPricingStatusClass(vehicle)"
                >
                  {{ vehicle.pricing_status.translated_value }}
                </div>
              </div>
              <div class="details">
                <div class="finition" :data-cy="`vehicle-trim-level-${vehicle.id}`">
                  {{ vehicle.local_trim_level[0] }}
                </div>
                <div class="power" :data-cy="`vehicle-power-${vehicle.id}`">
                  {{ vehicle.fiscal_hp[0] }} {{ $t("vehicleChoice.fiscal_hp") }}
                </div>
                <div class="year" :data-cy="`vehicle-year-${vehicle.id}`">
                  {{ vehicle.model_year }}
                </div>
                <div class="version" :data-cy="`vehicle-version-${vehicle.id}`">
                  {{ vehicle.version }}
                </div>
              </div>
              <div class="additional-info" v-if="areVehicleInfosShown(vehicle.id)">
                <u>
                  {{ $t("vehicleChoice.infos.additional_informations") }}
                </u>
                <div v-if="hasVariations" class="form-infos" data-cy="vehicle-infos">
                  <div class="infos-selects">
                    <div
                      v-for="vehicleDatumWithVariations in listVehicleDataWithVariations"
                      :key="`${vehicleDatumWithVariations}`"
                      class="multiselect-44"
                    >
                      <FormRowSelect
                        class="input-infos"
                        :label-inline="false"
                        :label="$t(`vehicleChoice.infos.${vehicleDatumWithVariations}`)"
                        label-select-attr="label"
                        :label-options-order="false"
                        :name="`${vehicleDatumWithVariations}_variations`"
                        :selected-option.sync="
                          form.vehicleVariations[vehicleDatumWithVariations]
                        "
                        :select-options="
                          getVehicleDatumVariations(vehicleDatumWithVariations)
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="dynamic-inputs" data-cy="vehicle-dynamic-data">
                  <div class="dynamic-inputs__items">
                    <div class="dynamic-inputs__item date-picker-100">
                      <label for="first_release_date">
                        {{ $t("vehicleChoice.infos.first_release_date") }}
                      </label>
                      <datepicker
                        id="first_release_date"
                        v-model="form.dynamicInputs.firstReleaseDate"
                        data-cy="first-release-date"
                        input-class="form-control"
                        :language="language"
                        :monday-first="isMondayFirst"
                        :disabled-dates="{ from: new Date(), to: oldestEligibleDate }"
                      ></datepicker>
                    </div>
                    <div class="dynamic-inputs__item">
                      <label for="id-km">
                        {{ $t("vehicleChoice.infos.km") }}
                      </label>
                      <FormInput
                        v-model="form.dynamicInputs.km"
                        class="required-inputs__item__input"
                        name="km"
                        type="text"
                        :errors="dynamicInputsErrors"
                      />
                    </div>
                    <div class="dynamic-inputs__item">
                      <label for="id-retail_price">
                        {{ $t("vehicleChoice.infos.retail_price") }}
                      </label>
                      <FormInput
                        v-model="form.dynamicInputs.retailPrice"
                        class="required-inputs__item__input"
                        name="retailPrice"
                        type="text"
                        :errors="dynamicInputsErrors"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              v-if="areOneVehicleInfosShown"
              class="btn btn-primary ml-auto"
              :disabled="isValidateButtonDisabled"
              @click="onValidate"
            >
              {{ $t("vehicleChoice.infos.validate") }}
            </button>
          </div>
          <div v-else>{{ $t("vehicleChoice.no_vehicle_found") }}</div>
          <div
            class="pagination"
            v-if="hasPagination && !areOneVehicleInfosShown"
            data-cy="vehicle-pagination"
          >
            <div class="label-page">{{ $t("vehicleChoice.page") }}</div>
            <div
              class="page"
              :class="{ current: currentPage === 1 }"
              @click="goToPage(1)"
            >
              1
            </div>
            <div
              class="page"
              :class="{ current: currentPage === 2 }"
              @click="goToPage(2)"
              data-cy="vehicle-pagination-2"
            >
              2
            </div>
            <div
              class="page"
              :class="{ current: currentPage === 3 }"
              @click="goToPage(3)"
              v-if="hasAThirdPagePagination"
            >
              3
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"
import DateService from "@/services/technical/DateService"
import ProgramService from "@/services/business/ProgramService"
import Datepicker from "vuejs-datepicker"
import { en, es, fr, ko } from "vuejs-datepicker/dist/locale"
import NumberService from "../../../services/technical/NumberService"
import VehicleSearchService from "../../../services/business/VehicleSearchService"
import i18n from "@/i18n"

export default {
  name: "ModalVehicleSearch",
  components: {
    ModalBootstrap,
    Datepicker,
  },
  props: {
    programProductId: {
      type: [Number, String],
      required: true,
    },
    productLineId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      currentPage: 1,
      filters: {
        vehicle_type: "",
        make: [],
        model: [],
        trim_level: [],
        year: [],
        power: [],
        cylinders: [],
        engine_type: [],
      },
      defaultFilters: {},
      areVehiclesUpdating: false,
      isResetButtonDisabled: false,
      form: {
        vehicleVariations: {},
        dynamicInputs: {
          firstReleaseDate: null,
          km: null,
          retailPrice: null,
        },
      },
      dynamicInputsErrors: { km: "", retailPrice: "" },
      selectedVehicle: null,
      languages: { en, es, fr, ko },
    }
  },
  computed: {
    ...mapGetters("program", ["getCurrentProgramVehicleTagLabel", "getProgram"]),
    ...mapGetters("searchVehicleModal", [
      "getFiltersData",
      "getIsFiltersDisabled",
      "getVehicleTypesSelectableValues",
      "getMakeSelectableValues",
      "getModelSelectableValues",
      "getTrimLevelSelectableValues",
      "getYearSelectableValues",
      "getPowerSelectableValues",
      "getCylindersSelectableValues",
      "getEngineTypeSelectableValues",
      "listVehicles",
      "hasPagination",
      "hasAThirdPagePagination",
      "listVehicleDataWithVariations",
      "getVehicleDatumVariations",
      "hasVariations",
      "getFilterRank",
      "areOneVehicleInfosShown",
      "areVehicleInfosShown",
      "getIsFilterDisabled",
    ]),
    // TODO: current implementation breaks OfferPricing in shared space !
    ...mapGetters("programProductCustomization", ["getEligibilityCriteriaValues"]),
    ...mapGetters("config", ["getCurrentLang"]),
    language() {
      return this.languages[this.getCurrentLang]
    },
    isMondayFirst() {
      return this.getCurrentLang === "fr" || this.getCurrentLang === "es"
    },
    isValidateButtonDisabled() {
      return !this.areAllVariationsFilled || !this.areAllDynamicInputsFilled
    },
    areAllVariationsFilled() {
      return this.listVehicleDataWithVariations.every(
        (vehicleDatumWithVariations) =>
          this.form.vehicleVariations.hasOwnProperty(vehicleDatumWithVariations) &&
          this.form.vehicleVariations[vehicleDatumWithVariations] !== ""
      )
    },
    areAllDynamicInputsFilled() {
      return Object.values(this.form.dynamicInputs).every(
        (value) => value !== null && value !== ""
      )
    },
    oldestEligibleDate() {
      const oldestEligibleDate = VehicleSearchService.getOldestEligibleDate(
        this.getEligibilityCriteriaValues(this.productLineId).max_age,
        this.getEligibilityCriteriaValues(this.productLineId).max_age_unity
      )
      return new Date(oldestEligibleDate)
    },
  },
  methods: {
    getEligibilityEmptyLabel(productLineId, filterName) {
      if (
        (filterName === "model" || filterName === "trim_level") &&
        this.getIsFilterDisabled(filterName)
      )
        return this.$t(`criteria.eligibility.disabled.${filterName}`)
      // TODO : use engine_code instead of engine_type (do it everywhere) !
      let eligibilityCriterion = filterName
      if (filterName === "engine_type") eligibilityCriterion = "engine_code"
      // end TODO
      return this.$t(`criteria.eligibility.empty.${eligibilityCriterion}`)
    },
    mustPricingStatusBeDisplayed(vehicle) {
      return vehicle.pricing_status.name !== "tariffable"
    },
    getPricingStatusClass(vehicle) {
      let pricingStatusClass = "badge badge-pill"
      if (vehicle.pricing_status.name === "not_eligible")
        return `${pricingStatusClass} badge-light-red-text`
      else if (vehicle.pricing_status.name === "not_tariffable")
        return `${pricingStatusClass} badge-light-orange-text`
      return `${pricingStatusClass} badge-light`
    },
    mustVehicleBeDisabled(vehicle) {
      const pricingStatusesDisablingVehicle = ["not_eligible", "not_tariffable"]
      return pricingStatusesDisablingVehicle.includes(vehicle.pricing_status.name)
    },
    mustHoverBeActive(vehicle) {
      const pricingStatusesPreventingHover = ["not_eligible", "not_tariffable"]
      return !pricingStatusesPreventingHover.includes(vehicle.pricing_status.name)
    },
    onValidate() {
      this.validateDynamicInputs()
      if (!this.areDynamicInputsInError()) {
        this.selectedVehicle = {
          ...this.selectedVehicle,
          ...this.form.vehicleVariations,
          ...this.getFormattedDynamicInputs(),
          name: `${this.selectedVehicle.make} ${this.selectedVehicle.model}`,
        }
        this.$emit("vehicle-selected", this.selectedVehicle)
        this.onClose()
      }
    },
    validateDynamicInputs() {
      const eligibilityCriteriaValues = this.getEligibilityCriteriaValues(
        this.productLineId
      )
      // No need to validate first release date as date selection is already restricted to eligible dates
      this.validateDynamicInput("km", eligibilityCriteriaValues.max_km)
      this.validateDynamicInput("retailPrice", eligibilityCriteriaValues.max_price)
    },
    validateDynamicInput(inputName, maxEligibleValue) {
      const inputValue = this.form.dynamicInputs[inputName]
      let errorMsg = ""
      if (!this.isDynamicInputValid(inputValue)) {
        errorMsg = this.$t(
          "vehicleChoice.dynamic_inputs_errors.not_a_valid_strictly_positive_number"
        )
      } else if (!this.isDynamicInputEligible(inputValue, maxEligibleValue)) {
        errorMsg = `${this.$t(
          "vehicleChoice.dynamic_inputs_errors.max_value_exceeded"
        )} ${maxEligibleValue}`
      }
      this.$set(this.dynamicInputsErrors, inputName, errorMsg)
    },
    isDynamicInputValid(inputValue) {
      return NumberService.isNumber(inputValue) && inputValue > -1
    },
    isDynamicInputEligible(inputValue, maxEligibleValue) {
      const isMaxEligibleValueDefined = maxEligibleValue !== null
      return (
        !isMaxEligibleValueDefined ||
        (isMaxEligibleValueDefined && parseInt(inputValue) <= maxEligibleValue)
      )
    },
    areDynamicInputsInError() {
      return Object.values(this.dynamicInputsErrors).some((value) => value !== "")
    },
    getFormattedDynamicInputs() {
      return {
        first_release_date: DateService.format(
          this.form.dynamicInputs.firstReleaseDate,
          "YYYY-MM-DD"
        ),
        km: parseInt(this.form.dynamicInputs.km),
        retail_price: parseInt(this.form.dynamicInputs.retailPrice),
      }
    },
    onClose() {
      this.$emit("close")
    },
    async onUpdateFilter(filterName) {
      this.areVehiclesUpdating = true
      this.isResetButtonDisabled = false
      this.currentPage = 1
      await this.$store.dispatch("searchVehicleModal/closeVehicleInfos")
      let filterValues = []
      if (filterName === "engine_type") {
        const selectableValues = this.getEngineTypeSelectableValues
        this.filters[filterName].forEach((value) => {
          if (value === i18n.t("vehicleChoice.hybrid")) {
            filterValues.push(
              ...Object.keys(selectableValues).filter((selectableValueKey) => {
                return VehicleSearchService.hybridTypes.includes(selectableValueKey)
              })
            )
          } else {
            filterValues.push(value)
          }
        })
      } else {
        filterValues = this.filters[filterName]
      }
      await this.$store.dispatch("searchVehicleModal/updateFilter", {
        filter: filterName,
        values: filterValues,
      })
      this.filters = this.getFiltersData
      await this.$store.dispatch(
        "searchVehicleModal/searchVehicles",
        this.productLineId
      )
      this.areVehiclesUpdating = false
    },
    async onUpdateVehicleTypes() {
      await this.$store.dispatch(
        "searchVehicleModal/updateVehicleTypesFilter",
        this.filters.vehicle_type
      )
      await this.$store.dispatch(
        "searchVehicleModal/removeFilterValuesIfNotInSelectableValues",
        { rank: this.getFilterRank("vehicle_type") }
      )
      await this.$store.dispatch(
        "searchVehicleModal/searchVehicles",
        this.productLineId
      )
    },
    async showVehicleInfos(vehicle) {
      if (!this.areVehicleInfosShown(vehicle.id)) {
        this.selectedVehicle = vehicle
        await this.$store.dispatch("searchVehicleModal/retrieveVehicleVariations", {
          uniqueVehicleId: vehicle.unique_id,
          productLineId: this.productLineId,
        })
        this.initDynamicInputs()
        this.$store.dispatch("searchVehicleModal/showVehicleInfos", vehicle.id)
      }
    },
    showAllVehicles() {
      this.$store.dispatch("searchVehicleModal/closeVehicleInfos")
      this.form.vehicleVariations = {}
      this.initDynamicInputs()
      this.selectedVehicle = null
    },
    async goToPage(pageNumber) {
      this.areVehiclesUpdating = true
      await this.$store.dispatch("searchVehicleModal/displaySpecificPageVehicles", {
        productLineId: this.productLineId,
        pageNumber: pageNumber,
      })
      this.currentPage = pageNumber
      this.areVehiclesUpdating = false
    },
    getFilteredOptions(criterion) {
      if (criterion === "make") {
        return this.getMakeSelectableValues
      }
      if (criterion === "model") {
        return this.getModelSelectableValues
      }
      if (criterion === "engine_type") {
        let isHybridIncluded = false
        let eligibleEngineCodes = this.listEligibleEngineCodes()
        const selectableValues = Object.entries(
          this.getEngineTypeSelectableValues
        ).reduce((acc, [engineCode, engineName]) => {
          if (eligibleEngineCodes.includes(engineCode))
            if (!VehicleSearchService.HYBRID_ENGINE_CODES.includes(engineCode)) {
              acc[engineCode] = engineName
            } else {
              isHybridIncluded = true
            }
          return acc
        }, {})
        if (isHybridIncluded) {
          selectableValues[i18n.t("vehicleChoice.hybrid")] =
            i18n.t("vehicleChoice.hybrid")
        }
        return selectableValues
      }
    },
    listEligibleEngineCodes() {
      const eligibleEngineCodesInEligibilityCriteria =
        this.getEligibilityCriteriaValues(this.productLineId).engine_code
      return eligibleEngineCodesInEligibilityCriteria.length > 0
        ? eligibleEngineCodesInEligibilityCriteria
        : ProgramService.listEngineCodesFromEngineTag(this.getProgram.engine_tag.label)
    },
    async resetFilters() {
      this.isResetButtonDisabled = true
      this.areVehiclesUpdating = true
      this.currentPage = 1
      await this.$store.dispatch(
        "searchVehicleModal/initFiltersAndSelectableValues",
        this.productLineId
      )
      await this.$store.dispatch("searchVehicleModal/closeVehicleInfos")
      await this.$store.dispatch(
        "searchVehicleModal/searchVehicles",
        this.productLineId
      )
      this.filters = this.getFiltersData
      this.areVehiclesUpdating = false
    },
    getTitledFilterName(filterName) {
      return filterName
        .split("_")
        .map((word) => word[0].toUpperCase() + word.substr(1))
        .join("")
    },
    getSelectedOptions(filterName) {
      const titledFilterName = this.getTitledFilterName(filterName)
      return eval(`this.get${titledFilterName}SelectableValues`)
    },
    initDynamicInputs() {
      this.form.dynamicInputs = {
        ...VehicleSearchService.getInitializedDynamicInputs(
          this.getCurrentProgramVehicleTagLabel,
          this.selectedVehicle
        ),
      }

      if (this.form.dynamicInputs.firstReleaseDate !== null) {
        // Adjust first release date :
        // => set it to the oldest eligible value if initialized value is not eligible
        // (can happen if default value used during initialization doesn't meet eligibility criterion)
        const isSelectedDateOlderThanOldestEligibleDate =
          this.form.dynamicInputs.firstReleaseDate.getTime() <
          this.oldestEligibleDate.getTime()
        if (isSelectedDateOlderThanOldestEligibleDate) {
          this.form.dynamicInputs.firstReleaseDate = new Date(
            this.oldestEligibleDate.getTime()
          )
        }
      }

      this.dynamicInputsErrors = { km: "", retailPrice: "" }
    },
  },
  async created() {
    this.isLoading = true
    this.isResetButtonDisabled = true
    await this.$store.dispatch(
      "searchVehicleModal/initFiltersAndSelectableValues",
      this.productLineId
    )
    await this.$store.dispatch("searchVehicleModal/closeVehicleInfos")
    await this.$store.dispatch("searchVehicleModal/searchVehicles", this.productLineId)
    this.filters = this.getFiltersData
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
.modal-vehicles {
  .body-search {
    display: grid;
    grid-template-columns: 22% 78%;
    .filters-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px 20px;
      background: $grey6;
      button {
        margin-top: 16px;
        padding: 8px 12px;
        min-width: auto;
        width: 100%;
        i {
          font-weight: 600;
          line-height: inherit;
        }
      }
      .title {
        font-family: $font-family-title;
        font-size: 26px;
        line-height: 31px;
      }
      label {
        text-transform: uppercase;
      }
    }
    .vehicles-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      padding: 10px 10px 16px 10px;
      .vehicles {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .vehicle {
        display: grid;
        grid-template-areas:
          "img infos"
          "img details"
          "additional-info additional-info";
        grid-template-columns: 18% auto;
        column-gap: 10px;
        padding: 10px;
        border: 1px solid $table-border-color;
        pointer-events: none;
        position: relative;
        &.hover-active {
          cursor: pointer;
          pointer-events: initial;
          &:hover {
            background: rgba($bleuc50, 0.5);
          }
        }
        .vehicle-disabled {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #fff;
          opacity: 0.6;
        }
        img {
          grid-area: img;
          justify-self: center;
          align-self: center;
          max-width: 120px;
          max-height: 64px;
        }
        .infos {
          grid-area: infos;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid $caareagray;
          .model {
            color: $bleuc700;
            font-size: 24px;
            letter-spacing: 0.15px;
            line-height: 28px;
          }
        }
        .details {
          grid-area: details;
          display: grid;
          grid-template-areas:
            "finition power"
            "year version";
          justify-content: space-between;
          gap: 4px;
          color: $medium-gray;
          font-size: 16px;
          letter-spacing: 0.15px;
          line-height: 19px;
          .finition {
            grid-area: finition;
          }
          .power {
            grid-area: power;
            text-align: end;
          }
          .year {
            grid-area: year;
          }
          .version {
            grid-area: version;
            text-align: end;
          }
        }
        .additional-info {
          grid-area: additional-info;
          background: $bleuc50;
          margin: 10px -5px -5px;
          padding: 16px 10px 24px;

          u {
            font-family: $font-family-title;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.15px;
          }

          .form-infos {
            .infos-selects {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 2rem;
            }
          }
          .dynamic-inputs {
            margin-top: 8px;

            .dynamic-inputs__items {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 2rem;

              .dynamic-inputs__item {
                text-align: center;
                min-width: 180px;
                label {
                  font-family: $font-family-title;
                  line-height: 14px;
                  letter-spacing: 0.15px;
                  text-transform: inherit;
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      gap: 8px;
      padding: 4px 16px;
      background: rgba($bleuc50, 0.3);
      font-family: "Effra";
      font-size: 13px;
      .label-page {
        letter-spacing: 0.25px;
      }
      .page {
        color: #9e9fbb;
        cursor: pointer;
        &:hover {
          color: inherit;
        }
        &.current {
          color: inherit;
          cursor: inherit;
        }
      }
    }
  }
  /deep/ {
    .v-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .vdp-datepicker__calendar .cell {
      &.selected {
        background: $primary;
        color: #fff;
      }
      &:hover {
        border-color: $primary;
      }
      &.day-header,
      &.blank {
        &:hover {
          border-color: transparent;
        }
      }
      &:not(.blank):not(.disabled) {
        &.day:hover,
        &.month:hover,
        &.year:hover {
          border-color: $primary;
        }
      }
    }
  }
}
</style>

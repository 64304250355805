import ProgramService from "../../../services/business/ProgramService"

export default {
  methods: {
    getTags(program) {
      const offerTags =
        program.offers.length !== 0
          ? program.offers.reduce((acc, offer) => {
              offer.program_products
                .map((programProduct) => programProduct.tags)
                .forEach((tag) => acc.push(tag))
              return acc
            }, [])
          : []
      return offerTags.length !== 0
        ? offerTags
            .map((tags) => tags.map((tag) => tag.label))
            .reduce((tagLabelA, tagLabelB) =>
              tagLabelA.filter((labelA) => tagLabelB.includes(labelA))
            )
        : []
    },
    getProgramTypeToDisplay(program) {
      let programType = ProgramService.getProgramType(this.getTags(program))
      return programType
        ? this.$t("programs.type_to_display." + programType)
        : this.$t("programs.type_to_display.baseProgram")
    },
  },
}

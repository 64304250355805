<template>
  <div>
    <spinner v-if="isEmailsSendingInProgress" :no-message="true"></spinner>
    <div v-else class="publishing">
      <ModalConfirmation
        v-if="showConfirmationModal"
        :title="$t('programParameters.publishing.modal.title')"
        :message="$t('programParameters.publishing.modal.message')"
        @close="onCloseConfirmationModal"
        @confirm="onConfirmButtonClick"
        modal-size="large"
      ></ModalConfirmation>
      <div
        :class="[
          'publishing__option-1',
          isSelected('IMMEDIATE') ? 'selected' : 'unselected',
        ]"
        data-cy="publishing-option-1"
      >
        <label
          class="wrapper-radio-button font-effra-medium font-size-22"
          data-cy="immediate-option"
        >
          <input
            id="immediate"
            name="publishing-type"
            type="radio"
            value="IMMEDIATE"
            v-model="selectedOption"
            data-cy="publishing-type-immediate-radio"
          />
          <span class="checkmark"></span>
          {{ $t("programParameters.publishing.options.immediate").toUpperCase() }}
        </label>
        <p data-cy="immediate-option-desc" class="publishing__option-1__desc">
          {{ $t("programParameters.publishing.options.immediate_desc") }}
        </p>
      </div>

      <div
        :class="[
          'publishing__option-2',
          isSelected('SCHEDULED') ? 'selected' : 'unselected',
        ]"
        data-cy="publishing-option-2"
      >
        <div class="publishing__option-2__selection">
          <label
            class="wrapper-radio-button font-effra-medium font-size-22"
            data-cy="scheduled-option"
          >
            <input
              id="scheduled"
              name="publishing-type"
              type="radio"
              value="SCHEDULED"
              v-model="selectedOption"
              data-cy="publishing-type-scheduled-radio"
            />
            <span class="checkmark"></span>
            {{ $t("programParameters.publishing.options.scheduled").toUpperCase() }}
          </label>
          <datepicker
            id="scheduled-date"
            class="publishing__option-2__date-picker"
            v-model="scheduledDate"
            data-cy="scheduled-date"
            :input-class="[
              'form-control',
              'date-picker-input',
              isSelected('SCHEDULED')
                ? 'date-picker-selected'
                : 'date-picker-unselected',
            ]"
            calendar-class="date-picker-calendar"
            :language="language"
            :monday-first="isMondayFirst"
            :disabled-dates="{ to: new Date() }"
          ></datepicker>
        </div>
        <p data-cy="scheduled-option-desc" class="publishing__option-2__desc">
          {{ $t("programParameters.publishing.options.scheduled_desc") }}
        </p>
      </div>
      <div class="publishing__button">
        <button
          class="btn btn-primary"
          data-cy="publish-button"
          @click="onPublishButtonClick()"
          data-toggle="modal"
          data-target="#modalConfirmation"
        >
          {{ $t("button.publish") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import { en, es, fr, ko } from "vuejs-datepicker/dist/locale"
import DateService from "@/services/technical/DateService"
import { mapGetters } from "vuex"
import ModalConfirmation from "../../components/modal/ModalConfirmation"

export default {
  name: "ProgramPublishing",
  components: { Datepicker, ModalConfirmation },
  data() {
    return {
      selectedOption: "IMMEDIATE",
      scheduledDate: null,
      showConfirmationModal: false,
      isEmailsSendingInProgress: false,
      languages: { en, es, fr, ko },
    }
  },
  computed: {
    ...mapGetters("config", ["getCurrentLang"]),
    ...mapGetters("program", [
      "getSelectedNetworkId",
      "getProgramPartnerId",
      "getCurrentProgramId",
    ]),
    language() {
      return this.languages[this.getCurrentLang]
    },
    isMondayFirst() {
      return this.getCurrentLang === "fr" || this.getCurrentLang === "es"
    },
  },
  methods: {
    isSelected(option) {
      return option === this.selectedOption
    },
    onPublishButtonClick() {
      this.showConfirmationModal = true
    },
    onCloseConfirmationModal() {
      this.showConfirmationModal = false
    },
    async onConfirmButtonClick() {
      this.showConfirmationModal = false

      if (this.selectedOption === "IMMEDIATE") {
        this.isEmailsSendingInProgress = true
        await this.$store.dispatch("network/inviteMembers", {
          networkId: this.getSelectedNetworkId,
          data: { program_id: this.getCurrentProgramId },
        })
        await this.$store.dispatch(
          "network/resetMembersEmail",
          this.getSelectedNetworkId
        )
        this.isEmailsSendingInProgress = false
      } else {
        // TODO: send reset email just after invitations
        await this.$store.dispatch("network/inviteMembers", {
          networkId: this.getSelectedNetworkId,
          data: {
            send_on: DateService.format(this.scheduledDate, "YYYY-MM-DD"),
            program_id: this.getCurrentProgramId,
          },
        })
      }

      this.$emit("publishing-done", this.getSelectedNetworkId, this.selectedOption)
    },
  },
  async created() {
    this.scheduledDate = DateService.format(new Date(), "YYYY-MM-DD")
    await this.$store.dispatch("network/getNetworks", this.getProgramPartnerId)
  },
}
</script>

<style scoped lang="scss">
.publishing {
  // All the settings done for positioning the radio buttons etc... are done with this height's value of 350px
  // Changing this height's value might break the display
  height: 350px;
  // !important due to my friend Bootstrap and its col-12 !
  // padding top and bottom to 29px as parent uses pt-3 and pb-3 !
  padding: 29px 35px !important;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "option-1"
    "option-2"
    "button";

  label {
    margin-bottom: 0;
  }
}

.publishing__option-1 {
  grid-area: option-1;
}

.publishing__option-1__desc {
  padding-left: 35px;
  padding-top: 0.2rem;
}

// Customize the wrapper of the radio button
.wrapper-radio-button {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Hide the browser's default radio button
.wrapper-radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

// Create a custom radio button
.checkmark {
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  // 24px
  height: 1.5rem;
  // 24px
  width: 1.5rem;
  border-radius: 50%;
  background-color: white;
  border: 1px solid $bleucaarea;
}

// On mouse-over, add a grey background color
.wrapper-radio-button:hover input ~ .checkmark {
  background-color: $caarealightgray;
}

// Create the indicator (the dot/circle - hidden when not checked)
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

// Show the indicator (dot/circle) when checked
.wrapper-radio-button input:checked ~ .checkmark:after {
  display: block;
}

// Style the indicator (dot/circle)
.wrapper-radio-button .checkmark:after {
  top: 0.2rem;
  left: 0.2rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: $bleucaarea;
}

.publishing__option-2 {
  grid-area: option-2;
}

.publishing__option-2__selection {
  display: flex;
  flex-direction: row;

  label {
    align-self: center;
  }
}

.publishing__option-2__date-picker {
  padding-left: 5rem;
  //flex-basis: 476px;
}

.publishing__option-2__desc {
  padding-left: 35px;
}

.publishing__button {
  grid-area: button;
  justify-self: end;
  align-self: end;
}

.selected {
  color: $bleucaarea;
}

.unselected {
  color: $bleuc100;
}

/deep/ {
  .v-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .vdp-datepicker__calendar .cell {
    &.selected {
      background: $primary;
      color: #fff;
    }
    &:hover {
      border-color: $primary;
    }
    &.day-header,
    &.blank {
      &:hover {
        border-color: transparent;
      }
    }
    &:not(.blank):not(.disabled) {
      &.day:hover,
      &.month:hover,
      &.year:hover {
        border-color: $primary;
      }
    }
  }
}
</style>

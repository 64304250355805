<template>
  <div :class="divClass">
    <div>
      <button
        type="button"
        :class="buttonClass"
        :style="btnStyle"
        data-cy="button-choose-photo"
        @click="onButtonChoosePhoto"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index"
import { mapGetters } from "vuex"
export default {
  name: "CloudinaryUploadWidget",
  props: {
    // Expected : btn-primary or btn-secondary or btn-tertiary etc...
    btnClass: { type: String, default: "btn-secondary" },
    // To add something like "width: 100%"
    btnStyle: { type: String, default: "" },
    buttonText: { type: String, default: i18n.t("button.choosePhoto") },
    divClass: { type: String, default: "" },
    uploadPreset: { required: true },
  },
  data() {
    return {
      cloudinaryWidget: null,
      languages: ["en", "es", "fr", "ko"],
    }
  },
  computed: {
    ...mapGetters("config", ["getCurrentLang"]),
    ...mapGetters("cloudinary", ["getSignature"]),
    buttonClass() {
      return `btn ${this.btnClass} font-roboto-medium`
    },
  },
  methods: {
    onButtonChoosePhoto() {
      if (this.cloudinaryWidget) {
        this.cloudinaryWidget.open()
      }
    },
    async generateUploadSignature(callback, paramsToSign) {
      await this.$store.dispatch("cloudinary/generateCloudinarySignature", paramsToSign)
      callback(this.getSignature)
    },
  },
  created() {
    this.cloudinaryWidget = cloudinary.createUploadWidget(
      {
        cloudName: "caarea",
        uploadPreset: `${this.uploadPreset}`,
        apiKey: `${process.env.VUE_APP_CLOUDINARY_API_KEY}`,
        uploadSignature: this.generateUploadSignature,
        sources: ["local", "url"],
        multiple: false,
        language: `${this.getCurrentLang}`,
        text: this.languages.includes(this.getCurrentLang) ? i18n.t("cloudinary") : "",
      },
      (error, result) => {
        if (error) {
          console.error(error)
        } else if (result.event === "success") {
          this.$emit("image-uploaded", result.info)
        }
      }
    )
  },
}
</script>

<style scoped></style>
